import PrintOptionTab from '@libs/customElement/PrintOptionTab';

import T_FormPrintOptions from '../../../tpl/blocks/billFormPrintOption.html';

class BillPrintOption extends PrintOptionTab {
	public static readonly tagName: string = 'ap-bill-print-options-tab';

	protected readonly KEY_SETTINGS = 'BILL.OPTIONS';

	protected eventChangeForm = 'options.print.**.change';

	constructor() {
		super();
		this.table = 'bills';
	}

	protected getFormTemplate() {
		return T_FormPrintOptions;
	}

	protected setDefaultValue(value: { [key: string]: any }) {
		this.data = { options: value };
	}

	public static register() {
		customElements.define(this.tagName, BillPrintOption);
	}
}

export default BillPrintOption;
