// CORE
import { Alert, LoggedUser, Router, global, utils } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
// LIBS
import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';
import Notifications from '@modules/Apps/js/libs/Notifications';
import OpenDocuments from '@libs/customElement/OpenDocuments';

// UTILS
import Utils from '@libs/utils/Utils';

// MODAL
import M_AdditionalInformation from '../modals/deliveries/editPage/AdditionalInformation';
import M_GeneralInformation from '../modals/deliveries/editPage/GeneralInformation';
import M_Target from '../modals/deliveries/editPage/Target';
import M_Transporter from '../modals/deliveries/editPage/SelectTransporter';

// CUSTOM_ELEMENT
import CE_ContentDeliveriesTab from '../libs/customElement/ContentDeliveriesTab';
import CE_PrintOptionTab from '../libs/customElement/PrintOptionDeliveries';

// SERVICE
import S_C_Address from '@services/Customer/CustomerAddressService';
import S_Devilery from '@services/DeliveryService';
import S_P_Address from '@services/Provider/ProviderAddressService';
import S_Site from '@services/Site/SiteService';

// TYPES
import Company from '@js/types/company/company';

class DeliveryCtrl extends ControllerPageID {
	private N_PrintOptionTab: CE_PrintOptionTab | null = null;
	private N_ContentDeliveriesTab: CE_ContentDeliveriesTab | null = null;

	private company: Company | undefined;

	private createFrom = '';

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.options = DeliveryCtrl.options || {};

		DeliveryCtrl.options = {};

		this.routeReturn = 'module/apps/deliveries';

		this.init('deliveries', id);
	}

	private static options: { [key: string]: any } = {};
	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		DeliveryCtrl.options = options || {};

		if (id) {
			await OpenDocuments.checkOpen(id, 'deliveries');
			Router.getInstance().navigate(`/module/apps/delivery?id=${id}`);
		} else {
			Router.getInstance().navigate('/module/apps/delivery');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		this.initTabs();
		this.initActionsDropdown();

		if (this.options.from === 'order') {
			this.createFrom = 'order';
		}

		const data = await this.getData();

		this.company = data.data.company;

		this.setData(data);

		this.postInit();

		this.initFullscreen();
		this.initMobile();
		this.initEditButton();

		this.updateDisplayActionsDropdown();
		this.updateEditButton();

		Utils.cancelEditingAggrid(this.N_ContentDeliveriesTab!.gridOptions, this.abortSignal);

		if (!this.id) {
			this.openTargetModal(true);
		}

		const N_closeNotification = this.el.querySelector('#close_notification') as HTMLButtonElement;

		if (this.options.notifications) {
			(N_closeNotification.parentNode as HTMLElement).classList.remove('d-none');
		}
		N_closeNotification?.addEventListener('click', async () => {
			await Notifications.closeNotification(this.options.notification);
			this.return();
		});
	}

	private openTargetModal(firstOpen = false) {
		const N_edit_Target = this.el.querySelector('[data-edit="target"]') as HTMLButtonElement;

		const res = {
			infos: {
				customer: this.form?.getDataByName('infos.customer') as string,
				contactIDCustomer: this.form?.getDataByName('infos.contactIDCustomer') as string,
				order: this.form?.getDataByName('infos.order') as string,
				orderNumber: this.form?.getDataByName('infos.orderNumber') as string,
				provider: this.form?.getDataByName('infos.provider') as string,
				contactIDProvider: this.form?.getDataByName('infos.contactIDProvider') as string,
				other: this.form?.getDataByName('infos.other') as string,
				contact: this.form?.getDataByName('infos.contact') as string,
				businessName: this.form?.getDataByName('infos.businessName') as string,
				user: this.form?.getDataByName('infos.user') as string,
				email: this.form?.getDataByName('infos.email') as string
			},
			type: this.form?.getDataByName('type') as string
		};

		new M_Target(res, this.createFrom).open().then((data) => {
			N_edit_Target.loading(new Promise<void>(async (resolve) => {
				this.setDataForm(data);

				if (res.type !== data.type || (data.type === 'customer' && res.infos.customer !== data.infos.customer.id) || (data.type === 'provider' && res.infos.provider !== data.infos.provider.id)) {
					await this.updateAddress();
				}

				if (data.type === 'customer' && data.infos.order) {
					const tmp = await this.checkDeliveryOrder(data.infos.order.id);
					if (!tmp) {
						return;
					}
				}

				this.updateSaveButton();
				resolve();
			}));
		}).catch(() => {
			if (firstOpen) {
				this.return();
			}
		});
	}

	private initEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_Target = this.el.querySelector('[data-edit="target"]') as HTMLButtonElement;
		const N_edit_Transporter = this.el.querySelector('[data-edit="transporter"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			const res = {
				infos: {
					deliveryDate: this.form?.getDataByName('infos.deliveryDate') as Moment,
					businessName: this.form?.getDataByName('infos.businessName') as string,
					number: this.form?.getDataByName('infos.number') as string
				}
			};

			new M_GeneralInformation(res).open().then((data) => {
				this.setDataForm(data);
				this.updateSaveButton();
			});
		});

		N_edit_Target.addEventListener('click', () => {
			this.openTargetModal();
		});

		N_edit_Transporter.addEventListener('click', () => {
			const res = {
				infos: {
					transporter: this.form?.getDataByName('infos.transporter') as string,
					trackingNumber: this.form?.getDataByName('infos.trackingNumber') as string,
					commentsTransporter: this.form?.getDataByName('infos.commentsTransporter') as string
				}
			};

			new M_Transporter(res).open().then((data) => {
				this.setDataForm(data);
				this.updateSaveButton();
			});
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			const res = {
				infos: {
					customer: this.form?.getDataByName('infos.customer') as string,
					provider: this.form?.getDataByName('infos.provider') as string,
					typeAddressCustomer: this.form?.getDataByName('infos.typeAddressCustomer') as string,
					siteCustomer: this.form?.getDataByName('infos.siteCustomer') as string,
					addressIDCustomer: this.form?.getDataByName('infos.addressIDCustomer') as string,
					addressIDProvider: this.form?.getDataByName('infos.addressIDProvider') as string,
					fullAddress: this.form?.getDataByName('infos.fullAddress') as string,
					GPSCoordinates: this.form?.getDataByName('infos.GPSCoordinates') as string,
					comments: this.form?.getDataByName('infos.comments') as string,
					transporter: this.form?.getDataByName('infos.transporter') as string,
					user: this.form?.getDataByName('infos.user') as string,
					email: this.form?.getDataByName('infos.email') as string
				},
				type: this.form?.getDataByName('type') as string,
				address: {
					internal: {
						id: this.form?.getDataByName('address.internal.id') as string,
						fullAddress: this.form?.getDataByName('address.internal.fullAddress') as string
					}
				},
				company: this.company as Company
			};

			new M_AdditionalInformation(res).open().then((data) => {
				this.setDataForm(data);
				this.updateSaveButton();
			});
		});
	}

	private initActionsDropdown() {
		const N_state_0 = this.el.querySelector('#state_0') as HTMLButtonElement;
		const N_state_1 = this.el.querySelector('#state_1') as HTMLButtonElement;
		const N_state_2 = this.el.querySelector('#state_2') as HTMLButtonElement;

		N_state_0.addEventListener('click', () => {
			this.setDataForm({
				state: '0'
			});

			this.N_ContentDeliveriesTab!.state = '0';
			this.updateEditButton();
			this.updateDisplayActionsDropdown();
			this.updateSaveButton();
		});

		N_state_1.addEventListener('click', () => {
			this.setDataForm({
				state: '1'
			});

			this.N_ContentDeliveriesTab!.state = '1';
			this.updateEditButton();
			this.updateDisplayActionsDropdown();
			this.updateSaveButton();
		});

		N_state_2.addEventListener('click', async () => {
			const transporterData = {
				infos: {
					transporter: this.form?.getDataByName('infos.transporter') as string,
					trackingNumber: this.form?.getDataByName('infos.trackingNumber') as string,
					commentsTransporter: this.form?.getDataByName('infos.commentsTransporter') as string
				}
			};
			new M_Transporter(transporterData).open().then(async (data) => {
				data.state = '2';
				this.setDataForm(data);
				this.N_ContentDeliveriesTab!.state = '2';
				this.updateDisplayActionsDropdown();
				this.updateSaveButton();
			});
		});
	}

	private async checkDeliveryOrder(order: string) {
		const listDeliveries = await (this.service as S_Devilery).getByCustomerOrder(order);

		for (const delivery of listDeliveries) {
			if (delivery.state === '0' && delivery._id !== this.id) {
				this.resetSaveButton();
				try {
					await Alert.confirm('Bon livraison trouvé', `Le BL n° ${delivery.infos.number} est en cours de prépration. <br> Voulez-vous l'ouvrir ?`, {
						noColor: 'close-modal',
						yesColor: 'validate-modal'
					});
					DeliveryCtrl.open(delivery._id, this.options);
				} catch (e) {
					this.openTargetModal(true);
				}
				return false;
			}
		}

		return true;
	}

	private initTabs() {
		this.initMaterials();
		if (!global.IS_MOBILE) {
			this.initPreviewPrint();
		}
	}

	private initMaterials() {
		this.N_ContentDeliveriesTab = this.el.querySelector(CE_ContentDeliveriesTab.tagName);

		this.N_ContentDeliveriesTab?.setParentElement(this.el);

		this.N_ContentDeliveriesTab?.setGetData(() => {
			return this.saveData;
		});

		this.N_ContentDeliveriesTab?.setOnUpdate(() => {
			this.updateSaveButton();
		});
	}

	private initPreviewPrint() {
		this.N_PrintOptionTab = this.el.querySelector(CE_PrintOptionTab.tagName);

		this.N_PrintOptionTab!.setParentElement(this.el);

		this.N_PrintOptionTab!.setID(() => {
			return this.id;
		});

		this.N_PrintOptionTab!.setGetData(() => {
			return this.saveData;
		});

		this.N_PrintOptionTab?.setOnUpdate(() => {
			this.updateSaveButton();
		});
	}

	private get isLock() {
		const state = this.form?.getDataByName('state') as string;
		return state !== '0' || !LoggedUser.getInstance().hasPermission('DELIVERIES.EDIT');
	}

	protected convertData(data: { [key: string]: any }) {
		if (data.state) {
			const state: { [key: string]: string } = {
				0: 'En cours de préparation',
				1: 'Prêt à expéditer',
				2: 'Expédié'
			};

			data.state = {
				id: data.state,
				text: state[data.state]
			};
		}

		return data;
	}

	protected setDataForm(data: { [key: string]: any }) {
		super.setDataForm(data);

		this.updateType();

		this.N_ContentDeliveriesTab!.updateButtonFillProduct();
	}

	protected setData(data: DataServer) {
		super.setData(data);

		this.N_ContentDeliveriesTab!.data = data.data.products || [];
		this.N_ContentDeliveriesTab!.state = data.data.state;

		if (!global.IS_MOBILE) {
			this.N_PrintOptionTab!.data = data.data;
		}
	}

	protected postInit(): void {
		super.postInit();

		this.N_ContentDeliveriesTab!.postInit();

		if (!global.IS_MOBILE) {
			this.N_PrintOptionTab!.postInit();
		}
	}

	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		newData.state = newData.state || '0';

		if (newData.infos.deliveryDate) {
			newData.infos.deliveryDate = newData.infos.deliveryDate.format('YYYY-MM-DD');
		}

		newData.products = this.N_ContentDeliveriesTab!.data;
		newData.options = this.N_PrintOptionTab!.data.options;

		return newData;
	}

	private updateEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_target = this.el.querySelector('[data-edit="target"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.disabled = this.isLock;
		N_edit_target.disabled = this.isLock;
		N_edit_AdditionalInformation.disabled = this.isLock;

		this.N_ContentDeliveriesTab!.isLock = this.isLock;
	}

	private async updateAddress() {
		const type = this.form?.getDataByName('type');

		this.form?.setDataByName('infos.fullAddress', '');
		this.form?.setDataByName('infos.GPSCoordinates', '');

		const customer = this.form?.getDataByName('infos.customer') as string;
		const provider = this.form?.getDataByName('infos.provider') as string;

		if (type === 'customer' && customer) {
			this.form?.setDataByName('infos.siteCustomer', '');
			this.form?.setDataByName('infos.addressIDCustomer', '');
			this.form?.setDataByName('infos.fullAddress', '');
			this.form?.setDataByName('infos.GPSCoordinates', '');

			let typeAddressCustomer = this.form?.getDataByName('infos.typeAddressCustomer') as string;
			typeAddressCustomer = typeAddressCustomer || 'customer';

			const customerAddressList = await S_C_Address.getInstance().getDeliveryAddressByCustomer(customer) || [];

			if (customerAddressList[0]) {
				const id = customerAddressList[0]._id as string;

				const addressInfos = await S_C_Address.getInstance().getAddressInfos(id);
				const addressIDCustomer = await S_C_Address.getInstance().getDataToSelect2ByID(id);

				if (typeAddressCustomer === 'customer') {
					this.form?.setDataByName('infos.addressIDCustomer', addressIDCustomer);
					this.form?.setDataByName('infos.fullAddress', addressInfos.fullAddress);
					this.form?.setDataByName('infos.GPSCoordinates', addressInfos.GPSCoordinates);
				}
			}

			const sites = await S_Site.getInstance().getAll() || [];

			const customerSites = sites.filter((site: any) => site.customer === customer);

			if (customerSites[0]) {
				const siteCustomer = await S_Site.getInstance().getDataToSelect2ByID(customerSites[0]._id);
				const GPSCoordinates = customerSites[0].latitude && customerSites[0].longitude ? customerSites[0].latitude + ', ' + customerSites[0].longitude : '';

				if (typeAddressCustomer === 'site') {
					this.form?.setDataByName('infos.siteCustomer', siteCustomer);
					this.form?.setDataByName('infos.fullAddress', customerSites[0].address);
					this.form?.setDataByName('infos.GPSCoordinates', GPSCoordinates);
				}
			}
		}

		if (type === 'provider' && provider) {
			const providerAddressList = await S_P_Address.getInstance().getDeliveryAddressByProvider(provider);

			if (providerAddressList[0]) {
				const id = providerAddressList[0]._id as string;

				const fullAddress = await S_P_Address.getInstance().getFullAddress(id);
				const addressIDProvider = await S_P_Address.getInstance().getDataToSelect2ByID(id);

				this.form?.setDataByName('infos.addressIDProvider', addressIDProvider);
				this.form?.setDataByName('infos.fullAddress', fullAddress);
				this.form?.setDataByName('infos.GPSCoordinates', '');
			} else {
				this.form?.setDataByName('infos.addressIDProvider', '');
				this.form?.setDataByName('infos.fullAddress', '');
				this.form?.setDataByName('infos.GPSCoordinates', '');
			}
		}
	}

	private updateType() {
		const type = this.form?.getDataByName('type');

		const N_customer = this.el.querySelectorAll('[data-type="customer"]') as NodeListOf<HTMLElement>;
		const N_provider = this.el.querySelectorAll('[data-type="provider"]') as NodeListOf<HTMLElement>;
		const N_other = this.el.querySelectorAll('[data-type="other"]') as NodeListOf<HTMLElement>;

		for (const N_el of N_customer) {
			N_el.classList.add('d-none');
		}
		for (const N_el of N_provider) {
			N_el.classList.add('d-none');
		}
		for (const N_el of N_other) {
			N_el.classList.add('d-none');
		}

		if (type === 'customer') {
			for (const N_el of N_customer) {
				N_el.classList.remove('d-none');
			}
			this.updateTypeAddressCustomer();
		}

		if (type === 'provider') {
			for (const N_el of N_provider) {
				N_el.classList.remove('d-none');
			}
		}

		if (type === 'other') {
			for (const N_el of N_other) {
				N_el.classList.remove('d-none');
			}
		}
	}

	private updateDisplayActionsDropdown() {
		const N_state_0 = this.el.querySelector('#state_0') as HTMLButtonElement;
		const N_state_1 = this.el.querySelector('#state_1') as HTMLButtonElement;
		const N_state_2 = this.el.querySelector('#state_2') as HTMLButtonElement;

		N_state_0.classList.add('d-none');
		N_state_1.classList.add('d-none');
		N_state_2.classList.add('d-none');

		const state = this.form?.getDataByName('state');
		if (state === '0') {
			N_state_1.classList.remove('d-none');
		}

		if (state === '1') {
			N_state_0.classList.remove('d-none');
			N_state_2.classList.remove('d-none');
		}

		if (state === '2') {
			N_state_1.classList.remove('d-none');
		}
	}

	private updateTypeAddressCustomer() {
		let type = this.form?.getDataByName('infos.typeAddressCustomer') as string;
		type = type || 'customer';

		const N_addressTypeCustomer = this.el.querySelectorAll('[address-type="customer"]') as NodeListOf<HTMLElement>;
		const N_addressTypeSite = this.el.querySelectorAll('[address-type="site"]') as NodeListOf<HTMLElement>;

		for (const N_el of N_addressTypeCustomer) {
			N_el.classList.add('d-none');
		}
		for (const N_el of N_addressTypeSite) {
			N_el.classList.add('d-none');
		}

		if (type === 'customer') {
			for (const N_el of N_addressTypeCustomer) {
				N_el.classList.remove('d-none');
			}
		}

		if (type === 'site') {
			for (const N_el of N_addressTypeSite) {
				N_el.classList.remove('d-none');
			}
		}
	}
}

export default DeliveryCtrl;
