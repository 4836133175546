import { GridOptions } from '@ag-grid-enterprise/all-modules';

import _ from 'lodash';

class SearchBar extends HTMLElement {
	public static readonly tagName: string = 'ap-search-bar';
	protected _gridOptions: GridOptions = {};

	public async connectedCallback() {
		this.innerHTML = `
			<div id="search-bar">
				<i class="icon icon-search"></i>
				<input id="search-input" type="search" placeholder="Rechercher ..."/>
			</div>
        `;
	}

	public setGridOptions(gridOptions: GridOptions) {
		this._gridOptions = gridOptions;

		this.initEvents();
	}

	private initEvents() {
		const N_search = this.querySelector('#search-input') as HTMLInputElement;
		N_search.addEventListener('input', _.debounce(() => {
			this._gridOptions.api?.setQuickFilter(N_search.value);
		}, 500));
	}

	public static register() {
		customElements.define(SearchBar.tagName, SearchBar);
	}
}

export default SearchBar;
