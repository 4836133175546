// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../../../tpl/modals/customers/editPage/dates.html';

// LIBS
import DeadlinePaymentUtils from '@libs/utils/DeadlinePaymentUtils';
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE

type DatesData = {
	infos: {
		date: Moment,
		datePayment: Moment,
		sendDate: Moment,
		number: string,
		deadlinePayment: string,
		typeDeadlinePayment: string,
		fixedDateDeadline: Moment

	},
	relunch: {
		date: Moment
	}
};

class Dates extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null;

	private oldData: { [key: string]: any } = {};

	constructor(data: DatesData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);
			this.oldData = data;

			await this.setData(data);

			this.initEvents();
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const res = _.merge(this.oldData, this.form?.getData());
					this.resolve(res);
				}));
			});

			this.updateButtonSave();
		});
	}

	private initEvents() {
		const N_date = this.element.querySelector('[name="infos.date"]') as HTMLInputElement;
		const N_datePayment = this.element.querySelector('[name="infos.datePayment"]') as HTMLInputElement;
		const N_sendDate = this.element.querySelector('[name="infos.sendDate"]') as HTMLInputElement;

		N_date.addEventListener('change', async () => {
			const date = this.form?.getDataByName('infos.date') as Moment;

			const number = (this.oldData.infos.number || '').replace('FC-').split('-');

			if (date) {
				const paidDate = DeadlinePaymentUtils.calculateDate(date, this.oldData.infos.deadlinePayment, this.oldData.infos.fixedDateDeadline, this.oldData.infos.typeDeadlinePayment);

				const tmp: { [key: string]: any } = {
					infos: {
						number: `FC-${date.format('YYYY-MM')}-${number.slice(2).join('-')}`
					}
				};

				if (this.oldData.infos.typeDeadlinePayment !== '3') {
					tmp.infos.datePayment = paidDate.clone();
					tmp.relunch = {
						date: paidDate.clone().add(1, 'week')
					};
				}

				this.oldData = _.merge(this.oldData, tmp);

				this.form?.setData(tmp);
			}

			this.updateButtonSave();
		});

		N_datePayment.addEventListener('change', () => {
			const datePayment = this.form?.getDataByName('infos.datePayment') as Moment;

			this.oldData.relunch.date = datePayment.clone().add(1, 'week');

			this.updateButtonSave();
		});

		N_sendDate.addEventListener('change', async () => {
			const sendDate = this.form?.getDataByName('infos.sendDate') as Moment;

			if (sendDate && this.oldData.infos.typeDeadlinePayment === '3') {
				const paidDate = DeadlinePaymentUtils.calculateDate(sendDate, this.oldData.infos.deadlinePayment, this.oldData.infos.fixedDateDeadline, this.oldData.infos.typeDeadlinePayment);

				const tmp: { [key: string]: any } = {
					infos: {
						datePayment: paidDate.clone()
					},
					relunch: {
						date: paidDate.clone().add(1, 'week')
					}
				};

				this.oldData = _.merge(this.oldData, tmp);

				this.form?.setData(tmp);
			}

			this.updateButtonSave();
		});
	}

	private async setData(data: { [key: string]: any }) {
		const res: { [key: string]: any } = {
			infos: {
				date: data.infos.date,
				datePayment: data.infos.datePayment,
				sendDate: data.infos.sendDate,
				number: data.infos.number,
				deadlinePayment: data.infos.deadlinePayment,
				typeDeadlinePayment: data.infos.typeDeadlinePayment,
				fixedDateDeadline: data.infos.fixedDateDeadline

			},
			relunch: {
				date: data.relunch.date
			}
		};

		this.form?.setData(res);
	}

	private updateButtonSave() {
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		const date = this.form?.getDataByName('infos.date') as Moment;
		const datePayment = this.form?.getDataByName('infos.datePayment') as Moment;

		N_save.disabled = !date || !datePayment;
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default Dates;
