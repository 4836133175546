// CORE
import { Form, toaster } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
import T_Modal from '@tpl/modals/SetSendDate.html';

// LIBS
import Modal from '@libs/Modal';

import ServiceManager from '@managers/ServiceManager';

// PRINTER
// UTILS
// MODAL
import M_PrintPreview from '@libs/modals/PrintPreview';

// CUSTOM_ELEMENT
// SERVICE

class SetSendDate extends Modal {
	// eslint-disable-next-line unused-imports/no-unused-vars
	private _addonButton: (N_addon: HTMLElement) => void = () => { };

	private form: Form | null = null;

	constructor(table: string, id: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_addon = this.element.querySelector('#addon-button') as HTMLElement;

			this._addonButton(N_addon);

			this.form = new Form(this.element.querySelector('.modal-body') as HTMLFormElement);

			const value = await ServiceManager.get(table)?.getInstance().getNewSendDate(id);

			this.form.setData(value);

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			const N_copy = this.element.querySelector('#copy') as HTMLButtonElement;
			const N_print = this.element.querySelector('#print') as HTMLButtonElement;

			const N_mail = this.element.querySelector('#mail') as HTMLElement;

			N_mail.innerHTML = (value.email || '--');

			N_save.addEventListener('click', async () => {
				await this.save(table, id);

				this.resolve();
			});

			N_copy.addEventListener('click', async () => {
				try {
					await navigator.clipboard.writeText(value.email);
					toaster.success('Copie réussie');
				} catch (e) {
					toaster.error('Erreur lors de la copie');
				}
			});

			N_print.addEventListener('click', async () => {
				await this.save(table, id);
				new M_PrintPreview(table, id).open();
			});
		});
	}

	private async save(table: string, id: string) {
		const data = this.form!.getData();

		data.date = (data.date as Moment).format('x');

		await ServiceManager.get(table)?.getInstance().setNewSendDate(data, id);
	}

	public setAddonButton(cb: (N_addon: HTMLElement) => void) {
		this._addonButton = cb;
	}
}

export default SetSendDate;
