import { Module, Modules, Router } from '@autoprog/core-client';

import C_Bills from './js/controllers/Bills';

import T_BillsCustomer from './tpl/bills.customer.html';
import T_BillsProvider from './tpl/bills.provider.html';
import T_tabs from '@tpl/tabs.html';

import Navigation from '@libs/Navigation';
import Notifications from '@modules/Apps/js/libs/Notifications';

import C_BillsCustomer from './js/controllers/Bills.Customer';
import C_BillsProvider from './js/controllers/Bills.Provider';

import CE_ComptaTab from './js/libs/customElement/ComptaTab';
import CE_ContentTab from './js/libs/customElement/ContentTab';
import CE_PaymentTab from './js/libs/customElement/Payment';
import CE_PrintOptionTab from './js/libs/customElement/BillPrintOption';
import CE_SettingsTab from './js/libs/customElement/SettingsTab';

import SettingsNotificationTabs from '@modules/Apps/js/tabs/Settings.Notification';

class BillModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_PaymentTab.register();
		CE_PrintOptionTab.register();
		CE_SettingsTab.register();

		CE_ComptaTab.register();

		CE_ContentTab.register();
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Documents',
			order: 100
		});
		navigation.addLink({
			order: 2,
			category: 'Documents',
			name: 'Factures',
			type: 'module',
			module: 'bills',
			permissions: ['BILLS._CUSTOMERS', 'BILLS._PROVIDERS'],
			icon: 'bill-euro/fill'
		});
		SettingsNotificationTabs.addInput({
			text: 'Factures',
			select: [
				{
					text: 'Retard',
					name: 'bills.relunch'
				}
			]
		}, 0);
		Notifications.addCategory(['bill-relunch'], {
			textFilter: 'Facture',
			isFilter: true,
			icon: 'icon-outline-file-invoice-dollar',
			action: async (item: { [key: string]: any }) => {
				if (item.data.id) {
					C_BillsCustomer.open(item.data.id);
				}
			}
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/bills',
			controller: C_Bills as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/bills/:tabs',
			controller: C_Bills as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/billsPage/customer',
			controller: C_BillsCustomer as any,
			tpl: T_BillsCustomer,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/billsPage/provider',
			controller: C_BillsProvider as any,
			tpl: T_BillsProvider,
			requireValidUser: true
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('Factures', BillModule);
