// CORE
// NODE_MODULE
// TEMPLATE
// LIBS
// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

// STYLE
import '../../../css/globalInformations.scss';

class GlobalInformations extends HTMLElement {
	public static readonly tagName: string = 'ap-global-informations';

	private _data: any;

	public async connectedCallback() {
		this.innerHTML = `
            <div class="text-nowrap">
                <div class="mb-3 d-flex align-items-center text-grey-800">
                    <i class="icon icon-solid-info ml-1 mr-2 h5"></i><span class="font-weight-bold h4">Informations globales</span>
                </div>

                <div class="d-flex flex-wrap pl-2">
                    <div class="p-0">
                        <div class="card-counter blue">
                            <i class="icon icon-methanisation-2"></i>
                            <span class="count-numbers" data-key="sites.number">...</span>
                            <span class="count-name">Sites</span>
                        </div>
                    </div>
                    <div class="p-0">
                        <div class="card-counter red">
                            <i class="icon icon-solid-project-diagram"></i>
                            <span class="count-numbers" data-key="projects.number">...</span>
                            <span class="count-name">Projets</span>
                        </div>
                    </div>
                    <div class="p-0">
                        <div class="card-counter light-blue">
                            <i class="icon icon-solid-box-open"></i>
                            <span class="count-numbers" data-key="products.number">...</span>
                            <span class="count-name">Produits</span>
                        </div>
                    </div>
                    <div class="p-0">
                        <div class="card-counter green">
                            <i class="icon icon-solid-database"></i>
                            <span class="count-numbers" data-key="stock.price">...</span>
                            <span class="count-name">Valeur du stock</span>
                        </div>
                    </div>
                    <div class="p-0">
                        <div class="card-counter orange">
                            <i class="icon icon-solid-hand-holding"></i>
                            <span class="count-numbers" data-key="providers.number">...</span>
                            <span class="count-name">Fournisseurs</span>
                        </div>
                    </div>
                    <div class="p-0">
                        <div class="card-counter grey">
                            <i class="icon icon-solid-hand-holding-usd"></i>
                            <span class="count-numbers" data-key="customers.number">...</span>
                            <span class="count-name">Clients</span>
                        </div>
                    </div>
                </div>
            </div>
        `;
	}

	private linkData() {
		const N_sites_number = this.querySelector('[data-key="sites.number"]');
		const N_projects_number = this.querySelector('[data-key="projects.number"]');
		const N_products_number = this.querySelector('[data-key="products.number"]');
		const N_stock_price = this.querySelector('[data-key="stock.price"]');
		const N_providers_number = this.querySelector('[data-key="providers.number"]');
		const N_customers_number = this.querySelector('[data-key="customers.number"]');

		N_sites_number!.innerHTML = this._data.sites.number;
		N_projects_number!.innerHTML = this._data.projects.number;
		N_products_number!.innerHTML = this._data.products.number;
		N_stock_price!.innerHTML = this._data.stock.price;
		N_providers_number!.innerHTML = this._data.providers.number;
		N_customers_number!.innerHTML = this._data.customers.number;
	}

	public set data(data: any) {
		this._data = data;
		this.linkData();
	}

	public get data() {
		return this._data;
	}

	public static register() {
		customElements.define(GlobalInformations.tagName, GlobalInformations);
	}
}

export default GlobalInformations;
