// CORE
import { Controller, Router, global } from '@autoprog/core-client';
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import moment from 'moment';

// TEMPLATE
// LIBS
import Keyboard from '@libs/Keyboard';
import Loader from '@libs/Loader';
import StateSaver from '@libs/agGrid/StateSaver';
import Utils from '@libs/utils/Utils';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
import M_Edit from '../modals/Edit';
import M_Image from '../modals/Image';
import M_Input from '../modals/Input';
import M_M_Input from '../modals/multiple/Input';
import M_M_Output from '../modals/multiple/Output';
import M_Output from '../modals/Output';

// CUSTOM_ELEMENT
// SERVICE
import S_Product from '@services/Product/ProductService';
import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';

class StockTactileController extends Controller {
	private gridOptions: GridOptions = {};

	private currentStock: string = '';

	private el: HTMLElement;

	private stateSaver: StateSaver | null = null;

	private keyboard: Keyboard | null = null;

	private viewAllProductsChecked = false;

	constructor(el: HTMLElement) {
		super(el);

		global.NO_ASIDE = true;
		global.NO_NAVBAR = true;

		this.el = el;

		$(document).on(global.CLICK_EVENT_TACTILE, ':not(.popover)', function () {
			//@ts-ignore
			$('.popover').popover('hide');
		});

		this.gridOptions = agUtils.french<GridOptions>({
			columnDefs: [
				{
					headerName: '',
					width: 100,
					suppressSizeToFit: true,
					cellClass: 'p-0',
					cellRenderer: (params) => {
						const N_div = document.createElement('div');
						N_div.classList.add('h-100', 'w-100');
						if (params.data.product.url_photo) {
							const url = S_Product.getInstance().getImageFromURL(params.data.product.url_photo);

							N_div.style.background = `url('${url}')`;
							N_div.style.backgroundSize = 'contain';
							N_div.style.backgroundRepeat = 'no-repeat';
							N_div.style.backgroundPosition = 'center';

							N_div.addEventListener('click', () => {
								new M_Image(url, _.get(params.data, 'product.' + S_Product.getInstance().referenceKey)).open();
							});
						} else {
							N_div.innerHTML = `
								<div class="align-items-center bg-light d-flex h-100 w-100 h4 justify-content-center rounded-lg text-grey-300">
									N/A
								</div>
							`;
						}
						return N_div;
					}
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: 'product.' + S_Product.getInstance().referenceKey,
					width: 220,
					suppressSizeToFit: true
				},
				{
					headerName: 'Désignation',
					field: 'product.name',
					filterParams: {
						textFormatter: (result: string) => {
							if (result == null) return null;
							return _.deburr(result.toLowerCase());
						},
						debounceMS: 200
					}
				},
				{
					field: 'stock',
					hide: false,
					filter: 'agTextColumnFilter',
					filterParams: { newRowsAction: 'keep' },
					suppressColumnsToolPanel: true
				},
				{
					field: 'displayStock',
					hide: false,
					filter: 'agTextColumnFilter',
					filterParams: { newRowsAction: 'keep' },
					suppressColumnsToolPanel: true
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Empl.',
					field: 'product.locationStock',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Dernière Vérification',
					field: 'lastUpdate',
					width: 200,
					suppressSizeToFit: true,
					cellClass: ['d-flex', 'align-items-center', 'justify-content-center', 'text-md'],
					cellRenderer: (params) => {
						if (params.value && params.value.user) {
							return `
                                ${moment(params.value.date).format('DD/MM/YYYY HH:mm')} 
                                <div class="text-xs text-muted text-center w-100" style="line-height:7px;">Par ${params.value.user}</div>        
                            `;
						} else {
							return `
                                <span class="text-muted">Aucune vérification</span>
                            `;
						}
					}
				},
				{
					headerName: 'Qté',
					field: 'quantity.formattedValue',
					width: 80,
					filter: 'agNumberColumnFilter',
					filterParams: { newRowsAction: 'keep' },
					cellClass: ['d-flex', 'align-items-center', 'justify-content-end', 'text-lg', 'text-monospace'],
					suppressSizeToFit: true
				},
				{
					headerName: '#',
					width: 80,
					suppressSizeToFit: true,
					suppressColumnsToolPanel: true,
					filter: false,
					pinned: 'right',
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						const N_edit = document.createElement('button');

						N_edit.classList.add('btn', 'btn-transparent');
						N_edit.innerHTML = '<i class="icon icon-solid-ellipsis-v"></i>';
						N_edit.setAttribute('tooltip', 'Plus');

						N_edit.addEventListener(global.CLICK_EVENT_TACTILE, (e: MouseEvent) => {
							e.stopImmediatePropagation();
							e.stopPropagation();
							e.preventDefault();

							//@ts-ignore
							$('.popover').popover('hide');
							//@ts-ignore
							$(N_edit).popover('toggle');
						});

						//@ts-ignore
						$(N_edit).popover({
							html: true,
							placement: 'top',
							boundary: 'window',
							trigger: 'manual',
							sanitize: false,
							title: '',
							content: `<div class="d-flex flex-column">
                                <button class="btn btn-blue-50" id="input"><i class="icon icon-solid-sign-in-alt mr-2 text-indigo"></i>Entrée</button>
                                <button class="btn btn-blue-50" id="output"><i class="icon icon-solid-sign-out-alt mr-2 text-red"></i>Sortie</button>
                                <button class="btn btn-blue-50" id="edit"><i class="icon icon-solid-sync-alt mr-2"></i>Mettre à jour</button>
                            </div>`
						}).on('shown.bs.popover', (e: any) => {
							const popoverId = e.target.getAttribute('aria-describedby');

							const N_popover = document.querySelector('#' + popoverId) as HTMLElement;

							const N_input = N_popover.querySelector('#input') as HTMLButtonElement;
							const N_output = N_popover.querySelector('#output') as HTMLButtonElement;
							const N_update = N_popover.querySelector('#edit') as HTMLButtonElement;

							this.keyboard?.close();

							N_input.addEventListener(global.CLICK_EVENT_TACTILE, () => {
								//@ts-ignore
								$(N_popover).popover('hide');

								new M_Input(params.data.product._id, params.data.quantity.value, params.data.stock).open().then(() => {
									this.getData();
								});
							});

							N_output.addEventListener(global.CLICK_EVENT_TACTILE, () => {
								//@ts-ignore
								$(N_popover).popover('hide');

								new M_Output(params.data.product._id, params.data.quantity.value, params.data.stock).open().then(() => {
									this.getData();
								});
							});

							N_update.addEventListener(global.CLICK_EVENT_TACTILE, () => {
								//@ts-ignore
								$(N_popover).popover('hide');

								new M_Edit(params.data.product._id, params.data.quantity.value, params.data.stock).open().then(() => {
									this.getData();
								});
							});
						});

						N_div.appendChild(N_edit);

						return N_div;
					}
				}
			],
			defaultColDef: {
				resizable: false,
				sortable: false,
				suppressMovable: true,
				suppressMenu: true,
				headerClass: ['bg-blue-50', 'text-lg'],
				cellClass: ['d-flex', 'align-items-center', 'text-lg']
			},
			suppressRowClickSelection: true,
			suppressCellSelection: true,
			headerHeight: 40,
			suppressContextMenu: true,
			getRowHeight: () => {
				return 60;
			},
			onGridReady: async (params) => {
				await this.getData();
				params.api.sizeColumnsToFit();
			},
			onFilterChanged: (params: any) => {
				const filter = params.api.getFilterModel();

				delete filter.stock;
				delete filter.displayStock;
				delete filter.quantity;

				params.columnApi.setColumnsVisible(['displayStock', 'stock'], false);
				params.api.sizeColumnsToFit();

				this.updatePrice();
			},
			rowData: []
		});

		this.initList(el);
		this.initMultipleOutput();
		this.initMultipleInput();

		const N_grid = el.querySelector('#grid') as HTMLElement;

		new Grid(N_grid, this.gridOptions, { modules: AllModules });

		const N_search = this.el.querySelector('#search') as HTMLInputElement;

		this.keyboard = new Keyboard(N_search, {
			eventClick: global.CLICK_EVENT_TACTILE,
			parent: this.el.querySelector('#container-keyboard') as HTMLElement
		});

		let timeout: any = null;
		N_search.addEventListener('input', () => {
			timeout && clearTimeout(timeout);
			timeout = setTimeout(() => {
				this.gridOptions.api?.setQuickFilter(N_search.value);
			}, 200);
		});

		const N_viewAllProducts = el.querySelector('#viewAllProducts') as HTMLInputElement;

		N_viewAllProducts.addEventListener('change', () => {
			this.viewAllProductsChecked = !this.viewAllProductsChecked;

			if (this.gridOptions.api) {
				this.viewAllProductsChecked = N_viewAllProducts.checked;

				const filterInstance = this.gridOptions.api?.getFilterInstance('displayStock');

				filterInstance?.setModel({
					type: 'equals',
					filter: this.viewAllProductsChecked ? '' : 'true'
				});

				this.gridOptions.api?.onFilterChanged();
			}
		});

		const N_home = el.querySelector('#home') as HTMLButtonElement;

		N_home.addEventListener('click', () => {
			Router.getInstance().navigate('');
		});
	}

	private initMultipleOutput() {
		const N_multiple_output = this.el.querySelector('#multiple_output') as HTMLButtonElement;

		N_multiple_output.addEventListener('click', () => {
			new M_M_Output(this.currentStock).open().then(() => {
				this.getData();
			});
		});
	}

	private initMultipleInput() {
		const N_multiple_input = this.el.querySelector('#multiple_input') as HTMLButtonElement;

		N_multiple_input.addEventListener('click', () => {
			new M_M_Input(this.currentStock).open().then(() => {
				this.getData();
			});
		});
	}

	private initList(el: HTMLElement) {
		const N_list = el.querySelector('#list') as HTMLSelectElement;

		S_Stock.getInstance().getRealStock().then((data) => {
			for (const item of data) {
				const option = new Option(item.name, item._id, item.default, item.default);

				N_list.append(option);

				if (item.default) {
					this.currentStock = item._id;
				}
			}
		});

		N_list.addEventListener('change', () => {
			if (this.gridOptions.api) {
				this.currentStock = N_list.value;

				const filterInstance = this.gridOptions.api.getFilterInstance('stock');

				filterInstance?.setModel({
					type: 'equals',
					filter: this.currentStock
				});

				this.gridOptions.api.onFilterChanged();
			}
		});
	}

	private async getData() {
		Loader.getInstance().open();

		const data = await S_StockEvent.getInstance().getCurrentStock({
			user: Utils.userID
		});

		this.stateSaver?.setData(data.settings);

		this.gridOptions.api?.setRowData(data.rowData);

		Loader.getInstance().close();

		const filterInstanceStock = this.gridOptions.api?.getFilterInstance('stock');

		filterInstanceStock?.setModel({
			type: 'equals',
			filter: this.currentStock
		});

		const filterInstanceDisplay = this.gridOptions.api?.getFilterInstance('displayStock');

		filterInstanceDisplay?.setModel({
			type: 'equals',
			filter: this.viewAllProductsChecked ? '' : 'true'
		});

		this.gridOptions.api?.onFilterChanged();
		this.gridOptions.api?.sizeColumnsToFit();
	}

	private updatePrice() {
		let res = new Decimal(0);
		let index = 0;

		this.gridOptions.api?.forEachNodeAfterFilter((node) => {
			if (node.data.quantity.value >= 0) {
				res = res.plus(Decimal.setDisplayNumber(node.data.totalPurchasePrice.value));
			}
			index++;
		});

		const N_price = this.el.querySelector('#price') as HTMLElement;
		const N_numberProducts = this.el.querySelector('#numberProducts') as HTMLElement;

		N_price.innerHTML = res.setSuffixAndHumanizeNumber('€');
		N_numberProducts.innerHTML = index.toString();
	}

	public destructor() {

	}
}

export default StockTactileController;
