// CORE
import { Form } from '@autoprog/core-client';

import { Moment } from 'moment';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../../tpl/modals/AddQuote/editPage/additionalInformations.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT
import CE_Address from '@libs/customElement/Address';
import CE_QuoteRelance from '../../../libs/customElement/QuoteRelance';
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Address from '@services/Customer/CustomerAddressService';
import S_Customer from '@services/Customer/CustomerService';

type AdditionalInformationsData = {
	infos: {
		customer: string,
		addressID: string,
		fullAddress: string,
		comments: string,
		validityType: string,
		validityDate: Moment,
		commentFinish: string,
		relunch: {
			type: string,
			date: Moment,
			disabled: boolean
		},
		sendDate: Moment

	}
};

class AdditionalInformations extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 | CE_Address } = {};

	private form: Form | null;

	private sendDate: Moment;

	constructor(data: AdditionalInformationsData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;
		this.sendDate = data.infos.sendDate;

		this.on('opened', async () => {
			this.initSelect2();
			this.initEvents();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);

			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					data.infos.customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer);
					data.infos.addressID = await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.addressID);

					this.resolve(data);
				}));
			});
		});
	}

	private initSelect2() {
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_addressID = this.element.querySelector('[name-select="infos.addressID"]') as CE_Address;

		N_customer.create(this.element, { disabled: true });

		N_addressID.setRef({ id_customer: N_customer.selectElement! });
		N_addressID.create(this.element);

		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['infos.addressID'] = N_addressID;
	}

	private initEvents() {
		const N_relunch = this.element.querySelector('ap-quote-relance') as CE_QuoteRelance;

		N_relunch.setSendDate(this.sendDate);
		N_relunch.postInit();
	}

	private async setData(data: AdditionalInformationsData) {
		const res: { [key: string]: any } = {
			infos: {
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer),
				addressID: await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.addressID),
				fullAddress: data.infos.fullAddress,
				comments: data.infos.comments,
				validityType: data.infos.validityType,
				validityDate: data.infos.validityDate,
				commentFinish: data.infos.commentFinish,
				relunch: {
					type: data.infos.relunch.type,
					date: data.infos.relunch.date,
					disabled: data.infos.relunch.disabled
				},
				sendDate: data.infos.sendDate

			}
		};

		this.form?.setData(res);
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}

		const N_relunch = this.element.querySelector('ap-quote-relance') as CE_QuoteRelance;

		N_relunch.postInit();
	}
}

export default AdditionalInformations;
