import { ICellEditorComp, ICellEditorParams } from '@ag-grid-community/core';

class NumericCellEditor implements ICellEditorComp {
	private value: number;
	private eInput: HTMLInputElement;
	private cancelBeforeStart: boolean;

	constructor() {
		this.cancelBeforeStart = false;

		this.value = 0;

		this.eInput = document.createElement('input');
		this.eInput.classList.add('ag-cell-edit-input');
	}

	public getGui() {
		return this.eInput;
	}

	// Retourne la valeur après l'édition
	public getValue() {
		if (this.eInput.value) {
			this.value = parseInt(this.eInput.value);
		} else {
			this.value = 0;
		}
		return this.value;
	}

	// focus and select can be done after the gui is attached
	public afterGuiAttached() {
		this.eInput.focus();
	}

	public isCancelBeforeStart() {
		return this.cancelBeforeStart;
	}

	// Si true, éditeur sous forme de popup
	public isPopup() {
		return false;
	}

	public init(params: ICellEditorParams) {
		this.value = params.value;

		if (this.isCharNumeric(params.charPress!)) {
			this.eInput.value = params.charPress!;
		} else {
			if (params.value !== undefined && params.value !== null) {
				this.eInput.value = params.value;
			}
			this.eInput.select();
		}

		this.eInput.addEventListener('keypress', (event) => {
			if (!this.isKeyPressedNumeric(event)) {
				this.eInput.focus();
				if (event.preventDefault) event.preventDefault();
			} else if (this.isKeyPressedNavigation(event)) {
				event.stopPropagation();
			}
		});

		// only start edit if key pressed is a number, not a letter
		const charPressIsNotANumber = params.charPress && ('-1234567890'.indexOf(params.charPress) < 0);
		this.cancelBeforeStart = !!charPressIsNotANumber;
	}

	private isCharNumeric(charStr: string) {
		return !!/\d/.test(charStr) || ['-'].includes(charStr);
	}

	private isKeyPressedNumeric(event: KeyboardEvent) {
		const charCode = this.getCharCodeFromEvent(event);
		return this.isCharNumeric(charCode);
	}

	private isKeyPressedNavigation(event: KeyboardEvent) {
		return event.code === '39' || event.code === '37';
	}

	private getCharCodeFromEvent(event: KeyboardEvent) {
		event = event || window.event;
		return (typeof event.key === 'undefined') ? event.code : event.key;
	}

	public destroy() {

	}
}
export default NumericCellEditor;
