import { toaster } from '@autoprog/core-client';

import SettingsApps from '@libs/Settings';

// Librairie externe

import AddEditProductProvider from './productProvider/AddEditProductProvider';

import S_Product from '@services/Product/ProductService';
import S_ProductProvider from '@services/Product/ProductProviderService';
import S_Provider from '@services/Provider/ProviderService';

import ServiceManager from '@managers/ServiceManager';

import AddEditModal2 from '@libs/AddEdit';
import ConfigManager from '@libs/ConfigManager';
import Decimal from '@libs/utils/Decimal';

import CE_ProductImagePreviewer from '../libs/customElement/ProductImagePreviewer';
import CE_Select2 from '@libs/customElement/Select2';

class AddEditProduct extends AddEditModal2 {
	private static readonly DATABASE_NAME = 'products';
	private static readonly PRODUCTS_PROVIDER_DB = 'products-provider';

	constructor(id?: string) {
		const config = ConfigManager.getInstance().getConfig(AddEditProduct.DATABASE_NAME);
		super(config, AddEditProduct.DATABASE_NAME, id);
	}

	/**
	 * Permet d'initialiser la modal
	 */
	protected async init() {
		await super.init();

		// Bouton pour créer un fournisseur par défaut
		const N_addProviderBtn = this.element.querySelector('#add-provider-btn') as HTMLButtonElement;

		// On gère l'évenement sur le bouton ajouter fournisseur
		N_addProviderBtn.addEventListener('click', async () => {
			// On save le produit
			const id = await this.save();

			this._id = id;

			//On ouvre la modale d'édition du produit fournisseur
			new AddEditProductProvider(undefined, { id_product: this._id }).open().then(async (id) => {
				// On récupère le proudit fournisseur sauvegardé
				const data = await S_ProductProvider.getInstance().getDataToAgGridByID(id);

				// On met à jout l'AgGrid des produits fournisseurs
				this.gridOptionsTable['products-provider'].api?.applyTransaction({
					add: [data.rowData]
				});

				const tmp = await S_ProductProvider.getInstance().getById(id);
				// On récupère le fournisseur associé au produit fournisseur pour afficher son nom
				const provider = await S_Provider.getInstance().getDataToSelect2ByID(tmp.id_provider);
				this.form?.setDataByName('defaultProvider.id', provider);
			});
		});

		this.selectPostinit['defaultProvider.id'].on('change', async (value) => {
			if (value) {
				const data = await S_ProductProvider.getInstance().getByProductAndProvider(this._id || '', value as string);
				this.updatePrice(data);
			} else {
				this.clearDefaultProviderField();
			}
		});

		const N_copy_internalCode = this.element.querySelector('#copy_internalCode') as HTMLElement;

		N_copy_internalCode.addEventListener('click', () => {
			const value = this.form?.getDataByName('internalCode') || '';
			this.form?.setDataByName('reference', value as string);
		});

		this.initPreview();
		this.initCompta();
	}

	private initCompta() {
		const dataCompta = (SettingsApps.getInstance().get('COMPTA') || {}) as { [key: string]: any };

		dataCompta.comptes = dataCompta.comptes || [];

		const N_select = this.element.querySelector('[name="comptaVente"]') as HTMLSelectElement;

		if (dataCompta.disable) {
			(N_select.parentNode as HTMLElement).classList.add('d-none');
		}

		for (const item of dataCompta.comptes) {
			if (item.isMAT) {
				const tmpDefault = item.number === this.oldFormData.comptaVente;
				const N_options = new Option(`${item.number} : ${item.label}`, item.number, tmpDefault, tmpDefault);
				N_select.append(N_options);
			}
		}
	}

	private getBase64ImageFromURL(url: string): Promise<string> {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await fetch(url);
				const blob = await data.blob();
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
					const base64data = reader.result as string;
					resolve(base64data);
				};
			} catch (e) {
				reject(e);
			}
		});
	}

	private async updateBase64Input(url: string) {
		const N_ProductImagePreviewer = this.element.querySelector('ap-product-image-previewer') as CE_ProductImagePreviewer;

		N_ProductImagePreviewer.displaySpinner();

		if (url) {
			url = S_Product.getInstance().getImageFromURL(url);

			try {
				const base64 = await this.getBase64ImageFromURL(url);

				this.form?.setDataByName('base64_photo', base64);

				N_ProductImagePreviewer.setURL(base64);
			} catch (e) {
				this.form?.setDataByName('base64_photo', '');

				const N_img = new Image();

				N_img.addEventListener('load', () => {
					N_ProductImagePreviewer.setURL(url);
				});

				N_img.addEventListener('error', () => {
					N_ProductImagePreviewer.displayInvalid();
				});

				N_img.src = url;
			}
		} else {
			this.form?.setDataByName('base64_photo', '');
			N_ProductImagePreviewer.displayDefault();
		}
	}

	private async initPreview() {
		const N_input = this.element.querySelector('[name="url_photo"]') as HTMLInputElement;

		let debounce: any = null;

		N_input.addEventListener('input', async () => {
			debounce && clearTimeout(debounce);

			debounce = setTimeout(() => {
				this.updateBase64Input(N_input.value);
			}, 500);
		});

		this.updateBase64Input(N_input.value);
	}

	protected createApSelect2Button(key: string, N_select: CE_Select2) {
		super.createApSelect2Button(key, N_select);

		if (key.includes('category')) {
			const cat_type = parseInt(key.replace('category', ''));
			this.selectPostinit[key].setRef({ type: cat_type.toString() });
		}

		if (key === 'defaultProvider.id') {
			this.selectPostinit[key].setRef({
				'products-provider': () => {
					const idsProvider: string[] = [];

					this.gridOptionsTable['products-provider'].api?.forEachNode(({ data }) => {
						idsProvider.push(data._id);
					});

					return idsProvider;
				}
			});
		}
	}

	/**
	 * Sauvegarde le produit dans la base de donnée
	 */
	protected async save() {
		if (this.form && this.form.checkValidity()) {
			let data = this.form.getData() as { [key: string]: any };

			try {
				data = await this.getFormData(data);

				const res = await ServiceManager.get(this.database)?.getInstance().save(data, {
					orders: this.saveGridtable()
				});

				if (res && res.err) {
					throw new Error(res.err);
				} else {
					toaster.success('Sauvegarde réussie');
					return data._id;
				}
			} catch (e) {
				console.error(e);
				toaster.error((e as any).message, 'Erreur lors de la sauvegarde');
				throw new Error((e as any).message);
			}
		} else {
			throw Error('Formulaire non-valide');
		}
	}

	/**
	 * Permet d'ajouter une ligne sur un tableau AgGrid de la modal
	 * @param params les paramètres renvoyés par AgGrid
	 * @param configGrid la configuration du tableau AgGrid
	 * @param table le nom du tablau
	 * @param keyRef le clés de référence par rapport à l'entité actuelle
	 */
	protected async addRowAgGridTable(params: any, configGrid: any, table: string, keyRef: string) {
		const id = await this.save();

		this._id = id;

		let addEdit: AddEditModal2 | AddEditProductProvider;

		if (table === AddEditProduct.PRODUCTS_PROVIDER_DB) {
			addEdit = new AddEditProductProvider(undefined, { id_product: this._id });
		} else {
			addEdit = new AddEditModal2(configGrid, table, '', {
				[keyRef]: this._id
			});
		}

		addEdit.open().then(async (id) => {
			const data = await ServiceManager.get(table)?.getInstance().getDataToAgGridByID(id);

			params.api?.applyTransaction({
				add: [data?.rowData]
			});

			if (table === AddEditProduct.PRODUCTS_PROVIDER_DB) {
				const rows: any[] = [];
				params.api?.forEachNode((node: any) => {
					rows.push(node.data);
				});

				if (rows.length === 1) {
					this.updateDefaultProviderField(rows[0]._id);
					await this.save();
				}
			}
		});
	}

	/**
	* Permet d'éditer une ligne d'un tableau AgGrid de la modal
	* @param params les paramètres renvoyés par AgGrid
	* @param configGrid la configuration du tableau AgGrid
	* @param table le nom du tablau
	* @param keyRef le clés de référence par rapport à l'entité actuelle
	*/
	protected async editRowAgGridTable(params: any, configGrid: any, table: string) {
		const id = await this.save();

		this._id = id;

		let addEdit: AddEditModal2 | AddEditProductProvider;

		if (table === AddEditProduct.PRODUCTS_PROVIDER_DB) {
			addEdit = new AddEditProductProvider(params.data._id, { id_product: this._id });
		} else {
			addEdit = new AddEditModal2(configGrid, table, params.data._id);
		}

		addEdit.open().then(async (id) => {
			const data = await ServiceManager.get(table)?.getInstance().getDataToAgGridByID(id);

			if (data) {
				params.node.setData(data.rowData);

				if (table === AddEditProduct.PRODUCTS_PROVIDER_DB) {
					const oldId = this.form?.getDataByName('defaultProvider.idProductProvider') as string;

					if (oldId === id) {
						this.updateDefaultProviderField(id);

						await this.save();
					}
				}
			}
		});
	}

	/**
	 * Permet de supprimer une ligne d'un AgGrid
	 * @param params les paramamètres AgGrid
	 * @param table le nom de la table
	 */
	protected async deleteRowAgGridTable(params: any, table: string) {
		try {
			await ServiceManager.get(table)?.getInstance().delete(params.value);

			params.api?.updateRowData({
				remove: [params.node.data]
			});

			toaster.success('Suppression réussi');

			if (table === AddEditProduct.PRODUCTS_PROVIDER_DB) {
				const idProductProvider = this.form?.getDataByName('defaultProvider.idProductProvider') as string;

				if (idProductProvider === params.data._id) {
					const rows: any[] = [];
					params.api?.forEachNode((node: any) => {
						rows.push(node.data);
					});

					if (rows.length) {
						this.updateDefaultProviderField(rows[0]._id);
					} else {
						this.clearDefaultProviderField();
					}

					await this.save();
				}
			}
		} catch (e) {

		}
	}

	private async updatePrice(data: { [key: string]: any }) {
		this.form?.setDataByName('defaultProvider.purchasePrice', Decimal.setDisplayNumber(data.purchase_price).humanizeNumber(4));
		this.form?.setDataByName('defaultProvider.costPrice', Decimal.setDisplayNumber(data.costPrice).humanizeNumber(2));
		this.form?.setDataByName('defaultProvider.recommendedSellPrice', Decimal.setDisplayNumber(data.providerSellPrice).humanizeNumber(2));
		this.form?.setDataByName('defaultProvider.idProductProvider', data._id);
	}

	private async updateDefaultProviderField(id: string) {
		const data = await S_ProductProvider.getInstance().getById(id);
		const provider = await S_Provider.getInstance().getDataToSelect2ByID(data.id_provider);
		this.form?.setDataByName('defaultProvider.id', provider);
		this.updatePrice(data);
	}

	private clearDefaultProviderField() {
		this.form?.setDataByName('defaultProvider.id', null);
		this.form?.setDataByName('defaultProvider.idProductProvider', null);
		this.form?.setDataByName('defaultProvider.purchasePrice', null);
		this.form?.setDataByName('defaultProvider.costPrice', null);
		this.form?.setDataByName('defaultProvider.recommendedSellPrice', null);
	}
}

export default AddEditProduct;
