import { Form } from '@autoprog/core-client';

import Modal from '@libs/Modal';

import T_modal from '../../../tpl/modals/groupsProducts/addLineGroupsProducts.html';

import S_Product from '@services/Product/ProductService';

import CE_Select2 from '@libs/customElement/Select2';

class AddLine extends Modal {
	private form: Form | null = null;

	private selectPostInit: { [key: string]: CE_Select2 } = {};

	constructor(data: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			this.init();

			const N_form = this.element.querySelector('form') as HTMLFormElement;

			this.form = new Form(N_form);

			data.quantity = data.quantity || 1;

			if (data.id_products) {
				data.id_products = await S_Product.getInstance().getDataToSelect2ByID(data.id_products);
			}

			this.form.setData(data);

			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				this.resolve(this.form!.getData());
			});

			this.updateButtonSave();
		});
	}

	private init() {
		const N_product = this.element.querySelector('[name="id_products"]') as CE_Select2;

		N_product.create(this.element);

		this.selectPostInit.id_products = N_product;
	}

	private postInit() {
		const N_quantity = this.element.querySelector('[name="quantity"]') as HTMLInputElement;
		this.selectPostInit.id_products.postInit();

		this.selectPostInit.id_products.on('change', () => {
			this.updateButtonSave();
		});

		N_quantity.addEventListener('change', () => {
			this.updateButtonSave();
		});
	}

	private updateButtonSave() {
		const data = this.form!.getData();

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !data.id_products || data.quantity === 0;
	}
}

export default AddLine;
