import GenericService from '../GenericService';

class ProductProviderService extends GenericService {
	private static instance: ProductProviderService | null = null;

	constructor() {
		super('products-provider');
	}

	public static getInstance(): ProductProviderService {
		if (!ProductProviderService.instance) {
			ProductProviderService.instance = new ProductProviderService();
		}

		return ProductProviderService.instance;
	}

	public getByProvider(id_provider: string) {
		return this.get_function('getByProvider', { id_provider });
	}

	public async getByProduct(id_product: string) {
		return await this.get_function('getByProduct', { id_product });
	}

	public async getByProductAndProvider(id_product: string, id_provider: string) {
		return await this.get_function('getByProductAndProvider', { id_provider, id_product });
	}
}

export default ProductProviderService;
