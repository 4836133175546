import axios from 'axios';
import moment from 'moment';

import _ from 'lodash';

import { global } from '@autoprog/core-client';

import S_users from '@js/services/User/UserService';
class Traceability extends HTMLElement {
	public static readonly tagName: string = 'ap-traceability';

	public async connectedCallback() {
		this.classList.add('flex-grow-1');

		this.setAttribute('ap-hidden', 'mobile');

		this.innerHTML = `
            <div class="text-muted item active" id="create"  tooltip="Création">
                <i class="icon icon-solid-spinner icon-spin"></i>
                <span class="text"> </span>
				<i class="icon icon-solid-info-circle" style="
					width: 0;
					height: 0;
					font-size: 11px;
					position: relative;
					bottom: 18px;
				"></i>
            </div>

            <div class="text-muted item" id="lastSave" tooltip="Dernier enregistrement" >
                <i class="icon icon-solid-spinner icon-spin"></i>
                <span class="text"> </span>
				<i class="icon icon-solid-info-circle" style="
					width: 0;
					height: 0;
					font-size: 11px;
					position: relative;
					bottom: 18px;
				"></i>
            </div>

            <div class="text-muted item" id="lastOpen" tooltip="Dernière ouverture">
                <i class="icon icon-solid-spinner icon-spin"></i>
                <span class="text"></span>
				<i class="icon icon-solid-info-circle" style="
					width: 0;
					height: 0;
					font-size: 11px;
					position: relative;
					bottom: 18px;
				"></i>
            </div>

            <div class="text-muted item" id="lastPrint" tooltip="Dernière impression">
                <i class="icon icon-solid-spinner icon-spin"></i>
                <span class="text"></span>
				<i class="icon icon-solid-info-circle" style="
					width: 0;
					height: 0;
					font-size: 11px;
					position: relative;
					bottom: 18px;
				"></i>
            </div>
        `;

		const N_items = this.querySelectorAll('.item');

		N_items.forEach((N_el) => {
			N_el.addEventListener('mouseenter', () => {
				N_items.forEach((N_el) => {
					N_el.classList.remove('active');
				});
				N_el.classList.add('active');
			});
		});
	}

	//TODO: a mettre coté serveur
	private async getLast(table: string, key: string, value: string) {
		if (value) {
			const { data } = await axios.post(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}history/_find`, {
				selector: {
					table,
					key,
					value
				},
				limit: Number.MAX_SAFE_INTEGER
			});

			data.docs = _.sortBy(data.docs, 'date');

			data.docs = _.filter(data.docs, 'user');

			if (key === 'open') {
				return data.docs[data.docs.length - 2] as { [key: string]: any } || null;
			} else {
				return data.docs[data.docs.length - 1] as { [key: string]: any } || null;
			}
		}

		return null;
	}

	private getLastFromData(data: any[], key: string) {
		let result = null;

		const byKey = data.filter(data => data.value.key === key);

		let last = null;

		if (byKey.length > 0) {
			last = byKey.reduce((prev, current) => {
				return prev.value.date > current.value.date ? prev : current;
			});
		}

		if (last) {
			result = {
				date: last.value.date,
				user: last.value.user
			};
		}

		return result;
	}

	private async updateElement(el: HTMLElement, icon: string, value: { [key: string]: string } | null, defaultText: string) {
		const N_span = el.querySelector('.text') as HTMLElement;
		const N_icon = el.querySelector('.icon') as HTMLElement;

		if (value) {
			const user = await S_users.getInstance().getById(value.user);

			if (user) {
				N_span.innerHTML = `Le ${moment(value.date, 'x').format('DD/MM/YYYY à HH:mm')} par ${user.firstname} ${user.lastname[0]}.`;
			} else {
				N_span.innerHTML = `Le ${moment(value.date, 'x').format('DD/MM/YYYY à HH:mm')} par ???`;
			}
		} else {
			N_span.innerHTML = defaultText;
		}

		N_icon.classList.remove('icon-spin', 'icon-solid-spinner');
		N_icon.classList.add(icon);
	}

	public async update(table: string, id: string) {
		const create = await this.getLast(table, 'create', id);
		const lastSave = await this.getLast(table, 'save', id);
		const lastOpen = await this.getLast(table, 'open', id);
		const lastPrint = await this.getLast(table, 'print', id);

		const lastData: { [key: string]: { [key: string]: string } | null } = {
			create,
			lastSave,
			lastOpen,
			lastPrint
		};

		await this.updateElements(lastData);
	}

	public async updateFromData(data: any[]) {
		const create = this.getLastFromData(data, 'create');
		const lastSave = this.getLastFromData(data, 'save');
		const lastOpen = this.getLastFromData(data, 'open');
		const lastPrint = this.getLastFromData(data, 'print');

		const lastData: { [key: string]: { [key: string]: string } | null } = {
			create,
			lastSave,
			lastOpen,
			lastPrint
		};

		await this.updateElements(lastData);
	}

	private async updateElements(lastData: { [key: string]: { [key: string]: string } | null }) {
		const N_create = this.querySelector('#create') as HTMLElement;
		const N_lastSave = this.querySelector('#lastSave') as HTMLElement;
		const N_lastOpen = this.querySelector('#lastOpen') as HTMLElement;
		const N_lastPrint = this.querySelector('#lastPrint') as HTMLElement;

		await this.updateElement(N_create, 'icon-solid-plus', lastData.create, 'Aucune date création enregistrer');
		await this.updateElement(N_lastSave, 'icon-solid-save', lastData.lastSave, 'Aucune Sauvegarde');
		await this.updateElement(N_lastOpen, 'icon-solid-folder-open', lastData.lastOpen, 'Aucune Ouverture');
		await this.updateElement(N_lastPrint, 'icon-printer', lastData.lastPrint, 'Aucune Impression');
	}

	public static register() {
		customElements.define(Traceability.tagName, Traceability);
	}
}

export default Traceability;
