import { Alert, DOM, LoggedUser, Router, toaster, utils } from '@autoprog/core-client';

import { Moment } from 'moment';
import _ from 'lodash';

import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';

import M_NotifNotFinish from '../modals/AddQuote/NotifNotFinish';
import M_SaveAs from '../modals/AddQuote/SaveAs';

import M_AdditionalInformations from '../modals/AddQuote/editPage/AdditionalInformations';

import M_ChangeState from '../modals/AddQuote/ChangeState';

import M_BusinessQuote from '../modals/AddQuote/editPage/BusinessQuote';
import M_Details from '../modals/AddQuote/editPage/Details';
import M_GeneralInformations from '../modals/AddQuote/editPage/GeneralInformations';

import M_InformationQuoteToDo from '../modals/QuotesToDo/Information';
import M_Intervention from '@modules/Apps/js/modals/Interventions';

import C_CommandCustomer from '@modules/Commands/js/controllers/Command.Customer';
import C_CommandCustomerFromQuote from '@modules/Commands/js/controllers/Command.Customer.FromQuote';

import CE_BrochureTab from '../libs/customElement/BrochureTab';
import CE_CGVTab from '@js/libs/customElement/CGVTab';
import CE_GridQuote from '../libs/customElement/GridQuote';
import CE_QuoteOptionReadonly from '../libs/customElement/QuoteOptionReadonly';
import CE_QuotePrintOption from '../libs/customElement/QuotePrintOption';
import CE_RecapProductsTab from '../libs/customElement/RecapProductsTab';
import CE_RecapTab from '../libs/customElement/RecapTab';
import CE_SitesFinalCustomerReadonly from '@libs/customElement/Sites-FinalCustomer-Readonly';

import '../../css/quotes.scss';

//Service
import S_C_Address from '@services/Customer/CustomerAddressService';
import S_Product from '@services/Product/ProductService';
import S_Quote from '@services/QuoteService';

import VerticalAccordion, { VerticalAccordionData } from '@libs/customElement/VerticalAccordion';
import History from '@libs/History';
import OpenDocuments from '@libs/customElement/OpenDocuments';

class QuotesCtrl extends ControllerPageID {
	private N_QuoteOptionReadonly: CE_QuoteOptionReadonly | null = null;
	private N_QuotePrintOption: CE_QuotePrintOption | null = null;
	private N_CGVTab: CE_CGVTab | null = null;
	private N_GridQuote: CE_GridQuote | null = null;
	private N_RecapTab: CE_RecapTab | null = null;
	private N_BrochureTab: CE_BrochureTab | null = null;
	private N_RecapProductsTab: CE_RecapProductsTab | null = null;

	private order: { [key: string]: string } = {};

	private intervalSocketIo: any = null;

	private notificationSession: { [key: string]: boolean } = {};

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.options = QuotesCtrl.options;

		QuotesCtrl.options = {};

		this.routeReturn = 'module/quotes';

		this.init('quotes', id);
	}

	private static options: { [key: string]: any } = {};

	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		QuotesCtrl.options = options || {};

		if (id) {
			await OpenDocuments.checkOpen(id, 'quotes');

			const data = await S_Quote.getInstance().getById(id);

			if (data.quoteToDo) {
				QuotesCtrl.options.quoteToDo = data.quoteToDo;
			}

			if (data.intervention) {
				QuotesCtrl.options.intervention = data.intervention;
			}

			let suffix = '';
			if (options.forceEdit) {
				suffix = '&forceEdit=true';
			}

			if (data.isV1) {
				Router.getInstance().navigate(`#module/quote/addV1?id=${id}${suffix}`);
			} else {
				Router.getInstance().navigate(`#module/quote/add?id=${id}${suffix}`);
			}
		} else {
			if (options.quoteToDo) {
				QuotesCtrl.options.quoteToDo = options.quoteToDo;
			}

			if (options.intervention) {
				QuotesCtrl.options.intervention = options.intervention;
			}

			Router.getInstance().navigate('#module/quote/add');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		this.initVerticalAccordion();

		await DOM.nextTick();

		this.initTabs();
		this.initEditButton();
		this.initNewQuote();

		this.initActionsDropdown();

		const data = await this.getData();

		await this.setData(data);

		this.updateEditButtons();
		this.updateButtonNotification();
		this.updateDocumentLink();
		this.updateGlobalPrice();
		this.updateRelunchEnable();

		this.postInit();
		this.initFullscreen();

		if (!this.id) {
			this.openGeneralInformation(true);
		}
	}

	private openGeneralInformation(isFirstOpen: boolean = false) {
		const res = {
			infos: {
				number: this.form?.getDataByName('infos.number') as string,
				customer: this.form?.getDataByName('infos.customer') as string,
				contact: this.form?.getDataByName('infos.contact') as string,
				email: this.form?.getDataByName('infos.email') as string,
				manager: this.form?.getDataByName('infos.manager') as string,
				date: this.form?.getDataByName('infos.date') as Moment
			}
		};

		let modal: M_GeneralInformations;

		if (isFirstOpen) {
			modal = new M_GeneralInformations(res).setNextCallback((data) => {
				this.setDataForm(data);
				this.openBusinessQuote(isFirstOpen);
			});
		} else {
			modal = new M_GeneralInformations(res);
		}

		modal.open().then(async (data) => {
			this.setDataForm(data);

			if (res.infos.customer !== data.infos.customer.id) {
				await this.updateCustomerAddress();
			}

			this.updateTitle();

			if (!isFirstOpen) {
				this.updateSaveButton();
			}
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private openBusinessQuote(isFirstOpen: boolean = false) {
		const res = {
			infos: {
				customer: this.form?.getDataByName('infos.customer') as string,
				label: this.form?.getDataByName('infos.label') as string,
				hasFinalCustomer: this.form?.getDataByName('infos.hasFinalCustomer') as boolean,
				finalCustomer: this.form?.getDataByName('infos.finalCustomer') as string,
				sites: this.form?.getDataByName('infos.sites') as string[],
				siteCustom: this.form?.getDataByName('infos.siteCustom') as string,
				description: this.form?.getDataByName('infos.description') as string
			}
		};

		let modal: M_BusinessQuote;

		if (isFirstOpen) {
			modal = new M_BusinessQuote(res).setPreviousCallback(() => {
				this.openGeneralInformation(isFirstOpen);
			});
		} else {
			modal = new M_BusinessQuote(res);
		}

		modal.open().then((data) => {
			const N_sites = this.el.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
			N_sites.update(data.infos.hasFinalCustomer);

			this.setDataForm(data);
			this.updateSaveButton();
			this.updateTitle();
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private openDetails() {
		const listState = _.find<any>(this.config.columns, { key: 'infos.state' } as any);

		const res = {
			infos: {
				number: this.form?.getDataByName('infos.number') as string,
				numberCommand: this.form?.getDataByName('infos.numberCommand') as string,
				state: this.form?.getDataByName('infos.state') as number,
				date: this.form?.getDataByName('infos.date') as Moment,
				sendDate: this.form?.getDataByName('infos.sendDate') as Moment
			},
			states: listState.object,
			order: this.order.value
		};

		new M_Details(res).open().then((data) => {
			this.setDataForm(data);
			this.updateSaveButton();
			this.updateTitle();
		});
	}

	private initEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_BusinessQuote = this.el.querySelector('[data-edit="businessQuote"]') as HTMLButtonElement;
		const N_edit_Details = this.el.querySelector('[data-edit="details"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			if (!this.isLock) {
				this.openGeneralInformation();
			}
		});

		N_edit_BusinessQuote.addEventListener('click', () => {
			if (!this.isLock) {
				this.openBusinessQuote();
			}
		});

		N_edit_Details.addEventListener('click', () => {
			this.openDetails();
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			if (!this.isLock) {
				const res = {
					infos: {
						customer: this.form?.getDataByName('infos.customer') as string,
						addressID: this.form?.getDataByName('infos.addressID') as string,
						fullAddress: this.form?.getDataByName('infos.fullAddress') as string,
						comments: this.form?.getDataByName('infos.comments') as string,
						validityType: this.form?.getDataByName('infos.validityType') as string,
						validityDate: this.form?.getDataByName('infos.validityDate') as Moment,
						commentFinish: this.form?.getDataByName('infos.commentFinish') as string,
						relunch: {
							type: this.form?.getDataByName('infos.relunch.type') as string,
							date: this.form?.getDataByName('infos.relunch.date') as Moment,
							disabled: this.form?.getDataByName('infos.relunch.disabled') as boolean
						},
						sendDate: this.form?.getDataByName('infos.sendDate') as Moment

					}
				};

				new M_AdditionalInformations(res).open().then((data) => {
					this.setDataForm(data);
					this.updateRelunchEnable();
					this.updateSaveButton();
				});
			}
		});
	}

	private initActionsDropdown() {
		const N_openCommand = this.el.querySelector('#open-command') as HTMLDivElement;

		const N_changeState = this.el.querySelector('#changeState') as HTMLElement;
		const N_notification = this.el.querySelector('#notification') as HTMLElement;
		const N_yes = N_notification.querySelector('#yes') as HTMLButtonElement;
		const N_no = N_notification.querySelector('#no') as HTMLButtonElement;

		N_openCommand.addEventListener('click', async () => {
			C_CommandCustomer.open(this.order.id);
		});

		N_changeState.addEventListener('click', async () => {
			await this.save();
			new M_ChangeState(this.id).open().then(async () => {
				await this.reload();
				this.updateEditButtons();
			});
		});

		N_yes.addEventListener('click', async () => {
			this.setDataForm({
				infos: {
					state: '5'
				}
			});

			this.updateGridQuote();

			await this.save();

			await (this.service as S_Quote)!.validateNotification(this.id, this.options.notification);

			toaster.success('Notification envoyée');

			N_notification.remove();
		});

		N_no.addEventListener('click', async () => {
			this.setDataForm({
				infos: {
					state: '0'
				}
			});

			this.updateGridQuote();

			await this.save();

			await (this.service as S_Quote)!.refuseNotification(this.id, this.options.notification);

			toaster.success('Notification envoyée');

			N_notification.remove();
		});
	}

	protected initButton() {
		super.initButton();

		const N_saveAs = this.el.querySelector('#save-as') as HTMLDivElement;

		N_saveAs.addEventListener('click', async () => {
			new M_SaveAs(this.id).open().then((newID) => {
				QuotesCtrl.open(newID);
			}).catch(() => {

			});
		});

		const N_resetPrice = this.el.querySelector('#resetPrice') as HTMLButtonElement;

		N_resetPrice.addEventListener('click', async () => {
			const data = this.N_GridQuote!.data;

			for (const item of data) {
				for (const detail of item.details) {
					if (detail.reference) {
						try {
							const tmp = await S_Product.getInstance().getById(detail.reference);
							detail.price = tmp.price;
							detail.costPrice = tmp.defaultProvider?.costPrice || 0;
						} catch (e) {

						}
					}
				}
			}

			this.N_GridQuote!.data = data;
		});

		const N_interventions = this.el.querySelector('#interventions') as HTMLButtonElement;
		const N_quoteToDo = this.el.querySelector('#quotes_to_do') as HTMLButtonElement;

		N_interventions.addEventListener('click', () => {
			new M_Intervention(this.options.intervention).open();
		});

		N_quoteToDo.addEventListener('click', () => {
			new M_InformationQuoteToDo(this.options.quote_to_do).open();
		});
	}

	private initTabs() {
		this.initCGV();
		this.initBrochures();
		this.initQuotes();
		this.initPreviewPrint();
		this.initGrid();
		this.initRecap();
		this.initMaterials();
	}

	private initCGV() {
		this.N_CGVTab = this.el.querySelector(CE_CGVTab.tagName) as CE_CGVTab;
		this.N_CGVTab.setParentElement(this.el);

		this.N_CGVTab!.setOnUpdate(() => {
			this.updateSaveButton();
		});
	}

	private initQuotes() {
		this.N_QuoteOptionReadonly = this.el.querySelector(CE_QuoteOptionReadonly.tagName) as CE_QuoteOptionReadonly;
		this.N_QuoteOptionReadonly.setParentElement(this.el);

		this.N_QuoteOptionReadonly!.setOnUpdate(() => {
			this.N_GridQuote!.optionsGrid = { ...this.saveData, pref: this.N_QuoteOptionReadonly!.data };

			this.updateGlobalPrice();
			this.updateTitle();
			this.updateSaveButton();
		});
	}

	private initPreviewPrint() {
		this.N_QuotePrintOption = this.el.querySelector(CE_QuotePrintOption.tagName) as CE_QuotePrintOption;

		this.N_QuotePrintOption!.setParentElement(this.el);

		this.N_QuotePrintOption!.setID(() => {
			return this.id;
		});

		this.N_QuotePrintOption!.setGetData(() => {
			return this.saveData;
		});

		this.N_QuotePrintOption!.setOnUpdate(() => {
			this.updateSaveButton();
		});
	}

	private initGrid() {
		this.N_GridQuote = this.el.querySelector(CE_GridQuote.tagName);

		this.N_GridQuote!.setOnUpdate(() => {
			this.updateGlobalPrice();
			this.updateSaveButton();
		});
	}

	private initRecap() {
		this.N_RecapTab = this.el.querySelector(CE_RecapTab.tagName) as CE_RecapTab;

		this.N_RecapTab!.setParentElement(this.el);

		this.N_RecapTab!.setIsSave(() => {
			return this.isSave;
		});

		this.N_RecapTab!.setGetData(() => {
			return this.saveData;
		});
	}

	private initMaterials() {
		this.N_RecapProductsTab = this.el.querySelector(CE_RecapProductsTab.tagName) as CE_RecapProductsTab;

		this.N_RecapProductsTab!.setParentElement(this.el);

		this.N_RecapProductsTab!.setID(() => {
			return this.id;
		});

		this.N_RecapProductsTab!.setGetData(() => {
			return this.saveData;
		});
	}

	private initBrochures() {
		this.N_BrochureTab = this.el.querySelector(CE_BrochureTab.tagName) as CE_BrochureTab;

		this.N_BrochureTab?.setParentElement(this.el);

		this.N_BrochureTab!.setOnUpdate(() => {
			this.updateSaveButton();
		});
	}

	private initNewQuote() {
		if (!this.id) {
			const N_verticalAccordion = this.el.querySelector('ap-vertical-accordion') as VerticalAccordion;
			N_verticalAccordion.open('quotes-informations');
		}
	}

	private initVerticalAccordion() {
		const N_verticalAccordion = this.el.querySelector('ap-vertical-accordion') as VerticalAccordion;

		const N_gridContainer = this.el.querySelector('#grid-container') as HTMLDivElement;
		const N_quotesInformations = this.el.querySelector('#quotes-informations') as HTMLDivElement;

		const accordionData: VerticalAccordionData = {
			[N_gridContainer.id]: {
				element: N_gridContainer,
				title: 'Devis'
			},
			[N_quotesInformations.id]: {
				element: N_quotesInformations,
				title: 'Paramètres'
			}
		};

		N_verticalAccordion.data = accordionData;
	}

	private get isLock() {
		const state = this.form?.getDataByName('infos.state');
		const sendDate = this.form?.getDataByName('infos.sendDate');

		const query = utils.getQuery();

		if (query.forceEdit === 'true') {
			return false;
		}

		//"0": "En Cours", "6": "À Valider"
		if (['0', '6'].includes(state) && sendDate) {
			return true;
		}

		if (!['0', '6'].includes(state)) {
			return true;
		}

		return !LoggedUser.getInstance().hasPermission('QUOTES.EDIT');
	}

	protected convertData(data: { [key: string]: any; }) {
		const N_numberCommand = this.el.querySelector('#numberCommand') as HTMLDivElement;
		N_numberCommand!.innerHTML = this.order?.value || 'Aucune commande';

		if (data.infos?.state) {
			const listState = _.find<any>(this.config.columns, { key: 'infos.state' } as any);

			data.infos.state = {
				id: data.infos.state,
				text: listState.object[data.infos.state]
			};
		}

		const durationTypes: { [key: string]: string } = {
			'1_week': '1 semaine',
			'2_week': '2 semaines',
			'3_week': '3 semaines',
			'1_month': '1 mois',
			'2_month': '2 mois'
		};

		if (data.infos?.validityType) {
			data.infos.validityType = {
				id: data.infos.validityType,
				text: durationTypes[data.infos.validityType]
			};
		}

		if (data.infos?.relunch?.type) {
			data.infos.relunch.type = {
				id: data.infos.relunch.type,
				text: durationTypes[data.infos.relunch.type]
			};
		}

		return data;
	}

	protected setDataForm(data: { [key: string]: any; }) {
		super.setDataForm(data);
	}

	protected async setData(data: DataServer) {
		// Variable utilisée dans le setData() ↓
		this.order = data.order;

		super.setData(data);

		this.updateFileNumber(data.data);

		this.N_QuoteOptionReadonly!.quoteOptions = data.data.quoteOptions;
		this.N_QuoteOptionReadonly!.data = data.data.pref;

		this.N_RecapProductsTab!.setData(data.materials);

		this.N_RecapTab!.data = data.recap.data;

		this.N_CGVTab!.data = data.data.CGV;
		this.N_QuotePrintOption!.data = data.data;

		this.N_GridQuote!.settings = {
			settings: data.settings,
			settingsDetails: data.settingsDetails
		};
		this.N_GridQuote!.notFinishGeneral = data.data.notFinishGeneral;
		this.N_GridQuote!.optionsGrid = data.data;
		this.N_GridQuote!.data = data.data.data;

		this.N_BrochureTab!.data = data.data.brochures || {};

		const N_openCommand = this.el.querySelector('#open-command') as HTMLButtonElement;
		const iconCommand = '<i class="icon icon-solid-file-alt text-lg mr-2"></i>';

		if (this.order.id) {
			N_openCommand.classList.remove('d-none');
			N_openCommand.innerHTML = iconCommand + `Ouvrir la commande (N° ${this.order.value})`;
		}

		if (data.data.intervention) {
			this.options.intervention = data.data.intervention;
		}

		if (data.data.quote_to_do) {
			this.options.quote_to_do = data.data.quote_to_do;
		}

		const N_sites = this.el.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
		N_sites.update(data.data.infos.hasFinalCustomer);

		const N_stateOther = this.el.querySelector('#state_other') as HTMLElement;
		const N_stateSend = this.el.querySelector('#state_send') as HTMLElement;

		//"1" : accepté, "2" : refusé, "7" : révisé
		if (data.data.infos.sendDate && !['1', '2', '7'].includes(data.data.infos.state)) {
			N_stateOther.classList.add('d-none');
			N_stateSend.classList.remove('d-none');
		} else {
			N_stateOther.classList.remove('d-none');
			N_stateSend.classList.add('d-none');
		}
	}

	protected postInit(): void {
		super.postInit();

		this.N_RecapTab?.postInit();
		this.N_QuoteOptionReadonly?.postInit();
		this.N_CGVTab?.postInit();
		this.N_QuotePrintOption?.postInit();
		this.N_RecapProductsTab?.postInit();
		this.N_BrochureTab?.postInit();
	}

	protected getPageData(newData: { [key: string]: any }) {
		newData.data = this.N_GridQuote!.data;
		newData.notFinishGeneral = this.N_GridQuote!.notFinishGeneral;

		if (this.options.quote_to_do) {
			newData.quoteToDo = this.options.quote_to_do;
		}

		if (this.options.intervention) {
			newData.intervention = this.options.intervention;
		}

		if (newData.infos.date) {
			newData.infos.date = newData.infos.date.format('YYYY-MM-DD');
		}

		if (newData.infos.sendDate) {
			newData.infos.sendDate = newData.infos.sendDate.format('YYYY-MM-DD');
		}

		if (newData.infos.validityDate) {
			newData.infos.validityDate = newData.infos.validityDate.format('YYYY-MM-DD');
		}

		if (newData.infos.relunch?.date) {
			newData.infos.relunch.date = newData.infos.relunch.date.format('YYYY-MM-DD');
		}

		newData.quoteOptions = this.N_QuoteOptionReadonly!.quoteOptions;

		newData.brochures = this.N_BrochureTab!.data;

		newData.pref = {
			...newData.pref,
			...this.N_QuoteOptionReadonly!.data,
			...this.N_QuotePrintOption!.data.pref
		};

		newData.CGV = this.N_CGVTab?.data;

		return newData;
	}

	protected setUrl() {
		const href: string[] = [];

		href.push(`id=${this.id}`);

		const query = utils.getQuery();

		if (query.forceEdit === 'true') {
			href.push('forceEdit=true');
		}

		const url = `#${Router.getInstance().getCurrentRoute()?.url}`;

		History.getInstance().replaceState(`${url}?${href.join('&')}`);
	}

	protected async postSave(newData: { [key: string]: any }) {
		//notification "Devis a finir"
		if (!this.notificationSession.notFinish && ['6', '0'].includes(newData.infos.state) && !newData.infos.sendDate && newData.notFinish) {
			try {
				const { reopen } = await new M_NotifNotFinish(newData._id).open();

				this.notificationSession.notFinish = reopen;
			} catch (e) {

			}
		}

		this.updateFileNumber(newData);

		const query = utils.getQuery();

		if (query.forceEdit === 'true') {
			Alert.confirm(this.order.value, 'Voulez-vous mettre à jour la commande avec les modifications apportées ?', {
				noText: 'Non',
				yesText: 'Oui'
			}).then(() => {
				C_CommandCustomerFromQuote.open(this.id, { isUpdate: true, idOrder: this.order.id, from: 'quote' });
			}).catch(() => {
				this.resetSaveButton();
			});
		}
	}

	private updateRelunchEnable() {
		const data = this.form?.getDataByName('infos.relunch.disabled') as boolean;
		const N_container_relunch = this.el.querySelector('#container_relunch') as HTMLElement;

		if (data) {
			N_container_relunch.style.opacity = '0.3';
		} else {
			N_container_relunch.style.opacity = '1';
		}
	}

	private updateEditButtons() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_BusinessQuote = this.el.querySelector('[data-edit="businessQuote"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.disabled = this.isLock;
		N_edit_BusinessQuote.disabled = this.isLock;
		N_edit_AdditionalInformation.disabled = this.isLock;

		this.N_GridQuote!.isLock = this.isLock;
		this.N_QuoteOptionReadonly!.isLock = this.isLock;
		this.N_CGVTab!.isLock = this.isLock;
	}

	private async updateCustomerAddress() {
		const customer = this.form?.getDataByName('infos.customer');
		const addresses = await S_C_Address.getInstance().getByCustomerToSelect2(customer as string);

		if (addresses.length && customer) {
			const fullAddress = await S_C_Address.getInstance().getFullAddress(addresses[0]?.id);
			this.form?.setDataByName('infos.addressID', addresses[0]);
			this.form?.setDataByName('infos.fullAddress', fullAddress);
		} else {
			this.form?.setDataByName('infos.addressID', { id: '', text: '' });
			this.form?.setDataByName('infos.fullAddress', '');
		}
	}

	private updateGlobalPrice() {
		const N_verticalAccordion = this.el.querySelector('ap-vertical-accordion') as VerticalAccordion;
		N_verticalAccordion.setTabSubTitle('grid-container', '<span class="text-green">' + this.N_GridQuote!.globalPriceText + '</span>');
	}

	private updateFileNumber(data: { [key: string]: any }) {
		const numberFile = _.compact(_.values(data.attachments || {})).length.toString();

		const N_verticalAccordion = this.el.querySelector('ap-vertical-accordion') as VerticalAccordion;
		const N_quotesInformations = this.el.querySelector('#quotes-informations') as HTMLDivElement;

		N_verticalAccordion.setTabSubTitle(N_quotesInformations.id, '<span class="text-red">Fichiers : ' + numberFile + '</span>');
	}

	private updateDocumentLink() {
		const N_interventions = this.el.querySelector('#interventions') as HTMLButtonElement;
		const N_quoteToDo = this.el.querySelector('#quotes_to_do') as HTMLButtonElement;

		N_interventions.classList.add('d-none');
		N_quoteToDo.classList.add('d-none');

		if (this.options.intervention) {
			N_interventions.classList.remove('d-none');
		}

		if (this.options.quote_to_do) {
			N_quoteToDo.classList.remove('d-none');
		}
	}

	private updateButtonNotification() {
		const N_notification = this.el.querySelector('#notification') as HTMLElement;
		const state = this.form?.getDataByName('infos.state');
		//"6": "À Valider"
		if (state === '6') {
			if (this.options.notification) {
				N_notification.classList.remove('d-none');
			}
		}
	}

	private updateGridQuote() {
		this.N_GridQuote!.optionsGrid = this.saveData;
	}

	public destructor() {
		super.destructor();

		clearInterval(this.intervalSocketIo);

		this.N_GridQuote?.destructor();
	}
}

export default QuotesCtrl;
