// CORE
import { DOM, Form } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
import T_modal from '../../../../tpl/modals/customers/editPageFromQuote/dates.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

type DatesData = {
	infos: {
		date: Moment,
		entryDate: Moment
		deliveryDate: Moment
	}
};

class Dates extends Modal {
	private form: Form | null;
	private previousCallback: null | (() => void) = null;

	constructor(data: DatesData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			const N_previous = this.element.querySelector('#previous') as HTMLButtonElement;

			N_previous.addEventListener('click', () => {
				if (this.previousCallback) {
					this.previousCallback();
				}

				DOM.nextTick(() => {
					this.reject(false);
				});
			});

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };
					this.resolve(data);
				}));
			});
		});
	}

	public setPreviousCallback(cb: () => void) {
		this.previousCallback = cb;
		return this;
	}

	private async setData(data: DatesData) {
		const res: { [key: string]: any } = {
			infos: {
				date: data.infos.date,
				entryDate: data.infos.entryDate,
				deliveryDate: data.infos.deliveryDate
			}
		};

		this.form?.setData(res);
	}
}

export default Dates;
