// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../../tpl/modals/providers/editPage/amounts.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT

// SERVICE

type AmountsData = {
	infos: {
		tva: string
	}
};

class Amounts extends Modal {
	private form: Form | null;

	constructor(data: AmountsData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					this.resolve(data);
				}));
			});
		});
	}

	private async setData(data: AmountsData) {
		const res: { [key: string]: any } = {
			infos: {
				tva: data.infos.tva
			}
		};

		this.form?.setData(res);
	}
}

export default Amounts;
