// CORE
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../../tpl/modals/customers/detailsDeliveries.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

class DetailsDeliveries extends Modal {
	constructor(data: any[]) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const gridOptions: GridOptions = agUtils.french<GridOptions>({
				animateRows: true,
				suppressDragLeaveHidesColumns: true,
				rowData: data,
				columnDefs: [
					{
						headerName: 'Produit',
						width: 150,
						field: 'text',
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						floatingFilterComponentParams: {
							suppressFilterButton: true
						},
						filterParams: {
							textFormatter: (result: string) => {
								if (result === null) return null;
								return _.deburr(result.toLowerCase());
							},
							debounceMS: 200
						}
					}, {
						headerName: 'Quantité commande',
						cellClass: ['text-right', 'text-monospace'],
						suppressSizeToFit: true,
						width: 200,
						valueGetter: (params) => {
							if (params.data.quantity === 0) {
								return '--';
							}
							return params.data.quantity;
						}
					}, {
						headerName: 'Quantité livrée',
						cellClass: ['text-right', 'text-monospace'],
						suppressSizeToFit: true,
						width: 200,
						field: 'deliveredQuantity'
					}, {
						headerName: 'Quantité restante',
						cellClass: ['text-right', 'text-monospace'],
						suppressSizeToFit: true,
						width: 200,
						valueGetter: (params) => {
							if (params.data.quantity === 0) {
								return '--';
							}
							return params.data.quantity - params.data.deliveredQuantity;
						}
					}
				],
				defaultColDef: {
					suppressMenu: true,
					resizable: true
				},
				onGridReady: (params) => {
					params.api.sizeColumnsToFit();
				}
			});

			const N_grid = this.element.querySelector('#grid') as HTMLElement;

			new Grid(N_grid, gridOptions, { modules: AllModules });
		});
	}
}

export default DetailsDeliveries;
