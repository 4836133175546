import { Alert, Controller, Router, toaster } from '@autoprog/core-client';
import agUtils from '@libs/agGrid/french';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter';
import Decimal from '@libs/utils/Decimal';

import S_Pdt_Provider from '@services/Product/ProductProviderService';
import S_Product from '@services/Product/ProductService';

import CE_Select2 from '@libs/customElement/Select2';

import Loader from '@libs/Loader';

import _ from 'lodash';
import moment from 'moment';
class UpdateBuyingPrice extends Controller {
	private el: HTMLElement;
	private gridOptions: GridOptions = {};
	private products: { [key: string]: any } = {};

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		const N_save = this.el.querySelector('#save') as HTMLButtonElement;

		const N_provider = this.el.querySelector('[name="provider"]') as CE_Select2;

		N_provider.create(this.el);

		S_Product.getInstance().getDataToAgGrid().then(({ rowData }) => {
			for (const item of rowData) {
				this.products[item._id] = item;
			}
		});

		N_provider.on('change', async (provider) => {
			Loader.getInstance().open();

			const products = await S_Pdt_Provider.getInstance().getByProvider(provider as string);

			const res: any[] = [];

			for (const item of products) {
				if (this.products[item.id_product]) {
					const obj: { [key: string]: any } = {
						...(this.products[item.id_product] || {})
					};

					obj.oldValue = item;
					obj.newValue = {
						_id: obj._id,
						public_price: Decimal.setDisplayNumber(item.public_price).toNumber(),
						discount: Decimal.setDisplayNumber(item.discount).toNumber(),
						purchase_price: Decimal.setDisplayNumber(item.purchase_price).toNumber(),
						shipPrice: Decimal.setDisplayNumber(item.shipPrice).toNumber(),
						costPrice: Decimal.setDisplayNumber(item.costPrice).toNumber(),
						marginRate: Decimal.setDisplayNumber(item.marginRate).toNumber(),
						providerSellPrice: Decimal.setDisplayNumber(item.providerSellPrice).toNumber()
					};

					res.push(obj);
				}
			}

			this.gridOptions.api?.setRowData(res);

			Loader.getInstance().close();
		});

		N_provider.postInit();

		N_save.addEventListener('click', async () => {
			const docs: { [key: string]: any }[] = [];

			this.gridOptions.api?.forEachNode((node) => {
				const obj = {
					_id: node.data.oldValue._id,
					public_price: Decimal.setDisplayNumber(node.data.newValue.public_price).toNumber(),
					discount: Decimal.setDisplayNumber(node.data.newValue.discount).toNumber(),
					purchase_price: Decimal.setDisplayNumber(node.data.newValue.purchase_price).toNumber(),
					shipPrice: Decimal.setDisplayNumber(node.data.newValue.shipPrice).toNumber(),
					costPrice: Decimal.setDisplayNumber(node.data.newValue.costPrice).toNumber(),
					marginRate: Decimal.setDisplayNumber(node.data.newValue.marginRate).toNumber(),
					providerSellPrice: Decimal.setDisplayNumber(node.data.newValue.providerSellPrice).toNumber()
				};
				docs.push(obj);
			});

			//TODO: save multiple
			for (const i in docs) {
				await S_Pdt_Provider.getInstance().save(docs[i]);
			}

			toaster.success('Sauvegarde Réussi');

			Router.getInstance().navigate('#module/apps/products');
		});

		this.initGrid();
		this.initEvents();
	}

	private initEvents() {
		this.initHelperEvent();
	}

	private initHelperEvent() {
		const popoverContent = 'A + B = C';

		const N_helper = this.el.querySelector('#calcul-helper') as HTMLElement;
		N_helper.setAttribute('popover', JSON.stringify({ title: 'Comment sont calculés les prix ?', content: popoverContent, trigger: 'hover', placement: 'left', sanitize: false }));
	}

	private async initGrid() {
		this.gridOptions = agUtils.french<GridOptions>({
			rowData: [],
			suppressContextMenu: true,
			columnDefs: [
				{
					headerName: 'Derniere mise a jour',
					field: 'lastUpdatePrice',
					width: 150,
					suppressSizeToFit: true,
					floatingFilter: true,
					filter: 'agNumberColumnFilter',
					floatingFilterComponent: DatePickerRangeFilter,
					filterParams: {
						newRowsAction: 'keep'
					},
					valueGetter: (params) => {
						return parseInt(moment(params.data.lastUpdatePrice).format('x'));
					},
					cellRenderer: (params) => {
						return moment(params.value).format('DD/MM/YYYY');
					}
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: S_Product.getInstance().referenceKey,
					width: 150,
					suppressSizeToFit: true,
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						newRowsAction: 'keep',
						textFormatter: (result: string) => {
							if (result === null) return null;
							return _.deburr(result.toLowerCase());
						},
						debounceMS: 200
					}
				}, {
					headerName: 'Désignation',
					field: 'name',
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						newRowsAction: 'keep',
						textFormatter: (result: string) => {
							if (result === null) return null;
							return _.deburr(result.toLowerCase());
						},
						debounceMS: 200
					}
				}, {
					headerName: 'Marque',
					field: 'brand',
					width: 150,
					suppressSizeToFit: true,
					floatingFilter: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: {
						newRowsAction: 'keep',
						applyMiniFilterWhileTyping: true
					}
				}, {
					headerComponentParams: {
						template: `
						<div class="ag-cell-label-container" role="presentation">
							${this.cellRendererButton('Prix Public', 'public_price')}
						</div>`
					},
					field: 'public_price',
					width: 250,
					cellRenderer: (params) => {
						return this.cellRenderer(params, 'public_price', '€', true, 4);
					}
				}, {
					headerComponentParams: {
						template: `
						<div class="ag-cell-label-container" role="presentation">
							${this.cellRendererButton('Remise', 'discount', true)}
						</div>`
					},
					field: 'discount',
					cellRenderer: (params) => {
						return this.cellRenderer(params, 'discount', '%', true);
					}
				}, {
					headerName: 'Prix d\'achat',
					field: 'purchase_price',
					width: 250,
					cellRenderer: (params) => {
						return this.cellRenderer(params, 'purchase_price', '€', false, 4);
					}
				}, {
					headerComponentParams: {
						template: `
						<div class="ag-cell-label-container" role="presentation">
							${this.cellRendererButton('Prix Transport', 'shipPrice')}
						</div>`
					},
					field: 'shipPrice',
					cellRenderer: (params) => {
						return this.cellRenderer(params, 'shipPrice', '€', true);
					}
				}, {
					headerName: 'Cout de revient',
					field: 'costPrice',
					cellRenderer: (params) => {
						return this.cellRenderer(params, 'costPrice', '€');
					}
				}, {
					headerComponentParams: {
						template: `
						<div class="ag-cell-label-container" role="presentation">
							${this.cellRendererButton('Taux de marge', 'marginRate')}
						</div>`
					},
					field: 'marginRate',
					cellRenderer: (params) => {
						return this.cellRenderer(params, 'marginRate', '%', true);
					}
				}, {
					headerName: 'Prix de vente',
					field: 'providerSellPrice',
					cellRenderer: (params) => {
						return this.cellRenderer(params, 'providerSellPrice', '€');
					}
				}
			],
			onGridReady: () => {
				this.initHeaderButtonsEvents();
			},
			defaultColDef: {
				suppressMenu: true,
				resizable: true,
				suppressMovable: true
			}
		});

		const N_grid = this.el.querySelector('#grid') as HTMLElement;

		new Grid(N_grid, this.gridOptions, { modules: AllModules });
	}

	private cellRenderer(params: any, key: string, unit: string, editable: boolean = false, numberDecimal: number = 2) {
		const N_div = document.createElement('div');
		N_div.classList.add('d-flex', 'align-items-center', 'justify-content-between');
		N_div.innerHTML = `
			<div class="text-muted">${Decimal.setDisplayNumber(params.data.oldValue[key] || '').setSuffixAndHumanizeNumber(unit, numberDecimal)}</div>
			<div class="h5"> &rarr; </div>
			<div style="max-width:50%">
				<input class="${editable ? 'form-control' : 'form-control-plaintext'} text-right" value="">
			</div>
		`;

		const N_input = N_div.querySelector('input') as HTMLInputElement;
		N_input.value = Decimal.setDisplayNumber(params.data.newValue[key] || params.data.oldValue[key] || '').humanizeNumber(numberDecimal);
		N_input.disabled = !editable;
		N_input.addEventListener('change', () => {
			params.node.data.newValue[key] = Decimal.setDisplayNumber(N_input.value).toNumber();
			params.node.setData(params.node.data);
			this.onUpdateRow();
		});
		return N_div;
	}

	private cellRendererButton(headerName: any, key: string, isPercent?: boolean) {
		const N_div = document.createElement('div');
		N_div.classList.add('d-flex', 'justify-content-center', 'align-items-center', 'w-100');

		const N_headerName = document.createElement('div');
		N_headerName.classList.add('mr-auto', 'price-header-name');
		N_headerName.innerHTML = headerName;

		const N_btnFix = document.createElement('button');
		N_btnFix.classList.add('btn', 'btn-price-header', 'ml-auto', 'mr-2', 'price-btn-fix');
		N_btnFix.setAttribute('tooltip', 'Mettre à jour avec un montant fixe');
		N_btnFix.innerHTML = '<i class="icon icon-ri-pencil-fill" style="font-size: 10px"></i>';
		N_btnFix.dataset.col = key;
		N_btnFix.setAttribute('headerName', headerName);

		N_div.appendChild(N_headerName);
		N_div.appendChild(N_btnFix);

		if (!isPercent) {
			const N_btnPercent = document.createElement('button');
			N_btnPercent.classList.add('btn', 'btn-price-header', 'price-btn-percent');
			N_btnPercent.setAttribute('tooltip', 'Mettre à jour avec un pourcentage');
			N_btnPercent.innerHTML = '<i class="icon icon-solid-percent" style="font-size: 9px"></i>';
			N_btnPercent.dataset.col = key;
			N_btnPercent.setAttribute('headerName', headerName);
			N_div.appendChild(N_btnPercent);
		}

		return N_div.outerHTML;
	}

	private initHeaderButtonsEvents() {
		const fixButtons = this.el.querySelectorAll('.price-btn-fix');

		fixButtons.forEach((N_btnFix) => {
			const key = N_btnFix.getAttribute('data-col') as string;
			const headerName = N_btnFix.getAttribute('headerName');

			N_btnFix.addEventListener('click', () => {
				Alert.prompt('Mettre à jour le ' + headerName?.toLowerCase() + ' avec une valeur fixe (€)').then((n) => {
					const number = Decimal.setDisplayNumber(n as string);

					this.gridOptions.api?.forEachNodeAfterFilter((node) => {
						let item = node.data;
						const oldPrice = Decimal.setDisplayNumber(item.oldValue[key] || '0');

						item.newValue[key] = oldPrice.plus(number).toNumber();

						item = this.calcPurchasePrice(item);
						item = this.calcCostPrice(item);
						item = this.calcSellPrice(item);
						node.setData(item);
					});

					this.gridOptions.api?.refreshCells({ force: true });
				});
			});
		});

		const percentButtons = this.el.querySelectorAll('.price-btn-percent');

		percentButtons.forEach((N_btnPercent) => {
			const key = N_btnPercent.getAttribute('data-col') as string;
			const headerName = N_btnPercent.getAttribute('headerName');

			N_btnPercent.addEventListener('click', () => {
				Alert.prompt('Mettre à jour le ' + headerName?.toLowerCase() + ' avec un pourcentage (%)').then((n) => {
					const number = Decimal.setDisplayNumber(n as string);

					this.gridOptions.api?.forEachNode((node) => {
						let item = node.data;
						const oldPrice = Decimal.setDisplayNumber(item.oldValue[key] || '0');

						const tmp = new Decimal(1).plus(number.dividedBy(100));

						// (oldPrice * (1 + (number as number) / 100));
						item.newValue[key] = oldPrice.times(tmp);

						item = this.calcPurchasePrice(item);
						item = this.calcCostPrice(item);
						item = this.calcSellPrice(item);

						node.setData(item);
					});

					this.gridOptions.api?.refreshCells({ force: true });
				});
			});
		});
	}

	private onUpdateRow() {
		const res: { [key: string]: any }[] = [];

		this.gridOptions.api?.forEachNode((node) => {
			let item = node.data;
			item = this.calcPurchasePrice(item);
			item = this.calcCostPrice(item);
			item = this.calcSellPrice(item);
			res.push(item);
		});

		this.gridOptions.api?.setRowData(res);
	}

	private calcPurchasePrice(item: { [key: string]: any }) {
		const discount = item.newValue.discount || item.oldValue.discount;
		const publicPriceFormValue = item.newValue.public_price || item.oldValue.public_price;

		// Prix achat = prix public x  (1 - (Pourc. Remise / 100))
		const percentDiscount = new Decimal(1).minus(Decimal.setDisplayNumber(discount).dividedBy(100));
		const purchasePrice = Decimal.setDisplayNumber(publicPriceFormValue).times(percentDiscount).toDecimalPlaces(4);

		item.newValue.purchase_price = purchasePrice.toNumber();

		return item;
	}

	private calcCostPrice(item: { [key: string]: any }) {
		const shipPriceFormValue = item.newValue.shipPrice || item.oldValue.shipPrice;
		const shipPrice = Decimal.setDisplayNumber(shipPriceFormValue);
		const purchasePriceFormValue = item.newValue.purchase_price || item.oldValue.purchase_price;
		const purchasePrice = Decimal.setDisplayNumber(purchasePriceFormValue);

		// Coût de revient = Prix achat + Prix transport
		const costPrice = purchasePrice.plus(shipPrice).toDecimalPlaces(2);

		item.newValue.costPrice = costPrice.toNumber();
		return item;
	}

	/**
	 * Calcul du prix théorique de vente avec la marge commerciale
	 */
	private calcSellPrice(item: { [key: string]: any }) {
		const costPriceFormValue = item.newValue.costPrice || item.oldValue.costPrice;
		const costPrice = Decimal.setDisplayNumber(costPriceFormValue);

		const marginRateFormValue = item.newValue.marginRate || item.oldValue.marginRate;
		const marginRate = Decimal.setDisplayNumber(marginRateFormValue);

		// prix de vente = coût de revient / (1 - tx marge/100)
		const recommandedSellPrice = costPrice.dividedBy(new Decimal(1).minus(marginRate.dividedBy(100))).toDecimalPlaces(2);

		item.newValue.providerSellPrice = recommandedSellPrice.toNumber();

		return item;
	}

	public destructor() {

	}
}

export default UpdateBuyingPrice;
