module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\n<style>\n\n@page {\n    size: A4 ' +
((__t = (orientation)) == null ? '' : __t) +
';\n}\n\n</style>\n\n<div class="pagePrint">\n\n    <table>\n\n        <tbody>\n\n            <tr>\n\n                ';
 for (const s of settings ) { ;
__p += '\n                    <td class="bg-grey-500 border border-dark text-center">' +
((__t = (s.name)) == null ? '' : __t) +
'</td>\n                ';
 } ;
__p += '\n\n            </tr>\n\n            ';
for(let item of data){;
__p += '\n\n            <tr >\n\n                ';
 for (const s of settings ) { ;
__p += '\n                    ';
 let value = _.get(item,s.key);;
__p += '\n\n                    ';
 if(value === true){ value = 'Oui'; };
__p += '\n                    ';
 if(value === false){ value = 'Non'; };
__p += '\n                    <td class="border border-dark text-truncate ' +
((__t = (s.class)) == null ? '' : __t) +
'" id="' +
((__t = (s.key)) == null ? '' : __t) +
'">' +
((__t = (value)) == null ? '' : __t) +
'</td>\n                ';
 } ;
__p += '\n\n            </tr>\n                \n            ';
};
__p += '\n\n        </tbody>\n\n    </table>\n\n</div>';

}
return __p
}