// CORE
import { Alert, services } from '@autoprog/core-client';

// NODE_MODULE
import h from 'hyperscript';

// TEMPLATE
// LIBS
import C_Database from '@js/controllers/Database';

import C_User from './User';

// PRINTER
// UTILS
import Utils from '@libs/utils/Utils';

// MODAL
// CUSTOM_ELEMENT
// SERVICE

class Users extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Utilisateurs',
			database: 'users'
		});

		const N_add = document.querySelector('#add') as HTMLButtonElement;
		N_add.setAttribute('permission', `${this.permissionKey}.CREATE`);

		N_add.addEventListener('click', () => {
			C_User.open(null, {
				add: true
			});
		}, {
			signal: this.abortSignal
		});

		const N_header = this.el.querySelector('#header-grid') as HTMLElement;
		N_header.classList.add('bg-white');

		const N_div = h('div.font-weight-bold.text-xl', 'Liste des utilisateurs');
		N_header.insertBefore(N_div, N_header.firstChild);
	}

	protected buttonEdit(params: any) {
		C_User.open(params.data._id);
	}

	protected buttonDelete(params: any) {
		if (Utils.userID === params.data._id) {
			Alert.error('Erreur', 'Impossible de ce supprimer soi-même');
		} else {
			super.buttonDelete(params);
		}
	}

	public addOnButtonGrid(params: any) {
		const N_fakeLoginButton = h('button.btn.btn-transparent', {
			attrs: {
				permission: 'MAIN_SETTINGS.USERS_SYSTEM.USERS.IMPERSONATE_USER'
			}
		}, h('ap-icon', { attrs: { name: 'ghost' } })) as HTMLButtonElement;

		if (!params.data.isSuperAdmin) {
			N_fakeLoginButton.addEventListener('click', async () => {
				try {
					await services.UserService.getInstance().fakeLogin(params.value);
					window.location.reload();
				} catch (e: any) {
					Alert.error(`Impossible de se faire passer pour ${params.value}`, '', e);
				}
			});
			N_fakeLoginButton.setAttribute('confirmation', '');
			N_fakeLoginButton.classList.add('text-indigo');
		} else {
			N_fakeLoginButton.classList.add('text-grey-300');
			N_fakeLoginButton.disabled = true;
		}

		return [N_fakeLoginButton];
	}
}

export default Users;
