// CORE
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions, GroupCellRenderer } from '@ag-grid-enterprise/all-modules';

// TEMPLATE
// LIBS
import C_Quote from '@modules/Quotes/js/controllers/AddQuotes';
import SettingsApps from '@libs/Settings';

// PRINTER
// UTILS
// MODAL

// CUSTOM_ELEMENT
// SERVICE

class ComptaOrderTab extends HTMLElement {
	public static readonly tagName: string = 'ap-compta-order-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private _gridOptions: GridOptions = {};

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		const data = (SettingsApps.getInstance().get('COMPTA') || {}) as { [key: string]: any };

		if (data.disable) {
			this.classList.add('d-none');
		}

		this.setAttribute('permission', 'ORDERS._CUSTOMERS._COMPTA.SHOW');

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Comptabilité">
						<i class="icon icon-ri-money-euro"></i>
					</div>
					<span>Comptabilité</span>
				</a>
			</li>
        `;

		this.id = '';
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');
		N_div.id = this.idTab;
		N_div.innerHTML = `
			<div class="d-flex flex-column h-100">
                <div class="title">
					Comptabilité
					<div class="ml-auto">
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
                </div>
                <div class="h-100 ag-theme-alpine grid"></div>
            </div>
		`;

		N_container.append(N_div);

		this.initGrid();
	}

	private initGrid() {
		this._gridOptions = agUtils.french({
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Devis',
					field: 'quote',
					rowGroup: true,
					hide: true
				},
				{
					headerName: 'Compte comptable',
					field: 'key',
					width: 200,
					suppressSizeToFit: true
				},
				{
					headerName: 'Libellé écriture compte',
					field: 'label'
				},
				{
					headerName: 'Montant HT',
					field: 'price',
					width: 120,
					cellClass: ['text-right', 'text-monospace'],
					suppressSizeToFit: true
				}
			],
			suppressContextMenu: true,
			groupRowRendererParams: {
				innerRenderer: (params: any) => {
					const N_div = document.createElement('div');

					let error = false;
					for (const item of params.node.childrenAfterGroup) {
						if (item.data.key === 'AUCUN') {
							error = true;
							break;
						}
					}

					const N_view = document.createElement('button');

					N_view.type = 'button';
					N_view.classList.add('h-100', 'py-0', 'btn-transparent', 'mx-2');
					N_view.setAttribute('confirmation', '');
					N_view.setAttribute('tooltip', 'Editer comptes comptables');
					N_view.innerHTML = '<i class="text-info icon icon-edit text-lg"></i>';

					N_view.addEventListener('click', async () => {
						C_Quote.open(params.node.childrenAfterGroup[0].data.quote);
					});

					N_div.innerHTML = `${error ? '<i class="icon icon-warning text-danger mr-2"></i>' : ''} ${params.node.childrenAfterGroup[0].data.number}`;

					N_div.append(N_view);

					return N_div;
				}
			},
			getRowClass: (params) => {
				if (params.data && params.data.key === 'AUCUN') {
					return 'text-danger';
				}
				return '';
			},
			groupRowRenderer: GroupCellRenderer,
			groupSelectsChildren: true,
			groupUseEntireRow: true,
			groupDefaultExpanded: 1
		} as GridOptions);

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	public set data(data: any[]) {
		this.gridOptions.api?.setRowData(data);
	}

	public get data(): any[] {
		const results: any[] = [];

		this._gridOptions.api?.forEachNode((node) => {
			results.push(node.data);
		});

		return results;
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public static register() {
		customElements.define(ComptaOrderTab.tagName, ComptaOrderTab);
	}
}

export default ComptaOrderTab;
