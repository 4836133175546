import { Controller, global } from '@autoprog/core-client';

import _ from 'lodash';
import axios from 'axios';

class Tools extends Controller {
	private N_list: HTMLElement;
	private N_content: HTMLElement;
	private data: any[] = [];

	constructor(el: HTMLElement) {
		super(el);

		this.N_list = el.querySelector('#list') as HTMLElement;
		this.N_content = el.querySelector('#content') as HTMLElement;

		this.init();
	}

	private async init() {
		const { data } = await axios.get(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}help/_all_docs`, {
			params: {
				include_docs: true
			}
		});

		this.data = _.map(data.rows, 'doc');

		let index = 0;
		for (const item of this.data) {
			const N_list = this.drawList(item, index.toString());
			const N_content = this.drawContent(item, index.toString());

			this.N_list.appendChild(N_list);
			this.N_content.appendChild(N_content);

			index++;
		}

		this.eventList();
	}

	private eventList() {
		const N_level1 = this.N_list.querySelectorAll('[level="1"]');

		for (const N_el of N_level1) {
			const N_title = N_el.querySelector('#title') as HTMLElement;

			N_title.addEventListener('click', () => {
				const N_icon = N_el.querySelector('.icon') as HTMLElement;
				const N_subMenu = N_el.querySelector('#subMenu') as HTMLElement;

				N_icon.classList.toggle('icon-solid-chevron-right');
				N_icon.classList.toggle('icon-solid-chevron-down');

				N_title.classList.toggle('font-weight-bold');

				N_subMenu.classList.toggle('d-none');
			});
		}

		const N_title = this.N_list.querySelectorAll('#title') as NodeListOf<HTMLElement>;

		for (const N_el of N_title) {
			N_el.addEventListener('click', () => {
				if ((N_el.parentNode as HTMLElement).getAttribute('level') !== '1') {
					const path = N_el.dataset.path || '';

					const N_scrollEl = this.N_content.querySelector(`[data-path="${path}"]`) as HTMLElement;

					N_scrollEl.scrollIntoView({
						behavior: 'smooth'
					});
				}
			});
		}
	}

	private drawContent(item: { [key: string]: any }, path = '', level = 0) {
		const N_result = document.createElement('div');

		N_result.setAttribute('level', level.toString());

		item.text = item.text || '';

		if (level === 0) {
			N_result.innerHTML = `
                <div class="font-weight-bold mt-3 h2 text-uppercase w-100" style="color:#424242;" id="title" data-path="${path}">
                    ${item.title}
                </div>
                <div>${item.text}</div>
                <div id="subMenu"></div>
            `;
		}

		if (level === 1) {
			N_result.innerHTML = `
                <div class="w-100 font-weight-bold my-3 h4" id="title" style="color:#424242;" data-path="${path}">
                     ${item.title}
                </div>
                <div>${item.text}</div>
                <div id="subMenu" class="mb-2"></div>
            `;
		}

		if (level === 2) {
			N_result.innerHTML = `
                <div class="h6" id="title" data-path="${path}">
                    ${item.title}
                </div>
                <div class="border-left-2x pl-3 mb-2">${item.text}</div>
            `;
		}

		const N_subMenu = N_result.querySelector('#subMenu') as HTMLElement;

		item.subMenu = item.subMenu || [];

		const nextLevel = level + 1;

		path += `.subMenu[${level}]`;

		for (const subMenu of item.subMenu) {
			N_subMenu.appendChild(this.drawContent(subMenu, path, nextLevel));
		}

		return N_result;
	}

	private drawList(item: { [key: string]: any }, path = '', level = 0) {
		const N_result = document.createElement('div');

		N_result.setAttribute('level', level.toString());

		if (level === 0) {
			N_result.innerHTML = `
                <div class="font-weight-bold mb-3 mt-3 text-lg text-uppercase w-100 cursor-pointer" style="color:#AAA" id="title" data-path="${path}">
                    ${item.title}
                </div>
                <div id="subMenu"></div>
            `;
		}

		if (level === 1) {
			N_result.innerHTML = `
                <div class="w-100 cursor-pointer" id="title" data-path="${path}">
                    <i class="icon icon-solid-chevron-right text-xs"></i> ${item.title}
                </div>
                <div class="d-none" id="subMenu"></div>
            `;
		}

		if (level === 2) {
			N_result.innerHTML = `
                <div class="border-left-2x pl-3 w-100 cursor-pointer" id="title" data-path="${path}">
                    ${item.title}
                </div>
            `;
		}

		const N_subMenu = N_result.querySelector('#subMenu') as HTMLElement;

		item.subMenu = item.subMenu || [];

		const nextLevel = level + 1;

		path += `.subMenu[${level}]`;

		for (const subMenu of item.subMenu) {
			N_subMenu.appendChild(this.drawList(subMenu, path, nextLevel));
		}

		return N_result;
	}

	public destructor() {

	}
}

export default Tools;
