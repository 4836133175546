import T_modal from '../../../tpl/modals/AddQuote/SelectFavorite.html';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

import Modal from '@libs/Modal';

import Options, { SettingQuoteOption } from '../../libs/GridOptions';

import S_Product from '@services/Product/ProductService';
import S_Q_Favorite from '@services/Quotes/FavoriteQuoteService';

class SelectFavorite extends Modal {
	private optionsGrid: Options;

	constructor(pref: { [key: string]: any }, quoteOptions: { [key: string]: any }) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.optionsGrid = new Options();

		this.on('opened', async () => {
			const { rowData } = await S_Q_Favorite.getInstance().getDataToAgGrid();

			const N_numberSelected = this.element.querySelector('#numberSelected') as HTMLElement;
			let number = 0;

			this.optionsGrid.updateInfos({ state: '0' });
			this.optionsGrid.updatePref(pref || {});
			this.optionsGrid.updateQuoteOptions(quoteOptions as SettingQuoteOption);

			const gridMaster = this.optionsGrid.gridMaster(this.element, 'favorite');

			gridMaster.columnDefs.pop();

			gridMaster.columnDefs[0].checkboxSelection = true;

			gridMaster.columnDefs.splice(1, 0, {
				field: 'title',
				headerName: 'Titre',
				suppressSizeToFit: true,
				width: 300
			});

			gridMaster.columnDefs.push({
				field: 'user',
				headerName: 'Utilisateur',
				width: 150,
				suppressSizeToFit: true
			});

			for (const item of gridMaster.columnDefs) {
				if (['quantity', 'price', 'sum', '_idGrid', 'unit'].includes(item.field || '')) {
					item.filter = false;
				}
			}

			const gridDetails = this.optionsGrid.gridDetails(this.element, 'favorite', () => { });

			gridDetails.columnDefs.pop();

			const gridOptions: GridOptions = agUtils.french<GridOptions>({
				rowData,
				...gridMaster,
				floatingFilter: true,
				suppressContextMenu: true,
				suppressRowClickSelection: true,
				rowSelection: 'multiple',
				defaultColDef: {
					suppressMenu: true,
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					sortable: true,
					resizable: true,
					filterParams: {
						newRowsAction: 'keep'
					}
				},
				masterDetail: true,
				detailRowHeight: 400,
				detailCellRendererParams: {
					detailGridOptions: <GridOptions>{
						...gridDetails,
						suppressDragLeaveHidesColumns: true,
						defaultColDef: {
							suppressMenu: true
						},
						onGridReady: (params) => {
							params.api.sizeColumnsToFit();
						}
					},
					template: () => {
						return '<div class="h-100"><div ref="eDetailGrid" class="h-100" style="border:2px solid black"></div></div>';
					},
					getDetailRowData: (params: any) => {
						params.successCallback(params.data.details);
					}
				},
				onRowSelected: (params) => {
					if (params.node.isSelected()) {
						number++;
					} else {
						number--;
					}
					N_numberSelected.innerHTML = number.toString();
				},
				onGridReady: (params) => {
					params.api?.sizeColumnsToFit();
				},
				onColumnResized: (params) => {
					if (params.finished) {
						params.api?.resetRowHeights();
					}
				}
			});

			const N_grid = this.element.querySelector('#grid') as HTMLElement;
			new Grid(N_grid, gridOptions, { modules: AllModules });

			const N_save = this.element.querySelector('#save') as HTMLElement;
			const N_update_label = this.element.querySelector('#update_label') as HTMLInputElement;

			N_save.addEventListener('click', async () => {
				if (gridOptions.api) {
					const data = gridOptions.api.getSelectedRows();

					let index = 0;

					for (const item of data) {
						item._idGrid = Date.now().toString(36) + '_' + index;
						item._idFav = item._id;

						delete item._id;
						delete item._rev;
						delete item.title;

						for (const detail of item.details) {
							if (detail.reference) {
								try {
									const product = await S_Product.getInstance().getById(detail.reference);

									if (product.price) {
										detail.price = product.price;
									}

									if (product.comptaVente) {
										detail.comptaVente = product.comptaVente;
									}

									detail.costPrice = product.defaultProvider?.costPrice || 0;

									if (N_update_label.checked && product.name) {
										detail.label = product.name;
									}
								} catch (e) {

								}
							}
						}

						index++;
					}

					this.resolve(data);
				}
			});
		});
	}
}

export default SelectFavorite;
