import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

import M_AddBrochure from '../../modals/AddQuote/AddBrochure';

import S_Q_Brochure from '@services/Quotes/BrochureQuoteService';

import _ from 'lodash';

class BrochureTab extends HTMLElement {
	public static readonly tagName: string = 'ap-brochure-quotes-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private _gridOptions: GridOptions = {};

	private N_el: HTMLElement | null = null;

	private ignoreUpdate = false;

	private _update: () => void = () => { };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Plaquettes">
						<i class="icon icon-solid-newspaper"></i>
					</div>
					<span>Plaquettes</span>
				</a>
			</li>
		`;

		this.removeAttribute('id');
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'h-100', 'fade');
		this.N_el.setAttribute('role', 'tabpanel');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="title" display="desktop">
					Plaquettes
					<div class="ml-auto">
						<button class="btn btn-success" type="button" id="add" permission="BROCHURES.ADD">
							<i class="icon icon-solid-plus mr-2"></i>Ajouter
						</button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
				</div>
				<div class="row h-100">

					<div class="col-6 h-100">
						<div class="h-100 ag-theme-alpine grid"></div>
					</div>

					<div class="col-6 h-100">

						<div class="d-flex flex-column h-100 border" style="border-radius: 10px;">

							<div style="height: 30px;" id="title_brochure" class="text-center font-weight-bold">

							</div>

							<div style="height: calc(100% - 30px); border-radius: 0px 0px 10px 10px;"
								class="bg-white scroll-y d-flex align-items-center justify-content-center">

								<div id="view_brochure">
									<span class="text-muted">Aperçu</span>
								</div>

							</div>

						</div>

					</div>

				</div>
			</div>
		`;

		const N_add = this.N_el.querySelector('#add') as HTMLButtonElement;

		N_add.addEventListener('click', () => {
			new M_AddBrochure().open().then((data) => {
				this._gridOptions.api?.applyTransaction({
					add: [data]
				});
			});
		});

		N_container.append(this.N_el);

		this.initGrid();
	}

	public setOnUpdate(cb: () => void) {
		this._update = cb;
	}

	private async initGrid() {
		const N_viewBrochure = document.querySelector('#view_brochure') as HTMLElement;
		const N_titleBrochure = document.querySelector('#title_brochure') as HTMLElement;

		const brochures = await S_Q_Brochure.getInstance().getDataToAgGrid();

		this._gridOptions = agUtils.french<GridOptions>({
			localeText: { noRowsToShow: 'Aucune Plaquettes' },
			rowData: brochures.rowData,
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowSelection: 'multiple',
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Nom',
					field: 'name',
					checkboxSelection: true
				}, {
					headerName: 'Ordre',
					field: 'order',
					editable: true
				}, {
					headerName: 'Action',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						N_div.classList.add('btn-group');

						const N_edit = document.createElement('button');
						N_edit.classList.add('h-100', 'py-0', 'btn-transparent');
						N_edit.type = 'button';
						N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';

						N_edit.setAttribute('permission', 'BROCHURES.ADD');

						N_edit.addEventListener('click', () => {
							new M_AddBrochure(params.data._id).open().then((data) => {
								params.node.setDataValue('name', data.name);

								params.api.refreshCells({ force: true });
							});
						});

						const N_view = document.createElement('button');
						N_view.classList.add('h-100', 'py-0', 'btn-transparent');
						N_view.type = 'button';
						N_view.innerHTML = '<i class="text-dark h5 icon icon-eye"></i>';

						N_view.addEventListener('click', () => {
							N_viewBrochure.innerHTML = params.data.svg;
							N_titleBrochure.innerHTML = params.data.name;
						});

						N_div.appendChild(N_edit);
						N_div.appendChild(N_view);

						return N_div;
					}
				}
			],
			onRowSelected: (params) => {
				let index = 0;

				params.api.forEachNode((node) => {
					if (node.isSelected()) {
						index++;
					}
				});

				if (_.isUndefined(params.node.data.order)) {
					params.node.setDataValue('order', index);
				}

				params.api.refreshCells({ force: true });

				if (!this.ignoreUpdate) {
					this._update();
				}
			},
			getContextMenuItems: (params) => {
				return [{
					name: 'Ajouter une brochure',
					action: () => {
						new M_AddBrochure().open().then(async (data) => {
							params.api?.applyTransaction({
								add: [data]
							});
						});
					}
				}];
			}
		});

		const N_grid = this.N_el!.querySelector('.grid') as HTMLDivElement;

		new Grid(N_grid, this._gridOptions, { modules: AllModules });
	}

	public set data(data: { [key: string]: any }) {
		this.ignoreUpdate = true;
		this._gridOptions.api?.forEachNode((node) => {
			if (!_.isUndefined(data[node.data._id])) {
				node.setSelected(true);
				node.setDataValue('order', data[node.data._id]);
			}
		});

		//pour eviter que l'evenement onRowSelected ce leve
		setTimeout(() => {
			this.ignoreUpdate = false;
		}, 3000);
	}

	public get data(): { [key: string]: any } {
		const results: { [key: string]: any } = {};

		this._gridOptions.api?.stopEditing();

		this._gridOptions.api?.forEachNode((node) => {
			if (node.isSelected()) {
				results[node.data._id] = node.data.order;
			}
		});

		return results;
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public static register() {
		customElements.define(BrochureTab.tagName, BrochureTab);
	}
}

export default BrochureTab;
