import GenericService from '../GenericService';

class ProductStockService extends GenericService {
	private static instance: ProductStockService | null = null;

	constructor() {
		super('products-stocks');
	}

	public static getInstance(): ProductStockService {
		if (!ProductStockService.instance) {
			ProductStockService.instance = new ProductStockService();
		}

		return ProductStockService.instance;
	}

	public async getByStockAndProduct(id_product: string, id_provider: string): Promise<{ [key: string]: any }> {
		const data = await this.get_function('getByStockAndProduct', {
			id_product,
			id_provider
		});

		return data[0];
	}

	public async getByProduct(id_product: string): Promise<{ [key: string]: any }> {
		return await this.get_function('getByProduct', {
			id_product
		});
	}

	public async getByStock(id_stock: string): Promise<{ [key: string]: any }> {
		return await this.get_function('getByStock', {
			id_stock
		});
	}
}

export default ProductStockService;
