import { Form, toaster } from '@autoprog/core-client';

import SettingsApps from '@libs/Settings';

import axios from 'axios';

import Utils from '../utils/Utils';
import _ from 'lodash';

class PrintOptionTab extends HTMLElement {
	public static readonly tagName: string = 'ap-print-options-tab';

	protected _dataId = (): string | null => { return null; };
	protected _data = (): any => { return {}; };
	protected tabId: string;
	protected selectorTab: string;
	protected isDefault: boolean;
	protected form: Form | null;
	protected isActive: boolean;
	public table: string;
	protected N_tabPanel: Element | null;

	private _update = (): void => { };

	protected eventChangeForm = '**.change';

	private N_printContainer: HTMLIFrameElement | null;
	private N_spinner: HTMLElement | null;

	protected KEY_SETTINGS = '';

	constructor() {
		super();

		this.tabId = this.getAttribute('tabID') || 'options';
		this.isActive = !!this.getAttribute('active');
		this.selectorTab = this.dataset.tabContainer || '.tab-content';
		this.form = null;
		this.N_tabPanel = null;
		this.isDefault = false;

		this.N_printContainer = null;
		this.N_spinner = null;

		this.table = '';

		const type = this.getAttribute('type');

		if (type === 'page') {
			this.innerHTML = `
				<li>
					<a class="${this.isActive ? 'active' : ''}" data-toggle="tab" href="#${this.tabId}" role="tab">
						<div class="icon-container" tooltip="Options d'impression">
							<i class="icon icon-printer"></i>
						</div>
						<span>Options d'impression</span>
					</a>
				</li>
			`;
		} else {
			this.innerHTML = `
				<li class="nav-item border-right">
					<a class="nav-link px-3 position-relative ${this.isActive ? 'active' : ''}" data-toggle="tab" href="#${this.tabId}" role="tab">
						<span>Options d'impression</span>
					</a>
				</li>
			`;
		}
	}

	public async connectedCallback() { }

	protected getFormTemplate() {
		return '';
	}

	public setParentElement(parent: HTMLElement) {
		this.isDefault = !!this.getAttribute('default');

		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_tabPanel = document.createElement('div');
		this.N_tabPanel.classList.add('tab-pane', 'h-100', 'fade');
		this.N_tabPanel.id = this.tabId;
		this.N_tabPanel.setAttribute('role', 'tabpanel');

		this.N_tabPanel.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="title" display="desktop">
					Options d'impression
					<div class="ml-auto">
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
				</div>
				<div class="row h-100 no-scroll">
					<div class="col-md-3 h-100 d-flex flex-column" id="optionsForm"></div>
					<div class="col h-100 position-relative" id="containerFakePrint">
						<iframe class="h-100 w-100 border" id="fakePrint" style=" z-index:1"></iframe>
						<div class="position-absolute h-100 w-100 px-3" style="top: 0; left: 0; opacity:0; z-index:9999; transition: opacity 0.2s;" id="spinner">
							<div class="d-flex justify-content-center align-items-center h-100 w-100" style="background: #9e9e9e;">
								<ap-icon class="icon-9x icon-spin text-white" name="loader-4/line"></ap-icon>
							</div>
						</div>
					</div>
				</div>
			</div>
		`;

		const N_optionsForm = this.N_tabPanel.querySelector('#optionsForm') as HTMLElement;
		const N_containerFakePrint = this.N_tabPanel!.querySelector('#containerFakePrint') as HTMLElement;

		const formTemplate = this.getFormTemplate();

		if (formTemplate) {
			N_optionsForm.innerHTML = `
                <div class="mb-2 d-flex">
                    <button class="btn btn-info flex-grow-1" type="button" id="applyPref">
                        <i class="icon icon-solid-check"></i>
                        Actualiser
                    </button>
                
                    <button class="btn btn-grey-500 ${this.KEY_SETTINGS ? '' : 'd-none'}" type="button" id="default-value-print" confirmation="" tooltip="Valeurs par défaut">
                        <i class="icon icon-solid-retweet"></i>
                    </button>
                </div>
                <div class="h-100 scroll-y">
                    ${formTemplate}
                </div>
            `;
		} else {
			N_optionsForm.classList.remove('d-flex');
			N_optionsForm.classList.add('d-none');

			N_containerFakePrint.innerHTML += `
                <div class="mb-2 d-flex position-absolute" style="top:5px; left: 20px;">
                    <button class="btn btn-info flex-grow-1 rounded" type="button" id="applyPref">
                        <i class="icon icon-solid-check"></i>
                        Actualiser
                    </button>
                </div>
            `;
		}

		this.N_printContainer = this.N_tabPanel!.querySelector('#fakePrint') as HTMLIFrameElement;
		this.N_spinner = this.N_tabPanel!.querySelector('#spinner') as HTMLElement;

		if (this.isActive) {
			this.N_tabPanel!.classList.add('active', 'show');
		}

		const N_form = this.N_tabPanel!.querySelector('#optionsForm') as HTMLFormElement;

		this.form = new Form(N_form);

		const N_apply = this.N_tabPanel!.querySelector('#applyPref') as HTMLButtonElement;

		this.form.on(this.eventChangeForm, () => {
			this._update();
		});

		if (this.isDefault) {
			N_apply.parentElement?.remove();

			if (this.form) {
				let timeoutDebounce: any = null;
				this.form.on(this.eventChangeForm, () => {
					timeoutDebounce && clearTimeout(timeoutDebounce);
					timeoutDebounce = setTimeout(() => {
						this.fakePrint();
					}, 300);
				});
			}
		} else {
			N_apply?.addEventListener('click', () => {
				N_apply.loading(this.fakePrint());
			});

			const N_applyDefault = this.N_tabPanel!.querySelector('#default-value-print') as HTMLButtonElement;

			if (this.KEY_SETTINGS) {
				N_apply.style.borderBottomRightRadius = '0';
				N_apply.style.borderTopRightRadius = '0';
			}

			N_applyDefault?.addEventListener('click', () => {
				const defaultOption = SettingsApps.getInstance().get(this.KEY_SETTINGS) as unknown as { [key: string]: any };

				if (defaultOption) {
					this.setDefaultValue(defaultOption);
					N_apply.loading(this.fakePrint());
					this._update();
				} else {
					toaster.info('Aucun paramètres par défaut');
				}
			});
		}

		N_container.append(this.N_tabPanel);
	}

	public get dataId() {
		return this._dataId();
	}

	public setID(cb: () => string | null) {
		this._dataId = cb;
	}

	public setGetData(cb: () => any) {
		this._data = cb;
	}

	public getData() {
		const result = _.merge(this._data(), this.data);
		return result;
	}

	protected setDefaultValue(value: { [key: string]: any }) {
		this.data = value;
	}

	public set data(data: { [key: string]: any }) {
		this.form!.setData(data);
	}

	public get data() {
		return this.form!.getData();
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public async postInit() {
		try {
			await this.fakePrint();
		} catch (e) {
		}

		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') !== `#${this.tabId}`) {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;
				N_li.classList.remove('active');
			} else {
				this.fakePrint();
			}
		});
	}

	protected async fakePrint() {
		const url = await this.getUrlPreviewPrint();

		const abortController = new AbortController();
		window.addEventListener('message', (event) => {
			if (event.data === 'DOMContentLoadedIframePreview') {
				this.N_printContainer!.style.visibility = 'visible';
				this.N_spinner!.style.opacity = '0';
				this.N_spinner!.style.pointerEvents = 'none';
				abortController.abort('');
			}
		}, {
			signal: abortController.signal
		});

		this.N_spinner!.style.opacity = '1';
		this.N_spinner!.style.pointerEvents = 'all';

		this.N_printContainer!.style.visibility = 'hidden';
		this.N_printContainer!.src = url;
	}

	protected async getUrlPreviewPrint(): Promise<string> {
		try {
			const token = (await axios.post(`/api/${this.table}/previewToken`, {
				defaultvalue: this.getData()
			})).data;

			return `/api/${this.table}/preview?id=${this.dataId}&token=${token}&companyID=${Utils.companyID}`;
		} catch (e) {
			return '';
		}
	}

	public static register() {
		customElements.define(this.tagName, PrintOptionTab);
	}
}

export default PrintOptionTab;
