// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../tpl/modals/credit.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
// CUSTOM_ELEMENT
// SERVICE

class Credit extends Modal {
	constructor(bill: { [key: string]: any }) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_addonTitle = this.element.querySelector('#addon-title') as HTMLElement;

			N_addonTitle.innerHTML = bill.title;

			const price = Decimal.setDisplayNumber(bill.infos.price);

			const form = new Form(this.element.querySelector('.modal-body') as HTMLFormElement);

			const data: { [key: string]: any } = {
				type: 'percent',
				credit: {
					percentList: '0',
					percent: 0
				},
				price: {
					credit: '0',
					bill: price.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€'),
					billAfterCredit: ''
				}
			};

			const dataNumber = {
				price
			};

			this.updatePrice(data, form, dataNumber);

			const N_percentList = this.element.querySelector('[name="credit.percentList"]') as HTMLSelectElement;

			N_percentList.addEventListener('click', () => {
				data.credit.percentList = N_percentList.value;
				data.credit.percent = N_percentList.value;

				this.updatePrice(data, form, dataNumber);
			});

			const N_percent = this.element.querySelector('[name="credit.percent"]') as HTMLInputElement;

			N_percent.addEventListener('change', () => {
				data.credit.percentList = N_percent.value;
				data.credit.percent = N_percent.value;

				this.updatePrice(data, form, dataNumber);
			});

			const N_type = this.element.querySelector('[name="type"]') as HTMLSelectElement;
			const N_priceInput = this.element.querySelector('[name="price.credit"]') as HTMLInputElement;
			const N_priceDiv = this.element.querySelector('[data-key="price.credit"]') as HTMLElement;

			N_type.addEventListener('change', () => {
				data.type = N_type.value;

				if (N_type.value === 'percent') {
					N_percent.disabled = false;
					N_percentList.disabled = false;

					N_priceInput.classList.add('d-none');
					N_priceDiv.classList.remove('d-none');

					this.updatePrice(data, form, dataNumber);
				}

				if (N_type.value === 'price') {
					N_percent.disabled = true;
					N_percentList.disabled = true;

					N_priceInput.classList.remove('d-none');
					N_priceDiv.classList.add('d-none');

					data.price.credit = dataNumber.price.times(Decimal.setDisplayNumber(data.credit.percent).dividedBy(100));

					this.updatePrice(data, form, dataNumber);

					N_priceInput.select();
				}
			});

			N_priceInput.addEventListener('input', () => {
				data.price.credit = N_priceInput.value;

				this.updatePrice(data, form, dataNumber);
			});

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				this.resolve({
					type: 'credit',
					price: Decimal.setDisplayNumber(data.price.credit).toNumber(),
					typeCredit: form.getDataByName('credit.type')
				});
			});
		});
	}

	private updatePrice(data: { [key: string]: any }, form: Form, dataNumber: { [key: string]: Decimal }) {
		if (data.type === 'percent') {
			const credit = dataNumber.price.times(Decimal.setDisplayNumber(data.credit.percent).dividedBy(100));
			const billAfterCredit = dataNumber.price.minus(credit);

			data.price.credit = credit.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€');
			data.price.billAfterCredit = billAfterCredit.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€');

			form.setData(data);
			this.update(data);

			this.updateInvalid(credit.toNumber(), billAfterCredit.toNumber());
		}

		if (data.type === 'price') {
			const priceCredit = Decimal.setDisplayNumber(data.price.credit);
			const credit = new Decimal(100).times(priceCredit).dividedBy(dataNumber.price);
			const billAfterCredit = dataNumber.price.minus(priceCredit);

			data.credit.percentList = data.credit.percent = credit.toDecimalPlaces(0).toString();
			data.price.billAfterCredit = billAfterCredit.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€');

			form.setData(data);
			this.update(data);

			this.updateInvalid(credit.toNumber(), billAfterCredit.toNumber());
		}
	}

	private updateInvalid(credit: number, billAfterCredit: number) {
		const N_price_credit = this.element.querySelector('[data-key="price.credit"]') as HTMLElement;
		const N_billAfterCredit = this.element.querySelector('[data-key="price.billAfterCredit"]') as HTMLElement;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;
		const N_error = this.element.querySelector('#error') as HTMLElement;

		(N_price_credit.parentNode as HTMLElement).classList.remove('text-danger');
		N_billAfterCredit.classList.remove('text-danger');

		if (credit <= 0 || billAfterCredit < 0) {
			if (billAfterCredit < 0) {
				N_billAfterCredit.classList.add('text-danger');
			}

			if (credit <= 0) {
				(N_price_credit.parentNode as HTMLElement).classList.add('text-danger');
			}

			N_save.disabled = true;

			N_save.classList.add('cursor-not-allowed');

			N_error.classList.remove('d-none');
		} else {
			N_save.classList.remove('cursor-not-allowed');

			N_save.disabled = false;

			N_error.classList.add('d-none');
		}
	}

	private update(data: { [key: string]: any }) {
		const N_list = this.element.querySelectorAll('[data-key]') as NodeListOf<HTMLElement>;

		N_list.forEach((N_el) => {
			const key = N_el.dataset.key || '';

			const value = _.get(data, key);

			N_el.innerHTML = value;
		});
	}
}

export default Credit;
