import { Form, Modal } from '@autoprog/core-client';

import T_Modal from '../../tpl/modals/Export.html';

import M_PrintPreview from '@libs/modals/PrintPreview';

import CE_Select from '@libs/customElement/Select2';

import S_Stock from '@services/StockService';

class Export extends Modal {
	constructor(stock: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_stock = this.element.querySelector('[name="stock"]') as CE_Select;

			N_stock.setRef({ realStock: true });
			N_stock.create(this.element);

			const form = new Form(this.element.querySelector('form') as HTMLFormElement);

			form.setData({
				date: Date.now(),
				stock: await S_Stock.getInstance().getDataToSelect2ByID(stock)
			});

			const N_print = this.element.querySelector('#print') as HTMLButtonElement;

			N_print.addEventListener('click', async () => {
				const data = form.getData();

				data.date = data.date.valueOf();

				(await new M_PrintPreview('stock_events', '').getToken(data)).open();

				this.resolve();
			});
		});
	}
}

export default Export;
