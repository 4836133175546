import SettingsApps from '@libs/Settings';

import T_modal from '../../../tpl/modals/Favorite/addEdit.html';

import Options from '../../libs/GridOptions';

import Modal from '@libs/Modal';

import S_Q_Favorite from '@services/Quotes/FavoriteQuoteService';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';
class AddEdit extends Modal {
	private gridOptionsDetail: GridOptions = {};
	private gridOptions: GridOptions = {};
	private optionsGrid: Options;

	constructor(id?: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.optionsGrid = new Options();

		this.on('opened', async () => {
			let data = {
				details: [],
				_id: Date.now().toString(36),
				title: '',
				unit: 'U',
				quantity: 1
			};

			const N_gridDetails = this.element.querySelector('#grid-group') as HTMLElement;
			const N_grid = this.element.querySelector('#grid') as HTMLElement;
			const N_title = this.element.querySelector('[name="title"]') as HTMLInputElement;

			const N_titleModal = this.element.querySelector('.modal-title') as HTMLElement;

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			if (id) {
				data = await S_Q_Favorite.getInstance().getById(id);
				N_titleModal.innerHTML = 'Modification Favoris';
			}

			N_title.value = data.title;

			const quoteOptions = SettingsApps.getInstance().get('QUOTE.OPTIONS') as unknown as { [key: string]: any };
			const pref = SettingsApps.getInstance().get('QUOTE.PREF') as unknown as { [key: string]: any };

			this.optionsGrid.updateInfos({ state: '0' });
			this.optionsGrid.updatePref(pref);
			this.optionsGrid.updateQuoteOptions(quoteOptions as any);

			this.gridOptionsDetail = agUtils.french<GridOptions>({
				rowData: data.details,
				...this.optionsGrid.gridDetails(this.element, 'modal', () => {
					const data: any[] = [];

					this.gridOptionsDetail.api?.forEachNode((node) => {
						data.push(node.data);
					});

					if (this.gridOptions.api) {
						//peut y avoir qu'une ligne
						this.gridOptions.api.forEachNode((node) => {
							node.data.details = data;
						});
						this.gridOptions.api.refreshCells({ force: true });
					}
				})
			});

			new Grid(N_gridDetails, this.gridOptionsDetail, { modules: AllModules });

			this.gridOptions = agUtils.french<GridOptions>({
				...this.optionsGrid.gridMaster(this.element, 'modal'),
				getRowHeight: () => {
					return 165;
				},
				onGridReady: (params) => {
					params.api.sizeColumnsToFit();
				},
				onCellEditingStopped: (params) => {
					params.api.resetRowHeights();
					params.api.refreshCells({ force: true });
				},
				getContextMenuItems: (params) => {
					return [this.optionsGrid.styleContextMenu(params)];
				},
				rowData: [
					data
				]
			});

			new Grid(N_grid, this.gridOptions, { modules: AllModules });

			N_save.addEventListener('click', async () => {
				let newData: { [key: string]: any } = {};

				this.gridOptions.api?.forEachNode((node) => {
					newData = node.data;
				});

				newData.details = [];

				this.gridOptionsDetail.api?.forEachNode((node) => {
					newData.details.push(node.data);
				});

				newData = {
					_id: id,
					...newData,
					title: N_title.value
				};

				const data = await S_Q_Favorite.getInstance().save(newData);

				this.resolve(data.data.data._id);
			});
		});
	}
}

export default AddEdit;
