// CORE
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../tpl/modals/Alerte.html';

// LIBS
import Modal from '@libs/Modal';
import ProductContextMenu from '@modules/Products/js/libs/ProductContextMenu';
import SelectFilter from '@libs/agGrid/SelectFilter';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_Product from '@services/Product/ProductService';
import S_StockEvent from '@services/StockEventService';

class Alerte extends Modal {
	constructor() {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const N_createOrder = this.element.querySelector('#create-order') as HTMLButtonElement;

			const gridOptions: GridOptions = agUtils.french<GridOptions>({
				animateRows: true,
				suppressDragLeaveHidesColumns: true,
				suppressRowClickSelection: true,
				rowSelection: 'multiple',
				columnDefs: [
					{
						headerCheckboxSelection: true,
						headerCheckboxSelectionFilteredOnly: true,
						checkboxSelection: true,
						width: 50,
						suppressSizeToFit: true
					},
					{
						headerName: S_Product.getInstance().columnNameReference,
						suppressSizeToFit: true,
						width: 150,
						field: `product.${S_Product.getInstance().referenceKey}`,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						floatingFilterComponentParams: {
							suppressFilterButton: true
						},
						filterParams: {
							textFormatter: (result: string) => {
								if (result === null) return null;
								return _.deburr(result.toLowerCase());
							},
							debounceMS: 200
						}
					},
					{
						headerName: 'Désignation',
						field: 'product.name',
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						floatingFilterComponentParams: {
							suppressFilterButton: true
						},
						filterParams: {
							textFormatter: (result: string) => {
								if (result === null) return null;
								return _.deburr(result.toLowerCase());
							},
							debounceMS: 200
						}
					},
					{
						headerName: 'Fournisseur par défaut',
						field: 'product.defaultProvider.id',
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						floatingFilterComponentParams: {
							suppressFilterButton: true
						},
						filterParams: {
							textFormatter: (result: string) => {
								if (result === null) return null;
								return _.deburr(result.toLowerCase());
							},
							debounceMS: 200
						}
					},
					{
						headerName: 'Type',
						suppressSizeToFit: true,
						width: 200,
						field: 'typeAlert',
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						floatingFilterComponent: SelectFilter,
						floatingFilterComponentParams: {
							suppressFilterButton: true,
							options: {
								data: [{
									text: 'Alerte',
									id: 'alert'
								}, {
									text: 'A réapprovisionner',
									id: 'warning'
								}, {
									text: 'Négative',
									id: 'negative'
								}]
							}
						},
						cellRenderer: (params) => {
							return ({
								alert: 'Alerte',
								warning: 'A réapprovisionner',
								negative: 'Négative'
							} as { [key: string]: any })[params.value] || '';
						}
					},
					{
						headerName: 'Qté',
						suppressSizeToFit: true,
						width: 100,
						field: 'quantity.formattedValue',
						cellClass: 'text-monospace text-right'
					},
					{
						headerName: 'Seuil Alarme',
						suppressSizeToFit: true,
						width: 100,
						field: 'product.alarmStock',
						cellClass: 'text-monospace text-right'
					},
					{
						headerName: 'Qté commandée',
						suppressSizeToFit: true,
						width: 200,
						field: 'lastOrder',
						cellClass: 'text-monospace text-right',
						cellRenderer: (params) => {
							let total = 0;
							let numberOrder = 0;

							const tooltip: string[] = [];

							params.value = params.value || {};

							if (Object.keys(params.value.customer || {}).length) {
								tooltip.push('<div class="d-flex font-weight-bold my-2 border-bottom-2x border-dark">Livraison direct client</div>');

								for (const id in params.value.customer) {
									total += params.value.customer[id].quantity;
									numberOrder++;

									tooltip.push(`<div class="d-flex">${params.value.customer[id].text} <div class="ml-auto pl-2">(Qté: ${params.value.customer[id].quantity})</div></div>`);
								}
							}

							if (Object.keys(params.value.internal || {}).length) {
								tooltip.push('<div class="d-flex font-weight-bold my-2 border-bottom-2x border-dark">Livraison interne</div>');

								for (const id in params.value.internal) {
									total += params.value.internal[id].quantity;
									numberOrder++;

									tooltip.push(`<div class="d-flex">${params.value.internal[id].text} <div class="ml-auto pl-2">(Qté: ${params.value.internal[id].quantity})</div></div>`);
								}
							}

							const N_div = document.createElement('div');

							N_div.classList.add('w-100');
							N_div.setAttribute('popover', JSON.stringify({ title: 'Détail commande:', content: tooltip.join('') || 'Aucune commande', trigger: 'hover', placement: 'right', sanitize: false }));

							N_div.innerHTML = `${total} (${numberOrder} Commande${numberOrder > 1 ? 's' : ''})`;

							return N_div;
						}
					}
				],
				defaultColDef: {
					suppressMenu: true,
					resizable: true
				},
				getContextMenuItems: (params) => {
					const products: { [key: string]: any }[] = [];

					params.api?.forEachNode((node) => {
						if (node.isSelected()) {
							products.push(node.data.product);
						}
					});

					if (products.length === 0) {
						products.push(params.node.data.product);
					}

					return [
						ProductContextMenu.orderProduct(products, !params.node, () => {
							this.resolve();
						})
					];
				},
				onRowSelected: (params) => {
					N_createOrder.disabled = params.api?.getSelectedRows().length === 0;
				},
				onGridReady: async (params) => {
					const data = await this.getData();
					params.api.setRowData(data);
					params.api.sizeColumnsToFit();
				}
			});

			const N_grid = this.element.querySelector('#grid') as HTMLElement;

			new Grid(N_grid, gridOptions, { modules: AllModules });

			N_createOrder.addEventListener('click', () => {
				const products: { [key: string]: any }[] = [];

				gridOptions.api?.forEachNode((node) => {
					if (node.isSelected()) {
						products.push(node.data.product);
					}
				});

				ProductContextMenu.createOrder(products, () => {
					this.resolve();
				});
			});
		});
	}

	private async getData() {
		const data = await S_StockEvent.getInstance().getCurrentStock();

		const alert = _.filter(data.rowData, { displayDashboard: true, typeAlert: 'alert' });
		const warning = _.filter(data.rowData, { displayDashboard: true, typeAlert: 'warning' });
		const negative = _.filter(data.rowData, { displayDashboard: true, typeAlert: 'negative' });

		return [...negative, ...alert, ...warning];
	}
}

export default Alerte;
