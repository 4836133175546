// CORE
import { Alert, Controller, Router, toaster, utils } from '@autoprog/core-client';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// TEMPLATE
// LIBS
import History from '@libs/History';
import Loader from '@libs/Loader';
import ModalManager from '@managers/ModalManager';
import agUtils from '@libs/agGrid/french';

// PRINTER
import P_InventoryExport from '../libs/printer/InventoryExport';
import P_InventoryGroup from '../libs/printer/InventoryGroup';

// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_Inventory from '@services/StockInventoryService';
import S_Product from '@services/Product/ProductService';
import S_Stock from '@services/StockService';

class StockController extends Controller {
	private gridOptions: GridOptions = {};

	private id: string | null = null;
	private stock: string = '';
	private title: string = '';

	constructor(el: HTMLElement) {
		super(el);

		const params = utils.getQuery();

		this.stock = params.stock;
		this.id = params.id;

		this.gridOptions = agUtils.french<GridOptions>({
			columnDefs: [
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: 'product.' + S_Product.getInstance().referenceKey,
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						return S_Product.getInstance().cellRendererByData(params.data.product);
					}
				},
				{
					headerName: 'Désignation',
					field: 'product.name',
					filterParams: {
						textFormatter: (result: string) => {
							if (result == null) return null;
							return _.deburr(result.toLowerCase());
						},
						debounceMS: 200
					}
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					width: 150,
					suppressSizeToFit: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: {
						applyMiniFilterWhileTyping: true
					}
				},
				{
					headerName: 'Empl.',
					field: 'product.locationStock',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Ancienne Qté',
					field: 'oldQuantity',
					width: 80,
					cellClass: 'text-right text-monospace',
					suppressSizeToFit: true
				},
				{
					headerName: 'Nouvelle Qté',
					field: 'newQuantity',
					width: 80,
					cellClass: 'text-right text-monospace',
					editable: true,
					suppressSizeToFit: true
				},
				{
					headerName: 'Action',
					width: 80,
					suppressSizeToFit: true,
					suppressColumnsToolPanel: true,
					filter: false,
					pinned: 'right',
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						const N_product = document.createElement('button');
						N_product.classList.add('btn', 'btn-transparent');
						N_product.setAttribute('tooltip', 'Éditer Produit');
						N_product.innerHTML = '<i class="icon icon-solid-pen"></i>';

						N_product.addEventListener('click', async () => {
							const product = await ModalManager.getInstance().openWithReturnData('products', params.data.product._id);
							params.node.data.product = product;

							params.api?.applyTransaction({
								update: [params.node.data]
							});
						});

						N_div.appendChild(N_product);

						return N_div;
					}
				}
			],
			defaultColDef: {
				filter: 'agTextColumnFilter',
				filterParams: { newRowsAction: 'keep' },
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			sideBar: {
				toolPanels: [{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}]
			},
			floatingFilter: true,
			suppressContextMenu: true,
			onGridReady: () => {
				this.getData();
			},
			onFilterChanged: (params: any) => {
				const filter = params.api.getFilterModel();
				const N_title = document.querySelector('#title') as HTMLElement;

				let N_icon = (N_title.parentNode as HTMLElement).querySelector('i#filter') as HTMLElement;

				if (!N_icon) {
					N_icon = document.createElement('i');
					N_icon.classList.add('icon', 'icon-filter', 'h4', 'mx-2');
					N_icon.id = 'filter';
					N_icon.title = 'Filtre actif';

					(N_title.parentNode as HTMLElement).insertBefore(N_icon, N_title);
				}

				if (_.isEmpty(filter)) {
					N_icon.classList.add('d-none');
				} else {
					N_icon.classList.remove('d-none');
				}
			},
			rowData: []
		});

		const N_grid = el.querySelector('#grid') as HTMLElement;

		new Grid(N_grid, this.gridOptions, { modules: AllModules });

		const N_print_groupByLocation = el.querySelector('#print_groupByLocation') as HTMLButtonElement;
		const N_print_groupByBrand = el.querySelector('#print_groupByBrand') as HTMLButtonElement;
		const N_print_export = el.querySelector('#print_export') as HTMLButtonElement;
		const N_createProduct = el.querySelector('#create-product') as HTMLButtonElement;
		const N_save = el.querySelector('#save') as HTMLButtonElement;
		const N_finish = el.querySelector('#finish') as HTMLButtonElement;
		const N_name_stock = el.querySelector('#name_stock') as HTMLElement;

		S_Stock.getInstance().getDisplayRefByID(this.stock).then((data) => {
			this.title = data;
			N_name_stock.innerHTML = data;
		});

		N_print_groupByLocation.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			this.gridOptions.api?.forEachNodeAfterFilter((node) => {
				data.push(node.data);
			});

			new P_InventoryGroup().print(data, 'product.locationStock', this.title);
		});

		N_print_groupByBrand.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			this.gridOptions.api?.forEachNodeAfterFilter((node) => {
				data.push(node.data);
			});

			new P_InventoryGroup().print(data, 'product.brand', this.title);
		});

		N_print_export.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			this.gridOptions.api?.forEachNode((node) => {
				if (node.data.newQuantity !== '0') {
					data.push(node.data);
				}
			});

			new P_InventoryExport().print(data, this.title);
		});

		N_createProduct.addEventListener('click', async () => {
			const product = await ModalManager.getInstance().openWithReturnData('products');
			this.gridOptions.api?.applyTransaction({
				add: [{
					product,
					newQuantity: 0,
					oldQuantity: 0
				}]
			});
		});

		N_save.addEventListener('click', async () => {
			const newData: { [key: string]: any } = {
				_id: this.id,
				stock: this.stock,
				products: {}
			};

			this.gridOptions.api?.forEachNode((node) => {
				newData.products[node.data.product._id] = {
					oldQuantity: node.data.oldQuantity,
					newQuantity: node.data.newQuantity
				};
			});

			const { data } = await S_Inventory.getInstance().save(newData);

			this.id = data.data._id;

			this.updateURL();

			toaster.success('Sauvegarde réussie');
		});

		N_finish.addEventListener('click', () => {
			Alert.confirm('Avertissement', `
			Toutes les quantités des produits vont être remplacées par celles indiquées dans l'inventaire. <br>
			Si aucune valeur n'a été saisie pour un produit, la nouvelle valeur appliquée par défaut sera <span class="font-weight-bold">0</span>. <br>
			Etes-vous sûr de vouloir continuer ?
			`).then(async () => {
				const newData: { [key: string]: any } = {
					_id: this.id,
					stock: this.stock,
					finish: true,
					products: {}
				};

				this.gridOptions.api?.forEachNode((node) => {
					newData.products[node.data.product._id] = {
						oldQuantity: node.data.oldQuantity,
						newQuantity: node.data.newQuantity
					};
				});

				await S_Inventory.getInstance().save(newData);

				toaster.success('Sauvegarde réussie');

				Router.getInstance().navigate('/#module/stock');
			});
		});
	}

	private updateURL() {
		const href: string[] = [];

		href.push(`id=${this.id}`);
		href.push(`stock=${this.stock}`);

		History.getInstance().replaceState(`${window.location.hash.split('?')[0]}?${href.join('&')}`);
	}

	private async getData() {
		Loader.getInstance().open();

		const { data } = await S_Inventory.getInstance().getDataToModal(this.id || '', { stock: this.stock });

		this.gridOptions.api?.setRowData(data.data.products);

		this.gridOptions.api?.sizeColumnsToFit();

		Loader.getInstance().close();
	}

	public destructor() {

	}
}

export default StockController;
