// CORE
// NODE_MODULE
// TEMPLATE
// LIBS
// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

//STYLE
import '../../../css/commands.scss';

class Commands extends HTMLElement {
	public static readonly tagName: string = 'ap-commands';

	private _data: any;

	public async connectedCallback() {
		this.innerHTML = `
            <div class="text-nowrap">
                <div class="mb-3 d-flex align-items-center text-grey-800">
                    <i class="icon icon-document ml-1 mr-2 h5"></i><span class="font-weight-bold h4">Commandes</span>
                </div>


                <div class="commands-subtitle font-weight-bold px-2 text-grey w-100 h6"><i class="icon icon-solid-hand-holding mr-2"></i>Commandes fournisseur</div>
                <div class="d-flex flex-wrap mb-2 pl-2">

                    <div class="p-0">
                        <div class="card card-custom">
                            <div class="card-content">
                                <div class="card-body px-3 py-2">
                                    <div class="media d-flex">
                                        <div class="media-body text-left">
                                            <div class="card-value text-light-green-a700" data-key="commands_provider.finish">...</div>
                                            <span class="card-title">Reçues</span>
                                        </div>
                                        <div class="align-self-center">
                                            <i class="card-icon icon icon-checkbox-checked text-light-green-a700 float-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            
                    <div class="p-0">
                        <div class="card card-custom">
                            <div class="card-content">
                                <div class="card-body px-3 py-2">
                                    <div class="media d-flex">
                                        <div class="media-body text-left">
                                            <div class="card-value text-amber" data-key="commands_provider.partial">...</div>
                                            <span class="card-title">Partiellement reçues</span>
                                        </div>
                                        <div class="align-self-center">
                                            <i class="card-icon icon icon-checkbox-partial text-amber float-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-0">
                        <div class="card card-custom">
                            <div class="card-content">
                                <div class="card-body px-3 py-2">
                                    <div class="media d-flex">
                                        <div class="media-body text-left">
                                            <div class="card-value text-grey" data-key="commands_provider.waiting">...</div>
                                            <span class="card-title">En attente de livraison</span>
                                        </div>
                                        <div class="align-self-center">
                                            <i class="card-icon icon icon-solid-business-time text-grey float-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-0">
                        <div class="card card-custom">
                            <div class="card-content">
                                <div class="card-body px-3 py-2">
                                    <div class="media d-flex">
                                        <div class="media-body text-left">
                                            <div class="card-value text-blue" data-key="commands_provider.inProgress">...</div>
                                            <span class="card-title">En cours de création</span>
                                        </div>
                                        <div class="align-self-center">
                                            <i class="card-icon icon icon-ri-file-edit-line text-blue float-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="commands-subtitle font-weight-bold px-2 text-grey w-100 h6"><i class="icon icon-solid-hand-holding-usd mr-2"></i>Commandes client</div>
                <div class="d-flex flex-wrap mb-2 pl-2">
                    <div class="p-0">
                        <div class="card card-custom">
                            <div class="card-content">
                                <div class="card-body px-3 py-2">
                                    <div class="media d-flex">
                                        <div class="media-body text-left">
                                            <div class="card-value text-grey" data-key="commands_customer.waiting">...</div>
                                            <span class="card-title">En attente de confirmation</span>
                                        </div>
                                        <div class="align-self-center">
                                            <i class="card-icon icon icon-solid-user-clock text-grey float-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="p-0">
                        <div class="card card-custom">
                            <div class="card-content">
                                <div class="card-body px-3 py-2">
                                    <div class="media d-flex">
                                        <div class="media-body text-left">
                                            <div class="card-value text-blue" data-key="commands_customer.notBill">...</div>
                                            <span class="card-title">En cours de facturation</span>
                                        </div>
                                        <div class="align-self-center">
                                            <i class="card-icon icon icon-ri-file-bill-euro-line text-blue float-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                    <div class="p-0">
                        <div class="card card-custom">
                            <div class="card-content">
                                <div class="card-body px-3 py-2">
                                    <div class="media d-flex">
                                        <div class="media-body text-left">
                                            <div class="card-value text-red" data-key="bill.toLate">...</div>
                                            <span class="card-title">En retard de paiement</span>
                                        </div>
                                        <div class="align-self-center">
                                            <i class="card-icon icon icon-ri-timer-line text-red float-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        `;
	}

	private linkData() {
		const N_commands_provider_finish = this.querySelector('[data-key="commands_provider.finish"]');
		const N_commands_provider_partial = this.querySelector('[data-key="commands_provider.partial"]');
		const N_commands_provider_waiting = this.querySelector('[data-key="commands_provider.waiting"]');
		const N_commands_provider_inProgress = this.querySelector('[data-key="commands_provider.inProgress"]');
		const N_commands_customer_waiting = this.querySelector('[data-key="commands_customer.waiting"]');
		const N_commands_customer_notBill = this.querySelector('[data-key="commands_customer.notBill"]');
		const N_bill_toLate = this.querySelector('[data-key="bill.toLate"]');

		N_commands_provider_finish!.innerHTML = this._data.commands_provider.finish;
		N_commands_provider_partial!.innerHTML = this._data.commands_provider.partial;
		N_commands_provider_waiting!.innerHTML = this._data.commands_provider.waiting;
		N_commands_provider_inProgress!.innerHTML = this._data.commands_provider.inProgress;
		N_commands_customer_waiting!.innerHTML = this._data.commands_customer.waiting;
		N_commands_customer_notBill!.innerHTML = this._data.commands_customer.notBill;
		N_bill_toLate!.innerHTML = this._data.bill.toLate;
	}

	public set data(data: any) {
		this._data = data;
		this.linkData();
	}

	public get data() {
		return this._data;
	}

	public static register() {
		customElements.define(Commands.tagName, Commands);
	}
}

export default Commands;
