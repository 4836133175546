// CORE
import { Router, toaster, utils } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
// LIBS
import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';
import History from '@js/libs/History';
import Loader from '@libs/Loader';
import ModalManager from '@managers/ModalManager';

import C_CommandCustomer from '../controllers/Command.Customer';

// PRINTER

// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
import M_AdditionalInformations from '../modals/customers/editPageFromQuote/AdditionalInformations';
import M_Amount from '../modals/customers/editPageFromQuote/Amount';
import M_Dates from '../modals/customers/editPageFromQuote/Dates';
import M_DetailsOrder from '../modals/customers/editPageFromQuote/DetailsOrder';
import M_GeneralInformations from '../modals/customers/editPageFromQuote/GeneralInformations';

// CUSTOM_ELEMENT
import CE_GroupQuotesTab from './../libs/customElement/customers/GroupQuotesTab';
import CE_SitesFinalCustomerReadonly from '@libs/customElement/Sites-FinalCustomer-Readonly';
import CE_TVA from '@libs/customElement/TVA';
import CE_UsersTab from './../libs/customElement/customers/UsersTab';

// SERVICE
import S_C_Address from '@services/Customer/CustomerAddressService';

class CommandCustomerFromQuoteCtrl extends ControllerPageID {
	private N_GroupQuotesTab: CE_GroupQuotesTab | null = null;
	private N_UsersTab: CE_UsersTab | null = null;

	private orderPrice: Decimal;

	private isInit: boolean;

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.orderPrice = new Decimal(0);
		this.isInit = false;
		this.options = CommandCustomerFromQuoteCtrl.options || {};

		CommandCustomerFromQuoteCtrl.options = {};

		this.routeReturn = 'module/quotes';

		this.init('commands-customer', id);
	}

	private static options: { [key: string]: any } = {};

	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		CommandCustomerFromQuoteCtrl.options = options || {};

		if (id) {
			if (CommandCustomerFromQuoteCtrl.options.idOrder) {
				Router.getInstance().navigate(`/module/order/customer/from_quote?id=${id}&id_order=${CommandCustomerFromQuoteCtrl.options.idOrder}`);
			} else {
				Router.getInstance().navigate(`/module/order/customer/from_quote?id=${id}`);
			}
		} else {
			Router.getInstance().navigate('/module/quotes');
		}
	}

	protected async init(table: string, id: string) {
		super.init(table, id);

		const data = await this.getData();

		//gestion de l'actualisation de la page lors de l'edition de l'enregistrement d'un devis
		if (!this.options.isUpdate && data.data.stateQuote === '1') {
			this.routeReturn = `/module/order/customer?id=${utils.getQuery().id_order}`;
			this.return();
			return;
		}

		this.initGroupQuotesTab(data.data.selectingRows);

		this.initUsers();

		this.setData(data);

		this.postInit();

		this.initFullscreen();
		this.initEditButton();

		this.updateDeliveryTypeAddress();

		if (this.options.isUpdate && this.options.from === 'order') {
			this.updateToUpdate();
		} else if (this.options.isUpdate && this.options.from === 'quote') {
			this.updateToUpdate();
			this.updateSaveButton();
		} else if (this.options.idOrder) {
			this.openGeneralInformation(true);
		} else {
			this.openGeneralInformation(true);
		}
	}

	protected get tableOpenDocument(): string {
		return 'quotes';
	}

	private updateToUpdate() {
		const N_edit_Amount = this.el.querySelector('[data-edit="amount"]') as HTMLButtonElement;

		N_edit_Amount.classList.add('d-none');
	}

	protected convertData(data: { [key: string]: any; }): { [key: string]: any; } {
		if (data.infos.tva) {
			data.infos.tva = CE_TVA.convertValue(data.infos.tva);
		}
		return data;
	}

	protected async getData() {
		Loader.getInstance().open();

		const tmp = (await this.service?.getDataToModal(this.id, { ...this.options, typeLoad: 'checkAddress' })).data;

		Loader.getInstance().close();

		if (!tmp.hasBillAddress) {
			toaster.info('Aucune adresse de facturation renseigné');

			try {
				await ModalManager.getInstance().open('customers', tmp.customer);
			} catch (e) {
				this.return();
			}
		}

		if (this.options.isUpdate) {
			this.options.typeLoad = 'updateQuote';
		} else {
			this.options.typeLoad = 'convertQuote';
		}

		return super.getData();
	}

	private openGeneralInformation(firstOpen = false) {
		const res = {
			command: this.form?.getDataByName('command') as string,
			infos: {
				number: this.form?.getDataByName('infos.number') as string,
				numberCommand: this.form?.getDataByName('infos.numberCommand') as string,
				addonNumberCommand: this.form?.getDataByName('infos.addonNumberCommand') as string,
				customer: this.form?.getDataByName('infos.customer') as string,
				contact: this.form?.getDataByName('infos.contact') as string,
				date: this.form?.getDataByName('infos.date') as Moment
			}
		};

		let modal: M_GeneralInformations;
		const disabledOrderEdition = !!this.options.idOrder;

		if (firstOpen) {
			modal = new M_GeneralInformations(res, disabledOrderEdition).setNextCallback((data) => {
				this.setDataForm(data);
				this.openDates();
			});
		} else {
			modal = new M_GeneralInformations(res, disabledOrderEdition);
		}

		modal.open().then(async (data) => {
			this.setDataForm(data);

			if (res.infos.customer !== data.infos.customer.id) {
				await this.updateAddress();
			}

			this.updateOrder();

			if (!firstOpen) {
				this.updateSaveButton();
			}
		}).catch((shouldClose = true) => {
			if (firstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private openDates() {
		const res = {
			infos: {
				date: this.form?.getDataByName('infos.date') as Moment,
				entryDate: this.form?.getDataByName('infos.entryDate') as Moment,
				deliveryDate: this.form?.getDataByName('infos.deliveryDate') as Moment
			}
		};

		new M_Dates(res).setPreviousCallback(() => {
			this.openGeneralInformation(true);
		}).open().then((data) => {
			this.setDataForm(data);
			this.updateSaveButton();
		}).catch((shouldClose = true) => {
			if (shouldClose) {
				this.return();
			}
		});
	}

	private initEditButton() {
		// INIT BOUTONS D EDITION -> MODALES D EDITION
		const N_edit_GeneralInformations = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsOrder = this.el.querySelector('[data-edit="detailsOrder"]') as HTMLButtonElement;
		const N_edit_Amount = this.el.querySelector('[data-edit="amount"]') as HTMLButtonElement;
		const N_edit_AdditionalInformations = this.el.querySelector('[data-edit="additionalInformations"]') as HTMLButtonElement;

		N_edit_GeneralInformations.addEventListener('click', () => {
			this.openGeneralInformation();
		});

		N_edit_DetailsOrder.addEventListener('click', () => {
			const res = {
				infos: {
					number: this.form?.getDataByName('infos.number') as string,
					label: this.form?.getDataByName('infos.label') as string,
					description: this.form?.getDataByName('infos.description') as string
				}
			};

			new M_DetailsOrder(res).open().then((data) => {
				this.setDataForm(data);
				this.updateSaveButton();
			});
		});

		N_edit_Amount.addEventListener('click', () => {
			const res = {
				price: this.form?.getDataByName('price') as string,
				infos: {
					tva: this.form?.getDataByName('infos.tva') as string,
					autoliquidation: this.form?.getDataByName('infos.autoliquidation') as boolean,
					nonValorisee: this.form?.getDataByName('infos.nonValorisee') as boolean
				}
			};

			new M_Amount(res).open().then((data) => {
				this.setDataForm(data);
				this.updateSaveButton();
			});
		});

		N_edit_AdditionalInformations.addEventListener('click', () => {
			const res = {
				infos: {
					customer: this.form?.getDataByName('infos.customer') as string,
					addressID: this.form?.getDataByName('infos.addressID') as string,
					fullAddress: this.form?.getDataByName('infos.fullAddress') as string,
					addressIDDelivery: this.form?.getDataByName('infos.addressIDDelivery') as string,
					fullAddressDelivery: this.form?.getDataByName('infos.fullAddressDelivery') as string,
					date: this.form?.getDataByName('infos.date') as Moment,
					entryDate: this.form?.getDataByName('infos.entryDate') as Moment,
					deliveryDate: this.form?.getDataByName('infos.deliveryDate') as Moment,
					finalCustomer: this.form?.getDataByName('infos.finalCustomer') as string,
					hasFinalCustomer: this.form?.getDataByName('infos.hasFinalCustomer') as string,
					sites: this.form?.getDataByName('infos.sites') as string[],
					siteCustom: this.form?.getDataByName('infos.siteCustom') as string,
					comments: this.form?.getDataByName('infos.comments') as string,
					delivery: {
						typeAddressCustomer: this.form?.getDataByName('infos.delivery.typeAddressCustomer') as string,
						addressIDCustomer: this.form?.getDataByName('infos.delivery.addressIDCustomer') as string,
						siteCustomer: this.form?.getDataByName('infos.delivery.siteCustomer') as string,
						fullAddress: this.form?.getDataByName('infos.delivery.fullAddress') as string,
						GPSCoordinates: this.form?.getDataByName('infos.delivery.GPSCoordinates') as string
					}
				}
			};

			new M_AdditionalInformations(res).open().then((data) => {
				this.setDataForm(data);
				this.updateFinalCustomer();
				this.updateDeliveryTypeAddress();
				this.updateSaveButton();
			});
		});
	}

	private initGroupQuotesTab(selectingRows: { [key: string]: boolean }) {
		this.N_GroupQuotesTab = this.el.querySelector(CE_GroupQuotesTab.tagName) as CE_GroupQuotesTab;

		this.N_GroupQuotesTab!.setParentElement(this.el);

		this.N_GroupQuotesTab!.setOnUpdate(() => {
			this.form?.setDataByName('price', this.N_GroupQuotesTab!.price);

			const N_newPriceOrder = this.el.querySelector('#new_price_order') as HTMLElement;
			N_newPriceOrder.innerHTML = this.orderPrice.plus(Decimal.setDisplayNumber(this.N_GroupQuotesTab!.price)).setSuffixAndHumanizeNumber('€');

			if (this.isInit) {
				this.updateSaveButton();
			}

			this.updateOrder();

			this.isInit = true;
		});

		this.N_GroupQuotesTab!.initGroupQuotes(this.id, selectingRows);
	}

	private initUsers() {
		this.N_UsersTab = this.el.querySelector(CE_UsersTab.tagName) as CE_UsersTab;

		this.N_UsersTab!.setParentElement(this.el);

		this.N_UsersTab!.setOnUpdate(() => {
			this.updateSaveButton();
		});
	}

	protected setData(data: DataServer) {
		data.data.infos.autoliquidation = data.data.infos.autoliquidation || false;

		super.setData(data);

		this.updateFinalCustomer();
		this.updateOrder();

		this.N_UsersTab!.data = data.data;
	}

	protected getPageData(newData: { [key: string]: any; }) {
		newData.selectingRows = this.N_GroupQuotesTab!.selectingRows;

		if (newData.infos.date) {
			newData.infos.date = newData.infos.date.format('x');
		}

		if (newData.infos.entryDate) {
			newData.infos.entryDate = newData.infos.entryDate.format('x');
		}

		if (newData.infos.deliveryDate) {
			newData.infos.deliveryDate = newData.infos.deliveryDate.format('x');
		}

		newData.infos = {
			...newData.infos,
			...this.N_UsersTab!.data.infos
		};

		return newData;
	}

	protected get otherDataSave() {
		if (this.options.isUpdate || this.form?.getDataByName('command') !== 'new') {
			return { type: 'update' };
		} else {
			return { type: 'new' };
		}
	}

	protected postInit(): void {
		super.postInit();

		this.N_GroupQuotesTab?.postInit();
		this.N_UsersTab?.postInit();
	}

	protected async _save() {
		const res = await this.service?.save(this.saveData, this.otherDataSave) || { ok: false, err: 'unknown', data: {} };

		if (res.ok) {
			toaster.success('Sauvegarde réussie');
			this.resetSaveButton();
			await this.postSave(res.data.data);
		} else {
			console.error(res.err);
			toaster.error('Erreur lors de la sauvegarde');
		}
	}

	protected async postSave(newData: { [key: string]: any; }) {
		History.getInstance().deleteCurrentState();
		C_CommandCustomer.open(newData._id);
	}

	private updateFinalCustomer() {
		const hasFinalCustomer = this.form?.getDataByName('infos.hasFinalCustomer');
		const N_sites = this.el.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
		N_sites.update(hasFinalCustomer);
	}

	private async updateOrder() {
		const order = this.form?.getDataByName('command') as string;
		const price = this.form?.getDataByName('price') as string;

		const N_newPriceOrder = this.el.querySelector('#new_price_order') as HTMLElement;
		const N_oldPriceOrder = this.el.querySelector('#old_price_order') as HTMLElement;

		this.orderPrice = new Decimal(0);

		if (order && order !== 'new') {
			const orderData = await this.service?.getById(order);

			this.setDataForm({
				infos: {
					tva: orderData.infos.tva,
					addressID: await S_C_Address.getInstance().getDataToSelect2ByID(orderData.infos.addressID),
					fullAddress: orderData.infos.fullAddress,
					autoliquidation: orderData.infos.autoliquidation,
					nonValorisee: orderData.infos.nonValorisee,
					hasFinalCustomer: orderData.infos.hasFinalCustomer
				}
			});

			for (const item of orderData.quotes) {
				if (item._id !== this.id) {
					this.orderPrice = this.orderPrice.plus(Decimal.setDisplayNumber(item.price));
				}
			}
		}

		N_oldPriceOrder.innerHTML = this.orderPrice.setSuffixAndHumanizeNumber('€');
		N_newPriceOrder.innerHTML = this.orderPrice.plus(Decimal.setDisplayNumber(price)).setSuffixAndHumanizeNumber('€');
	}

	private async updateAddress() {
		this.form?.setDataByName('infos.addressID', '');
		this.form?.setDataByName('infos.fullAddress', '');

		this.form?.setDataByName('infos.addressIDDelivery', '');
		this.form?.setDataByName('infos.fullAddressDelivery', '');

		this.form?.setDataByName('infos.delivery.addressIDCustomer', '');
		this.form?.setDataByName('infos.delivery.siteCustomer', '');
		this.form?.setDataByName('infos.delivery.fullAddress', '');
		this.form?.setDataByName('infos.delivery.GPSCoordinates', '');

		const customer = this.form?.getDataByName('infos.customer') as string;

		if (customer) {
			let typeAddressCustomer = this.form?.getDataByName('infos.delivery.typeAddressCustomer') as string;
			typeAddressCustomer = typeAddressCustomer || 'customer';

			const customerBillAddressList = await S_C_Address.getInstance().getBillAddressByCustomer(customer) || [];

			if (customerBillAddressList[0]) {
				const id = customerBillAddressList[0]._id as string;

				const addressIDCustomer = await S_C_Address.getInstance().getDataToSelect2ByID(id);
				const addressInfos = await S_C_Address.getInstance().getAddressInfos(id);

				this.form?.setDataByName('infos.addressID', addressIDCustomer);
				this.form?.setDataByName('infos.fullAddress', addressInfos.fullAddress);
			}

			const customerDeliveryAddressList = await S_C_Address.getInstance().getDeliveryAddressByCustomer(customer) || [];

			if (customerDeliveryAddressList[0]) {
				const id = customerDeliveryAddressList[0]._id as string;

				const addressIDCustomer = await S_C_Address.getInstance().getDataToSelect2ByID(id);
				const addressInfos = await S_C_Address.getInstance().getAddressInfos(id);

				if (typeAddressCustomer === 'customer') {
					this.form?.setDataByName('infos.delivery.addressIDCustomer', addressIDCustomer);
					this.form?.setDataByName('infos.delivery.fullAddress', addressInfos.fullAddress);
					this.form?.setDataByName('infos.delivery.GPSCoordinates', addressInfos.GPSCoordinates);
				}
			}
		}
	}

	private updateDeliveryTypeAddress() {
		let type = this.form?.getDataByName('infos.delivery.typeAddressCustomer') as string;
		type = type || 'customer';

		const N_addressTypeCustomer = this.el.querySelectorAll('[address-type="customer"]') as NodeListOf<HTMLElement>;
		const N_addressTypeSite = this.el.querySelectorAll('[address-type="site"]') as NodeListOf<HTMLElement>;

		for (const N_el of N_addressTypeCustomer) {
			N_el.classList.add('d-none');
		}
		for (const N_el of N_addressTypeSite) {
			N_el.classList.add('d-none');
		}

		if (type === 'customer') {
			for (const N_el of N_addressTypeCustomer) {
				N_el.classList.remove('d-none');
			}
		}

		if (type === 'site') {
			for (const N_el of N_addressTypeSite) {
				N_el.classList.remove('d-none');
			}
		}
	}
}

export default CommandCustomerFromQuoteCtrl;
