import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import agUtils from '@libs/agGrid/french';

import SettingsApps from '@libs/Settings';

import TextareaEditor from '@libs/agGrid/TextareaEditor';

type CGVLine = {
	category: string,
	subCategory: string,
	text: string,
	_idGrid: string
};

export type CGV = Array<CGVLine>;
class CGVTab extends HTMLElement {
	public static readonly tagName: string = 'ap-cgv-tab';

	private idTab: string;
	private selectorTab: string;
	private mode: 'QUOTE' | 'BILL' | null;
	private settings: SettingsApps;

	private N_el: HTMLElement | null = null;

	private _update = (): void => { };

	private _gridOptions: GridOptions = {};

	private _isLock: boolean = false;

	public static register() {
		customElements.define(this.tagName, CGVTab);
	}

	constructor() {
		super();
		this.settings = SettingsApps.getInstance();
		this.mode = this.getAttribute('mode') as 'QUOTE' | 'BILL' || null;
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		const type = this.getAttribute('type');

		if (type === 'page') {
			this.innerHTML = `
				<li>
					<a data-toggle="tab" href="#${this.idTab}"role="tab">
						<div class="icon-container" tooltip="CGV">
							<i class="icon icon-solid-list"></i>
						</div>
						<span title="Conditions générales de vente">CGV</span>
					</a>
				</li>
			`;
		} else {
			this.innerHTML = `
				<li class="nav-item border-right">
					<a class="nav-link px-3" data-toggle="tab" href="#${this.idTab}" role="tab">
						<span>CGV</span>
					</a>
				</li>
			`;
		}

		this.removeAttribute('id');
	}

	public async connectedCallback() {
	}

	public set isLock(value: boolean) {
		this._isLock = value;
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this._gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'h-100', 'fade');
		this.N_el.setAttribute('role', 'tabpanel');

		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="d-flex flex-column h-100">
                <div class="title">
					CGV
					<div class="ml-auto">
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
                </div>
                <div class="h-100 ag-theme-alpine grid"></div>
            </div>
        `;

		N_container.append(this.N_el);

		this.initGrid();
	}

	private initGrid() {
		this._gridOptions = agUtils.french<GridOptions>({
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				editable: () => {
					return !this._isLock;
				}
			},
			columnDefs: [
				{
					field: 'category',
					headerName: 'Catégorie',
					width: 180,
					rowDrag: true,
					suppressSizeToFit: true
				}, {
					field: 'subCategory',
					headerName: 'Sous-Catégorie',
					width: 140,
					suppressSizeToFit: true
				}, {
					field: 'text',
					headerName: 'Texte',
					cellClass: 'cell-wrap-text',
					cellEditor: TextareaEditor,
					autoHeight: true,
					cellRenderer: (params) => {
						const value = (params.value || '').split('\n');
						let result = '';
						for (const item of value) {
							result += `<div style="line-height: 17px">${item || '&nbsp;'}</div>`;
						}

						return `<div style="margin-top:5px; margin-bottom:5px;" >${result}</div>`;
					}
				}, {
					field: '#',
					headerName: 'Action',
					pinned: 'right',
					width: 80,
					editable: false,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const button = document.createElement('button');

						button.classList.add('h-100', 'py-0', 'btn-transparent');
						button.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
						button.setAttribute('confirmation', '');
						button.type = 'button';

						button.addEventListener('click', () => {
							params.api?.applyTransaction({
								remove: [params.node.data]
							});
						});

						return button;
					}
				}
			],
			getContextMenuItems: (params) => {
				const result = [{
					name: 'Ajouter ligne',
					disabled: this._isLock,
					action: () => {
						let index = 0;

						params.api?.forEachNode(() => {
							index++;
						});

						params.api?.applyTransaction({
							add: [{
								category: '',
								subCategory: '',
								text: '',
								_idGrid: Date.now().toString(36)
							}]
						});

						params.api?.ensureIndexVisible(index);
					}
				}, {
					name: 'Insérer ligne',
					disabled: !params.node || this._isLock,
					action: () => {
						params.api?.applyTransaction({
							add: [{
								category: '',
								subCategory: '',
								text: '',
								_idGrid: Date.now().toString(36)
							}],
							addIndex: (params.node.rowIndex || 0) + 1
						});
					}
				}];

				if (this.mode) {
					result.push({
						name: 'Insérer les valeurs par defaut',
						disabled: this._isLock,
						action: async () => {
							const CGV = this.settings.get(`${this.mode}.CGV`) || [];

							params.api?.applyTransaction({
								add: CGV as { [key: string]: any }[]
							});
						}
					});
				}

				return result;
			},
			onCellEditingStarted: (params) => {
				if (params.column.getColId() === 'text') {
					const value = (params.value || '').split('\n');

					params.node.setRowHeight((value.length + 2) * 20);
				}
			},
			onCellEditingStopped: (params) => {
				setTimeout(() => {
					params.api.resetRowHeights();
				}, 100);
				this._update();
			},
			onRowDataUpdated: () => {
				this._update();
			},
			onColumnResized: (params) => {
				if (params.finished) {
					params.api.resetRowHeights();
				}
			},
			rowData: []
		});

		const N_grid = this.N_el!.querySelector('.grid') as HTMLDivElement;

		new Grid(N_grid, this._gridOptions, { modules: AllModules });
	}

	public get data(): CGV {
		const results: CGV = [];

		this._gridOptions.api?.stopEditing();

		this._gridOptions.api?.forEachNode((node) => {
			results.push(node.data);
		});

		return results as CGV;
	}

	public set data(data: CGV) {
		this._gridOptions.api?.setRowData(data);
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}
}

export default CGVTab;
