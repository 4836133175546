import T_template from '../../../tpl/blocks/template_materials.ejs';

import _ from 'lodash';

import Utils from '@libs/utils/Utils';

//services
import S_Customer from '@services/Customer/CustomerService';
import S_Product from '@services/Product/ProductService';
import S_Quote from '@services/QuoteService';
import S_Site from '@js/services/Site/SiteService';
import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';

class MaterialsByBrand {
	constructor(id: string, content: { [key: string]: any }[] = [], displayStock: boolean) {
		content = _.cloneDeep(content);
		this.init(id, content, displayStock);
	}

	private async init(id: string, content: { [key: string]: any }[] = [], displayStock: boolean) {
		const data = await S_Quote.getInstance().getById(id);

		if (data.infos.customer) {
			data.infos.customer = await S_Customer.getInstance().getById(data.infos.customer);
		}

		if (data.infos.sites) {
			for (let index = 0; index < data.infos.sites.length; index++) {
				try {
					data.infos.sites[index] = (await S_Site.getInstance().getById(data.infos.sites[index])).name;
				} catch (e) {
				}
			}
		}

		for (const obj of content) {
			obj.product.brand = obj.product.brand || 'MARQUE NON RENSEIGNEE';
		}

		content = _.sortBy(content, ['product.brand', 'product.reference']) as any[];

		let brand = '';
		for (let i = 0; i < content.length; i++) {
			if (content[i].product) {
				content[i].product = this.convertProductToPrint(content[i].product);
			}

			if (brand !== content[i].product.brand) {
				content.splice(i, 0, {
					type: 'brand',
					label: content[i].product.brand
				});

				i++;

				brand = content[i].product.brand;
			}

			if (i % 54 === 0) {
				content.splice(i, 0, {
					type: 'brand',
					label: brand
				});

				i++;
			}
		}

		const N_container = document.createElement('div');

		N_container.classList.add('containerPrint');

		const stock = await this.getStock();

		const chunkMaterials = _.chunk(content, 53);

		for (const chunk of chunkMaterials) {
			N_container.innerHTML += T_template({
				column: {
					reference: S_Product.getInstance().columnNameReference
				},
				infos: data.infos,
				stock,
				displayStock,
				data: chunk
			});
		}

		document.body.appendChild(N_container);

		setTimeout(() => {
			window.print();

			document.body.removeChild(N_container);

			Utils.removeTooltip();
		}, 1000);
	}

	private convertProductToPrint(product: { [key: string]: any }) {
		return {
			reference: product[S_Product.getInstance().referenceKey],
			name: product.name,
			brand: product.brand
		};
	}

	private async getStock() {
		const location = await S_Stock.getInstance().getStockToCommandCustomer();

		const data = await S_StockEvent.getInstance().getCurrentStock();

		const stock: { [key: string]: any } = {};
		for (const item of data.rowData) {
			stock[item.product._id] = stock[item.product._id] || {};
			stock[item.product._id][item.stock] = item.quantity;
		}

		return {
			stock,
			location
		};
	}
}

export default MaterialsByBrand;
