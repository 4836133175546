// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../../tpl/modals/customers/editPageFromQuote/amount.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE

type AmountData = {
	price: string,
	infos: {
		tva: string,
		autoliquidation: boolean
		nonValorisee: boolean
	}
};

class Amount extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null;

	constructor(data: AmountData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);
			this.initEvents();
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					this.resolve(data);
				}));
			});
		});
	}

	private async setData(data: AmountData) {
		const res: { [key: string]: any } = {
			price: data.price,
			infos: {
				tva: data.infos.tva,
				autoliquidation: data.infos.autoliquidation,
				nonValorisee: data.infos.nonValorisee
			}
		};

		this.form?.setData(res);
	}

	private updateTva() {
		const N_tva = this.element.querySelector('[name="infos.tva"]') as HTMLSelectElement;
		const N_autoliquidation = this.element.querySelector('[name="infos.autoliquidation"]') as HTMLInputElement;

		N_tva.disabled = N_autoliquidation.checked;

		if (N_autoliquidation.checked) {
			N_tva.value = '-1';
		}
	}

	private initEvents() {
		const N_autoliquidation = this.element.querySelector('[name="infos.autoliquidation"]') as HTMLInputElement;

		N_autoliquidation.addEventListener('change', () => {
			this.updateTva();
		});

		this.updateTva();
	}

	private postInit() {
		const N_autoliquidation = this.element.querySelector('[name="infos.autoliquidation"]') as HTMLInputElement;

		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}

		N_autoliquidation.addEventListener('change', () => {
			this.updateTva();
		});

		this.updateTva();
	}
}

export default Amount;
