import { toaster } from '@autoprog/core-client';

import moment from 'moment';

import Modal from '@libs/Modal';

import agUtils from '@libs/agGrid/french';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import T_ListSelectQuote from '../../tpl/modals/SelectQuote.html';

import S_Quotes from '@services/QuoteService';

class SelectQuote extends Modal {
	constructor(customer: string) {
		super({
			tpl: T_ListSelectQuote,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const N_numberSelected = this.element.querySelector('#numberSelected') as HTMLElement;

			let selectId: { [key: string]: any } = {};

			const gridOptions = agUtils.french<GridOptions>({
				rowSelection: 'single',
				columnDefs: [
					{
						headerName: 'Numéro',
						checkboxSelection: true,
						suppressSizeToFit: true,
						field: 'infos.number'
					},
					{
						headerName: 'Client',
						field: 'infos.customer',
						suppressSizeToFit: true,
						valueGetter: (params) => {
							return params.data.infos.customer;
						}
					},
					{
						headerName: 'Objet',
						field: 'infos.label'
					},
					{
						headerName: 'Date',
						suppressSizeToFit: true,
						field: 'infos.date',
						cellRenderer: (params) => {
							return moment(params.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
						}
					},
					{
						headerName: 'Site',
						suppressSizeToFit: true,
						field: 'infos.sites'
					},
					{
						headerName: 'Contact',
						suppressSizeToFit: true,
						field: 'infos.contact'
					},
					{
						headerName: 'Montant (€)',
						suppressSizeToFit: true,
						cellClass: 'text-right text-monospace',
						field: 'globalPrice.formattedValue'
					},
					{
						field: 'defaultDisplay',
						hide: true
					}
				],
				suppressRowClickSelection: true,
				defaultColDef: {
					filter: 'agTextColumnFilter',
					filterParams: {
						newRowsAction: 'keep'
					},
					floatingFilter: true,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					sortable: true,
					suppressMenu: true,
					resizable: true
				},
				onGridReady: (params) => {
					S_Quotes.getInstance().getDataToAgGrid().then(({ rowData }) => {
						const res: { [key: string]: any }[] = [];

						for (const item of rowData) {
							item.infos.customer = item.infos.customer || '';
							if (item.infos.customer.includes(customer) && item.infos.state.toString() !== '1' && item.infos.sendDate) {
								item.defaultDisplay = (!['2', '7'].includes(item.infos.state.toString())).toString();

								res.push(item);
							}
						}

						params.api?.setRowData(res);
						params.api?.sizeColumnsToFit();

						const filterInstanceDisplay = params.api?.getFilterInstance('defaultDisplay');

						filterInstanceDisplay?.setModel({
							type: 'equals',
							filter: 'true'
						});

						params.api?.onFilterChanged();
					}).catch((e) => {
						console.error(e);
						toaster.error('Erreur lors de la recuperation des données');
					});
				},
				onRowSelected: (params) => {
					if (params.node.isSelected()) {
						selectId = {
							[params.node.data._id]: true
						};
					} else {
						delete selectId[params.node.data._id];
					}

					N_numberSelected.innerHTML = Object.keys(selectId).length.toString();
				},
				getRowStyle: (params: any) => {
					//en attente commande
					if (params.data.infos.state === '4') {
						return {
							'background-color': 'var(--ap-orange-50)',
							color: 'var(--ap-orange-900)'
						};
					}

					//refusé
					if (params.data.infos.state === '7' || params.data.infos.state === '2') {
						return {
							'background-color': 'var(--ap-red-50)',
							color: 'var(--ap-red-900)'
						};
					}
				}

			});

			const N_grid = this.element.querySelector('#grid') as HTMLElement;

			new Grid(N_grid, gridOptions, { modules: AllModules });

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener2('click', () => {
				this.resolve(Object.keys(selectId)[0]);
			});

			const N_viewAll = this.element.querySelector('#viewAll') as HTMLInputElement;

			N_viewAll.addEventListener('change', async () => {
				const filterInstance = gridOptions.api?.getFilterInstance('defaultDisplay');

				filterInstance?.setModel({
					type: 'equals',
					filter: N_viewAll.checked ? '' : 'true'
				});

				gridOptions.api?.onFilterChanged();
			});
		});
	}
}

export default SelectQuote;
