module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="pagePrint">\n\n    <table style="max-width: calc(100% - 2px)">\n\n        <tbody>\n\n            <tr>\n                <td colspan="6">\n\n                    <div class="w-100 d-flex">\n                        Inventaire - ' +
((__t = (date)) == null ? '' : __t) +
' - ' +
((__t = (title)) == null ? '' : __t) +
'\n\n                        <span class="ml-auto">\n                            Page ' +
((__t = (page)) == null ? '' : __t) +
'/' +
((__t = (totalPage)) == null ? '' : __t) +
'\n                        </span>\n                    </div>\n                </td>\n            </tr>\n\n            <tr>\n                <td class="border border-dark bg-grey-500 font-weight-bold text-center" id="position">#</td>\n                <td class="bg-grey-500 border border-dark text-center" style="max-width:144px; width:144px">Référence</td>\n                <td class="bg-grey-500 border border-dark text-center">Libellé</td>\n                <td class="bg-grey-500 border border-dark text-center">Empl.</td>\n                <td class="bg-grey-500 border border-dark text-center">Qté</td>\n                <td class="bg-grey-500 border border-dark text-center">Prix</td>\n            </tr>\n\n\n            ';
for(let i=0; i < 54; i++){;
__p += '\n\n                ';
 let item=data[i] || {quantity : {}, price : {}};;
__p += '\n\n                ';
if(item.type=='brand' ){;
__p += '\n\n                    <tr>\n                        <td class="font-weight-bold border border-dark px-1" colspan="6">\n                            <div class="d-flex">\n                                ' +
((__t = (item.label)) == null ? '' : __t) +
'\n                                <div class="ml-auto">Prix : ' +
((__t = (item.price)) == null ? '' : __t) +
'</div>\n                            </div>\n                        </td>\n                    </tr>\n                    \n                ';
}else{;
__p += '\n\n                    <tr class="' +
((__t = (item.classes)) == null ? '' : __t) +
'">\n                        <td class="border border-dark px-1 text-center" id="position">' +
((__t = (item.index)) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark px-1" style="max-width:144px; width:144px">' +
((__t = (item.reference || '&nbsp;')) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark px-1">' +
((__t = (item.name || '&nbsp;')) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark px-1">' +
((__t = (item.locationStock)) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark px-1 text-right">' +
((__t = (item.quantity)) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark px-1 text-right">' +
((__t = (item.price)) == null ? '' : __t) +
'</td>\n                    </tr>\n\n                ';
};
__p += '\n\n            ';
};
__p += '\n\n        </tbody>\n\n    </table>\n\n</div>';

}
return __p
}