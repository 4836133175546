import { Form, Tab, toaster } from '@autoprog/core-client';
import SettingsApps from '@libs/Settings';

import _ from 'lodash';

import CE_Select2 from '@libs/customElement/Select2';

import S_Users from '@services/User/UserService';

type select = {
	name: string
	isSingle?: boolean
	text?: string
};

export type Input = {
	text?: string
	select: select[]
};

class SettingsNotificationTabs extends Tab {
	private el: HTMLElement;

	private static links: Input[][] = [];

	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private keys: string[] = [];
	private isSingle: { [key: string]: boolean } = {};

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.generateHTML();

		const settings = SettingsApps.getInstance();

		const form = new Form(this.el.querySelector('form') as HTMLFormElement);

		this.convertData().then((data) => {
			form.setData(data);

			this.postInit();
		});

		const N_save = this.el.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener('click', () => {
			settings.setGeneral('NOTIFICATION', form.getData());

			toaster.success('Sauvegarde réussi');
		});
	}

	private async convertData() {
		const settings = SettingsApps.getInstance();

		let data: { [key: string]: any } = (settings.get('NOTIFICATION') || {}) as { [key: string]: any };

		data = _.cloneDeep(data);

		for (const key of this.keys) {
			const values = _.get(data, key);

			if (values) {
				if (this.isSingle[key]) {
					const selectData = await S_Users.getInstance().getDataToSelect2ByID(values);

					_.set(data, key, selectData);
				} else {
					const res: { [key: string]: string }[] = [];

					for (const item of values) {
						const selectData = await S_Users.getInstance().getDataToSelect2ByID(item);
						res.push(selectData);
					}

					_.set(data, key, res);
				}
			}
		}

		return data;
	}

	private generateHTML() {
		const N_container = this.el.querySelector('#content_form') as HTMLElement;
		this.el.classList.add('p-3');

		for (const col of SettingsNotificationTabs.links) {
			const N_col = document.createElement('div');
			N_col.classList.add('col-4');

			for (const category of col) {
				const N_category = document.createElement('div');

				N_category.innerHTML = `<div class="font-weight-bold">${category.text || ''}</div>`;

				for (const item of category.select) {
					const N_item = document.createElement('div');
					N_item.classList.add('ml-4');
					N_item.innerHTML = `
						<div class="form-group">
							<label>${item.text || {}}</label>							
							<ap-select2-button name="${item.name}" table="users" hidden-btn-add="true" hidden-btn-edit="true">
							</ap-select2-button>
						</div>
					`;

					this.isSingle[item.name] = !!item.isSingle;

					this.keys.push(item.name);

					this.selectPostinit[item.name] = N_item.querySelector(`[name="${item.name}"]`) as CE_Select2;

					N_category.appendChild(N_item);
				}

				N_col.appendChild(N_category);
			}

			N_container.appendChild(N_col);
		}
		for (const name in this.selectPostinit) {
			this.selectPostinit[name].create(this.el, { multiple: !this.isSingle[name] });
		}
	}

	private postInit() {
		for (const name in this.selectPostinit) {
			this.selectPostinit[name].postInit();
		}
	}

	public static addInput(item: Input, column: number) {
		this.links[column] = this.links[column] || [];
		this.links[column].push(item);
	}
}

export default SettingsNotificationTabs;
