// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
// LIBS
// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

class SettingsBillTab extends HTMLElement {
	public static readonly tagName: string = 'ap-settings-bill-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private form: Form | null = null;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `
            <li class="nav-item border-right">
                <a class="nav-link px-3 icon-container" class="nav-link px-3" data-toggle="tab" href="#${this.idTab}" role="tab">
					<span>Paramètres</span>
                </a>
            </li>
        `;

		this.id = '';
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') !== `#${this.idTab}`) {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;
				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');
		N_div.id = this.idTab;
		N_div.innerHTML = `
			<div class="d-flex flex-column h-100 w-50" id="form">
				<div class="card shadow-none p-3">
					<div class="font-weight-bold mt-2">
						Facture d'acompte / avancement
					</div>
			
					<div class="ml-3">
			
						<div class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" id="displayRow_notbill" name="displayRow.notBill">
							<label class="custom-control-label" for="displayRow_notbill">
								Afficher la ligne "Montant restant à facturer" en fin de devis.
							</label>
						</div>
			
					</div>
				</div>

				<div class="card shadow-none p-3 mt-3">
					<div class="font-weight-bold mt-2">
						Facture définitive
					</div>
			
					<div class="ml-3">
			
						<div class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" id="display_modal_quote" name="display.modalQuotes">
							<label class="custom-control-label" for="display_modal_quote">
								Afficher la fenêtre modal de sélection des devis à la création de la facture.
							</label>
						</div>
			
					</div>
				</div>
            </div>
		`;

		const N_form = N_div.querySelector('#form') as HTMLFormElement;
		this.form = new Form(N_form);

		N_container.append(N_div);
	}

	public set data(data: any) {
		this.form?.setData(data);
	}

	public get data(): any {
		return this.form?.getData();
	}

	public static register() {
		customElements.define(SettingsBillTab.tagName, SettingsBillTab);
	}
}

export default SettingsBillTab;
