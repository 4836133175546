// CORE
// NODE_MODULE
// TEMPLATE
// LIBS
import Form from '@libs/FormControllerPageID';

// PRINTER
// UTILS
// MODAL
import M_EditUsers from '../../../modals/customers/editPageFromQuote/EditUsers';

// CUSTOM_ELEMENT
// SERVICE

class UsersTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-customers-users-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private form: Form | null = null;

	private _update = (): void => { };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Personnels">
						<i class="icon icon-solid-user-alt"></i>
					</div>
					<span>Personnels</span>
				</a>
			</li>
		`;

		this.id = '';
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') !== `#${this.idTab}`) {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');

		N_div.id = this.idTab;
		N_div.innerHTML = `
			<div class="d-flex flex-column h-100">
                <div class="title">
					Personnels
					<div class="ml-auto">
						<button class="btn btn-transparent ml-auto" tooltip="Éditer" type="button" id="edit">
							<i class="text-info icon icon-edit"></i>
						</button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
                </div>
                <div class="h-100">
				
					<div class="d-flex mb-2">
						<div class="page-card-label mr-1 text-nowrap">Responsable :</div>
						<div class="page-card-value" name="infos.manager"></div>
					</div>

					<div class="d-flex mb-2">
						<div class="page-card-label mr-1 text-nowrap">Responsable achat :</div>
						<div class="page-card-value" name="infos.purchaseManager"></div>
					</div>

					<div class="d-flex mb-2">
						<div class="page-card-label mr-1 text-nowrap">Responsable planning :</div>
						<div class="page-card-value" name="infos.calendarManager"></div>
					</div>

					<div class="d-flex mb-2">
						<div class="page-card-label mr-1 text-nowrap">
							Personne(s)	informée(s) :
						</div>
						<div class="page-card-value" name="infos.usersInfos"></div>
					</div>

					<div class="d-flex mb-2">
						<div class="page-card-label mr-1 text-nowrap">
							Intervenants : 
						</div>
						<div class="page-card-value" name="infos.users"></div>
					</div>

				</div>
            </div>
        `;

		this.form = new Form(N_div);

		const N_edit = N_div.querySelector('#edit');

		N_edit?.addEventListener('click', () => {
			new M_EditUsers(this.form?.getData()).open().then((data) => {
				this.form?.setData(data);
				this._update();
			});
		});

		N_container.append(N_div);
	}

	public set data(data: { [key: string]: any }) {
		const res = {
			infos: {
				manager: data.infos.manager,
				purchaseManager: data.infos.purchaseManager,
				calendarManager: data.infos.calendarManager,
				users: data.infos.users,
				usersInfos: data.infos.usersInfos
			}
		};

		this.form?.setData(res);
	}

	public get data(): { [key: string]: any } {
		return this.form?.getData();
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public static register() {
		customElements.define(UsersTab.tagName, UsersTab);
	}
}

export default UsersTab;
