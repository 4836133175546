// CORE
import { Module, Modules, Router } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_CommandCustomer from './tpl/command.customer.html';
import T_CommandCustomerFromQuote from './tpl/command.customer.fromQuote.html';
import T_CommandProvider from './tpl/command.provider.html';
import T_MaterialCustomer from './tpl/material.customer.html';
import T_RecapCustomer from './tpl/recap.customer.html';
import T_RecapProvider from './tpl/recap.provider.html';
import T_tabs from '@tpl/tabs.html';

// LIBS
import C_CommandCustomer from './js/controllers/Command.Customer';
import C_CommandCustomerFromQuote from './js/controllers/Command.Customer.FromQuote';
import C_CommandProvider from './js/controllers/Command.Provider';
import C_MaterialCustomer from './js/controllers/Material.customer';
import C_RecapCustomer from './js/controllers/Recap.customer';
import C_RecapProvider from './js/controllers/Recap.provider';
import C_Tabs from './js/controllers/index';
import Navigation from '@libs/Navigation';
import Notifications from '@modules/Apps/js/libs/Notifications';
import SettingsNotificationTabs from '@modules/Apps/js/tabs/Settings.Notification';

// PRINTER
// UTILS
// MODAL

// CUSTOM_ELEMENT
import CE_BillingRequestTab from './js/libs/customElement/customers/BillingRequest';
import CE_BillsTab from './js/libs/customElement/Bills';
import CE_ComptaTab from './js/libs/customElement/customers/ComptaTab';
import CE_DeliveryTab from './js/libs/customElement/customers/DeliveryTab';
import CE_GroupQuotesTab from './js/libs/customElement/customers/GroupQuotesTab';
import CE_MaterialsTab from './js/libs/customElement/customers/MaterialsTab';
import CE_OrderCustomer from './js/libs/customElement/providers/OrderCustomer';
import CE_OutputStockTab from './js/libs/customElement/customers/OutputStockTab';
import CE_ProviderOrderTab from './js/libs/customElement/customers/ProviderOrder';
import CE_QuotesTab from './js/libs/customElement/customers/QuotesTab';
import CE_UsersTab from './js/libs/customElement/customers/UsersTab';

import CE_ContentTab from './js/libs/customElement/providers/ContentTab';

// SERVICE

class CommandsModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_GroupQuotesTab.register();
		CE_DeliveryTab.register();
		CE_ProviderOrderTab.register();
		CE_BillingRequestTab.register();
		CE_BillsTab.register();
		CE_MaterialsTab.register();
		CE_OrderCustomer.register();
		CE_QuotesTab.register();
		CE_OutputStockTab.register();
		CE_ComptaTab.register();
		CE_UsersTab.register();

		CE_ContentTab.register();
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Documents',
			order: 100
		});

		navigation.addLink({
			order: 1,
			category: 'Documents',
			name: 'Commandes',
			type: 'module',
			module: 'commands',
			permissions: ['ORDERS._CUSTOMERS', 'ORDERS._PROVIDERS'],
			icon: 'file-text/fill'
		});

		SettingsNotificationTabs.addInput({
			text: 'Commande - client',
			select: [{
				text: 'Responsable planning',
				isSingle: true,
				name: 'command_customers.calendarManager'
			}, {
				text: 'Responsable achat',
				isSingle: true,
				name: 'command_customers.purchaseManager'
			}, {
				text: 'Intervenants',
				name: 'command_customers.users'
			}, {
				text: 'Informations',
				name: 'command_customers.infos'
			}, {
				text: 'Demandes de facturation',
				name: 'command_customers.billingRequest.infos'
			}]
		}, 0);

		Notifications.addCategory(['command-customers'], {
			textFilter: 'Commande Client',
			isFilter: true,
			icon: 'icon-solid-file-alt',
			action: async (item: { [key: string]: any }) => {
				if (item.data.id) {
					C_CommandCustomer.open(item.data.id, { notification: item._id });
				}
			}
		});

		Notifications.addCategory(['command-providers'], {
			textFilter: 'Commande Fournisseur',
			icon: 'icon-solid-file-alt',
			action: async (item: { [key: string]: any }) => {
				if (item.data.id) {
					C_CommandProvider.open(item.data.id, { notification: item._id });
				}
			}
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/commands/:tabs',
			controller: C_Tabs as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/commands',
			controller: C_Tabs as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/commands_export/material/customer',
			controller: C_MaterialCustomer as any,
			tpl: T_MaterialCustomer,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/commands_export/customer',
			controller: C_RecapCustomer as any,
			tpl: T_RecapCustomer,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/commands_export/provider',
			controller: C_RecapProvider as any,
			tpl: T_RecapProvider,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/order/customer',
			controller: C_CommandCustomer as any,
			tpl: T_CommandCustomer,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/order/customer/from_quote',
			controller: C_CommandCustomerFromQuote as any,
			tpl: T_CommandCustomerFromQuote,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/order/provider',
			controller: C_CommandProvider as any,
			tpl: T_CommandProvider,
			requireValidUser: true
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('Commandes', CommandsModule);
