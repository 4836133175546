import Form from '@libs/FormControllerPageID';
import Options from '../GridOptions';

import M_Edit from '../../modals/QuoteOptions';

import SettingsApps from '@libs/Settings';

import _ from 'lodash';

export type SettingQuoteOption = {
	hourlyPriceType: {
		[key: string]: string
	},
	marginType: {
		[key: string]: string
	}
};

class InputDeleteBtnReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes-options-input-readonly';

	public async connectedCallback() {
		const text = this.getAttribute('text');
		const name = this.getAttribute('name') || '';
		const type = this.getAttribute('type') || '';

		const nameCompta = name.replace('price', 'comptaVente');

		this.innerHTML = `			
			<div class="d-flex mb-2">
				<div class="page-card-label mr-1 text-nowrap">${text} :</div>
				<div class="page-card-value mr-2" type="${type === 'hourlyPrice' ? 'price' : 'percentage'}" name="${name}"></div>
				<div class="d-flex" id="compta">
					(<div name="${nameCompta}" defaultValue="Aucun compte comptable"></div>)
				</div>
			</div>
		`;

		const N_compta = this.querySelector('#compta') as HTMLElement;
		const settings = (SettingsApps.getInstance().get('COMPTA') || {}) as { [key: string]: any };

		if (name.split('.')[1] !== 'price') {
			N_compta.remove();
		}

		if (settings.disable) {
			N_compta.classList.remove('d-flex');
			N_compta.classList.add('d-none');
		}

		this.removeAttribute('name');
	}

	public static register() {
		customElements.define(InputDeleteBtnReadonly.tagName, InputDeleteBtnReadonly);
	}
}

class QuoteOptionTabReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes-options-tab-readonly';

	private selectorTab: string = '';

	private idTab: string = '';

	private _update: () => void = () => { };

	private N_tab: HTMLElement | null = null;

	private settingQuoteOptions: SettingQuoteOption | undefined;

	private form: Form | null = null;

	private _isLock: boolean = false;

	private N_div: HTMLDivElement | null = null;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || '';

		this.innerHTML = `
			<li class="nav-item">
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Paramètres devis">
						<i class="icon icon-ri-settings-3-line"></i>
					</div>
					<span>Paramètres devis</span>
				</a>
			</li>
		`;

		this.id = '';
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') !== `#${this.idTab}`) {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;
				N_li.classList.remove('active');
			}
		});
	}

	public async setParentElement(parent: HTMLElement) {
		this.N_div = document.createElement('div');

		this.N_div.classList.add('tab-pane', 'h-100', 'fade');
		this.N_div.setAttribute('role', 'tabpanel');
		this.N_div.id = this.idTab;
		this.N_div.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="title" display="desktop">
					Paramètres devis
					<div class="ml-auto">
						<button class="btn btn btn-transparent" type="button" id="edit" permission="QUOTES.EDIT">
							<i class="text-info icon icon-edit"></i>
						</button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
				</div>
				<div class="row h-100">

					<div class="col-md-4">
						<div class="d-flex font-weight-bold mb-3">
							TAUX HORAIRE (€)
						</div>
						<div id="price-list"> </div>
					</div>

					<div class="col-md-4">
						<div class="d-flex font-weight-bold mb-3">
							MARGES (%)
						</div>
						<div id="marges-list"></div>
					</div>

					<div class="col-md-4">

						<div class="d-flex font-weight-bold mb-3">
							INFORMATIONS
						</div>

						<div class="d-flex mb-2">
							<div class="page-card-label mr-1 text-nowrap">Nombre de décimales :</div>
							<div class="page-card-value" name="pref.decimalNumber"></div>
						</div>

						<div class="d-flex mb-2">
							<div class="page-card-label mr-1 text-nowrap">Coef total :</div>
							<div class="page-card-value" name="pref.coefTotal"></div>
						</div>

						<hr>

						<div class="d-flex font-weight-bold mb-3">TYPE PAR DEFAUT</div>

						<div class="d-flex mb-2">
							<div class="page-card-label mr-1 text-nowrap">Marge :</div>
							<div class="page-card-value" name="pref.typeMat"></div>
						</div>

						<div class="d-flex mb-2">
							<div class="page-card-label mr-1 text-nowrap">Taux horaire :</div>
							<div class="page-card-value" name="pref.type"></div>
						</div>

						<hr>

						<div class="d-flex font-weight-bold mb-3">CALCUL DE LA MARGE</div>
		
						<div class="d-flex mb-2">
							<div class="page-card-label mr-1 text-nowrap">Appliquer une marge commerciale :</div>
							<div class="page-card-value" name="pref.calculMarkUp"></div>
						</div>

						<hr>

						<div class="d-flex font-weight-bold mb-3">OPTION D'AFFICHAGE DU GROUPE</div>

						<div class="d-flex mb-2">
							<div class="page-card-label mr-1 text-nowrap">Afficher la colonne <b>PU total</b>:</div>
							<div class="page-card-value" name="pref.displayPUDetails"></div>
							<i class="icon icon-solid-info-circle ml-2" tooltip="Prix unitaire avec marge"></i>
						</div>

					</div>

				</div>
			</div>
        `;

		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
		N_container.appendChild(this.N_div);

		this.N_tab = this.N_div;

		this.form = new Form(this.N_tab as HTMLFormElement);

		const N_edit = this.N_div.querySelector('#edit') as HTMLButtonElement;

		N_edit.addEventListener('click', () => {
			if (!this._isLock) {
				new M_Edit({ quoteOptions: this.quoteOptions, pref: this.data }).open().then((data) => {
					this.data = data;
					this._update();
				});
			}
		});
	}

	public set isLock(isLock: boolean) {
		if (this.N_div) {
			const N_edit = this.N_div.querySelector('#edit') as HTMLButtonElement;
			N_edit.disabled = isLock;
		}

		this._isLock = isLock;
	}

	public set quoteOptions(quoteOptions: SettingQuoteOption) {
		this.settingQuoteOptions = quoteOptions;

		const N_priceHoursList = this.N_tab!.querySelector('#price-list') as HTMLElement;

		for (const key in this.settingQuoteOptions!.hourlyPriceType) {
			const value = this.settingQuoteOptions!.hourlyPriceType[key];

			if (![Options.NO_MO].includes(key)) {
				const N_div = this.createTemplateType(`pref.price.${key}`, value, 'hourlyPrice');

				N_priceHoursList.appendChild(N_div);
			}
		}

		const N_marginList = this.N_tab!.querySelector('#marges-list') as HTMLElement;

		for (const key in this.settingQuoteOptions!.marginType) {
			const value = this.settingQuoteOptions!.marginType[key];

			if (![Options.NO_MARGIN, Options.LICENCE, Options.DISCOUNT].includes(key)) {
				const N_div = this.createTemplateType(`pref.marges.${key}`, value, 'margin');

				N_marginList.appendChild(N_div);
			}
		}

		this.form?.updateInputs();
	}

	public get quoteOptions(): SettingQuoteOption {
		return this.settingQuoteOptions!;
	}

	private convertValue(data: { [key: string]: any }) {
		data.typeMat = {
			id: data.typeMat,
			text: this.settingQuoteOptions!.marginType[data.typeMat]
		};

		data.type = {
			id: data.type,
			text: this.settingQuoteOptions!.hourlyPriceType[data.type]
		};

		for (const key in data.marges) {
			data.marges[key] = data.marges[key] || 0;
		}

		for (const key in data.price) {
			data.price[key] = data.price[key] || 0;
		}

		data.decimalNumber = data.decimalNumber || 0;

		return data;
	}

	public set data(data: { [key: string]: any }) {
		data = _.cloneDeep(data);
		data = this.convertValue(data);
		this.form!.setData({ pref: data });
	}

	public get data(): { [key: string]: any } {
		const data = this.form!.getData() as { [key: string]: any };
		return data.pref;
	}

	private createTemplateType(key: string, value: string, type: 'margin' | 'hourlyPrice'): InputDeleteBtnReadonly {
		const N_div = document.createElement('ap-quotes-options-input-readonly') as InputDeleteBtnReadonly;

		N_div.setAttribute('name', key);
		N_div.setAttribute('text', value);
		N_div.setAttribute('type', type);

		return N_div;
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public static register() {
		customElements.define(QuoteOptionTabReadonly.tagName, QuoteOptionTabReadonly);
		InputDeleteBtnReadonly.register();
	}
}

export default QuoteOptionTabReadonly;
