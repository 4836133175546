import GenericService from '../GenericService';

class BillService extends GenericService {
	private static instance: BillService | null = null;

	constructor() {
		super('bills-providers');
	}

	public static getInstance(): BillService {
		if (!BillService.instance) {
			BillService.instance = new BillService();
		}

		return BillService.instance;
	}

	public async getByOrder(id: string) {
		return await this.get_function('getByOrder', { id });
	}

	public getNotPaidById(id: string) {
		return this.get_function('getNotPaidById', { id });
	}
}

export default BillService;
