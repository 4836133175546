// CORE
// NODE_MODULE
// TEMPLATE
import T_SelectProduct from '../../tpl/modals/selectProduct.html';

// LIBS
import Loader from '@libs/Loader';
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL

// CUSTOM_ELEMENT
import CE_SelectProductTabs from '../libs/customElement/SelectProductTabs';

class SelectProductModal extends Modal {
	constructor() {
		super({
			tpl: T_SelectProduct,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			Loader.getInstance().open();

			const N_tabs = this.element.querySelector('ap-select-products-tabs') as CE_SelectProductTabs;

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				this.resolve(N_tabs.data);
			});

			Loader.getInstance().close();
		});
	}
}

export default SelectProductModal;
