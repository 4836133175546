// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../../../tpl/modals/customers/editPage/additionalInformation.html';

// LIBS
import DeadlinePaymentUtils from '@libs/utils/DeadlinePaymentUtils';
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT
import CE_Address from '@libs/customElement/Address';
import CE_Select2 from '@libs/customElement/Select2';
import CE_SitesFinalCustomer from '@libs/customElement/Sites-FinalCustomer';

// SERVICE
import S_C_Address from '@services/Customer/CustomerAddressService';
import S_Customer from '@services/Customer/CustomerService';
import S_Sites from '@services/Site/SiteService';

type AdditionalInformationData = {
	infos: {
		customer: string,
		addressID: string,
		fullAddress: string,
		addressIDDelivery: string,
		fullAddressDelivery: string,
		deadlinePayment: string,
		typeDeadlinePayment: string,
		fixedDateDeadline: Moment,
		autoliquidation: boolean,
		commentFinish: string,
		comments: string,
		finalCustomer: string,
		hasFinalCustomer: string,
		sites: string[],
		siteCustom: string,
		number: string,
		datePayment: Moment,
		sendDate: Moment
		email: string
	},
	relunch: {
		date: Moment
	}
};

class AdditionalInformation extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 | CE_Address } = {};

	private form: Form | null;

	private oldData: { [key: string]: any } = {};

	constructor(data: AdditionalInformationData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);
			this.oldData = data;

			await this.setData(this.oldData);
			this.initFinalCustomer();
			this.initEvents();
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const formData = this.form?.getData() as { [key: string]: any };

					formData.infos.customer = await S_Customer.getInstance().getDataToSelect2ByID(formData.infos.customer);
					formData.infos.addressID = await S_C_Address.getInstance().getDataToSelect2ByID(formData.infos.addressID);
					formData.infos.addressIDDelivery = await S_C_Address.getInstance().getDataToSelect2ByID(formData.infos.addressIDDelivery);

					const sites: any[] = [];
					for (const item of formData.infos.sites) {
						const tmp = await S_Sites.getInstance().getDataToSelect2ByID(item);
						sites.push(tmp);
					}

					formData.infos.sites = sites;

					const res = _.merge(this.oldData, formData);
					this.resolve(res);
				}));
			});
		});
	}

	private initEvents() {
		const N_deadlinePayment = this.element.querySelector('ap-deadline-payment') as HTMLInputElement;

		N_deadlinePayment.addEventListener('change', async () => {
			const deadlinePayment = this.form?.getDataByName('infos.deadlinePayment') as number;
			const fixedDateDeadline = this.form?.getDataByName('infos.fixedDateDeadline') as number;
			const typeDeadlinePayment = this.form?.getDataByName('infos.typeDeadlinePayment') as string;

			if (typeDeadlinePayment === '3') {
				const sendDate = this.oldData.infos.sendDate;

				if (sendDate) {
					const paidDate = DeadlinePaymentUtils.calculateDate(sendDate, deadlinePayment, fixedDateDeadline, typeDeadlinePayment);

					const tmp: { [key: string]: any } = {
						infos: {
							datePayment: paidDate.clone()
						},
						relunch: {
							date: paidDate.clone().add(1, 'week')
						}
					};

					this.form?.setData(tmp);
				}
			} else {
				const date = this.oldData.infos.date;

				const paidDate = DeadlinePaymentUtils.calculateDate(date, deadlinePayment, fixedDateDeadline, typeDeadlinePayment);

				const tmp: { [key: string]: any } = {
					infos: {
						datePayment: paidDate.clone()
					},
					relunch: {
						date: paidDate.clone().add(1, 'week')
					}
				};

				this.form?.setData(tmp);
			}
		});
	}

	private init() {
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_sites = this.element.querySelector('[name="infos.sites"]') as CE_Select2;
		const N_address = this.element.querySelector('[name-select="infos.addressID"]') as CE_Address;
		const N_addressDelivery = this.element.querySelector('[name-select="infos.addressIDDelivery"]') as CE_Address;

		N_customer.create(this.element, { disabled: true });

		N_address.setRef({ id_customer: N_customer.selectElement!, bill: true });
		N_address.create(this.element);

		N_addressDelivery.setRef({ id_customer: N_customer.selectElement! });
		N_addressDelivery.create(this.element);

		N_sites.setRef({ customer: N_customer.selectElement! });
		N_sites.create(this.element, { multiple: true });

		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['infos.addressID'] = N_address;
		this.selectPostinit['infos.addressIDDelivery'] = N_addressDelivery;
		this.selectPostinit['infos.sites'] = N_sites;
	}

	private initFinalCustomer() {
		const N_sites = this.element.querySelector('ap-sites-final-customer') as CE_SitesFinalCustomer;
		N_sites.setFinalCustomer();
	}

	private async setData(data: { [key: string]: any }) {
		const sites: any[] = [];

		for (const item of data.infos.sites) {
			const tmp = await S_Sites.getInstance().getDataToSelect2ByID(item);
			sites.push(tmp);
		}

		const res: { [key: string]: any } = {
			infos: {
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer),
				addressID: await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.addressID),
				fullAddress: data.infos.fullAddress,
				addressIDDelivery: await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.addressIDDelivery),
				fullAddressDelivery: data.infos.fullAddressDelivery,
				deadlinePayment: data.infos.deadlinePayment,
				typeDeadlinePayment: data.infos.typeDeadlinePayment,
				fixedDateDeadline: data.infos.fixedDateDeadline,
				autoliquidation: data.infos.autoliquidation,
				description: data.infos.description,
				commentFinish: data.infos.commentFinish,
				comments: data.infos.comments,
				finalCustomer: data.infos.finalCustomer,
				hasFinalCustomer: data.infos.hasFinalCustomer,
				sites,
				siteCustom: data.infos.siteCustom,
				number: data.infos.number,
				datePayment: data.infos.datePayment,
				sendDate: data.infos.sendDate,
				email: data.infos.email
			},
			relunch: {
				date: data.relunch.date
			}
		};

		this.form?.setData(res);
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default AdditionalInformation;
