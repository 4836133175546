// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../../tpl/modals/providers/editPage/generalInformation.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_P_Contact from '@services/Provider/ProviderContactService';
import S_Provider from '@services/Provider/ProviderService';

type GeneralInformationData = {
	infos: {
		provider: string,
		contact: string
	}
};

class GeneralInformation extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null;

	constructor(data: GeneralInformationData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);
			this.initEvents();
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					data.infos.provider = await S_Provider.getInstance().getDataToSelect2ByID(data.infos.provider);
					data.infos.contact = await S_P_Contact.getInstance().getDataToSelect2ByID(data.infos.contact);

					this.resolve(data);
				}));
			});

			this.updateButtonSave();
		});
	}

	private initEvents() {
		this.selectPostinit['infos.provider'].on('change', async (provider) => {
			const contacts = await S_P_Contact.getInstance().getByProviderToSelect2(provider as string);

			if (contacts.length && provider) {
				this.form?.setDataByName('infos.contact', contacts[0]);
			} else {
				this.form?.setDataByName('infos.contact', { id: '', text: '' });
			}

			this.updateButtonSave();
		});
	}

	private init() {
		const N_provider = this.element.querySelector('[name="infos.provider"]') as CE_Select2;
		const N_contact = this.element.querySelector('[name="infos.contact"]') as CE_Select2;

		N_provider.create(this.element);

		N_contact.setRef({ id_provider: N_provider.selectElement! });
		N_contact.create(this.element);

		this.selectPostinit['infos.provider'] = N_provider;
		this.selectPostinit['infos.contact'] = N_contact;
	}

	private async setData(data: GeneralInformationData) {
		const res: { [key: string]: any } = {
			infos: {
				provider: await S_Provider.getInstance().getDataToSelect2ByID(data.infos.provider),
				contact: await S_P_Contact.getInstance().getDataToSelect2ByID(data.infos.contact)
			}
		};

		this.form?.setData(res);
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}

	private updateButtonSave() {
		const provider = this.form?.getDataByName('infos.provider') as string;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !provider;
	}
}

export default GeneralInformation;
