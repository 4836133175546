// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import moment, { Moment } from 'moment';

// TEMPLATE
import T_Modal from '@tpl/modals/SetRelunchDate.html';

// LIBS
// PRINTER
// UTILS
// MODAL
import Modal from '@libs/Modal';

import ServiceManager from '@managers/ServiceManager';

// CUSTOM_ELEMENT
// SERVICE

class SetRelunchDate extends Modal {
	constructor(table: string, id: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const form = new Form(this.element.querySelector('.modal-body') as HTMLFormElement);

			const value = await ServiceManager.get(table)?.getInstance().getNewRelunchDate(id);

			form.setDataByName('date', value.date);

			const N_relunchType = this.element.querySelector('#typeRelunch') as HTMLInputElement;

			N_relunchType.addEventListener('change', () => {
				if (form) {
					const date = moment();

					const [number, type] = N_relunchType.value.split('_');

					date.add(number, type as any);

					form.setDataByName('date', date);
				}
			});

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				const data = form.getData();

				data.date = (data.date as Moment).format('x');

				await ServiceManager.get(table)?.getInstance().setNewRelunchDate(data, id);

				this.resolve();
			});
		});
	}
}

export default SetRelunchDate;
