import { AllModules, ColDef, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

import _ from 'lodash';

import P_MaterialsByBrand from '../printer/MaterialsByBrand';
import P_MaterialsByCategory from '../printer/MaterialsByCategory';

import S_Products from '@services/Product/ProductService';
import S_Quote from '@services/QuoteService';
import S_StockEvent from '@services/StockEventService';

class RecapTab extends HTMLElement {
	public static readonly tagName: string = 'ap-products-recap-quote';

	private idTab: string;
	private selectorTab: string;

	private _gridOptions: GridOptions = {};
	private _dataId = (): string => { return ''; };
	private _data = (): any => { return {}; };

	private locations: { [key: string]: any }[] = [];

	public static register() {
		customElements.define(this.tagName, RecapTab);
	}

	constructor() {
		super();
		this.idTab = this.id;
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Produits">	
						<i class="icon icon-solid-box-open"></i>
					</div>
					<span>Produits</span>
				</a>
			</li>
		`;

		this.removeAttribute('id');
	}

	public async connectedCallback() {
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this._gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');

		N_div.id = this.idTab;
		N_div.innerHTML = `
			<div class="d-flex flex-column h-100">
                <div class="title">
					Produits

					<div class="d-flex align-items-center text-sm ml-2">
						<strong class="text-grey mr-3 pt-1">Légende :</strong>

						<small class="d-flex align-items-center text-orange-900 mr-3">
							<div class="badge-legend bg-orange-100"></div>
							Stock insuffisant
						</small>

						<small class="d-flex align-items-center text-green-900">
							<div class="badge-legend bg-green-100"> </div>
							Stock OK
						</small>

					</div>
					
					<div class="ml-auto d-flex align-items-center">
						<div class="d-flex align-items-center mr-2">

							<span class="text-sm">Imprimer le stock :</span>

							<div class="ml-2 custom-control custom-switch">
								<input type="checkbox" class="custom-control-input"
									id="displayStock" checked>
								<label class="custom-control-label" for="displayStock"></label>
							</div>

							<select id="print-type" class="form-control mx-1" style="width: 200px;">
								<option value="by-brand" selected>Par marque</option>
								<option value="by-category">Par catégorie</option>
							</select>

							<i class="icon icon-solid-question-circle" tooltip="Par marque (prend en compte les filtres)"></i>

						</div>

						<button class="btn btn-print-modal mx-2" type="button" id="print-by-type">
							<i class="icon icon-printer"></i>Imprimer
						</button>
						<button class="btn btn-action" type="button" id="reload">
							<ap-icon name="refresh/line"></ap-icon>
						</button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
                </div>
                <div class="h-100 ag-theme-alpine grid"></div>
            </div>
        `;

		const N_printType = N_div.querySelector('#print-type') as HTMLSelectElement;
		const N_printByType = N_div.querySelector('#print-by-type') as HTMLButtonElement;
		const N_displayStock = N_div.querySelector('#displayStock') as HTMLInputElement;
		const N_reload = N_div.querySelector('#reload') as HTMLButtonElement;

		N_printByType.addEventListener('click', () => {
			if (N_printType.value === 'by-brand') {
				const rowData: { [key: string]: any }[] = [];

				this._gridOptions.api?.forEachNodeAfterFilter((node) => {
					rowData.push(node.data);
				});

				new P_MaterialsByBrand(this._dataId(), rowData, N_displayStock.checked);
			}

			if (N_printType.value === 'by-category') {
				new P_MaterialsByCategory(this._dataId(), N_displayStock.checked);
			}
		});

		N_reload.addEventListener('click', async () => {
			const data = await S_Quote.getInstance().getMaterials(this._data());
			this._gridOptions.api?.setRowData(data);
			this.initStock();
		});

		N_container.append(N_div);
	}

	private initGrid() {
		const childrenStock: ColDef[] = [];

		for (const item of this.locations) {
			((item: any) => {
				childrenStock.push({
					headerName: item.name,
					width: 100,
					field: 'currentStock.' + item._id + '.quantity',
					cellClass: ['text-right', 'text-monospace'],
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.currentStock = params.data.currentStock || {};
						params.data.currentStock[item._id] = params.data.currentStock[item._id] || {};
						return params.data.currentStock[item._id].quantity;
					},
					cellRenderer: (params) => {
						return _.isUndefined(params.data.currentStock[item._id].quantity) ? '<i class="icon icon-solid-spinner icon-spin"></i>' : params.data.currentStock[item._id].quantity;
					}
				});
			})(item);
		}

		this._gridOptions = agUtils.french<GridOptions>({
			rowData: this._gridOptions.rowData || [],
			columnDefs: [
				{
					headerName: S_Products.getInstance().columnNameReference,
					field: `product.${S_Products.getInstance().referenceKey}`,
					width: 200,
					suppressSizeToFit: true,
					floatingFilter: true,
					cellRenderer: (params) => {
						return S_Products.getInstance().cellRendererByData(params.data.product);
					}
				},
				{
					headerName: 'Libellé',
					field: 'product.name',
					floatingFilter: true
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					floatingFilter: true,
					sort: 'asc',
					width: 200,
					suppressSizeToFit: true
				},
				...(childrenStock.length === 1 ? childrenStock : [{ headerName: 'Stock', children: childrenStock }]),
				{
					headerName: 'Quantité',
					field: 'quantity',
					cellClass: ['text-right', 'text-monospace'],
					width: 100,
					suppressSizeToFit: true
				},
				{
					headerName: 'Unité',
					field: 'unit',
					width: 100,
					suppressSizeToFit: true
				}
			],
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true,
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			getRowStyle: (params: any) => {
				const stockProduct = params.data.currentStock || {};

				if (_.isUndefined(stockProduct)) {
					return {
						background: '#fff'
					};
				}

				let totalStock = 0;

				for (const key in stockProduct) {
					totalStock += stockProduct[key].quantity || 0;
				}

				if (totalStock < params.data.quantity) {
					return {
						'background-color': 'var(--ap-orange-50)',
						color: 'var(--ap-orange-900)'
					};
				}

				if (totalStock >= params.data.quantity) {
					return {
						'background-color': 'var(--ap-green-50)',
						color: 'var(--ap-green-900)'
					};
				}
			},
			getContextMenuItems: () => {
				const N_displayStock = this.querySelector('#displayStock') as HTMLInputElement;
				return [{
					name: 'Imprimer',
					subMenu: [{
						name: 'Par marque',
						action: () => {
							const rowData: { [key: string]: any }[] = [];

							this._gridOptions.api?.forEachNodeAfterFilter((node) => {
								rowData.push(node.data);
							});

							new P_MaterialsByBrand(this.id, rowData, N_displayStock.checked);
						}
					}, {
						name: 'Par categorie',
						action: () => {
							new P_MaterialsByCategory(this.id, N_displayStock.checked);
						}
					}]
				}];
			}
		});

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	public setData(data: { [key: string]: any }) {
		this.locations = data.locations;

		this._gridOptions.rowData = data.products;
		this._gridOptions.api?.setRowData(data.products);

		if (!this._gridOptions.api) {
			this.initGrid();
			this.initStock();
		}
	}

	private async initStock() {
		const { rowData } = await S_StockEvent.getInstance().getCurrentStock();

		const stock: { [key: string]: any } = {};
		for (const item of rowData) {
			stock[item.product._id] = stock[item.product._id] || {};
			stock[item.product._id][item.stock] = stock[item.product._id][item.stock] || { quantity: 0 };
			stock[item.product._id][item.stock].quantity = item.quantity.formattedValue;
		}

		this._gridOptions.api?.forEachNode((node) => {
			for (const item of this.locations) {
				stock[node.data.reference] = stock[node.data.reference] || {};
				node.data.currentStock = node.data.currentStock || {};
				node.data.currentStock[item._id] = stock[node.data.reference][item._id] || { quantity: 0 };
			}

			node.setData(node.data);
		});
	}

	public setID(cb: () => string) {
		this._dataId = cb;
	}

	public setGetData(cb: () => any) {
		this._data = cb;
	}
}
export default RecapTab;
