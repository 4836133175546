import { Alert } from '@autoprog/core-client';
import { GridOptions } from '@ag-grid-enterprise/all-modules';

import _ from 'lodash';

import Decimal from '@libs/utils/Decimal';
import PrintPreview from '@libs/modals/PrintPreview';

import S_Quote from '@services/QuoteService';

import M_DetailsRecapProducts from '../../modals/AddQuote/DetailsRecapProducts';

class DetailCellRenderer {
	private eGui: HTMLElement | null = null;

	constructor(data: any) {
		this.init(data);
	}

	private init(data: any) {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('d-none', 'h-100', 'p-3', 'border', 'flex-wrap');

		this.eGui.style.borderBottomLeftRadius = '10px';
		this.eGui.style.borderBottomRightRadius = '10px';
		this.eGui.style.borderTop = 'none';

		this.eGui.innerHTML = `
			<div>
				<div class="mr-4">
					<div class="font-weight-bold pb-3">
						Main d'oeuvre
					</div>
					<div>
						${this.displayMO(data)}
					</div>
				</div>
				<div class="mr-4">
					<div class="font-weight-bold pb-3">
						Matériel
					</div>
					<div>
						${this.displayMAT(data)}
					</div>
				</div>
			</div>
			<div class="h-100">
				<div class="font-weight-bold pb-2 pr-3 d-flex align-items-center">
					Produits

					<button class="btn btn-xs ml-3" ${Object.keys(data.byBrand).length === 0 ? 'disabled' : ''} type="button" id="details">Voir details</button>
				</div>
  				<div class="d-flex flex-wrap">
					${this.displayProduct(data)}
				</div>
			</div>
		`;

		const N_details = this.eGui.querySelector('#details') as HTMLButtonElement;

		N_details.addEventListener('click', () => {
			new M_DetailsRecapProducts(data.id, data.products).open();
		});
	}

	private displayMO(data: any) {
		let html = `
			<table class="table table-bordered table-hover">
				<thead>
					<tr>
						<th style="min-width:150px"> Type </th>
						<th style="min-width:150px"> Temps </th>
						<th style="min-width:150px"> Km </th>
					<tr>
				</thead>
				<tbody>
		`;

		for (const key in data.typeMO) {
			if (data.typeMO[key].time || data.typeMO[key].km) {
				if (data.typeMO[key].time) {
					html += `
						<tr>
							<td> ${key} </td>
							<td class="text-right text-monospace"> ${Decimal.setDisplayNumber(data.typeMO[key].time).setSuffixAndHumanizeNumber('h', data.decimalNumber)} </td>
							<td class="text-right text-monospace"> -- </td>
						</tr>
					`;
				}

				if (data.typeMO[key].km) {
					html += `
						<tr>
							<td> ${key} </td>
							<td class="text-right text-monospace"> -- </td>
							<td class="text-right text-monospace"> ${Decimal.setDisplayNumber(data.typeMO[key].km).setSuffixAndHumanizeNumber('km', data.decimalNumber)} </td>
						</tr>
					`;
				}
			}
		}

		html += `
			<tr>
				<td> Total </td>
				<td class="text-right text-monospace"> ${Decimal.setDisplayNumber(data.total.time).setSuffixAndHumanizeNumber('h', data.decimalNumber)} </td>
				<td class="text-right text-monospace"> ${Decimal.setDisplayNumber(data.total.km).setSuffixAndHumanizeNumber('km', data.decimalNumber)} </td>
			</tr>
		`;

		html += '</tbody> </table>';

		return html;
	}

	private displayProduct(data: any) {
		const brandsChunk = _.chunk(Object.keys(data.byBrand).sort(), 10);

		let result = '';

		if (brandsChunk.length) {
			for (const brands of brandsChunk) {
				const html = document.createElement('div');
				html.classList.add('mr-3', 'shadow-sm', 'border', 'mb-2');

				html.style.width = '300px';
				html.style.minWidth = '300px';
				html.style.borderRadius = '10px';

				let i = 0;
				for (const item of brands) {
					const N_item = document.createElement('div');

					N_item.classList.add('d-flex', 'align-items-baseline');
					N_item.style.padding = '0.75rem';

					if (i === 0) {
						N_item.style.borderRadius = '10px 10px 0 0';
					}

					if (i === 9) {
						N_item.style.borderRadius = '0 0 10px 10px';
					} else {
						N_item.classList.add('border-bottom');
					}

					N_item.innerHTML = `
						<div class="text-truncate mr-2"> ${item || 'Aucune marque'} </div>
						<div class="ml-auto text-muted mr-1" style="white-space: nowrap;">Qté : </div>
						<div class="text-right text-monospace"> ${('####' + data.byBrand[item]).slice(-4).replace(/#/gmi, '&nbsp;')} </div>
					`;

					html.appendChild(N_item);

					i++;
				}

				result += html.outerHTML;
			}
		} else {
			const html = `
				<div class="mr-3 shadow-sm border" style="width: 300px; border-radius: 10px;">
					<div class="d-flex border-bottom" style="padding: 0.75rem; border-radius: 10px;">
						Aucune référence
					</div>
				</div>
			`;

			result += html;
		}

		return result;
	}

	private displayMAT(data: any) {
		let html = `
			<table class="table table-bordered table-hover">
				<thead>
					<tr>
						<th style="min-width:150px"> Type </th>
						<th style="min-width:150px"> Coût de revient </th>
						<th style="min-width:150px"> Marge </th>
						<th style="min-width:150px"> Total </th>
					<tr>
				</thead>
				<tbody>
		`;

		for (const key in data.typeMat) {
			if (data.typeMat[key].price || data.typeMat[key].marges || data.typeMat[key].sumMaterials) {
				html += `
					<tr>
						<td> ${key} </td>
						<td class="text-right text-monospace"> ${Decimal.setDisplayNumber(data.typeMat[key].price).setSuffixAndHumanizeNumber('€', data.decimalNumber)} </td>
						<td class="text-right text-monospace"> ${Decimal.setDisplayNumber(data.typeMat[key].marges).setSuffixAndHumanizeNumber('€', data.decimalNumber)} </td>
						<td class="text-right text-monospace"> ${Decimal.setDisplayNumber(data.typeMat[key].sumMaterials).setSuffixAndHumanizeNumber('€', data.decimalNumber)} </td>
					</tr>
				`;
			}
		}

		html += `
			<tr>
				<td> Total </td>
				<td class="text-right text-monospace"> ${Decimal.setDisplayNumber(data.total.price).setSuffixAndHumanizeNumber('€', data.decimalNumber)} </td>
				<td class="text-right text-monospace"> ${Decimal.setDisplayNumber(data.total.marges).setSuffixAndHumanizeNumber('€', data.decimalNumber)} </td>
				<td class="text-right text-monospace"> ${Decimal.setDisplayNumber(data.total.sumMaterials).setSuffixAndHumanizeNumber('€', data.decimalNumber)} </td>
			</tr>
		`;

		html += '</tbody> </table>';

		return html;
	}

	public getGui() {
		return this.eGui!;
	}

	public open() {
		this.eGui?.classList.remove('d-none');
		this.eGui?.classList.add('d-flex');
	}

	public close() {
		this.eGui?.classList.add('d-none');
		this.eGui?.classList.remove('d-flex');
	}
}

class RecapTab extends HTMLElement {
	public static readonly tagName: string = 'ap-recap-quote';

	private tabId: string;
	private selectorTab: string;

	private _data = (): any => { return {}; };
	private _isSave = (): boolean => { return false; };

	private _gridOptions: GridOptions = {};

	private N_div: HTMLElement | null = null;

	public static register() {
		customElements.define(this.tagName, RecapTab);
	}

	constructor() {
		super();
		this.tabId = this.id;
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.tabId}" role="tab">
					<div class="icon-container" tooltip="Récapitulatif">
						<i class="icon icon-solid-clipboard-list"></i>
					</div>
					<span>Récapitulatif</span>
				</a>
			</li>
		`;

		this.removeAttribute('id');
	}

	public async connectedCallback() {
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.tabId}`) {
				this._gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_div = document.createElement('div');

		this.N_div.classList.add('tab-pane', 'h-100', 'fade');
		this.N_div.setAttribute('role', 'tabpanel');

		this.N_div.id = this.tabId;
		this.N_div.innerHTML = `
			<div class="d-flex flex-column h-100">
                <div class="title">
					Récapitulatif
					
					<div class="ml-auto d-flex">
						<button class="btn btn-print-modal mr-2" type="button" id="print-recap">
							<i class="icon icon-printer"></i> 
							Imprimer
						</button>
						<button class="btn btn-action" type="button" id="reload">
							<ap-icon name="refresh/line"></ap-icon>
						</button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
                </div>
                <div class="h-100 scroll-y" id="content"></div>
            </div>
        `;

		N_container.append(this.N_div);

		const N_print = this.N_div.querySelector('#print-recap') as HTMLButtonElement;
		const N_reload = this.N_div.querySelector('#reload') as HTMLButtonElement;

		N_print.addEventListener('click', () => {
			if (this._isSave()) {
				new PrintPreview('quotes', this._data()._id, 'pdfRecap').open();
			} else {
				Alert.warning('Attention', '<div class="pt-modal-body">Le devis doit être sauvegardée avant d\'imprimer le récapitulatif.</div>');
			}
		});

		N_reload.addEventListener('click', async () => {
			const data = await S_Quote.getInstance().getRecap(this._data());
			this.data = data;
		});
	}

	public setIsSave(cb: () => boolean) {
		this._isSave = cb;
	}

	public setGetData(cb: () => any) {
		this._data = cb;
	}

	public set data(data: { [key: string]: any }[]) {
		this.renderData(data);
	}

	private renderData(data: { [key: string]: any }[]) {
		const N_grid = this.N_div!.querySelector('#content') as HTMLDivElement;

		N_grid.innerHTML = '';

		for (const item of data) {
			N_grid.appendChild(this.renderItem(item));
		}
	}

	private renderItem(item: any) {
		let isOpen = false;

		const N_item = document.createElement('div');

		N_item.classList.add('pb-2', 'w-100');

		const N_title = document.createElement('div');
		N_title.classList.add('font-weight-bold', 'p-3', 'border', 'd-flex', 'cursor-pointer', 'align-items-center');
		N_title.innerHTML = `<ap-icon class="text-xl mr-2" style="transition: all .3s ease-in; transform: rotate(-90deg)" name="arrow-down-s/line"></ap-icon>${item.id}`;

		N_title.style.borderTopLeftRadius = '10px';
		N_title.style.borderTopRightRadius = '10px';
		N_title.style.borderBottomLeftRadius = '10px';
		N_title.style.borderBottomRightRadius = '10px';

		if (item.isOption) {
			N_title.style.backgroundColor = 'var(--ap-purple-50)';
			N_title.style.color = 'var(--ap-purple-900)';
		}

		const details = new DetailCellRenderer(item);

		N_title.addEventListener('click', () => {
			const N_Icon = N_title.querySelector('ap-icon') as HTMLElement;

			if (!item.isOption) {
				N_title.classList.toggle('bg-grey-100');
			}

			if (isOpen) {
				details.close();

				N_Icon.style.transform = 'rotate(-90deg)';

				N_title.style.borderBottomLeftRadius = '10px';
				N_title.style.borderBottomRightRadius = '10px';
			} else {
				details.open();

				N_Icon.style.transform = 'rotate(0)';

				N_title.style.borderBottomLeftRadius = '0px';
				N_title.style.borderBottomRightRadius = '0px';
			}

			isOpen = !isOpen;
		});

		N_item.appendChild(N_title);
		N_item.appendChild(details.getGui());

		return N_item;
	}
}
export default RecapTab;
