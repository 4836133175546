import C_database from '@js/controllers/Database';

class BrandsTabs extends C_database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Marque',
			database: 'brands'
		});
	}
}

export default BrandsTabs;
