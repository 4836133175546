import { Controller, Tabs, global } from '@autoprog/core-client';

import C_Tab from '@js/controllers/TabDatabase';

import C_Brands from '../tabs/Products.Brands';
import C_Categories from '../tabs/Products.Categories';
import C_Groups from '../tabs/Products.Groups';
import C_Products from '../tabs/Products.Products';

import T_blockTabs from '@tpl/blocks/tabs.html';

import History from '@libs/History';

class ProductsCtrl extends Controller {
	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		params = params || {};

		const defaultTab = params.tabs ? params.tabs : 'products';

		const navElement = el.querySelector('.tabs-container') as HTMLElement;

		const tabs = new Tabs({
			navElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		tabs.add({
			name: 'Produits',
			id: 'products',
			default: defaultTab === 'products',
			controller: C_Tab,
			data: {
				instance: C_Products
			},
			permission: 'PRODUCTS.SHOW',
			template: T_blockTabs
		});

		if (!global.IS_MOBILE) {
			tabs.add({
				name: 'Groupes',
				id: 'groups',
				default: defaultTab === 'groups',
				controller: C_Groups,
				permission: 'PRODUCTS._GROUPS.SHOW',
				template: T_blockTabs
			});

			tabs.add({
				name: 'Catégories',
				id: 'categories',
				default: defaultTab === 'categories',
				controller: C_Tab,
				data: {
					instance: C_Categories
				},
				permission: 'PRODUCTS._CATEGORIES.SHOW',
				template: T_blockTabs
			});

			tabs.add({
				name: 'Marque',
				id: 'brand',
				default: defaultTab === 'brand',
				controller: C_Tab,
				data: {
					instance: C_Brands
				},
				permission: 'PRODUCTS._BRANDS.SHOW',
				template: T_blockTabs
			});
		}

		let firstLoad = true;
		tabs.on('change', (data: { [key: string]: string }) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				History.getInstance().pushState(`/#module/apps/products/${data.id}`);
			}
		});
	}

	public destructor() {

	}
}

export default ProductsCtrl;
