import { toaster } from '@autoprog/core-client';

import AddEditModal2 from '@libs/AddEdit';
import ConfigManager from '@libs/ConfigManager';
import Decimal from '@libs/utils/Decimal';

// Librairie externe

//Services
import ProviderService from '@services/Provider/ProviderService';

import ServiceManager from '@managers/ServiceManager';

class AddEditProductProvider extends AddEditModal2 {
	private static readonly PRODUCTS_PROVIDER_DB = 'products-provider';

	private static readonly ID_PROVIDER_INPUT = 'id_provider';
	private static readonly PUBLIC_PRICE_INPUT = 'public_price';
	private static readonly DISCOUNT_INPUT = 'discount';
	private static readonly PURCHASE_PRICE_INPUT = 'purchase_price';
	private static readonly SHIP_PRICE_INPUT = 'shipPrice';
	private static readonly COST_PRICE_INPUT = 'costPrice';
	private static readonly MARGIN_RATE_INPUT = 'marginRate';
	private static readonly SELL_PRICE_INPUT = 'providerSellPrice';

	private static readonly INPUT_NAME_LIST = [AddEditProductProvider.PURCHASE_PRICE_INPUT, AddEditProductProvider.PUBLIC_PRICE_INPUT, AddEditProductProvider.DISCOUNT_INPUT, AddEditProductProvider.SHIP_PRICE_INPUT, AddEditProductProvider.MARGIN_RATE_INPUT];

	private productId: string;

	constructor(id?: string, defaultValue: { [key: string]: any } = {}) {
		const config = ConfigManager.getInstance().getConfig(AddEditProductProvider.PRODUCTS_PROVIDER_DB);

		super(config, AddEditProductProvider.PRODUCTS_PROVIDER_DB, id, defaultValue);

		this.productId = defaultValue.id_product;
	}

	/**
	 * Permet d'initialiser la modal
	 */
	protected async init() {
		await super.init();

		// Gestion des événements au changement sur le formulaire
		this.form!.on('*.input', (event: any) => {
			const inputName = event.target.name;

			if (AddEditProductProvider.INPUT_NAME_LIST.includes(inputName) && this.form) {
				this.calcData();
			}
		});

		// A la sélection d'un fournisseur, on préremplit la valeur de la remise
		$(this.form!.getElementByName(AddEditProductProvider.ID_PROVIDER_INPUT)!).on('select2:select', (event) => {
			ProviderService.getInstance().getById(event.params.data.id).then((data) => {
				this.form?.setDataByName(AddEditProductProvider.DISCOUNT_INPUT, data.discount || '0');
				this.form?.setDataByName(AddEditProductProvider.MARGIN_RATE_INPUT, data.marginRate || '0');
				this.calcData();
			});
		});

		this.updateInput();
	}

	private updateInput() {
		const N_PUBLIC_PRICE_INPUT = this.form?.getElementByName(AddEditProductProvider.PUBLIC_PRICE_INPUT) as HTMLInputElement;
		const N_PURCHASE_PRICE_INPUT = this.form?.getElementByName(AddEditProductProvider.PURCHASE_PRICE_INPUT) as HTMLInputElement;
		const N_select_purchase_price = this.form?.getElementByName('select_purchase_price') as HTMLInputElement;

		N_select_purchase_price.addEventListener('change', () => {
			N_PUBLIC_PRICE_INPUT.readOnly = N_select_purchase_price.checked;
			N_PURCHASE_PRICE_INPUT.readOnly = !N_select_purchase_price.checked;
		});

		N_PUBLIC_PRICE_INPUT.readOnly = N_select_purchase_price.checked;
		N_PURCHASE_PRICE_INPUT.readOnly = !N_select_purchase_price.checked;
	}

	private calcData() {
		let data = this.form!.getData();

		if (data.select_purchase_price) {
			if (data.discount !== '' && data.purchase_price !== '') {
				this.calcPublicPrice();
				data = this.form!.getData();
			}
		} else {
			if (data.discount !== '' && data.public_price !== '') {
				this.calcPurchasePrice();
				data = this.form!.getData();
			}
		}

		if (data.purchase_price !== '' && data.shipPrice !== '') {
			this.calcCostPrice();
			data = this.form!.getData();
		}

		if (data.costPrice !== '' && data.marginRate !== '') {
			this.calcSellPrice();
		}
	}

	/**
	 * Calcule le prix d'achat
	 * Prix d'achat = Prix Public x La remise
	 */
	private calcPublicPrice() {
		if (this.form) {
			const discount = this.form.getDataByName(AddEditProductProvider.DISCOUNT_INPUT) as string | number;
			const purchasePriceFormValue = this.form.getDataByName(AddEditProductProvider.PURCHASE_PRICE_INPUT) as string | number;

			// Prix public = prix d'achat / (1 - (Pourc. Remise / 100))
			const percentDiscount = new Decimal(1).minus(Decimal.setDisplayNumber(discount).dividedBy(100));
			const publicPrice = Decimal.setDisplayNumber(purchasePriceFormValue).dividedBy(percentDiscount).toDecimalPlaces(2);
			this.form.setDataByName(AddEditProductProvider.PUBLIC_PRICE_INPUT, publicPrice.humanizeNumber(2));
		}
	}

	/**
	 * Calcule le prix d'achat
	 * Prix d'achat = Prix Public x La remise
	 */
	private calcPurchasePrice() {
		if (this.form) {
			const discount = this.form.getDataByName(AddEditProductProvider.DISCOUNT_INPUT) as string | number;
			const publicPriceFormValue = this.form.getDataByName(AddEditProductProvider.PUBLIC_PRICE_INPUT) as string | number;

			// Prix achat = prix public x  (1 - (Pourc. Remise / 100))
			const percentDiscount = new Decimal(1).minus(Decimal.setDisplayNumber(discount).dividedBy(100));
			const purchasePrice = Decimal.setDisplayNumber(publicPriceFormValue).times(percentDiscount).toDecimalPlaces(4);
			this.form.setDataByName(AddEditProductProvider.PURCHASE_PRICE_INPUT, purchasePrice.humanizeNumber(4));
		}
	}

	/**
	 * Calcule le coût de revient
	 * Coût de revient = Prix d'achat + Le prix de transport
	 */
	private calcCostPrice() {
		if (this.form) {
			const shipPriceFormValue = this.form.getDataByName(AddEditProductProvider.SHIP_PRICE_INPUT) as string | number;
			const shipPrice = Decimal.setDisplayNumber(shipPriceFormValue);
			const purchasePriceFormValue = this.form.getDataByName(AddEditProductProvider.PURCHASE_PRICE_INPUT) as string | number;
			const purchasePrice = Decimal.setDisplayNumber(purchasePriceFormValue);

			// Coût de revient = Prix achat + Prix transport
			const costPrice = purchasePrice.plus(shipPrice).toDecimalPlaces(2);
			this.form.setDataByName(AddEditProductProvider.COST_PRICE_INPUT, costPrice.humanizeNumber());
		}
	}

	/**
	 * Calcul du prix théorique de vente avec la marge commerciale
	 */
	private calcSellPrice() {
		if (this.form) {
			const costPriceFormValue = this.form.getDataByName(AddEditProductProvider.COST_PRICE_INPUT) as string | number;
			const costPrice = Decimal.setDisplayNumber(costPriceFormValue);

			const marginRateFormValue = this.form.getDataByName(AddEditProductProvider.MARGIN_RATE_INPUT) as string | number;
			const marginRate = Decimal.setDisplayNumber(marginRateFormValue);

			// prix de vente = coût de revient / (1 - tx marge/100)
			const recommandedSellPrice = costPrice.dividedBy(new Decimal(1).minus(marginRate.dividedBy(100))).toDecimalPlaces(2);

			this.form.setDataByName(AddEditProductProvider.SELL_PRICE_INPUT, recommandedSellPrice.humanizeNumber());
		}
	}

	/**
	* Sauvegarde le produit dans la base de donnée
	*/
	protected async save() {
		if (this.form && this.form.checkValidity()) {
			let data = this.form.getData() as { [key: string]: any };

			try {
				data = await this.getFormData(data);

				// on ajoute l'id du produit
				data.id_product = this.productId;

				const res = await ServiceManager.get(this.database)?.getInstance().save(data);

				if (res && res.err) {
					throw new Error(res.err);
				} else {
					toaster.success('Sauvegarde réussie');
					return data._id;
				}
			} catch (e: any) {
				console.error(e);
				toaster.error(e.message, 'Erreur lors de la sauvegarde');
				throw new Error(e.message);
			}
		} else {
			toaster.error('Formulaire non-valide.');
			throw Error('Formulaire non-valide');
		}
	}
}

export default AddEditProductProvider;
