// CORE
// NODE_MODULE
import { AllModules, ColDef, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// TEMPLATE
import T_Modal from '../../../tpl/modals/AddQuote/DetailsRecapProducts.html';

// LIBS
import Modal from '@libs/Modal';
import agUtils from '@libs/agGrid/french';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_Products from '@services/Product/ProductService';
import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';

class DetailsRecapProducts extends Modal {
	private gridOptions: GridOptions = {};

	private locationStock: { [kkey: string]: any }[] = [];

	constructor(title: string, data: { [key: string]: any }[]) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_addonTitle = this.element.querySelector('#addon-title') as HTMLElement;
			N_addonTitle.innerHTML = ' - ' + title;

			this.locationStock = await S_Stock.getInstance().getStockToCommandCustomer();

			this.initGrid();

			this.gridOptions.api?.setRowData(data);
		});
	}

	private initGrid() {
		const childrenStock: ColDef[] = [];

		for (const item of this.locationStock) {
			((item: any) => {
				childrenStock.push({
					headerName: item.name,
					width: 100,
					field: 'currentStock.' + item._id + '.quantity',
					cellClass: ['text-right', 'text-monospace'],
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.currentStock = params.data.currentStock || {};
						params.data.currentStock[item._id] = params.data.currentStock[item._id] || {};
						return params.data.currentStock[item._id].quantity;
					},
					cellRenderer: (params) => {
						return _.isUndefined(params.data.currentStock[item._id].quantity) ? '<i class="icon icon-solid-spinner icon-spin"></i>' : params.data.currentStock[item._id].quantity;
					}
				});
			})(item);
		}

		this.gridOptions = agUtils.french<GridOptions>({
			columnDefs: [
				{
					headerName: S_Products.getInstance().columnNameReference,
					field: S_Products.getInstance().referenceKey,
					width: 200,
					suppressSizeToFit: true,
					floatingFilter: true,
					cellRenderer: (params) => {
						return S_Products.getInstance().cellRendererByData(params.data);
					}
				},
				{
					headerName: 'Libellé',
					field: 'name',
					floatingFilter: true
				},
				{
					headerName: 'Marque',
					field: 'brand',
					floatingFilter: true,
					sort: 'asc',
					width: 200,
					suppressSizeToFit: true
				},
				...(childrenStock.length === 1 ? childrenStock : [{ headerName: 'Stock', children: childrenStock }]),
				{
					headerName: 'Quantité',
					field: 'quantity',
					cellClass: ['text-right', 'text-monospace'],
					width: 100,
					suppressSizeToFit: true
				},
				{
					headerName: 'Unité',
					field: 'unit',
					width: 100,
					suppressSizeToFit: true
				}
			],
			suppressDragLeaveHidesColumns: true,
			defaultColDef: {
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true,
				suppressMenu: true,
				resizable: true
			},
			getRowStyle: (params: any) => {
				const stockProduct = params.data.stock || {};

				if (_.isUndefined(stockProduct)) {
					return {
						background: '#fff'
					};
				}

				let totalStock = 0;

				for (const key in stockProduct) {
					totalStock += stockProduct[key] || 0;
				}

				if (totalStock < params.data.quantity) {
					return {
						'background-color': 'var(--ap-orange-50)',
						color: 'var(--ap-orange-900)'
					};
				}

				if (totalStock >= params.data.quantity) {
					return {
						'background-color': 'var(--ap-green-50)',
						color: 'var(--ap-green-900)'
					};
				}
			},
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
				this.initStock();
			}

		});

		const N_grid = this.element.querySelector('#grid-quote');
		new Grid(N_grid as HTMLElement, this.gridOptions, { modules: AllModules });
	}

	private async initStock() {
		const { rowData } = await S_StockEvent.getInstance().getCurrentStock();

		const stock: { [key: string]: any } = {};
		for (const item of rowData) {
			stock[item.product._id] = stock[item.product._id] || {};
			stock[item.product._id][item.stock] = stock[item.product._id][item.stock] || { quantity: 0 };
			stock[item.product._id][item.stock].quantity = item.quantity.formattedValue;
		}

		this.gridOptions.api?.forEachNode((node) => {
			for (const item of this.locationStock) {
				stock[node.data._id] = stock[node.data._id] || {};
				node.data.currentStock = node.data.currentStock || {};
				node.data.currentStock[item._id] = stock[node.data._id][item._id] || { quantity: 0 };
			}

			node.setData(node.data);
		});
	}
}

export default DetailsRecapProducts;
