import _ from 'lodash';

// MANAGER
import ConfigManager from '@js/libs/ConfigManager';
import ServiceManager from '@js/managers/ServiceManager';

// SERVICE
import ProductProviderService from '@js/services/Product/ProductProviderService';
import ProductService from '@js/services/Product/ProductService';

// TYPE
import Product from '@js/types/product/product';

import Decimal from '@libs/utils/Decimal';

class ImportProduct {
	public static readonly PRODUCTS_PROVIDER_TABLE = 'products-provider';
	public static readonly PRODUCTS_TABLE = 'products';

	// Convertit les données pour l'import des produits fournisseurs
	public static async importProductProvider(data: { [key: string]: any }[], attributeName: { [key: string]: string }, refName: { [key: string]: string }) {
		const config = ConfigManager.getInstance().getConfig(ImportProduct.PRODUCTS_PROVIDER_TABLE);

		// On déclare le tableau de données à importer
		const dataToImport: any[] = [];

		for (const item of data) {
			const obj: { [key: string]: any } = {};

			for (const key in item) {
				if (key) {
					const col = _.find<any>(config.columns, { key: attributeName[key] });

					if (!col) {
						throw Error('Clé introuvable dans le fichier de configuration !');
					}

					let value = item[key];

					if (col.type === 'table') {
						const selector = {
							[refName[key]]: { $eq: item[key] },
							$or: [{
								deleted: {
									$eq: false
								}
							}, {
								deleted: {
									$exists: false
								}
							}]
						};

						const externalData = await ServiceManager.get(col.table)?.getInstance().find(selector, 1);

						value = (externalData.docs[0] || {})._id || '';
					}

					if (col.type === 'string' || col.type === 'primaryKey' || col.type === 'number') {
						value = item[key];
					}

					_.set(obj, attributeName[key], value);
				}
			}

			let calcObj = {};

			if (obj.public_price) {
				calcObj = await ImportProduct.getCalculateProductProvider(obj);
			} else {
				throw Error('Prix public non renseigné !');
			}

			// On récupère l'ancienne donnée si elle existe
			try {
				const colRef = config.import.colRef;

				const selector = {
					$or: [{
						deleted: {
							$eq: false
						}
					}, {
						deleted: {
							$exists: false
						}
					}]
				} as { [key: string]: any };

				for (const item of colRef) {
					selector[item] = { $eq: obj[item] };
				}

				const oldData = await ServiceManager.get(ImportProduct.PRODUCTS_PROVIDER_TABLE)?.getInstance().find(selector, 1);

				const findDocs = oldData.docs[0] || {};

				const _id = findDocs._id || _.uniqueId(Date.now().toString(36) + '_');

				calcObj = {
					...findDocs,
					...calcObj,
					_id
				};
			} catch (e: any) {
				if (e.response.status !== 404) {
					throw e;
				}
			}
			dataToImport.push(calcObj);
		}

		// On récupère les produits à mettre à jour
		const productToUpdate = await ImportProduct.getUpdateProduct(dataToImport);

		const importProductProvider = ProductProviderService.getInstance().updateMultiple(dataToImport);
		const updateProduct = ProductService.getInstance().updateMultiple(productToUpdate);

		const result = await Promise.all([importProductProvider, updateProduct]);

		return result;
	}

	private static async getUpdateProduct(productProvider: { [key: string]: any }[]) {
		const productMap = await ProductService.getInstance().getProductMap();

		const productToUpdate: Product[] = [];

		for (const item of productProvider) {
			const product = productMap.get(item.id_product);

			if (product && (product.defaultProvider.idProductProvider === item._id || !product.defaultProvider.idProductProvider)) {
				product.defaultProvider.id = item.id_provider;
				product.defaultProvider.purchasePrice = item.purchase_price;
				product.defaultProvider.costPrice = item.costPrice;
				product.defaultProvider.idProductProvider = item._id;
				product.defaultProvider.recommendedSellPrice = item.providerSellPrice;
				productToUpdate.push(product);
			}
		}

		return productToUpdate;
	}

	/**
	 * Convertit le produit fournisseur du fichier d'import avec les valeurs calculées
	 * @param obj le produit fournisseur provenant du fichier d'import
	 * @returns le produit fournisseur avec les valeurs calculées
	 */
	private static async getCalculateProductProvider(obj: { [key: string]: any }) {
		let discountValue: number;
		let marginRateValue: number;

		// Si les remise et le taux de marge n'est pas renseignée, on prend ceux par défaut dans le fournisseur
		if ((!obj.discount && obj.discount !== 0) || (!obj.marginRate && obj.marginRate !== 0)) {
			const provider = await ServiceManager.get('providers')?.getInstance().getById(obj.id_provider);
			discountValue = Number(obj.discount.replace(',', '.')) || Number(provider.discount);
			marginRateValue = Number(obj.marginRate.replace(',', '.')) || Number(provider.marginRate);
		} else {
			discountValue = Number(obj.discount.replace(',', '.'));
			marginRateValue = Number(obj.marginRate.replace(',', '.'));
		}

		if (!discountValue && discountValue !== 0) {
			throw Error('Une remise est non définie !');
		}

		if (!marginRateValue && marginRateValue !== 0) {
			throw Error('Un taux marge est non défini !');
		}

		const shipPrice = Decimal.setDisplayNumber(obj.shipPrice);
		const publicPrice = Decimal.setDisplayNumber(obj.public_price);
		const discount = Decimal.setDisplayNumber(discountValue);
		const marginRate = Decimal.setDisplayNumber(marginRateValue);

		// Prix achat = prix public x  (1 - (Pourc. Remise / 100))
		const percentDiscount = new Decimal(1).minus(discount.dividedBy(100));
		const purchasePrice = publicPrice.times(percentDiscount).toDecimalPlaces(4);
		const costPrice = purchasePrice.plus(shipPrice).toDecimalPlaces(2);

		// prix de vente = coût de revient / (1 - tx marge/100)
		const recommandedSellPrice = costPrice.dividedBy(new Decimal(1).minus(marginRate.dividedBy(100))).toDecimalPlaces(2);

		const calcProductProvider = {
			...obj,
			discount: discount.humanizeNumber(),
			marginRate: marginRate.humanizeNumber(),
			shipPrice: shipPrice.humanizeNumber(),
			public_price: publicPrice.humanizeNumber(2),
			purchase_price: purchasePrice.humanizeNumber(4),
			costPrice: costPrice.humanizeNumber(),
			providerSellPrice: recommandedSellPrice.humanizeNumber()
		};

		return calcProductProvider;
	}
}
export default ImportProduct;
