import C_Database from '@js/controllers/Database';

class CategoriesTab extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Catégorie',
			database: 'categories'
		});
	}
}

export default CategoriesTab;
