// CORE
import { Alert, LoggedUser, toaster } from '@autoprog/core-client';
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, ColDef, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import moment from 'moment';

// TEMPLATE
// LIBS
import C_BillsCustomer from '@modules/Bills/js/controllers/Bills.Customer';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
import M_BillingRequest from '../../../modals/customers/BillingRequest';

// CUSTOM_ELEMENT
// SERVICE
import S_Users from '@services/User/UserService';

class BillingRequestTab extends HTMLElement {
	public static readonly tagName: string = 'ap-billing-request-tab';

	private N_tab: HTMLElement = document.createElement('div');

	private selectorTab: string = '';

	private _disabled = { advance: false, normal: false, last: false, createBill: false };

	private idTab: string = '';

	private gridOptions: GridOptions = {};

	private _mode: string = 'full';

	private deletedData: any[] = [];

	private _update = (): void => { };
	private _isSave = (): boolean => { return false; };
	private _goToBill = (): void => { };
	private _order: () => { [key: string]: any } = () => { return {}; };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Demandes de facturation">
						<i class="icon icon-ri-file-bill-dollar-fill"></i>
						<span class="nav-icon-badge d-none" id="number">0</span>
					</div>
					<span>Demandes de facturation</span>
				</a>
			</li>
		`;

		this.id = '';
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setMode(value: string) {
		this._mode = value;
	}

	public hiddenTabLink() {
		this.classList.add('d-none');
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_tab.classList.add('tab-pane', 'h-100', 'fade');
		this.N_tab.setAttribute('role', 'tabpanel');
		this.N_tab.id = this.idTab;
		this.N_tab.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="title">
					Demandes de facturation
					<div class="ml-auto d-flex">
						<div id="br-top-btn-container">
							<button type="button" class="btn btn-blue-600" id="create-bill-btn" permission="BILLS._CUSTOMERS.ADD">Créer Facture</button>
							<div class="btn-group" role="group" permission="ORDERS._CUSTOMERS._BILLING_REQUESTS.ADD">
								<button id="btnGroupDrop1" type="button" class="btn btn-blue-600 dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
									Demande de facturation
								</button>
								<div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
									<a class="dropdown-item cursor-pointer" id="new-advance-btn">Acompte</a>
									<a class="dropdown-item cursor-pointer" id="new-normal-btn">Avancement</a>
									<a class="dropdown-item cursor-pointer" id="new-last-btn">Définitive</a>
								</div>
							</div>
						</div>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
				</div>
                <div class="h-100 ag-theme-alpine grid"></div>
            </div>
		`;

		this.initTopButtons();

		N_container.append(this.N_tab);

		this.initGrid();
	}

	public setDataOrder(cb: () => { [key: string]: any }) {
		this._order = cb;
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public setIsSave(cb: () => boolean) {
		this._isSave = cb;
	}

	public setOnGoToBill(cb: () => void) {
		this._goToBill = cb;
	}

	public active() {
		const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;
		N_li.classList.add('active');
		const N_grid = document.querySelector(`#${this.idTab}`) as HTMLElement;
		N_grid.classList.add('active', 'show');
	}

	private disabledButton(N_btn: HTMLElement, value: boolean) {
		if (value) {
			N_btn.classList.add('disabled');
			N_btn.classList.remove('cursor-pointer');
		} else {
			N_btn.classList.remove('disabled');
			N_btn.classList.add('cursor-pointer');
		}
	}

	private async initTopButtons() {
		const N_topButtonsContainer = this.N_tab.querySelector('#br-top-btn-container') as HTMLButtonElement;

		$(N_topButtonsContainer).on('show.bs.dropdown', () => {
			const rowData: { [key: string]: any }[] = [];
			this.gridOptions.api?.forEachNode((node) => {
				rowData.push(node.data);
			});

			const findAdvance = !!_.find(rowData, { type: 'advance' }) || this._disabled.advance;
			const findNormal = !!_.find(rowData, { type: 'normal' }) || this._disabled.normal;
			const findLast = !!_.find(rowData, { type: 'last' }) || this._disabled.last;

			const N_newAdvanceButton = this.N_tab.querySelector('#new-advance-btn') as HTMLElement;
			const N_newNormalButton = this.N_tab.querySelector('#new-normal-btn') as HTMLElement;
			const N_newLastButton = this.N_tab.querySelector('#new-last-btn') as HTMLElement;

			this.disabledButton(N_newAdvanceButton, findAdvance || findLast || findNormal);
			this.disabledButton(N_newNormalButton, findLast);
			this.disabledButton(N_newLastButton, findLast);
		});

		const N_createBillButton = this.N_tab.querySelector('#create-bill-btn') as HTMLButtonElement;
		N_createBillButton.disabled = true;

		N_createBillButton.addEventListener('click', async () => {
			this.checkBeforeCreation();
		});

		const N_newAdvanceButton = this.N_tab.querySelector('#new-advance-btn') as HTMLButtonElement;
		N_newAdvanceButton.addEventListener('click', async () => {
			this.createAdvance();
		});

		const N_newNormalButton = this.N_tab.querySelector('#new-normal-btn') as HTMLButtonElement;
		N_newNormalButton.addEventListener('click', async () => {
			this.createNormal();
		});

		const N_newLastButton = this.N_tab.querySelector('#new-last-btn') as HTMLButtonElement;
		N_newLastButton.addEventListener('click', async () => {
			this.createLast();
		});

		if (this._mode === 'bill') {
			N_topButtonsContainer.classList.add('d-none');
			N_createBillButton.classList.add('d-none');
		}
	}

	private async initGrid() {
		const users = await S_Users.getInstance().getAll();

		this.gridOptions = agUtils.french<GridOptions>({
			localeText: { noRowsToShow: 'Aucune demande' },
			rowData: this.gridOptions.rowData || [],
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: false,
			rowDragManaged: true,
			rowSelection: 'multiple',
			rowMultiSelectWithClick: true,
			isRowSelectable: (rowNode) => {
				if (rowNode.data.state === 'bill') {
					return false;
				}

				if (!rowNode.data._id) {
					return false;
				}

				if (rowNode.data.billingStartDate) {
					return moment(rowNode.data.billingStartDate, 'x').isSameOrBefore(moment(), 'day');
				} else {
					return true;
				}
			},
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			sideBar: {
				toolPanels: [{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}]
			},
			columnDefs: [
				{
					headerName: 'ID',
					field: '_id',
					hide: true,
					width: 110,
					suppressSizeToFit: true
				}, {
					headerName: '',
					pinned: 'left',
					field: '_checkbox_',
					hide: this._mode === 'bill',
					cellClass: 'text-center text-lg',
					width: 40,
					suppressSizeToFit: true,
					resizable: false,
					suppressMovable: true,
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						if (params.data.billingStartDate && moment(params.data.billingStartDate, 'x').isAfter(moment(), 'day')) {
							N_div.innerHTML = '<ap-icon class="text-red" name="forbid-2/line"></ap-icon>';

							return N_div;
						}

						const updateSelected = () => {
							if (params.node.isSelected()) {
								N_div.innerHTML = '<ap-icon name="checkbox/line"></ap-icon>';
							} else {
								N_div.innerHTML = '<ap-icon name="checkbox-blank/line"></ap-icon>';
							}
						};

						N_div.addEventListener('click', () => {
							params.node.setSelected(!params.node.isSelected());
							updateSelected();
						});

						updateSelected();

						return N_div;
					}
				}, {
					headerName: 'Date',
					field: 'date',
					sort: 'asc',
					width: 110,
					maxWidth: 110,
					minWidth: 110,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						return moment(params.value, 'x').format('DD/MM/YYYY');
					}
				}, {
					headerName: 'Personne',
					field: 'user',
					width: 180,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const user = _.find(users, { _id: params.value }) as any;
						return `${user.lastname} ${user.firstname}`;
					}
				}, {
					headerName: 'Prix',
					initialWidth: 100,
					suppressSizeToFit: true,
					maxWidth: 200,
					minWidth: 100,
					field: 'price',
					cellClass: 'text-right text-monospace',
					cellRenderer: (params) => {
						if (params.data.type !== 'last') {
							return Decimal.setDisplayNumber(params.value).setSuffixAndHumanizeNumber('€');
						} else {
							return 'Restant';
						}
					}
				},
				{
					headerName: '%',
					width: 100,
					minWidth: 100,
					maxWidth: 100,
					hide: this._mode === 'bill',
					field: 'percents',
					cellClass: 'text-right text-monospace',
					cellRenderer: (params) => {
						if (params.data.type !== 'last') {
							const total = Decimal.setDisplayNumber(this._order().infos.price);
							const price = Decimal.setDisplayNumber(params.data.price);
							const percent = new Decimal(100).times(price).dividedBy(total).toDecimalPlaces(2);
							return percent.humanizePercent() + ' %';
						} else {
							return '-';
						}
					}
				},
				{
					headerName: 'Type',
					width: 120,
					maxWidth: 150,
					suppressSizeToFit: true,
					field: 'type',
					cellRenderer: (params) => {
						const types: { [key: string]: string } = {
							normal: 'Avancement',
							credit: 'Avoir',
							advance: 'Acompte',
							last: 'Définitive'
						};

						return types[params.value];
					}
				}, {
					headerName: 'Type Demande',
					field: 'typePrice',
					width: 130,
					maxWidth: 170,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const types: { [key: string]: string } = {
							percent: 'Pourcentage',
							price: 'Prix fixe'
						};

						return types[params.value] || 'Prix fixe';
					}
				}, {
					headerName: 'Intitulé',
					field: 'designation',
					tooltipField: 'designation',
					hide: true,
					minWidth: 300
				}, {
					headerName: 'À traiter à partir du',
					field: 'billingStartDate',
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.value) {
							return moment(params.value, 'x').format('DD/MM/YYYY');
						} else {
							return 'Aucune date';
						}
					}
				}, {
					headerName: 'Commentaire',
					field: 'comments',
					tooltipField: 'comments',
					minWidth: 300
				}, {
					headerName: 'État',
					field: 'state',
					hide: this._mode === 'bill',
					width: 100,
					maxWidth: 100,
					minWidth: 100,
					cellRenderer: (params) => {
						const state: { [key: string]: string } = {
							waiting: 'En attente',
							bill: 'Terminée'
						};
						return state[params.value];
					}
				}, {
					headerName: 'Action',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					suppressSizeToFit: true,
					hide: this._mode === 'onlyBill' || this._mode === 'bill',
					width: 100,
					cellRenderer: (params) => {
						const N_div: HTMLDivElement = document.createElement('div');

						const N_edit: HTMLButtonElement = document.createElement('button');

						N_edit.disabled = params.data.state === 'bill';

						N_edit.setAttribute('permission', 'ORDERS._CUSTOMERS._BILLING_REQUESTS.EDIT');

						N_edit.classList.add('h-100', 'py-0', 'btn-transparent');
						N_edit.setAttribute('tooltip', 'Éditer');
						N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';
						N_edit.type = 'button';

						N_edit.addEventListener('click', () => {
							let price = new Decimal(0);

							params.api?.forEachNode((node: any) => {
								if (node.data.state === 'waiting') {
									price = price.plus(Decimal.setDisplayNumber(node.data.price));
								}
							});

							price = price.minus(Decimal.setDisplayNumber(params.data.price));

							new M_BillingRequest(this._order(), price, params.data).open().then((data) => {
								data.state = 'waiting';
								params.data = {
									_id: params.data._id,
									...data
								};
								params.node.setData(params.data);

								this._update();
							});
						});

						const N_delete: HTMLButtonElement = document.createElement('button');

						N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

						N_delete.disabled = params.data.state === 'bill';

						N_delete.setAttribute('permission', 'ORDERS._CUSTOMERS._BILLING_REQUESTS.DELETE');

						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
						N_delete.setAttribute('confirmation', '');
						N_delete.setAttribute('tooltip', 'Supprimer');
						N_delete.type = 'button';

						N_delete.addEventListener('click', () => {
							if (params.data._id) {
								params.data.deleted = true;
								this.deletedData.push(params.data);
							}

							params.api?.updateRowData({
								remove: [params.data]
							});
						});

						N_div.appendChild(N_edit);
						N_div.appendChild(N_delete);

						return N_div;
					}
				}
			],
			suppressContextMenu: this._mode === 'onlyBill' || this._mode === 'bill',
			getContextMenuItems: (params) => {
				const rowSelected = params.api?.getSelectedNodes() || [];

				const rowData: { [key: string]: any }[] = [];
				params.api?.forEachNode((node) => {
					rowData.push(node.data);
				});

				const findAdvance = !!_.find(rowData, { type: 'advance' }) || this._disabled.advance;
				const findNormal = !!_.find(rowData, { type: 'normal' }) || this._disabled.normal;
				const findLast = !!_.find(rowData, { type: 'last' }) || this._disabled.last;

				return [
					{
						name: 'Créer facture',
						disabled: rowSelected.length === 0 || this._disabled.createBill || !LoggedUser.getInstance().hasPermission('BILLS._CUSTOMERS.ADD'),
						action: async () => {
							this.checkBeforeCreation();
						}
					},
					{
						name: 'Demande de facturation',
						disabled: !LoggedUser.getInstance().hasPermission('ORDERS._CUSTOMERS._BILLING_REQUESTS.ADD'),
						subMenu: [
							{
								name: 'Acompte',
								disabled: findLast || findAdvance || findNormal,
								action: async () => {
									this.createAdvance();
								}
							}, {
								name: 'Avancement',
								disabled: findLast,
								action: async () => {
									this.createNormal();
								}
							}, {
								name: 'Définitive',
								disabled: findLast,
								action: () => {
									this.createLast();
								}
							}
						]
					}
				];
			},
			onRowDataChanged: (params) => {
				let number = 0;

				params.api?.forEachNode((node) => {
					if (node.data.state === 'waiting') {
						number++;
					}
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params) => {
				let number = 0;

				params.api?.forEachNode((node) => {
					if (node.data.state === 'waiting') {
						number++;
					}
				});

				this._update();

				this.updateNumber(number);
			},
			onRowSelected: (params) => {
				const N_createBillButton = document.querySelector('#create-bill-btn')! as HTMLButtonElement;
				const rowSelected = params.api.getSelectedNodes() || [];
				if (rowSelected.length && !this._disabled.createBill) {
					N_createBillButton.disabled = false;
				} else {
					N_createBillButton.disabled = true;
				}
				params.api.refreshCells({ columns: ['_checkbox_'], force: true });
			},
			onGridReady: () => {
				this.gridOptions.api?.sizeColumnsToFit();
			}
		});

		const columnDefs: any[] = [];

		for (const col of (this.gridOptions.columnDefs || [])) {
			const item = col as ColDef;

			if (item.hide && ['_id', 'designation'].includes(item.field || '')) {
				columnDefs.push(item);
			}

			if (!item.hide) {
				columnDefs.push(item);
			}
		}

		this.gridOptions.columnDefs = columnDefs;

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this.gridOptions, { modules: AllModules });
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector('#number') as HTMLElement;

		if (number) {
			N_number.innerHTML = number.toString();
			N_number.classList.remove('d-none');
		} else {
			N_number.classList.add('d-none');
		}
	}

	public set data(data: any[]) {
		this.gridOptions.rowData = data;
		this.gridOptions.api?.setRowData(data);
		this.gridOptions.api?.sizeColumnsToFit();
	}

	public get data(): any[] {
		const results: any[] = [];

		this.gridOptions.api?.forEachNode((node) => {
			results.push(node.data);
		});

		results.push(...this.deletedData);

		return results;
	}

	public set disabled(value: { normal: boolean, advance: boolean, last: boolean, createBill: boolean }) {
		this._disabled = value;
	}

	public openTab() {
		$(`[role="tablist"] a[href="#${this.idTab}"]`).tab('show');
		this.gridOptions.api?.sizeColumnsToFit();
	}

	private async checkBeforeCreation() {
		const isSave = this._isSave();

		if (isSave) {
			this.createBill();
		} else {
			Alert.warning('Attention', '<div class="pt-modal-body">La commande doit être sauvegardée après ajouts ou modifications avant de pouvoir créer la facture.</div>');
		}
	}

	public async createBill() {
		const rowSelected = this.gridOptions.api?.getSelectedNodes() || [];

		const order = this._order();

		if (rowSelected.length) {
			let type = '';

			for (const item of rowSelected) {
				if (item.data.type === 'last') {
					type = 'last';
				}

				if (type !== 'last') {
					type = item.data.type;
				}
			}

			if (type === 'last') {
				this._goToBill();
				C_BillsCustomer.open(null, {
					idCommand: order._id,
					type: 'last'
				});
			} else {
				this._goToBill();
				const rowInfos: string[] = rowSelected.map(node => node.data._id);

				C_BillsCustomer.open(null, {
					idCommand: order._id,
					ignoreModalAdvance: true,
					billingRequests: rowInfos,
					type: 'advance',
					advance: {
						type
					}
				});
			}
		} else {
			toaster.error('Veuillez sélectionner au moins une demande de facturation');
		}
	}

	private async createAdvance() {
		const N_newAdvanceButton = this.N_tab.querySelector('#new-advance-btn') as HTMLButtonElement;
		let price = new Decimal(0);

		this.gridOptions.api?.forEachNode((node) => {
			if (node.data.state === 'waiting') {
				price = price.plus(Decimal.setDisplayNumber(node.data.price));
			}
		});

		await new M_BillingRequest(this._order(), price, { type: 'advance' }).open().then((data) => {
			N_newAdvanceButton.classList.add('disabled');

			data.type = 'advance';
			data.state = 'waiting';

			this.gridOptions.api?.applyTransaction({
				add: [data]
			});

			this._update();
		});
	}

	private async createNormal() {
		const N_newAdvanceButton = this.N_tab.querySelector('#new-advance-btn') as HTMLButtonElement;
		let price = new Decimal(0);

		this.gridOptions.api?.forEachNode((node) => {
			if (node.data.state === 'waiting') {
				price = price.plus(Decimal.setDisplayNumber(node.data.price));
			}
		});

		const order = this._order();
		order.infos.advance = '0';

		await new M_BillingRequest(order, price, { type: 'normal' }).open().then((data) => {
			N_newAdvanceButton.classList.add('disabled');

			data.type = 'normal';
			data.state = 'waiting';

			this.gridOptions.api?.applyTransaction({
				add: [data]
			});

			this._update();
		});
	}

	private async createLast() {
		const N_newAdvanceButton = this.N_tab.querySelector('#new-advance-btn')!;
		const N_newLastButton = this.N_tab.querySelector('#new-last-btn')!;
		const N_newNormalButton = this.N_tab.querySelector('#new-normal-btn')!;

		let price = new Decimal(0);

		this.gridOptions.api?.forEachNode((node) => {
			if (node.data.state === 'waiting') {
				price = price.plus(Decimal.setDisplayNumber(node.data.price));
			}
		});

		new M_BillingRequest(this._order(), price, { type: 'last' }).open().then((data) => {
			N_newAdvanceButton.classList.add('disabled');
			N_newLastButton.classList.add('disabled');
			N_newNormalButton.classList.add('disabled');

			data.type = 'last';
			data.state = 'waiting';

			this.gridOptions.api?.applyTransaction({
				add: [data]
			});

			this._update();
		});
	}

	public static register() {
		customElements.define(BillingRequestTab.tagName, BillingRequestTab);
	}
}

export default BillingRequestTab;
