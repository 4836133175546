// CORE
import { Router } from '@autoprog/core-client';

// NODE_MODULE
import { GetContextMenuItemsParams } from '@ag-grid-enterprise/all-modules';

// TEMPLATE
// LIBS
import BillingRequestContextMenu from '../../libs/BillingRequestContextMenu';

import C_BillsCustomer from '@modules/Bills/js/controllers/Bills.Customer';
import C_Database from '@js/controllers/Database';
import C_OrdersCustomer from '../../controllers/Command.Customer';
import SettingsApps from '@libs/Settings';
import Utils from '@libs/utils/Utils';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
import M_ListBillingRequest from '../../modals/customers/ListBillingRequest';

// CUSTOM_ELEMENT
import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

// SERVICE
import S_Order from '@services/Customer/CustomerOrderService';

class ListCommand extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Commandes - Clients',
			type: 'database',
			database: 'commands-customer'
		});
	}

	protected header() {
		const router = Router.getInstance();

		const N_div = document.createElement('div');

		N_div.classList.add('d-flex', 'flex-grow-1');

		N_div.innerHTML = `
			<div class="ml-auto d-flex" id="container_button_header">
				<div permission="ORDERS._CUSTOMERS.RECAP_BILLS || ORDERS._CUSTOMERS.RECAP_PRODUCTS">
					<div class="dropdown d-inline-flex h-100">
						<button class="btn btn-action" type="button" data-toggle="dropdown">
							<i class="icon icon-ri-line-chart-fill"></i>
							Récapitulatif
                            <i class="icon icon-ri-arrow-drop-down-line"></i>
						</button>
						<div class="dropdown-menu mt-2" style="border-radius:5px">
							<div class="dropdown-item cursor-pointer" permission="ORDERS._CUSTOMERS.RECAP_PRODUCTS" id="material">Produits</div>
							<div class="dropdown-item cursor-pointer" permission="ORDERS._CUSTOMERS.RECAP_BILLS" id="recap">Factures</div>
						</div>
					</div>
				</div>
			</div>
        `;

		const N_recap = N_div.querySelector('#recap') as HTMLButtonElement;
		N_recap.addEventListener('click', () => {
			router.navigate('module/commands_export/customer');
		});

		const N_materials = N_div.querySelector('#material') as HTMLButtonElement;
		N_materials.addEventListener('click', () => {
			router.navigate('module/commands_export/material/customer');
		});

		return N_div;
	}

	protected buttonEdit(params: any) {
		C_OrdersCustomer.open(params.data._id);
	}

	protected iconsColumn(params: any) {
		const result: HTMLElement[] = [];

		const N_billstate = document.createElement('i');
		N_billstate.classList.add('icon', 'text-lg', 'icon-outline-file-invoice-dollar', 'mr-2');

		//facturer a 100%
		if (params.data.bill.state === '2') {
			N_billstate.setAttribute('tooltip', 'Facturée à 100%');
			N_billstate.classList.add('text-green-400');
		}

		//demande de facturation
		if (params.data.bill.state === '3') {
			N_billstate.setAttribute('tooltip', 'Demande de facturation en attente');
			N_billstate.classList.add('text-teal-400');
		}

		//pas facturer
		if (params.data.bill.state === '0') {
			N_billstate.setAttribute('tooltip', 'Facturation pas commencée');
			N_billstate.classList.add('text-red-400');
		}

		//en cours de facturation
		if (params.data.bill.state === '1') {
			N_billstate.setAttribute('tooltip', 'Facturation en cours');
			N_billstate.classList.add('text-orange-400');
		}

		const N_state = document.createElement('i');
		N_state.classList.add('icon', 'text-lg', 'mr-2');

		if (params.data.state === '0') {
			N_state.setAttribute('tooltip', 'À préparer');
			N_state.classList.add('icon-ri-file-edit-line', 'text-dark');
		}

		if (params.data.state === '1') {
			N_state.setAttribute('tooltip', 'En cours de préparation');
			N_state.classList.add('icon-solid-box-open', 'text-blue-600');
		}

		if (params.data.state === '2') {
			N_state.setAttribute('tooltip', 'Prête partiellement');
			N_state.classList.add('icon-solid-box', 'text-orange-400');
		}

		if (params.data.state === '3') {
			N_state.setAttribute('tooltip', 'Prête en totalité');
			N_state.classList.add('icon-solid-box', 'text-green-400');
		}

		if (params.data.state === '4') {
			N_state.setAttribute('tooltip', 'Expédiée partiellement');
			N_state.classList.add('icon-ri-truck-line', 'text-orange-400');
		}

		if (params.data.state === '5') {
			N_state.setAttribute('tooltip', 'Expédiée en totalité');
			N_state.classList.add('icon-ri-truck-line', 'text-green-400');
		}

		const N_finish = document.createElement('i');
		N_finish.classList.add('icon', 'text-lg', 'mr-2', 'icon-solid-check');

		const N_open = document.createElement('ap-open-document') as HTMLElement;
		N_open.dataset.id = params.data._id;
		N_open.dataset.table = 'commands-customer';

		if (params.data.finish) {
			result.push(N_finish);
		} else {
			result.push(N_billstate);
			result.push(N_state);
		}

		result.push(N_open);

		return result;
	}

	protected getContextMenu(params: GetContextMenuItemsParams) {
		if (params.node) {
			const order = params.node.data;

			const numberBillingRequest = {
				advance: 0,
				last: 0
			};

			for (const item of order.billingRequest) {
				if (item.state === 'waiting') {
					if (item.type === 'advance' || item.type === 'normal') {
						numberBillingRequest.advance++;
					}
					if (item.type === 'last') {
						numberBillingRequest.last++;
					}
				}
			}

			const objBill = {
				name: 'Créer une facture',
				icon: '<i class="icon icon-outline-file-invoice-dollar"></i>',
				disabled: params.node.data.bill.state === '2' || params.node.data.compta.error || params.node.data.infos.nonValorisee,
				subMenu: [
					{
						name: `Acompte/Avancement${numberBillingRequest.advance ? ' - ' + numberBillingRequest.advance + ' demande(s)' : ''}`,
						action: () => {
							this.createBill(order, 'advance');
						}
					},
					{
						name: `Définitive${numberBillingRequest.last ? ' - ' + numberBillingRequest.last + ' demande' : ''}`,
						action: () => {
							this.createBill(order, 'last');
						}
					}
				]
			};

			const disabled = {
				advance: (order.bill.price.value + order.credit.price.value) !== 0,
				normal: (order.bill.price.value + order.credit.price.value) === order.infos.price.value,
				last: (order.bill.price.value + order.credit.price.value) === order.infos.price.value
			};

			const billingRequest = BillingRequestContextMenu.addBillingRequest(disabled, order.rowData, order.billingRequest, async (data) => {
				order.billingRequest.push(data);

				await S_Order.getInstance().save({
					_id: order._id,
					billingRequest: order.billingRequest
				});

				this.refreshData();
			});

			return [
				...super.getContextMenu(params),
				'separator',
				objBill,
				'separator',
				billingRequest
			];
		} else {
			return [];
		}
	}

	private createBill(order: { [key: string]: any }, type: 'last' | 'advance') {
		if (type === 'advance') {
			BillingRequestContextMenu.checkBillRequest(order.billingRequest)
				.then((value) => {
					if (value === 'open-modal') {
						new M_ListBillingRequest(order).open().then(() => {
							this.refreshData();
						});
					}

					if (value === 'open-bill') {
						C_BillsCustomer.open(null, {
							type,
							idCommand: order._id
						});
					}
				}).catch(() => { });
		} else {
			C_BillsCustomer.open(null, {
				type,
				idCommand: order._id
			});
		}
	}

	protected async onDataUpdate(params: any) {
		let priceTotal = new Decimal(0);
		let priceBill = new Decimal(0);
		let priceCredit = new Decimal(0);
		let priceRequest = new Decimal(0);

		params.api.forEachNodeAfterFilter(async (node: any) => {
			priceTotal = priceTotal.plus(node.data.infos.price.value);
			priceBill = priceBill.plus(node.data.bill.price.value);
			priceCredit = priceCredit.plus(node.data.credit.price.value);
			priceRequest = priceRequest.plus(node.data.request.price.value);
		});

		const notBill = priceTotal.minus(priceBill).toDecimalPlaces(2);

		priceTotal = priceTotal.toDecimalPlaces(2);
		priceBill = priceBill.toDecimalPlaces(2);
		priceCredit = priceCredit.toDecimalPlaces(2);
		priceRequest = priceRequest.toDecimalPlaces(2);

		this.setDataTitle([{
			text: 'Global HT',
			price: priceTotal.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Facturé HT',
			price: priceBill.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Restant à facturer HT',
			price: notBill.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'À facturer HT',
			price: priceRequest.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Avoir HT',
			price: priceCredit.setSuffixAndHumanizeNumber('€')
		}]);
	}

	protected getRowStyle(params: any): { [key: string]: string } {
		//teminée
		if (params.data.finish) {
			return {
				'background-color': 'var(--ap-green-50)',
				color: 'var(--ap-green-900)'
			};
		}

		//demande de facturation
		if (params.data.request.price.value !== 0) {
			return {
				'background-color': 'var(--ap-blue-50)',
				color: 'var(--ap-blue-900)'
			};
		}

		return {
			'background-color': 'white'
		};
	}

	protected get configFilter(): ConfigFilter {
		const comptaSettings = (SettingsApps.getInstance().get('COMPTA') as { [key: string]: any }) || { disable: false };

		return [
			[
				{
					column: '_createBy_',
					type: 'switch',
					filters: [
						{
							value: Utils.userID,
							text: 'Commande personnelle'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'affected',
					type: 'switch',
					filters: [{
						value: 'true',
						text: 'Commande affectée'
					}]
				}, {
					type: 'separator'
				}, {
					type: 'title',
					title: 'Demande facturation : '
				}, {
					column: 'billing.state',
					type: 'checkbox',
					filters: [{
						value: 'no',
						text: 'Aucune demande'
					}, {
						value: 'advance',
						text: 'Acompte / avancement'
					}, {
						value: 'last',
						text: 'Définitive'
					}]
				}, {
					type: 'separator',
					hide: comptaSettings.disable
				}, {
					type: 'title',
					title: 'Compta :',
					hide: comptaSettings.disable
				}, {
					column: 'compta.error',
					type: 'switch',
					hide: comptaSettings.disable,
					filters: [{
						value: 'true',
						text: 'Erreur'
					}]
				}, {
					type: 'separator'
				}, {
					column: 'infos.nonValorisee',
					type: 'switch',
					filters: [{
						value: 'true',
						text: 'Commande non valorisée'
					}]
				}
			], [
				{
					column: 'finish',
					type: 'switch-3',
					filters: [{
						values: ['false', 'true'],
						text: 'Terminée'
					}]
				}, {
					type: 'separator'
				}, {
					type: 'title',
					title: 'État :'
				}, {
					column: 'state',
					type: 'checkbox',
					filters: [{
						value: '0',
						text: 'À préparer',
						icon: 'icon-ri-file-edit-line',
						color: 'dark'
					}, {
						value: '1',
						text: 'En cours de préparation',
						icon: 'icon-solid-box-open',
						color: 'blue-600'
					}, {
						value: '2',
						text: 'Prête partiellement',
						icon: 'icon-solid-box',
						color: 'orange-400'
					}, {
						value: '3',
						text: 'Prête en totalité',
						icon: 'icon-solid-box',
						color: 'green-400'
					}, {
						value: '4',
						text: 'Expédiée partiellement',
						icon: 'icon-ri-truck-line',
						color: 'orange-400'
					}, {
						value: '5',
						text: 'Expédiée en totalité',
						icon: 'icon-ri-truck-line',
						color: 'green-400'
					}]
				}, {
					type: 'separator'
				}, {
					type: 'title',
					title: 'État facturation : '
				}, {
					column: 'bill.state',
					type: 'checkbox',
					filters: [{
						value: '0',
						text: 'Facturation pas commencée',
						icon: 'icon-outline-file-invoice-dollar',
						color: 'red-400'
					}, {
						value: '1',
						text: 'En cours de facturation',
						icon: 'icon-outline-file-invoice-dollar',
						color: 'orange-400'
					}, {
						value: '2',
						text: 'Facturée à 100%',
						icon: 'icon-outline-file-invoice-dollar',
						color: 'green-400'
					}]
				}
			]
		];
	}
}

export default ListCommand;
