import { Module, Modules, Router } from '@autoprog/core-client';

import C_AddQuotes from './js/controllers/AddQuotes';
import C_Quotes from './js/controllers/Quotes';
import C_ViewQuotes from './js/controllers/ViewQuotes';

import T_AddQuotes from './tpl/addQuotes.html';
import T_Tabs from '@tpl/tabs.html';
import T_ViewQuotes from './tpl/viewQuotes.html';

import Navigation from '@libs/Navigation';
import NavigationMobile from '@libs/NavigationMobile';
import Notifications from '@modules/Apps/js/libs/Notifications';

import M_quotes_to_do from './js/modals/QuotesToDo/Information';

import CE_BrochureTab from './js/libs/customElement/BrochureTab';
import CE_GridQuote from './js/libs/customElement/GridQuote';
import CE_QuoteInformations from './js/libs/customElement/QuoteInformations';
import CE_QuoteOption from './js/libs/customElement/QuoteOption';
import CE_QuoteOptionReadonly from './js/libs/customElement/QuoteOptionReadonly';
import CE_QuotePrintOption from './js/libs/customElement/QuotePrintOption';
import CE_QuoteRelance from './js/libs/customElement/QuoteRelance';
import CE_RecapProductTab from './js/libs/customElement/RecapProductsTab';
import CE_RecapTab from './js/libs/customElement/RecapTab';

import SettingsNotificationTabs from '@modules/Apps/js/tabs/Settings.Notification';

class QuotesModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_GridQuote.register();
		CE_RecapTab.register();
		CE_RecapProductTab.register();
		CE_QuoteInformations.register();
		CE_QuoteOption.register();
		CE_QuoteOptionReadonly.register();
		CE_QuotePrintOption.register();
		CE_QuoteRelance.register();
		CE_BrochureTab.register();
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Documents',
			order: 100
		});
		navigation.addLink({
			order: 0,
			category: 'Documents',
			name: 'Devis / Devis à faire',
			type: 'module',
			module: 'quotes',
			permissions: ['QUOTES', 'QUOTES._FAVORITE', 'QUOTES._QUOTES_TO_DO'],
			icon: 'file-edit/fill'
		});

		NavigationMobile.getInstance().addLink({
			icon: 'icon-solid-file-contract',
			name: 'Devis',
			order: 5,
			dataset: {
				link: 'quotes'
			}
		});

		SettingsNotificationTabs.addInput({
			text: 'Devis',
			select: [
				{
					text: 'Relance',
					name: 'quotes.relunch'
				}
			]
		}, 0);

		Notifications.addCategory(['quote', 'quote-relunch', 'quote-validate', 'quote-not-finish', 'quotes-to-do'], {
			textFilter: 'Devis',
			isFilter: true,
			icon: 'icon-solid-file-contract',
			action: async (item: { [key: string]: any }) => {
				if (['quote', 'quote-not-finish', 'quote-relunch'].includes(item.type) && item.data.id) {
					C_AddQuotes.open(item.data.id);
				}

				if (['quote-validate'].includes(item.type) && item.data.id) {
					C_AddQuotes.open(item.data.id, { notification: item._id });
				}

				if (['quotes-to-do'].includes(item.type) && item.data.id) {
					const data = await new M_quotes_to_do(item.data.id, 'notification').open();

					if (data === 'notification') {
						await Notifications.closeNotification(item._id);
					}
				}
			}
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/quotes/:tabs',
			controller: C_Quotes as any,
			tpl: T_Tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/quotes',
			controller: C_Quotes as any,
			tpl: T_Tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/quote/view',
			controller: C_ViewQuotes as any,
			tpl: T_ViewQuotes,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/quote/viewV1',
			controller: C_ViewQuotes as any,
			tpl: T_ViewQuotes,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/quote/add',
			controller: C_AddQuotes as any,
			tpl: T_AddQuotes,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/quote/addV1',
			controller: C_AddQuotes as any,
			tpl: T_AddQuotes,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/quote/addCommand',
			controller: C_AddQuotes as any,
			tpl: T_AddQuotes,
			data: {
				mode: 'command'
			},
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/quote/addInCommand',
			controller: C_AddQuotes as any,
			tpl: T_AddQuotes,
			data: {
				mode: 'addInCommand'
			},
			requireValidUser: true
		});
	}

	public async postInit() {
	}
}

Modules.registerModule('Devis', QuotesModule);
