import M_SelectOrder from '../../../modals/providers/SelectOrder';

import CE_Select2 from '@libs/customElement/Select2';

class OrderCustomer extends HTMLElement {
	public static readonly tagName: string = 'ap-order-customer';

	private N_select: CE_Select2 | null = null;

	public connectedCallback() {
		const name = this.getAttribute('name') as string;

		this.innerHTML = `
			<div class="d-flex">
				<div class="border border-1x w-100 bg-white d-flex align-items-center flex-grow-1 flex-wrap" id="values"></div>
				<div class="input-group flex-nowrap d-none">	
					<ap-select2-button name="${name}" table="commands-customer" hidden-btn-add="true" hidden-btn-edit="true"></ap-select2-button>
				</div>
				<div class="bg-white border ml-1 p-0 d-flex" style="border-radius: 5px;">
					<button class="btn btn-white" type="button" id="add_order_customer" style="border-radius: 5px;">
						<i class="icon icon-solid-plus"></i>
					</button>
				</div>
			</div>
        `;

		this.N_select = this.querySelector(`[name="${name}"]`) as CE_Select2;

		this.N_select.create(this, {
			multiple: true,
			disabled: true,
			allowClear: false
		});

		this.classList.add('w-100');
		this.removeAttribute('name');
	}

	private update(values: string[]) {
		const N_div = this.querySelector('#values') as HTMLElement;

		const N_values = this.N_select!.selectElement!.querySelectorAll('option');
		const valuesAll: { id: string, text: string }[] = [];

		N_div.innerHTML = '';

		const htmlReadonly: string[] = [];

		for (const N_option of N_values) {
			const value = N_option.value;
			const text = N_option.innerHTML;

			if (values.includes(value) && valuesAll.findIndex(item => item.id === value) === -1) {
				if (this.getAttribute('readonly') === null) {
					const N_tmp = document.createElement('div');

					N_tmp.classList.add('border', 'border-1x', 'border-grey', 'd-flex', 'align-items-center', 'm-1');
					N_tmp.style.whiteSpace = 'nowrap';
					N_tmp.style.borderRadius = '5px';

					N_tmp.innerHTML = `
						<div class="border-right-1x border-grey px-2">
							${text}
						</div>
						<button class="btn p-0 px-2" style="--size: 1.285rem;" data-id="${value}" id="delete" type="button" confirmation>
							×
						</button>
					`;

					N_div.appendChild(N_tmp);

					const N_delete = N_tmp.querySelector('#delete') as HTMLButtonElement;

					N_delete.addEventListener('click', () => {
						const id = N_delete.dataset.id || '';

						const index = valuesAll.findIndex(item => item.id === id);

						valuesAll.splice(index, 1);

						this.N_select?.setValues(valuesAll);
					});
				} else {
					htmlReadonly.push(text);
				}

				valuesAll.push({
					id: value,
					text
				});
			}
		}

		if (htmlReadonly.length) {
			N_div.innerHTML += htmlReadonly.join(', ');
		}
	}

	public addValues(values: { id: string, text: string }[]) {
		const oldValue = this.N_select!.value as string[];
		const addValues: { id: string, text: string }[] = [];

		for (const item of values) {
			if (!oldValue.includes(item.id)) {
				addValues.push(item);
			}
		}

		addValues.length && this.N_select!.addValues(addValues);
	}

	public postInit(cb: (materials: any, idOrder: any) => Promise<void>) {
		this.N_select!.postInit();

		this.N_select!.on('change', (value) => {
			this.update(value as string[]);
		});

		this.update(this.N_select!.value as string[]);

		const N_add_order_customer = this.querySelector('#add_order_customer') as HTMLButtonElement;

		N_add_order_customer.addEventListener('click', () => {
			new M_SelectOrder().open().then(async ({ materials, idOrder }) => {
				cb(materials, idOrder);
			});
		});
	}

	public static register() {
		customElements.define(OrderCustomer.tagName, OrderCustomer);
	}
}

export default OrderCustomer;
