// CORE
import { Form, global } from '@autoprog/core-client';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

// TEMPLATE
import T_modal from '../../../tpl/modals/multiple/Input.html';

// LIBS
import Modal from '@libs/Modal';
import NumericCellEditor from '@libs/agGrid/NumericCellEditor';
import agUtils from '@libs/agGrid/french';

// PRINTER
// UTILS
// MODAL
import M_OutputCustomer from '../multiple/OutputCustomer';

// CUSTOM_ELEMENT
import CE_InputTracaForm from '../../libs/customElement/InputTracaForm';
import CE_SelectProductTabs from '@modules/Products/js/libs/customElement/SelectProductTabs';

// SERVICE
import S_P_Stock from '@services/Product/ProductStockService';
import S_Product from '@services/Product/ProductService';
import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';

class Input extends Modal {
	private form: Form | null = null;

	private filters: { [key: string]: any } = {};

	private gridOptionsProducts: GridOptions = {};

	private isFilterEnabled: boolean = true;

	private productStockData: { [key: string]: any } = {};

	constructor(stock?: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.filters.stock = stock;

		this.on('opened', async () => {
			if (global.IS_ELECTRON) {
				this.isFilterEnabled = false;
			}

			const N_close = this.element.querySelector('.close') as HTMLButtonElement;

			N_close.addEventListener('click', (e: MouseEvent) => {
				this.reject();

				e.stopImmediatePropagation();
				e.stopPropagation();
			});

			const N_confirmSelection = this.element.querySelector('#confirm') as HTMLButtonElement;

			N_confirmSelection.addEventListener('click', () => {
				this.getSelectedData();
				this.toggleDisplaySelectionOrList();
			});

			const N_selectProducts = this.element.querySelector('#select_products') as HTMLButtonElement;

			N_selectProducts.addEventListener('click', () => {
				this.toggleDisplaySelectionOrList();
			});

			if (global.IS_MOBILE) {
				const N_modalDialog = this.element.querySelector('.modal-dialog') as HTMLSelectElement;
				N_modalDialog.classList.remove('modal-70');
			}

			await this.loadProductStock();
			await this.initStock();
			this.init();

			const N_form = this.element.querySelector('.modal-content') as HTMLFormElement;

			this.form = new Form(N_form);

			if (stock) {
				this.form?.setDataByName('stock', stock);
			}

			const N_reset = this.element.querySelector('#reset') as HTMLButtonElement;

			N_reset.addEventListener('click', () => {
				this.reset();
			});

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				this.save();
			});

			this.postInit();
		});
	}

	private async loadProductStock() {
		const data = await S_P_Stock.getInstance().getAll();

		for (const item of data) {
			this.productStockData[item._id] = item;
		}
	}

	private async initStock() {
		const N_list = this.element.querySelector('[name="stock"]') as HTMLSelectElement;
		const data = await S_Stock.getInstance().getRealStock();

		for (const item of data) {
			const option = new Option(item.name, item._id);

			N_list.append(option);
		}

		N_list.addEventListener('change', () => {
			if (N_list.value !== 'receiptToCustomer') {
				this.gridOptionsProducts.columnApi?.setColumnsVisible(['stockLocation'], true);
			} else {
				this.gridOptionsProducts.columnApi?.setColumnsVisible(['stockLocation'], false);
			}
			this.getSelectedData();
		});
	}

	private init() {
		const N_inputTracaForm = this.element.querySelector(CE_InputTracaForm.tagName) as CE_InputTracaForm;
		const N_stock = this.element.querySelector('[name="stock"]') as HTMLSelectElement;

		N_inputTracaForm.init(this.element, N_stock);

		this.initQuantitesEditorGrid();
	}

	private initQuantitesEditorGrid() {
		this.gridOptionsProducts = agUtils.french<GridOptions>({
			columnDefs: [
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: 'product.' + S_Product.getInstance().referenceKey
				},
				{
					headerName: 'Désignation',
					field: 'product.name'
				},
				{
					headerName: 'Emplacement',
					field: 'stockLocation'
				},
				{
					headerName: 'Quantité',
					field: 'quantity',
					type: 'numberColumn',
					cellClass: ['text-right', 'text-monospace', 'cursor-pointer'],
					editable: true,
					singleClickEdit: true,
					cellEditor: NumericCellEditor,
					cellRenderer: (params) => {
						const eDiv = document.createElement('div');
						eDiv.innerHTML = params.value + '<i class="icon icon-edit ml-2 text-light-blue-600"></i>';
						return eDiv;
					}
				}
			],
			columnTypes: {
				valueColumn: {
					valueParser: 'Number(newValue)',
					filter: 'agNumberColumnFilter'
				}
			},
			suppressContextMenu: true,
			floatingFilter: this.isFilterEnabled,
			defaultColDef: {
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true,
				suppressMenu: true,
				resizable: true
			},
			onGridReady: async () => {
				this.getSelectedData();
			}
		});

		const N_grid_products_list = this.element.querySelector('#grid_products_list');

		if (N_grid_products_list) {
			new Grid(N_grid_products_list as HTMLElement, this.gridOptionsProducts, { modules: AllModules });
		}
	}

	private postInit() {
		const N_inputTracaForm = this.element.querySelector(CE_InputTracaForm.tagName) as CE_InputTracaForm;
		N_inputTracaForm.postInit();
	}

	private toggleDisplaySelectionOrList() {
		const N_products_selection = this.element.querySelector('#products_selection') as HTMLDivElement;
		const N_products_list = this.element.querySelector('#products_list') as HTMLDivElement;

		if (N_products_list.classList.contains('d-none')) {
			N_products_selection.classList.add('d-none');
			N_products_list.classList.remove('d-none');
		} else {
			N_products_list.classList.add('d-none');
			N_products_selection.classList.remove('d-none');
		}
	}

	private async getSelectedData() {
		const N_tabs = this.element.querySelector('ap-select-products-tabs') as CE_SelectProductTabs;
		const selection: { [key: string]: any } = N_tabs.onlyProducts;
		const formData = this.form?.getData() as { [key: string]: any };

		const rowData = [];
		for (const key in selection) {
			selection[key].stockLocation = '';
			for (const keyPS in this.productStockData) {
				const hasTheSameProduct: boolean = this.productStockData[keyPS].id_product === selection[key].product._id;
				const hasTheSameStock: boolean = this.productStockData[keyPS].id_stock === formData.stock;

				if (hasTheSameProduct && hasTheSameStock) {
					selection[key].stockLocation = this.productStockData[keyPS].stockLocation;
				}
			}

			rowData.push(selection[key]);
		}

		this.gridOptionsProducts.api?.setRowData(rowData);
		this.gridOptionsProducts.api?.sizeColumnsToFit();
	}

	private async save() {
		const formData = this.form?.getData() as { [key: string]: any };

		for (const key in formData.traceability) {
			if (formData.traceability[key] === '') {
				delete formData.traceability[key];
			}
		}

		const rowData: any[] = [];
		const events: any[] = [];

		this.gridOptionsProducts.api?.stopEditing();

		this.gridOptionsProducts.api?.forEachNode(async (row) => {
			rowData.push(row.data);

			events.push({
				product: row.data.product._id,
				quantity: row.data.quantity,
				stock: formData.stock,
				traceability: formData.traceability,
				type: 'input'
			});
		});

		let saveEvent = true;

		if (formData.stock === 'receiptToCustomer') {
			try {
				await new M_OutputCustomer(rowData).open();
			} catch (e) {
				saveEvent = false;
			}
		}

		if (saveEvent) {
			for (const item of events) {
				await S_StockEvent.getInstance().save(item);
			}

			this.resolve();
		}
	}

	private reset() {
		const N_tabs = this.element.querySelector('ap-select-products-tabs') as CE_SelectProductTabs;
		N_tabs.resetSelection();
		this.getSelectedData();
	}
}

export default Input;
