// CORE
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import h from 'hyperscript';
import moment from 'moment';

// TEMPLATE
// LIBS
import C_CommandProvider from '../../../controllers/Command.Provider';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

class ProviderOrderTab extends HTMLElement {
	public static readonly tagName: string = 'ap-provider-order-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private _gridOptions: GridOptions = {};

	private _idOrder: () => string = () => { return ''; };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Commandes fournisseurs">
						<i class="icon icon-solid-file-alt"></i>
						<span class="nav-icon-badge d-none" id="number">0</span>
					</div>
					<span>Commandes fournisseurs</span>
				</a>
			</li>
		`;

		this.removeAttribute('id');
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');
		N_div.id = this.idTab;
		N_div.innerHTML = `	
			<div class="d-flex flex-column h-100">
				<div class="title">
					Commande Fournisseur
					<div class="ml-auto">
						<button class="btn btn-add" type="button" id="add" permission="ORDERS._PROVIDERS.ADD">
							<i class="icon icon-solid-plus"></i>Ajouter
						</button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
				</div>
				<div class="h-100 ag-theme-alpine grid"></div>
			</div>
		`;

		N_container.append(N_div);

		this.initGrid();
		this.initButton();
	}

	public setIdOrder(cb: () => string) {
		this._idOrder = cb;
	}

	private initGrid() {
		this._gridOptions = agUtils.french({
			localeText: { noRowsToShow: 'Aucune Commande Fournisseur' },
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Numero',
					field: 'infos.number',
					pinned: 'left',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Fournisseur',
					field: 'infos.provider',
					width: 300,
					suppressSizeToFit: true
				},
				{
					headerName: 'Date commande',
					field: 'infos.date',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.value) {
							return moment(params.value, 'x').format('DD/MM/YY');
						}
					}

				},
				{
					headerName: "Nom d'Affaire",
					field: 'infos.businessName'
				},
				{
					headerName: 'Action',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_div = h('div');

						const N_edit = document.createElement('button');

						N_edit.classList.add('h-100', 'py-0', 'btn-transparent');

						N_edit.setAttribute('permission', 'ORDERS._PROVIDERS.EDIT');

						N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';
						N_edit.setAttribute('tooltip', 'Éditer');
						N_edit.type = 'button';

						N_edit.addEventListener('click', () => {
							C_CommandProvider.open(params.data._id);
						});

						N_div.appendChild(N_edit);

						return N_div;
					}
				}
			],
			getContextMenuItems: (params) => {
				return [
					{
						name: `N° ${params.node.data.infos.number}`,
						disabled: true,
						cssClasses: ['title-context-menu']
					}, {
						name: 'Éditer',
						icon: '<i class="icon icon-edit"></i>',
						disabled: !params.node,
						action: () => {
							C_CommandProvider.open(params.node.data._id);
						}
					}
				];
			},
			onRowDataChanged: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		} as GridOptions);

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector('#number') as HTMLElement;

		if (number) {
			N_number.innerHTML = number.toString();
			N_number.classList.remove('d-none');
		} else {
			N_number.classList.add('d-none');
		}
	}

	private initButton() {
		const N_btn = document.querySelector(`#${this.idTab} #add`) as HTMLButtonElement;

		N_btn.addEventListener('click', () => {
			C_CommandProvider.open(null, {
				type: 'command',
				materials: [],
				idOrder: this._idOrder()
			});
		});
	}

	public set data(data: any[]) {
		this.gridOptions.api?.setRowData(data);
	}

	public get data(): any[] {
		const results: any[] = [];

		this._gridOptions.api?.forEachNode((node) => {
			results.push(node.data);
		});

		return results;
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public static register() {
		customElements.define(ProviderOrderTab.tagName, ProviderOrderTab);
	}
}

export default ProviderOrderTab;
