import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

import Options from '../GridOptions';

import M_EditGroup from '../../modals/AddQuote/EditGroup';
import M_SelectFavorite from '../../modals/AddQuote/SelectFavorite';
import M_SelectQuote from '../../modals/AddQuote/SelectQuote';

import Clipboard from '../Clipboard';

import AgGridStateSaver from '@libs/agGrid/StateSaver';
import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/Utils';
import UtilsQuotes from '../UtilsQuotes';

import _ from 'lodash';

class GridQuote extends HTMLElement {
	public static readonly tagName: string = 'ap-grid-quote';

	private _gridOptions: GridOptions = {};
	private _optionsGrid: Options = new Options();

	private _isLock: boolean = false;

	private _textNotOption: string = '';
	private _settingsDetails: { [key: string]: any } = {};
	private _settings: { [key: string]: any } = {};
	private _notFinishGeneral: boolean = false;
	private intervalFlagNotFinish: any = null;

	private abortController: AbortController | null = null;
	private get abortSignal(): AbortSignal {
		return this.abortController!.signal;
	}

	private _update: () => void = () => { };

	public async connectedCallback() {
		this.abortController = new AbortController();

		this.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="h-100 ag-theme-alpine" id="grid"></div>

				<div class="d-flex mt-2">

					<div class="d-flex flex-wrap flex-grow-1 align-items-center" style="white-space:nowrap">

						<div class="font-weight-bold mr-2">
							Légende :
						</div>
						<small class="d-flex align-items-center mr-2">
							<div class="badge-legend">
								<i class="icon icon-solid-flag" id="flagNotFinish"></i>
							</div>
							À finir
						</small>
						<small class="d-flex align-items-center text-purple-900 mr-2">
							<div class="badge-legend bg-purple-100">
							</div>
							Catégorie en option
						</small>
						<small class="d-flex align-items-center text-red-900 mr-2">
							<div class="badge-legend bg-red-100">
							</div>
							Coef total désactivé
						</small>
						<small class="d-flex align-items-center text-blue-900 mr-2">
							<div class="badge-legend bg-blue-50">
							</div>
							Masquer à l'impression
						</small>
						<small class="d-flex align-items-center text-orange-900 mr-2">
							<div class="badge-legend bg-orange-50">
							</div>
							Produit "supprimé"
						</small>
					</div>

					<div class="align-items-center d-flex ">
						<div id="globalPrice" class="price-quote" style="white-space:nowrap"></div>
					</div>

				</div>

			</div>
		`;

		const N_flagNotFinish = this.querySelector('#flagNotFinish') as HTMLElement;

		this.intervalFlagNotFinish = setInterval(() => {
			const data = this.data as any;

			if (this._notFinishGeneral || _.find(data, { notFinish: true })) {
				N_flagNotFinish.classList.toggle('text-danger');
			} else {
				N_flagNotFinish.classList.remove('text-danger');
			}
		}, 3000);
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public set isLock(value: boolean) {
		this._isLock = value;
		this._optionsGrid.isLock = value;
	}

	private async initGrid() {
		this._gridOptions = agUtils.french<GridOptions>({
			rowData: this._gridOptions.rowData || [],
			masterDetail: true,
			detailRowHeight: 400,
			rowDragManaged: true,
			...this._optionsGrid.gridMaster(this, 'quote'),
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();

				const stateSaver = new AgGridStateSaver(this.gridOptions as any, 'add-quote');
				stateSaver.setData(this._settings);
			},
			onCellEditingStopped: (params) => {
				if (['name'].includes(params.column.getColId())) {
					params.node.setDataValue(params.column.getColId(), params.newValue.trim());
				}

				this._update();
				this.updateGlobalPrice();

				params.api.resetRowHeights();
				params.api.refreshCells({ force: true });
			},
			getContextMenuItems: (params) => {
				const addLine = {
					name: 'Ajouter un groupe',
					disabled: this._isLock,
					action: () => {
						const data = {
							tva: this._optionsGrid.tva,
							quantity: '1',
							unit: 'U',
							_idGrid: Utils.generateId(),
							details: []
						};

						params.api?.applyTransaction({
							add: [data]
						});

						let length = 0;

						params.api?.forEachNode(() => {
							length++;
						});

						params.api?.ensureIndexVisible(length - 1);
					}
				};

				const insertLine = {
					name: 'Inserer un groupe',
					disabled: this._isLock,
					action: () => {
						const data = {
							tva: '20',
							quantity: '1',
							unit: 'U',
							_idGrid: Utils.generateId(),
							details: []
						};

						params.api?.applyTransaction({
							add: [data],
							addIndex: (params.node.rowIndex || 0) + 1
						});
					}
				};

				const addFavorite = {
					name: 'Ajouter un favori',
					disabled: this._isLock,
					icon: '<i class="icon icon-star"></i>',
					action: async () => {
						new M_SelectFavorite(this._optionsGrid.pref, this._optionsGrid.quoteOptions).open().then((data) => {
							this.gridOptions.api?.applyTransaction({
								add: data
							});

							this.gridOptions.api?.resetRowHeights();
							this.gridOptions.api?.refreshCells({ force: true });
						}).catch(() => {

						});
					}
				};

				const addQuote = {
					name: 'Ajouter un devis',
					disabled: this._isLock,
					icon: '<i class="icon icon-solid-file-contract"></i>',
					action: () => {
						new M_SelectQuote(this.id).open().then((data) => {
							this.gridOptions.api?.applyTransaction({
								add: data
							});

							this.gridOptions.api?.resetRowHeights();
							this.gridOptions.api?.refreshCells({ force: true });
						}).catch((e) => {
							e && console.error(e);
						});
					}
				};

				const editGroup = {
					name: 'Modifier groupe',
					action: () => {
						new M_EditGroup(this.data, params.node.data._idGrid, this._optionsGrid).open().then((data) => {
							data = {
								...params.node.data,
								...data
							};

							params.node.setData(data);

							params.node.detailNode?.detailGridInfo?.api?.setRowData(data.details);
							params.node.detailNode?.detailGridInfo?.api?.setPinnedBottomRowData([{}]);

							params.api?.refreshCells({ rowNodes: [params.node], force: true });

							params.api?.resetRowHeights();

							this._update();

							this.updateGlobalPrice();
						}).catch((e) => {
							e && console.error(e);
						});
					}
				};

				const options = {
					name: 'Mettre/Enlever de l\'options',
					disabled: this._isLock,
					action: () => {
						params.node.data.isOption = !params.node.data.isOption;

						params.node.setData(params.node.data);

						this.gridOptions.api?.refreshCells({ force: true });

						this.updateGlobalPrice();

						this._update();
					}
				};

				const notFinish = {
					name: 'À finir',
					disabled: this._isLock,
					subMenu: [{
						name: `Groupe ${params.node ? (params.node.data.notFinish ? '(enlever)' : '(définir)') : ''}`,
						disabled: !params.node,
						action: async () => {
							params.node.data.notFinish = !params.node.data.notFinish;

							params.node.setDataValue('notFinish', params.node.data.notFinish);

							this.gridOptions.api?.refreshCells({ force: true });

							this._update();
						}
					}, {
						name: `Général (${this._notFinishGeneral ? 'enlever' : 'définir'})`,
						action: async () => {
							this._notFinishGeneral = !this._notFinishGeneral;

							this._update();
						}
					}]
				};

				const clipboard = Clipboard.getInstance();

				const copy = {
					name: 'Copier',
					disabled: !params.node,
					icon: '<i class="icon icon-copy"></i>',
					action: () => {
						clipboard.setData(_.cloneDeep(params.node.data), 'master');
					}
				};

				const paste = {
					name: 'Coller',
					disabled: this._isLock || !clipboard.getData('master'),
					icon: '<i class="icon icon-solid-paste"></i>',
					action: () => {
						const data = _.cloneDeep(clipboard.getData('master'));

						data._idGrid = Date.now().toString(36);

						params.api?.applyTransaction({
							add: [data]
						});

						params.api?.refreshCells({ force: true });
					}
				};

				const style = this._optionsGrid.styleContextMenu(params, () => {
					this._update();
				});

				if (params.node) {
					return [
						editGroup,
						addLine,
						insertLine,
						'separator',
						options,
						notFinish,
						'separator',
						style,
						'separator',
						addFavorite,
						addQuote,
						'separator',
						copy,
						paste
					];
				} else {
					return [
						addLine,
						'separator',
						notFinish,
						'separator',
						addFavorite,
						addQuote,
						'separator',
						copy,
						paste
					];
				}
			},
			onRowDragEnd: () => {
				this._update();
			},
			onRowDataUpdated: () => {
				this._update();
				this.updateGlobalPrice();
			},
			onRowDataChanged: () => {
				this.updateGlobalPrice();
			},
			onColumnResized: (params) => {
				if (params.finished) {
					params.api.resetRowHeights();
				}
			},
			getRowStyle: (params: any) => {
				if (params.data.isOption) {
					return {
						'background-color': 'var(--ap-purple-50)',
						color: 'var(--ap-purple-900)'
					};
				} else {
					return {
						background: '',
						color: ''
					};
				}
			},
			detailCellRendererParams: {
				detailGridOptions: agUtils.french<GridOptions>({
					...this._optionsGrid.gridDetails(this, '', () => {
						this.updateGlobalPrice();
						this.gridOptions.api?.refreshCells({ force: true });
						this._update();
					}),
					onRowDragEnd: () => {
						this._update();
					},
					onRowDataUpdated: () => {
						this._update();
						this.updateGlobalPrice();
					},
					onGridReady: (params: any) => {
						const stateSaver = new AgGridStateSaver(params as any, 'add-quote-details');
						stateSaver.setData(this._settingsDetails);
						stateSaver.onUpdate((value) => {
							this._settingsDetails = value;
						});
					}
				}),
				template: () => {
					return '<div class="h-100"><div ref="eDetailGrid" class="h-100" style="padding: 3px;background: black;"></div></div>';
				},
				getDetailRowData: (params: any) => {
					for (const i in params.data.details) {
						params.data.details[i]._idGrid = Utils.generateId();
					}
					params.successCallback(params.data.details);
				}

			}
		});

		const N_grid = this.querySelector('#grid') as HTMLElement;

		new Grid(N_grid, this.gridOptions, { modules: AllModules });

		Utils.cancelEditingAggrid(this._gridOptions, this.abortSignal);
	}

	private updateGlobalPrice() {
		const N_text = this.querySelector('#globalPrice') as HTMLElement;

		const data = UtilsQuotes.calculateGlobalPrice(this.data, this._optionsGrid.pref || {});

		const textAll = Decimal.setDisplayNumber(data.all).setSuffixAndHumanizeNumber('€', this._optionsGrid.pref?.decimalNumber);
		this._textNotOption = Decimal.setDisplayNumber(data.notOption).setSuffixAndHumanizeNumber('€', this._optionsGrid.pref?.decimalNumber);
		const textOption = Decimal.setDisplayNumber(data.option).setSuffixAndHumanizeNumber('€', this._optionsGrid.pref?.decimalNumber);

		N_text.innerHTML = this._textNotOption;

		const contentPopover = [`Avec option : ${textAll}`, `Option : ${textOption}`];

		N_text.setAttribute('popover', JSON.stringify({ title: 'Détail Prix', content: contentPopover.join('</br>'), trigger: 'hover', placement: 'bottom', sanitize: false }));
	}

	public set data(data: any[]) {
		this._gridOptions.rowData = data;
		this._gridOptions.api?.setRowData(data);

		if (!this.gridOptions.api) {
			this.initGrid();
		}

		this.updateGlobalPrice();
	}

	public get data(): any[] {
		const results: any[] = [];

		this._gridOptions.api?.forEachNode((node) => {
			const obj = node.data;

			obj.name = obj.name || '';
			obj.category = obj.category || '';
			obj.subCategory = obj.subCategory || '';
			obj.tva = obj.tva || 20;

			if (node.detailNode?.detailGridInfo) {
				obj.details = [];

				const details = node.detailNode.detailGridInfo;

				details.api?.forEachNode((nodeDetails) => {
					const tmp = nodeDetails.data;

					tmp.typeMat = this._optionsGrid.getTypeMat(nodeDetails);
					tmp.type = this._optionsGrid.getType(nodeDetails);
					tmp.label = tmp.label || '';

					obj.details.push(tmp);
				});

				node.data.details = obj.details;
			}

			results.push(obj);
		});

		return results;
	}

	public set optionsGrid(data: { [key: string]: any }) {
		this._optionsGrid.updatePref(data.pref || {});
		this._optionsGrid.updateInfos(data.infos || {});
		this._optionsGrid.updateQuoteOptions(data.quoteOptions || {});
		this._optionsGrid.updateTVA(data.tva || '20');

		this._gridOptions.api?.refreshCells({ force: true });
		this._gridOptions.api?.forEachNode((node) => {
			node.detailNode?.detailGridInfo?.api?.setPinnedBottomRowData([{}]);

			node.detailNode?.detailGridInfo?.api?.refreshCells({ force: true });

			node.detailNode?.detailGridInfo?.columnApi?.setColumnVisible('PU_Total', this._optionsGrid.pref.displayPUDetails);
		});

		this.updateGlobalPrice();
	}

	public set settings(data: { [key: string]: any }) {
		//suppression de l'affichage de la colonne "Compte comptable"
		delete data.settingsDetails?.columns?.comptaVente?.hide;

		this._settingsDetails = data.settingsDetails;
		this._settings = data.settings;
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public set notFinishGeneral(notFinishGeneral: boolean) {
		this._notFinishGeneral = notFinishGeneral;
	}

	public get notFinishGeneral() {
		return this._notFinishGeneral;
	}

	public get globalPriceText() {
		return this._textNotOption;
	}

	public destructor() {
		this.abortController!.abort('destroyed');

		clearInterval(this.intervalFlagNotFinish);
	}

	public static register() {
		customElements.define(GridQuote.tagName, GridQuote);
	}
}

export default GridQuote;
