// CORE
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions, MenuItemDef } from '@ag-grid-enterprise/all-modules';
import { Moment } from 'moment';

// TEMPLATE
// LIBS
import Aggrid from '@libs/utils/Aggrid';
import Decimal from '@libs/utils/Decimal';
import SelectEditor from '@libs/agGrid/SelectEditor';
import TextareaEditor from '@libs/agGrid/TextareaEditor';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import CE_TVA from '@libs/customElement/TVA';

// SERVICE
import S_Products from '@services/Product/ProductService';
import _ from 'lodash';

class ContentDeliveriesTab extends HTMLElement {
	public static readonly tagName: string = 'ap-bills-customers-content-tab';

	private selectorTab: string = '';
	private idTab: string = '';
	private _isLock: boolean = false;
	private _update = (): void => { };
	private _form = (): any => { return {}; };
	private _quotes: { [key: string]: string }[] = [];

	private _gridOptions: GridOptions = {};

	private N_el: HTMLElement | null = null;

	constructor() {
		super();
		this.idTab = this.getAttribute('tabID') || 'content-deliveries';
		this.selectorTab = this.dataset.tabContainer || '.tab-content';
	}

	public async connectedCallback() {
		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Contenus">
						<i class="icon icon-solid-list-ul"></i>
					</div>
					<span>Contenus</span>
				</a>
			</li>
		`;
	}

	public set isLock(value: boolean) {
		this._isLock = value;
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this._gridOptions.api?.sizeColumnsToFit();
				this.getInfos();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'h-100', 'fade');
		this.N_el.setAttribute('role', 'tabpanel');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="title" display="desktop">
					Contenus
					<div class="ml-auto">
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
				</div>

				<div id="grid" class="ag-theme-alpine h-100 w-100"></div>
			</div>
        `;

		N_container.append(this.N_el);

		this.initGrid();
	}

	private initGrid() {
		this._gridOptions = agUtils.french<GridOptions>({
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				editable: (params) => {
					return !params.data.typeLine && !this._isLock;
				},
				resizable: true
			},
			getRowHeight: (params: any) => {
				if (params.node.rowPinned) {
					return 31 * params.data.value?.length || 1;
				}

				return 12 + 15 + 17 * (params.data.label || '').split('\n').length;
			},
			columnDefs: [{
				headerName: S_Products.getInstance().columnNameReference,
				field: 'reference',
				width: 170,
				rowDrag: true,
				suppressSizeToFit: true,
				autoHeight: true,
				wrapText: true,
				colSpan: (params) => {
					return params.node?.rowPinned ? 8 : 1;
				},
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: {
						table: 'products',
						rootElement: this
					}
				},
				cellClass: (params) => {
					return params.node.rowPinned ? ['text-right'] : '';
				},
				cellRenderer: (params) => {
					if (params.node.rowPinned) {
						const N_div = document.createElement('div');
						N_div.classList.add('d-flex');
						N_div.style.lineHeight = '31px';

						const N_title = document.createElement('div');
						N_title.classList.add('font-weight-bold', 'ml-auto', 'mr-2');

						const N_price = document.createElement('div');

						const title: string[] = [];
						const price: string[] = [];

						for (const item of (params.data.value || [])) {
							title.push(item.title + ' :');
							price.push(item.text);
						}

						N_title.innerHTML = title.join('<br>');
						N_price.innerHTML = price.join('<br>');

						N_div.appendChild(N_title);
						N_div.appendChild(N_price);

						return N_div;
					} else {
						return S_Products.getInstance().cellRendererReference(params.value);
					}
				}
			}, {
				headerName: 'Marque',
				field: 'brand',
				width: 95,
				suppressSizeToFit: true
			}, {
				field: 'quantity',
				headerName: 'Qté',
				width: 80,
				suppressSizeToFit: true
			}, {
				field: 'label',
				headerName: 'Désignation',
				autoHeight: true,
				wrapText: true,
				cellEditor: TextareaEditor,
				cellRenderer: (params) => {
					if (params.data.typeLine === 'detailsQuote') {
						const quote = _.find(this._quotes, { id: params.data.id });

						return `<span class="text-muted">Devis n°${quote!.text}</span>`;
					} else if (params.data.typeLine === 'advance') {
						return '<span class="text-muted">Récapitulatif précédente facture</span>';
					} else if (params.data.typeLine === 'leftAmount') {
						return '<span class="text-muted">Montant restant à facturer après facture</span>';
					} else {
						const value = (params.value || '').split('\n');
						let result = '';
						for (const item of value) {
							result += `<div style="line-height: 17px">${item || '&nbsp;'}</div>`;
						}

						return `<div style="margin-top:10px; margin-bottom:10px;" >${result}</div>`;
					}
				}
			}, {
				headerName: 'TVA',
				field: 'tva',
				width: 80,
				suppressSizeToFit: true,
				cellClass: 'text-right text-monospace',
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: {
						data: [
							{
								id: '-',
								text: '-'
							},
							...CE_TVA.getDataToBill()
						],
						dropdownParent: $(this),
						minimumResultsForSearch: Infinity
					}
				},
				cellRenderer: (params) => {
					return CE_TVA.convertValue(params.value).text;
				}
			}, {
				field: 'unit',
				headerName: 'U',
				width: 80,
				suppressSizeToFit: true
			}, {
				field: 'price',
				headerName: 'PU',
				cellClass: 'text-right text-monospace',
				width: 100,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					return params.value ? Decimal.setDisplayNumber(params.value).setSuffixAndHumanizeNumber('€') : '';
				}
			}, {
				headerName: 'Total HT',
				width: 100,
				cellClass: 'text-right text-monospace',
				editable: false,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					if (params.data.price && params.data.quantity) {
						const value = Decimal.setDisplayNumber(params.data.price).times(params.data.quantity).toDecimalPlaces(2);

						return value.setSuffixAndHumanizeNumber('€');
					}

					return '';
				}
			}, {
				field: '#',
				headerName: 'Action',
				width: 100,
				editable: false,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					if (params.node.rowPinned || this._isLock) {
						return '';
					} else {
						const N_delete = document.createElement('button');

						N_delete.classList.add('h-100', 'btn-transparent');

						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
						N_delete.setAttribute('confirmation', '');
						N_delete.setAttribute('tooltip', 'Supprimer');
						N_delete.type = 'button';

						N_delete.addEventListener('click', () => {
							params.api.updateRowData({
								remove: [params.node.data]
							});

							this._update();
						});

						return N_delete;
					}
				}
			}],
			getContextMenuItems: (params) => {
				const detailsQuotes: MenuItemDef = {
					name: 'Ajouter détails devis',
					disabled: this._isLock,
					subMenu: [{
						name: 'Ajouter tous les devis',
						action: async () => {
							const add: { [key: string]: any }[] = [];
							for (const item of this._quotes) {
								add.push({
									typeLine: 'detailsQuote',
									id: item.id,
									tva: '-'
								});
							}

							Aggrid.addLinesAndScroll(params, add);

							this._update();
						}
					}]
				};

				for (const item of this._quotes) {
					(detailsQuotes.subMenu as MenuItemDef[])!.push({
						name: item.text,
						action: async () => {
							Aggrid.addLinesAndScroll(params, [{
								typeLine: 'detailsQuote',
								id: item.id,
								tva: '-'
							}]);

							this._update();
						}
					});
				}

				return [
					{
						name: 'Ajouter ligne',
						disabled: this._isLock,
						action: () => {
							Aggrid.addLinesAndScroll(params, [{
								tva: '-'
							}]);
							this._update();
						}
					}, {
						name: 'Inserer ligne',
						disabled: !params.node || this._isLock,
						action: () => {
							params.api?.applyTransaction({
								add: [{
									tva: '-'
								}],
								addIndex: (params.node.rowIndex || 0) + 1
							});
							this._update();
						}
					},
					detailsQuotes,
					{
						name: 'Ajout récaptitulatif facture',
						disabled: this._isLock,
						action: () => {
							params.api?.applyTransaction({
								add: [{
									typeLine: 'advance',
									tva: '-'
								}]
							});
							this._update();
						}
					}, {
						name: 'Ajout montant restant à facturer',
						disabled: this._isLock,
						action: () => {
							params.api?.applyTransaction({
								add: [{
									typeLine: 'leftAmount',
									tva: '-'
								}]
							});
							this._update();
						}
					}

				];
			},
			onColumnResized: (params) => {
				if (params.finished) {
					params.api.resetRowHeights();
				}
			},
			onCellEditingStarted: (params) => {
				if (params.column.getColId() === 'label') {
					const value = (params.value || '').split('\n');

					params.node.setRowHeight((value.length + 2) * 20);
				}
			},
			onCellEditingStopped: (params) => {
				if (['label'].includes(params.column.getColId())) {
					params.node.setDataValue(params.column.getColId(), params.newValue.trim());
				}

				if (params.column.getColId() === 'reference') {
					S_Products.getInstance().getById(params.data.reference).then((data) => {
						params.node.setDataValue('quantity', 1);
						params.node.setDataValue('label', data.name);
						params.node.setDataValue('unit', data.unit || 'U');
						params.node.setDataValue('brand', data.brand);
						params.node.setDataValue('price', data.price);

						params.api.resetRowHeights();

						params.api.refreshCells({
							force: true,
							rowNodes: [params.node]
						});

						this._update();
					});
				}

				if (params.column.getColId() === 'quantity') {
					const value = Decimal.setDisplayNumber(params.data.quantity).times(100).toDecimalPlaces(2).toString();

					const label = (params.node.data.label || '').replace(/([0-9.]{1,})%/gmi, `${value}%`);

					if (this.type === 'advance') {
						params.node.setDataValue('label', label);
					}

					if (params.data.tva === '-') {
						params.node.setDataValue('tva', '20');
					}

					if (!params.data.unit) {
						params.node.setDataValue('unit', 'U');
					}

					params.api.refreshCells({ force: true, rowNodes: [params.node] });
				}

				if (['price', 'quantity', 'label'].indexOf(params.column.getColId()) !== -1) {
					params.api?.resetRowHeights();
					params.api?.refreshCells({
						force: true,
						rowNodes: [params.node]
					});
				}

				this._update();
			},
			rowData: []
		});

		const N_grid = this.N_el!.querySelector(`#${this.idTab} #grid`) as HTMLElement;

		new Grid(N_grid, this._gridOptions, { modules: AllModules });
	}

	public set data(content: any) {
		this._gridOptions.rowData = content;
		this._gridOptions.api?.setRowData(content);
		this._gridOptions.api?.setPinnedBottomRowData([{}]);
		this.getInfos();
	}

	public get gridOptions() {
		return this._gridOptions;
	}

	public get data(): any {
		const results: any[] = [];

		this._gridOptions.api?.stopEditing();

		this._gridOptions.api?.forEachNode((node: any) => {
			results.push(node.data);
		});

		return results;
	}

	public getInfos() {
		const data = this.data;

		const pinnedRows: any[] = [];

		let globalPriceTTC = new Decimal(0);
		let globalPrice = new Decimal(0);

		let tvaKey = 0;

		for (const item of data) {
			if (item.price) {
				const value = Decimal.setDisplayNumber(item.price).times(item.quantity);

				const tvaValue = CE_TVA.convertValueToCalc(item.tva);

				if (!tvaKey) {
					tvaKey = tvaValue;
				}

				const tmpTVA = Decimal.setDisplayNumber(tvaValue).dividedBy(100);

				globalPrice = globalPrice.plus(value);
				globalPriceTTC = globalPriceTTC.plus(value.times(new Decimal(1).plus(tmpTVA)));
			}
		}

		const formData = this._form();

		if (formData.infos.type === 'last') {
			const tvaPercent = new Decimal(1).plus(new Decimal(tvaKey).dividedBy(100));

			globalPrice = globalPrice.minus(Decimal.setDisplayNumber(formData.infos.advance));
			globalPriceTTC = globalPrice.times(tvaPercent);

			pinnedRows.unshift({
				title: 'Acompte',
				text: `${Decimal.setDisplayNumber(formData.infos.advance).setSuffixAndHumanizeNumber('€')}`
			});
		}

		let globalPriceTVA = globalPriceTTC.minus(globalPrice);

		globalPriceTTC = globalPriceTTC.toDecimalPlaces(2);
		globalPrice = globalPrice.toDecimalPlaces(2);
		globalPriceTVA = globalPriceTVA.toDecimalPlaces(2);

		const str_globalPriceTTC = globalPriceTTC.setSuffixAndHumanizeNumber('€');
		const str_globalPrice = globalPrice.setSuffixAndHumanizeNumber('€');
		const str_tva = globalPriceTVA.setSuffixAndHumanizeNumber('€');

		pinnedRows.push({
			title: 'Total HT',
			text: `${str_globalPrice}`
		});

		pinnedRows.push({
			title: 'TVA',
			text: `${str_tva}`
		});

		pinnedRows.push({
			title: 'Total TTC',
			text: `${str_globalPriceTTC}`
		});

		this._gridOptions.api!.setPinnedBottomRowData([{ value: pinnedRows }]);

		return {
			globalPrice,
			globalPriceTTC,
			globalPriceTVA
		};
	}

	public updateDateAvancement(oldDate: Moment, newDate: Moment) {
		this.gridOptions.api?.forEachNode((node) => {
			const label = (node.data.label || '').replace(new RegExp(oldDate.format('DD/MM/YY'), 'gmi'), newDate.format('DD/MM/YY'));
			node.setDataValue('label', label);
		});
	}

	public set quotes(quotes: { [key: string]: string }[]) {
		this._quotes = quotes;
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public setGetForm(cb: () => { [key: string]: any }) {
		this._form = cb;
	}

	private get type() {
		return this._form().infos.type;
	}

	public static register() {
		customElements.define(ContentDeliveriesTab.tagName, ContentDeliveriesTab);
	}
}

export default ContentDeliveriesTab;
