// CORE
import { Alert, toaster } from '@autoprog/core-client';

// NODE_MODULE
import { GetContextMenuItemsParams } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import moment from 'moment';

// TEMPLATE
// LIBS
import C_Database from '@js/controllers/Database';

import C_BillsCustomer from '../controllers/Bills.Customer';

// UTILS
import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/Utils';

// MODAL
import M_AddPayment from '../modals/AddLinePaymentBill';
import M_SelectCustomerToPrint from '../modals/SelectCustomerToPrint';
import M_SetRelunchDate from '@libs/modals/SetRelunchDate';
import M_SetSendDate from '@libs/modals/SetSendDate';
import M_exportCompta from '../modals/customers/ExportCompta';
import M_optionBill from '../modals/customers/OptionBill';

import M_PrintPreview from '@libs/modals/PrintPreview';

// CUSTOM_ELEMENT
import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

// SERVICE
import S_C_Bill from '@services/Customer/CustomerBillService';
import S_Quote from '@services/QuoteService';

// EXPORTS CSV
import Export from '@libs/export/Export';
import ExportUtils from '../../../../js/libs/utils/ExportUtils';

class Bill extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Factures - Clients',
			type: 'database',
			database: 'bills'
		});
	}

	protected header() {
		const N_div = document.createElement('div');

		N_div.classList.add('d-flex', 'flex-grow-1');

		N_div.innerHTML = `
			<div class="ml-auto d-flex">

				<div class="border-right px-2 d-flex">

					<button class="btn btn-export-csv" id="export_compta" tooltip="Export des écritures comptables en CSV" permission="BILLS._CUSTOMERS._COMPTA.EXPORT">
						<i class="icon icon-solid-file-invoice"></i>
					</button>
					
					<button class="btn btn-export-csv ml-2" permission="BILLS._CUSTOMERS.EXPORT" type="button" id="export_excel" tooltip="Exporter les données en CSV">
						<i class="icon icon-solid-file-csv"></i>
					</button>

				</div>

				<div class="ml-2 d-flex">

					<div class="dropdown d-inline-flex h-100" >
						<button class="btn btn-info" type="button" data-toggle="dropdown">
							<i class="icon icon-printer mr-2"></i>
							Imprimer
							<i class="icon icon-ri-arrow-drop-down-line ml-2"></i>
						</button>
						<div class="dropdown-menu mt-2" style="border-radius:5px">
							<div class="dropdown-item cursor-pointer" id="recap">Récapitulatif</div>
							<div class="dropdown-item cursor-pointer" id="byCustomer">Relevé</div>
						</div>
					</div>

					<button class="btn btn-settings ml-2" id="default_value" permission="BILLS._CUSTOMERS.DEFAULT_SETTINGS" tooltip="Paramètres par défaut">
						<i class="icon icon-cog"></i>
					</button>

				</div>

			</div>
   		`;

		const N_default_value = N_div.querySelector('#default_value') as HTMLButtonElement;
		N_default_value.addEventListener('click', () => {
			new M_optionBill().open();
		});

		const N_export_compta = N_div.querySelector('#export_compta') as HTMLButtonElement;
		N_export_compta.addEventListener('click', () => {
			new M_exportCompta().open();
		});

		const N_recap = N_div.querySelector('#recap') as HTMLElement;
		const N_byCustomer = N_div.querySelector('#byCustomer') as HTMLElement;
		const N_exportElement = N_div.querySelector('#export_excel') as HTMLButtonElement;

		N_recap.addEventListener('click', async () => {
			(await new M_PrintPreview('bills', '', 'pdfList').getToken(this.getDataToPrint())).open();
		});

		N_byCustomer.addEventListener('click', () => {
			new M_SelectCustomerToPrint().open();
		});

		N_exportElement.addEventListener('click', () => {
			const csvString = new Export()
				.included(['infos.customer', 'infos.label', 'infos.number', 'infos.commandCustomer', 'infos.date', 'infos.sendDate', 'infos.datePayment', 'relunch.date', 'relunch.number', 'infos.type', 'globalPrice', 'globalPriceTVA', 'globalPriceTTC', 'paymentPrice', 'notPaymentPrice', 'paid.state', 'send.state'])
				.fromTableName(this.tableName)
				.fromGridOptions(this.gridOptions)
				.createCSV();

			const a = ExportUtils.createFileLink(csvString);
			a.download = `Export-Factures-Client-${moment().format('DD-MM-YYYY')}`;
			a.click();
			a.remove();
		});

		return N_div;
	}

	private getDataToPrint() {
		const data: { [key: string]: any }[] = [];

		this.gridOptions.api.forEachNodeAfterFilterAndSort((node: any) => {
			const tmp = _.cloneDeep(node.data);

			data.push(tmp._id);
		});

		return data;
	}

	protected buttonEdit(params: any) {
		C_BillsCustomer.open(params.data._id);
	}

	protected iconsColumn(params: any) {
		const N_open = document.createElement('ap-open-document') as HTMLElement;
		N_open.dataset.id = params.data._id;
		N_open.dataset.table = 'bills';

		return [N_open];
	}

	protected getRowStyle(params: any): { [key: string]: string } {
		if (params.data) {
			//payer à 100%
			if (params.data.stateColor === '100%') {
				return {
					'background-color': 'var(--ap-green-50)',
					color: 'var(--ap-green-900)'
				};
			}

			//paiement en retard
			if (params.data.stateColor === 'relunch') {
				return {
					'background-color': 'var(--ap-blue-50)',
					color: 'var(--ap-blue-900)'
				};
			}

			//en cours de paiement
			if (params.data.stateColor === 'payment') {
				return {
					'background-color': 'var(--ap-orange-50)',
					color: 'var(--ap-orange-900)'
				};
			}

			//envoyé
			if (params.data.stateColor === 'send') {
				return {
					'background-color': 'var(--ap-purple-50)',
					color: 'var(--ap-purple-900)'
				};
			}
		}

		return {
			'background-color': 'white'
		};
	}

	protected onDataUpdate(params: any) {
		let priceTTC = new Decimal(0);
		let priceHT = new Decimal(0);
		let pricePayment = new Decimal(0);
		let toLatePayment = new Decimal(0);

		const lengthSelectedRows = params.api.getSelectedRows().length;

		params.api.forEachNodeAfterFilter((node: any) => {
			if ((lengthSelectedRows && node.isSelected()) || (!lengthSelectedRows)) {
				priceTTC = priceTTC.plus(node.data.globalPriceTTC.value);
				priceHT = priceHT.plus(node.data.globalPrice.value);

				pricePayment = pricePayment.plus(node.data.paymentPrice.value);

				if (node.data.paid.isToLate) {
					toLatePayment = toLatePayment.plus(new Decimal(node.data.globalPriceTTC.value).minus(node.data.paymentPrice.value));
				}
			}
		});

		const notPaid = priceTTC.minus(pricePayment).toDecimalPlaces(2);
		const tva = priceTTC.minus(priceHT).toDecimalPlaces(2);

		priceHT = priceHT.toDecimalPlaces(2);
		priceTTC = priceTTC.toDecimalPlaces(2);
		pricePayment = pricePayment.toDecimalPlaces(2);
		toLatePayment = toLatePayment.toDecimalPlaces(2);

		const values = [{
			text: 'Montant HT',
			price: priceHT.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Montant TVA',
			price: tva.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Montant TTC',
			price: priceTTC.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Montant TTC payé',
			price: pricePayment.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Montant TTC à payer',
			price: notPaid.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Dont en retard (TTC)',
			price: toLatePayment.setSuffixAndHumanizeNumber('€')
		}];

		if (lengthSelectedRows) {
			values.unshift({
				text: `Factures sélectionnées (${lengthSelectedRows})`,
				price: ''
			});
		}

		this.setDataTitle(values);
	}

	protected getContextMenu(params: GetContextMenuItemsParams) {
		if (params.node) {
			const priceTotal = Decimal.setDisplayNumber(params.node.data.globalPriceTTC.value).toDecimalPlaces(2);
			const pricePayment = Decimal.setDisplayNumber(params.node.data.paymentPrice.value).toDecimalPlaces(2);

			params.node.data.relunch = params.node.data.relunch || {};

			return [
				...super.getContextMenu(params),
				'separator',
				{
					name: 'Définir date d\'envoi',
					icon: '<i class="icon icon-envelope"></i>',
					disabled: params.node.data.infos.sendDate,
					action: async () => {
						const modal = new M_SetSendDate('bills', params.node.data._id);

						modal.setAddonButton(async (N_addon) => {
							const N_button = document.createElement('button');
							N_button.classList.add('btn', 'btn-info');
							N_button.innerHTML = `
								<i class="icon icon-printer mr-2"></i>
								Imprimer les devis        
							`;

							N_button.addEventListener('click', async () => {
								const data = await S_C_Bill.getInstance().getById(params.node.data._id);

								const quotes = data.infos.quotes as any[];

								if (quotes.length > 1) {
									const data: { [key: string]: string }[] = [];

									for (const item of quotes) {
										const tmp = await S_Quote.getInstance().getDataToSelect2ByID(item);

										data.push({
											name: tmp.text,
											value: tmp.id
										});
									}

									const res = await Alert.prompt('Sélectionner le devis a imprimer', '', {
										type: 'select',
										values: data as any
									});

									new M_PrintPreview('quotes', res as string).open();
								} else {
									new M_PrintPreview('quotes', quotes[0]).open();
								}
							});

							N_addon.appendChild(N_button);
						});
						modal.open().then(() => {
							this.refreshData();
						});
					}
				},
				{
					name: 'Définir prochaine relance',
					icon: '<i class="icon icon-solid-redo"></i>',
					disabled: priceTotal.equals(pricePayment) || moment(params.node.data.relunch.date || params.node.data.infos.datePayment, 'x').isSameOrAfter(moment(), 'day'),
					action: async () => {
						new M_SetRelunchDate('bills', params.node.data._id).open().then(() => {
							this.refreshData();
						});
					}
				}, 'separator', {
					name: 'Ajout paiement',
					icon: '<i class="icon icon-solid-euro-sign"></i>',
					disabled: priceTotal.equals(pricePayment),
					action: async () => {
						new M_AddPayment('bills', params.node.data._id).open().then(async (payment) => {
							const data = await S_C_Bill.getInstance().getById(params.node.data._id);

							data.payment = data.payment || [];

							data.payment.push(payment);

							await S_C_Bill.getInstance().save(data);

							toaster.success('Sauvegarde réussi');

							this.refreshData();
						});
					}
				}, {
					name: 'Créer un avoir',
					disabled: params.node.data.isCredit,
					action: () => {
						C_BillsCustomer.open(null, { type: 'credit', idBill: params.node.data._id, idCommand: params.node.data.infos.idCommand });
					}
				}
			];
		} else {
			return [];
		}
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: '_createBy_',
					type: 'switch',
					filters: [
						{
							value: Utils.userID,
							text: 'Facture personnelle'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'relunch.state',
					type: 'switch',
					filters: [{
						value: 'true',
						text: 'À relancer',
						color: 'blue-200'
					}]
				}, {
					column: 'paid.isToLate',
					type: 'switch',
					filters: [{
						value: 'true',
						text: 'Retard'
					}]
				}, {
					type: 'separator'
				}, {
					type: 'title',
					title: 'État de l\'envoi : '
				}, {
					column: 'send.state',
					type: 'checkbox',
					filters: [{
						value: '0',
						text: 'Non envoyée',
						color: 'grey-200'
					}, {
						value: '1',
						text: 'Envoyée',
						color: 'purple-200'
					}]
				}, {
					type: 'separator'
				}, {
					type: 'title',
					title: 'État paiement : '
				}, {
					column: 'paid.state',
					type: 'checkbox',
					filters: [{
						value: '0',
						text: 'Pas commencé'
					}, {
						value: '1',
						text: 'En cours de paiement',
						color: 'orange-200'
					}, {
						value: '2',
						text: 'Payée à 100%',
						color: 'green-200'
					}]
				}
			]
		];
	}
}

export default Bill;
