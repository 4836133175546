import { LoggedUser, global } from '@autoprog/core-client';

import Product from '@js/types/product/product';

import GenericService from '../GenericService';

import axios from 'axios';

class ProductService extends GenericService {
	private static instance: ProductService | null = null;

	constructor() {
		super('products');
	}

	public static getInstance(): ProductService {
		if (!ProductService.instance) {
			ProductService.instance = new ProductService();
		}

		return ProductService.instance;
	}

	public get referenceKey() {
		return global.CONFIG.products.referenceKey || '';
	}

	public get columnNameReference() {
		return global.CONFIG.products.columnNameReference || '';
	}

	public cellRendererReference(value: string) {
		const N_div = document.createElement('div');

		N_div.classList.add('h-100', 'w-100');

		if (value) {
			this.getById(value).then((data) => {
				const N_cell = N_div.parentNode as HTMLElement;
				const N_row = N_cell.parentNode as HTMLElement;

				if (data.deleted) {
					if (!N_row.style.backgroundColor) {
						N_row.style.backgroundColor = 'var(--ap-orange-50)';
						N_row.style.color = 'var(--ap-orange-900)';
					}
				} else {
					if (N_row.style.backgroundColor === 'var(--ap-orange-50)') {
						N_row.style.backgroundColor = '';
						N_row.style.color = '';
					}
				}

				const loggedUser = LoggedUser.getInstance();

				if (data.url_photo && !loggedUser.get('hiddenProductPhoto')) {
					N_div.setAttribute('popover', JSON.stringify({ content: `<ap-product-image-previewer src="${data.base64_photo || this.getImageFromURL(data.url_photo)}"></ap-product-image-previewer>`, trigger: 'hover', placement: 'bottom', sanitize: false }));
				}

				N_div.innerHTML = data[this.referenceKey];
			}).catch(() => {
				N_div.innerHTML = value || '';
			});
		} else {
			N_div.innerHTML = value || '';
		}

		return N_div;
	}

	public cellRendererByData(data: { [key: string]: string }) {
		const N_div = document.createElement('div');

		N_div.classList.add('h-100', 'w-100');

		N_div.innerHTML = data[this.referenceKey] || '';

		const loggedUser = LoggedUser.getInstance();

		if (data.url_photo && !loggedUser.get('hiddenProductPhoto')) {
			N_div.setAttribute('popover', JSON.stringify({ content: `<ap-product-image-previewer src="${data.base64_photo || this.getImageFromURL(data.url_photo)}"></ap-product-image-previewer>`, trigger: 'hover', placement: 'bottom', sanitize: false }));
		}

		return N_div;
	}

	public getImageFromURL(url_photo: string) {
		if (url_photo.includes('dropbox.com/')) {
			url_photo = url_photo.replace(/(\?dl=0)$/gmi, '');
			url_photo = url_photo + '?raw=1';
		}

		return url_photo;
	}

	public async getLastOrderProvider(id: string) {
		const { data } = await axios.get(`${this.uriFunction}/getLastOrderProvider`, { params: { id } });
		if (data.ok) {
			return data.data;
		} else {
			throw new Error(data.err);
		}
	}

	//FIXME: sert pour l'import a voir si sert toujjours après migration
	public async getProductMap(): Promise<Map<string, Product>> {
		const productMap: Map<string, Product> = new Map();
		const productList = await this.getAll();

		productList.forEach((product: any) => {
			productMap.set(product._id, product);
		});

		return productMap;
	}

	/**
	* Retourne la clé a exporter pour les données avec une référence
	* @returns le nom de la clé
	*/
	public getExportKey(): string {
		return this.referenceKey;
	}
}

export default ProductService;
