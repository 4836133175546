import Modal from '@libs/Modal';

import T_modal from '../../tpl/modals/quoteOptions.html';

import CE_QuoteOption from '../libs/customElement/QuoteOption';

class QuoteOptions extends Modal {
	constructor(data: { [key: string]: any }) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_quoteOption = this.element.querySelector(CE_QuoteOption.tagName) as CE_QuoteOption;

			N_quoteOption.setParentElement(this.element);

			N_quoteOption.quoteOptions = data.quoteOptions;

			data.pref.decimalNumber = data.pref.decimalNumber || '0';

			N_quoteOption.data = data.pref;

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				this.resolve(N_quoteOption.data);
			});
		});
	}
}

export default QuoteOptions;
