import { Module, Modules, Router, Settings, global } from '@autoprog/core-client';

import Navigation from '@libs/Navigation';
import NavigationMobile from '@libs/NavigationMobile';

import C_ControllerDatabase from '@js/controllers/ControllerDatabase';

import C_Contacts from './js/controllers/Contacts';
import C_Home from './js/controllers/Home';
import C_Interventions from './js/controllers/Interventions';
import C_Notifications from './js/controllers/Notifications';

//TABS
import C_SettingsBank from './js/tabs/Settings.Bank';
import C_SettingsCompanies from './js/tabs/Settings.Companies';
import C_SettingsCompta from './js/tabs/Settings.Compta';
import C_SettingsGeneral from './js/tabs/Settings.General';
import C_SettingsNotification from './js/tabs/Settings.Notification';

import T_Database from '@tpl/database.html';
import T_Home from './tpl/home.html';
import T_HomeMobile from './tpl/home.mobile.html';

import T_SettingsBank from './tpl/tabs/Settings.Bank.html';
import T_SettingsCompanies from './tpl/tabs/Settings.Companies.html';
import T_SettingsComtpa from './tpl/tabs/Settings.Compta.html';
import T_SettingsGeneral from './tpl/tabs/Settings.General.html';
import T_SettingsNotification from './tpl/tabs/Settings.notification.html';

import Notifications from './js/libs/Notifications';

import M_Interventions from './js/modals/Interventions';

//CUSTOM_ELEMENT
import CE_Commands from './js/libs/customElement/Commands';
import CE_GlobalInformations from './js/libs/customElement/GlobalInformations';
import CE_Interventions from './js/libs/customElement/quickAccess/Interventions';
import CE_Quotes from './js/libs/customElement/quickAccess/Quotes';
import CE_QuotesToDo from './js/libs/customElement/quickAccess/QuotesToDo';
import CE_RecentlyAccessed from './js/libs/customElement/RecentlyAccessed';
import CE_Stock from './js/libs/customElement/quickAccess/Stock';

import './css/notif.scss';
import './css/home.mobile.scss';
import './css/home.tactile.scss';

class AppsModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_GlobalInformations.register();
		CE_Commands.register();
		CE_RecentlyAccessed.register();
		CE_Interventions.register();
		CE_QuotesToDo.register();
		CE_Quotes.register();
		CE_Stock.register();
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addLink({
			order: 0,
			name: 'Accueil',
			type: 'module',
			module: 'apps/home',
			permissions: ['HOME'],
			icon: 'home-2/fill'
		});
		navigation.addCategory({
			name: 'Données',
			order: 0
		});
		navigation.addLink({
			order: 3,
			category: 'Données',
			name: 'Contacts',
			type: 'module',
			module: 'apps/contacts',
			permissions: ['CONTACTS'],
			icon: 'contacts-book/fill'
		});
		navigation.addCategory({
			name: 'Documents',
			order: 100
		});
		navigation.addLink({
			order: 4,
			category: 'Documents',
			name: 'Interventions',
			type: 'module',
			module: 'apps/interventions',
			permissions: ['INTERVENTIONS'],
			icon: 'map-pin-2/line'
		});

		NavigationMobile.getInstance().addLink({
			icon: 'icon-solid-map-marker-alt',
			name: 'Interventions',
			order: 7,
			dataset: {
				link: 'interventions'
			}
		});

		C_SettingsNotification.addInput({
			text: 'Interventions',
			select: [
				{
					text: 'Informations',
					name: 'interventions.infos'
				}
			]
		}, 1);

		Notifications.addCategory(['interventions'], {
			textFilter: 'Interventions',
			isFilter: true,
			icon: 'icon-solid-map-marker-alt',
			action: async (item: { [key: string]: any }) => {
				if (item.data.id) {
					new M_Interventions(item.data.id).open();
				}
			}
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/apps/home',
			controller: C_Home as any,
			tpl: global.IS_MOBILE ? T_HomeMobile : T_Home,
			default: true,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/apps/interventions',
			controller: C_ControllerDatabase,
			template: T_Database,
			data: {
				instance: C_Interventions
			},
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/apps/contacts',
			controller: C_ControllerDatabase,
			template: T_Database,
			data: {
				instance: C_Contacts
			},
			tpl: T_Database,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/apps/notification',
			controller: C_ControllerDatabase,
			template: T_Database,
			data: {
				instance: C_Notifications
			},
			tpl: T_Database,
			requireValidUser: true
		});

		const settings = Settings.getInstance();

		const page = settings.getPageById('MAIN');

		if (page) {
			page.addTab({
				id: 'settingsApps',
				name: 'Application',
				controller: C_SettingsGeneral,
				template: T_SettingsGeneral,
				requireValidUser: true
			});

			page.addTab({
				id: 'settingsCompanies',
				name: 'Sociétés',
				controller: C_SettingsCompanies,
				template: T_SettingsCompanies,
				requireValidUser: true
			});

			page.addTab({
				id: 'settingsNotification',
				name: 'Notifications',
				controller: C_SettingsNotification,
				template: T_SettingsNotification,
				requireValidUser: true
			});

			page.addTab({
				id: 'settingsBank',
				name: 'Banques',
				controller: C_SettingsBank,
				template: T_SettingsBank,
				requireValidUser: true
			});

			page.addTab({
				id: 'settingsCompta',
				name: 'Comptabilité',
				controller: C_SettingsCompta,
				template: T_SettingsComtpa,
				requireValidUser: true
			});
		}
	}

	public async postInit() {
		new Notifications();
	}
}

Modules.registerModule('Apps', AppsModule);
