import { global } from '@autoprog/core-client';

import Decimal from 'decimal.js';
import _ from 'lodash';

type Value = Decimal.Value | DecimalApps;

class DecimalApps {
	private value: Decimal;
	private rawValue: Value;

	constructor(n: Value) {
		this.rawValue = n;
		this.value = new Decimal(this.convertToDecimalJS(Number(n) || 0));
	}

	public static setDisplayNumber(text: string | number = '') {
		text = text.toString();
		text = text.replace(',', '.');
		text = text.replace(/\s/gmi, '');
		text = text.replace(/(€.*)/gmi, '');

		const value = Number(text);

		return new DecimalApps(value);
	}

	public setSuffixAndHumanizeNumber(suffix: string, numberDecimal: number | string = 2) {
		return this.setSuffixStr(this.humanizeNumber(numberDecimal), suffix);
	}

	/**
	 * Met en forme le nombre avec la précision maximale
	 * */
	public setSuffixAndHumanizeNumberWithPrecision(suffix: string) {
		let decimalNumber = 0;
		this.getNumberDecimal() > 2 ? decimalNumber = this.getNumberDecimal() : decimalNumber = 2;
		return this.setSuffixStr(this.humanizeNumber(decimalNumber), suffix);
	}

	public humanizeNumberWithPrecision() {
		let decimalNumber = 0;
		this.getNumberDecimal() > 2 ? decimalNumber = this.getNumberDecimal() : decimalNumber = 2;
		return this.humanizeNumber(decimalNumber);
	}

	private setSuffixStr(value: string | number, suffix: string) {
		if (value === undefined || value === null) {
			value = '';
		}

		value = value.toString();

		if (value) {
			return value + suffix;
		}

		return value;
	}

	public setSuffix(suffix: string) {
		const value = this.value.toString();

		if (value) {
			return value + suffix;
		}

		return value;
	}

	public humanizeNumber(numberDecimal: number | string = 2) {
		const numberStr = Number(this.value.toNumber().toFixed(6)).toString();
		numberDecimal = Number(numberDecimal);

		let result = '';

		if (!isNaN(Number(this.rawValue))) {
			const chunkNumber = numberStr.split('.');

			const tmp = chunkNumber[0].split('').reverse();

			// Ajoute les espaces sur les milliers (1000 => 1 000)
			for (let i = tmp.length - 1; i >= 0; i--) {
				result += tmp[i];

				if (i % 3 === 0 && i !== 0) {
					result += ' ';
				}
			}

			// Ajoute les décimales
			if (numberDecimal === -1) {
				chunkNumber[1] = chunkNumber[1] || '';

				if (chunkNumber[1].length) {
					result = result + global.DECIMAL_SEPARATOR + chunkNumber[1];
				}
			} else if (numberDecimal) {
				chunkNumber[1] = chunkNumber[1] || '';

				chunkNumber[1] = (chunkNumber[1] + _.fill(new Array(numberDecimal), '0').join('')).slice(0, numberDecimal);

				result = result + global.DECIMAL_SEPARATOR + chunkNumber[1];
			}
		} else {
			result = '<span class="font-weight-bold text-danger">!ERR</span>';
		}

		return result;
	}

	public humanizePercent(ignoreDecimal = false) {
		const array = this.value.toString().replace('NaN', '0').split('.');
		array[0] = (array[0] || '0');
		array[1] = (array[1] || '');

		let value = `${('###' + array[0]).slice(-3)}${global.DECIMAL_SEPARATOR}${(array[1] + '00').slice(0, 2)}`;

		if (ignoreDecimal) {
			value = `${('###' + array[0]).slice(-3)}`;
		}

		return value.replace(/#/gmi, '&nbsp;');
	}

	private convertToDecimalJS(n: Value): Decimal {
		if (n instanceof DecimalApps) {
			return n.value;
		}

		return n as Decimal;
	}

	public plus(n: Value): DecimalApps {
		return new DecimalApps(this.value.plus(this.convertToDecimalJS(n)));
	}

	public minus(n: Value): DecimalApps {
		return new DecimalApps(this.value.minus(this.convertToDecimalJS(n)));
	}

	public times(n: Value) {
		return new DecimalApps(this.value.times(this.convertToDecimalJS(n)));
	}

	public dividedBy(n: Value) {
		return new DecimalApps(this.value.dividedBy(this.convertToDecimalJS(n)));
	}

	public toDecimalPlaces(n: number) {
		return new DecimalApps(this.value.toDecimalPlaces(n));
	}

	public toString() {
		return this.value.toString();
	}

	public toNumber() {
		return this.value.toNumber();
	}

	public toExcel() {
		return this.value.toNumber().toString().replace(/\./gmi, global.DECIMAL_SEPARATOR);
	}

	public valueOf() {
		return this.value.valueOf();
	}

	public equals(n: Value) {
		return this.value.equals(this.convertToDecimalJS(n));
	}

	public greaterThan(n: Value) {
		return this.value.greaterThan(this.convertToDecimalJS(n));
	}

	public lessThan(n: Value) {
		return this.value.lessThan(this.convertToDecimalJS(n));
	}

	/**
	 * Revoie le nombre de décimal significative de la valeur
	*/
	public getNumberDecimal(): number {
		if (Math.floor(this.value.toNumber()) === this.value.toNumber()) {
			return 0;
		} else {
			return this.value.toString().split('.')[1].length || 0;
		}
	}
}

export default DecimalApps;
