// CORE
import { Alert, LoggedUser, Router, toaster, utils } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
// LIBS
import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';
import Decimal from '@libs/utils/Decimal';
import Loader from '@libs/Loader';
import ModalManager from '@managers/ModalManager';
import OpenDocuments from '@libs/customElement/OpenDocuments';
import SettingsApps from '@libs/Settings';

// UTILS

// MODAL
import M_AdditionalInformation from '../modals/customers/editPage/AdditionalInformation';
import M_Advance from '../modals/Advance';
import M_Credit from '../modals/Credit';
import M_Dates from '../modals/customers/editPage/Dates';
import M_DetailsBill from '../modals/customers/editPage/DetailsBill';
import M_GeneralInformation from '../modals/customers/editPage/GeneralInformation';
import M_Last from '../modals/Last';

// CUSTOM_ELEMENT
import CE_SitesFinalCustomerReadonly from '@libs/customElement/Sites-FinalCustomer-Readonly';
import CE_TVA from '@libs/customElement/TVA';

import CE_BillingRequests from '@modules/Commands/js/libs/customElement/customers/BillingRequest';

import CE_CGVTab from '@libs/customElement/CGVTab';

import CE_BillPrintOption from '../libs/customElement/BillPrintOption';
import CE_ComptaTab from '../libs/customElement/ComptaTab';
import CE_ContentTab from '../libs/customElement/ContentTab';
import CE_PaymentTab from '@modules/Bills/js/libs/customElement/Payment';

// SERVICE
import S_C_Bill from '@services/Customer/CustomerBillService';
import S_C_Order from '@services/Customer/CustomerOrderService';

import C_OrdersCustomer from '@modules/Commands/js/controllers/Command.Customer';
import C_Quotes from '@modules/Quotes/js/controllers/AddQuotes';
import M_PrintPreview from '@js/libs/modals/PrintPreview';

class BillsCustomerCtrl extends ControllerPageID {
	private N_PaymentTab: CE_PaymentTab | null = null;
	private N_CGVTab: CE_CGVTab | null = null;
	private N_PrintOptionTab: CE_BillPrintOption | null = null;
	private N_BillingRequestTab: CE_BillingRequests | null = null;
	private N_ComptaTab: CE_ComptaTab | null = null;
	private N_ContentTab: CE_ContentTab | null = null;

	private quotes: { [key: string]: string }[] = [];

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.options = BillsCustomerCtrl.options || {};

		BillsCustomerCtrl.options = {};

		this.routeReturn = 'module/bills/customers';

		this.init('bills', id);
	}

	private static options: { [key: string]: any } = {};
	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		BillsCustomerCtrl.options = options || {};

		if (id) {
			await OpenDocuments.checkOpen(id, 'bills');
			Router.getInstance().navigate(`/module/billsPage/customer?id=${id}`);
		} else {
			Router.getInstance().navigate('/module/billsPage/customer');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		this.initTabs();

		await this.fromOrder();

		const data = await this.getData();

		this.setData(data);

		this.postInit();

		this.initFullscreen();
		this.initEditButton();

		this.updateEditButton();

		if (!this.id) {
			this.updateSaveButton();
		}
	}

	private initEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsBill = this.el.querySelector('[data-edit="detailsBill"]') as HTMLButtonElement;
		const N_edit_Dates = this.el.querySelector('[data-edit="dates"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			const res = {
				infos: {
					customer: this.form?.getDataByName('infos.customer') as string,
					contact: this.form?.getDataByName('infos.contact') as string,
					label: this.form?.getDataByName('infos.label') as string
				}
			};

			new M_GeneralInformation(res).open().then((data) => {
				this.setDataForm(data);
				this.updateTitle();
				this.updateSaveButton();
			});
		});

		N_edit_DetailsBill.addEventListener('click', () => {
			const res = {
				infos: {
					number: this.form?.getDataByName('infos.number') as string,
					index: this.form?.getDataByName('infos.index') as string,
					type: this.form?.getDataByName('infos.type') as string,
					quotes: this.form?.getDataByName('infos.quotes') as string
				}
			};

			new M_DetailsBill(res).open().then((data) => {
				this.setDataForm(data);
				this.updateTitle();
				this.updateSaveButton();
			});
		});

		N_edit_Dates.addEventListener('click', () => {
			const res = {
				infos: {
					date: this.form?.getDataByName('infos.date') as Moment,
					datePayment: this.form?.getDataByName('infos.datePayment') as Moment,
					sendDate: this.form?.getDataByName('infos.sendDate') as Moment,
					number: this.form?.getDataByName('infos.number') as string,
					deadlinePayment: this.form?.getDataByName('infos.deadlinePayment') as string,
					typeDeadlinePayment: this.form?.getDataByName('infos.typeDeadlinePayment') as string,
					fixedDateDeadline: this.form?.getDataByName('infos.fixedDateDeadline') as Moment
				},
				relunch: {
					date: this.form?.getDataByName('relunch.date') as Moment
				}
			};

			new M_Dates(res).open().then((data) => {
				this.N_ContentTab!.updateDateAvancement(this.form?.getDataByName('infos.date') as Moment, data.infos.date);

				this.setDataForm(data);
				this.updateTitle();
				this.updateEditButton();
				this.updateSaveButton();
			});
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			const res = {
				infos: {
					customer: this.form?.getDataByName('infos.customer') as string,
					addressID: this.form?.getDataByName('infos.addressID') as string,
					fullAddress: this.form?.getDataByName('infos.fullAddress') as string,
					addressIDDelivery: this.form?.getDataByName('infos.addressIDDelivery') as string,
					fullAddressDelivery: this.form?.getDataByName('infos.fullAddressDelivery') as string,
					deadlinePayment: this.form?.getDataByName('infos.deadlinePayment') as string,
					typeDeadlinePayment: this.form?.getDataByName('infos.typeDeadlinePayment') as string,
					fixedDateDeadline: this.form?.getDataByName('infos.fixedDateDeadline') as Moment,
					autoliquidation: this.form?.getDataByName('infos.autoliquidation') as boolean,
					description: this.form?.getDataByName('infos.description') as string,
					commentFinish: this.form?.getDataByName('infos.commentFinish') as string,
					comments: this.form?.getDataByName('infos.comments') as string,
					finalCustomer: this.form?.getDataByName('infos.finalCustomer') as string,
					hasFinalCustomer: this.form?.getDataByName('infos.hasFinalCustomer') as string,
					sites: this.form?.getDataByName('infos.sites') as string[],
					siteCustom: this.form?.getDataByName('infos.siteCustom') as string,
					number: this.form?.getDataByName('infos.number') as string,
					datePayment: this.form?.getDataByName('infos.datePayment') as Moment,
					sendDate: this.form?.getDataByName('infos.sendDate') as Moment,
					email: this.form?.getDataByName('infos.email') as string
				},
				relunch: {
					date: this.form?.getDataByName('relunch.date') as Moment
				}
			};

			new M_AdditionalInformation(res).open().then((data) => {
				this.setDataForm(data);
				this.updateTitle();
				this.updateSaveButton();

				const N_sites = this.el.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
				N_sites.update(data.infos.hasFinalCustomer);
			});
		});
	}

	protected initButton() {
		super.initButton();

		const N_printQuotes = this.el.querySelector('#print_quotes') as HTMLButtonElement;

		N_printQuotes.addEventListener('click', async () => {
			if (this.quotes.length > 1) {
				const values: { [key: string]: string }[] = [];

				for (const item of this.quotes) {
					values.push({
						name: item.text,
						value: item.id
					});
				}

				const res = await Alert.prompt('Sélectionner le devis a imprimer', '', {
					type: 'select',
					values: values as any
				});

				new M_PrintPreview('quotes', res as string).open();
			} else {
				new M_PrintPreview('quotes', this.quotes[0].id).open();
			}
		});

		const N_openOrderButton = this.el.querySelector('#open_order') as HTMLElement;

		N_openOrderButton.addEventListener('click', () => {
			const idOrder = this.form?.getDataByName('infos.command');
			C_OrdersCustomer.open(idOrder);
		});

		const N_openQuotesButton = this.el.querySelector('#open_quotes') as HTMLElement;

		N_openQuotesButton.addEventListener('click', async () => {
			if (this.quotes.length > 1) {
				const values: { [key: string]: string }[] = [];

				for (const item of this.quotes) {
					values.push({
						name: item.text,
						value: item.id
					});
				}

				const res = await Alert.prompt('Sélectionner le devis a ouvrir', '', {
					type: 'select',
					values: values as any
				});

				C_Quotes.open(res as string);
			} else {
				C_Quotes.open(this.quotes[0].id);
			}
		});
	}

	private async fromOrder() {
		if (this.options.idCommand) {
			try {
				Loader.getInstance().open();

				const tmp = (await S_C_Bill.getInstance().getDataToModal(this.id, { ...this.options, from: 'order' })).data;

				Loader.getInstance().close();

				if (!tmp.hasBillAddress) {
					toaster.info('Aucune adresse de facturation renseigné');

					await ModalManager.getInstance().open('customers', tmp.infos.customer);
				}

				if (this.options.type === 'last') {
					const settings = (SettingsApps.getInstance().get('BILL') || {}) as { [key: string]: any };

					if (settings?.display?.modalQuotes) {
						const res = await new M_Last(tmp.infos.quotes).open();
						this.options.last = res;
					} else {
						this.options.last = {};
					}
				}

				if (this.options.type === 'advance' && !this.options.ignoreModalAdvance) {
					this.options.advance = await new M_Advance(tmp, 'customer').open();
				}

				if (this.options.type === 'credit') {
					this.options.credit = await new M_Credit(tmp).open();
				}

				this.updateSaveButton();
			} catch (e) {
				this.return();
				throw new Error('');
			}
		}
	}

	private initTabs() {
		this.initBillingRequest();
		this.initContent();
		this.initPayment();
		this.initCGV();
		this.initPrintOption();
		this.initCompta();
	}

	private async initBillingRequest() {
		this.N_BillingRequestTab = this.el.querySelector(CE_BillingRequests.tagName) as CE_BillingRequests;

		this.N_BillingRequestTab.setMode('bill');

		this.N_BillingRequestTab.setParentElement(this.el);
	}

	private initContent() {
		this.N_ContentTab = this.el.querySelector(CE_ContentTab.tagName) as CE_ContentTab;

		this.N_ContentTab.setParentElement(this.el);

		this.N_ContentTab!.setOnUpdate(() => {
			this.updateInfos();
			this.updateSaveButton();
		});

		this.N_ContentTab!.setGetForm(() => {
			return this.saveData;
		});
	}

	private initPrintOption() {
		this.N_PrintOptionTab = this.el.querySelector(CE_BillPrintOption.tagName) as CE_BillPrintOption;

		this.N_PrintOptionTab!.setParentElement(this.el);

		this.N_PrintOptionTab!.setID(() => {
			return this.id;
		});

		this.N_PrintOptionTab!.setGetData(() => {
			return this.saveData;
		});

		this.N_PrintOptionTab.setOnUpdate(() => {
			this.updateSaveButton();
		});
	}

	private async initCompta() {
		this.N_ComptaTab = this.el.querySelector(CE_ComptaTab.tagName) as CE_ComptaTab;

		this.N_ComptaTab.setCallback(() => {
			return this.saveData;
		});

		this.N_ComptaTab.setParentElement(this.el);
	}

	private async initPayment() {
		this.N_PaymentTab = this.el.querySelector('ap-payment-tab') as CE_PaymentTab;

		this.N_PaymentTab.setParentElement(this.el);

		this.N_PaymentTab.setUpdateInfos(async () => {
			this.updateInfos();
		});

		this.N_PaymentTab.setCallback(async () => {
			this.updateInfos();
			await this.save();
		});

		this.N_PaymentTab.setGetTable(() => {
			return 'bills';
		});

		this.N_PaymentTab.setGetID(() => {
			return this.id;
		});

		this.N_PaymentTab.setGetglobalPriceTTC(() => {
			return this.form?.getDataByName('globalPriceTTC');
		});
	}

	private initCGV() {
		this.N_CGVTab = this.el.querySelector(CE_CGVTab.tagName) as CE_CGVTab;

		this.N_CGVTab.setParentElement(this.el);

		this.N_CGVTab.setOnUpdate(() => {
			this.updateSaveButton();
		});
	}

	private get isLock() {
		const finish = this.form?.getDataByName('infos.sendDate');

		if (LoggedUser.getInstance().hasPermission('BILLS._CUSTOMERS.EDIT_SENDED')) {
			return false;
		}

		return finish || !LoggedUser.getInstance().hasPermission('BILLS._CUSTOMERS.EDIT');
	}

	protected convertData(data: { [key: string]: any }) {
		if (data.infos.tva) {
			data.infos.tva = CE_TVA.convertValue(data.infos.tva);
		}

		const types: { [key: string]: string } = {
			'credit-error': 'Avoir (Erreur)',
			credit: 'Avoir (Remise)',
			normal: 'Avancement',
			advance: 'Acompte',
			last: 'Définitive'
		};

		if (data.infos.type) {
			data.infos.type = {
				id: data.infos.type,
				text: types[data.infos.type]
			};
		}

		return data;
	}

	protected setData(data: DataServer) {
		super.setData(data);

		this.N_ContentTab!.data = data.data.content || [];
		this.N_ContentTab!.quotes = data.data.infos.quotes || [];
		this.N_PaymentTab!.data = data.data.payment || [];
		this.N_CGVTab!.data = data.data.CGV || SettingsApps.getInstance().get('BILL.CGV') || [];
		this.N_PrintOptionTab!.data = { options: data.data.options || SettingsApps.getInstance().get('BILL.OPTIONS') || {} };

		this.N_BillingRequestTab!.data = data.data.billingRequests;

		this.N_ComptaTab!.data = data.compta || [];
		this.N_ComptaTab!.infosBill = data.data;
		this.N_ComptaTab!.comptaDetailsCredit = data.data.comptaDetailsCredit;

		const N_sites = this.el.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
		N_sites.update(data.data.infos.hasFinalCustomer);

		if (data.data._id) {
			const N_printQuotes = this.el.querySelector('#print_quotes') as HTMLButtonElement;
			N_printQuotes.classList.remove('d-none');
		}

		const N_edit_DetailsBill = this.el.querySelector('[data-edit="detailsBill"]') as HTMLButtonElement;

		if (data.data.infos.type === 'credit' || data.data.infos.type === 'credit-error') {
			N_edit_DetailsBill.classList.remove('d-none');
		}

		this.quotes = data.data.infos.quotes;

		this.updateCommandQuotes();
		this.updateInfos();
	}

	protected postInit(): void {
		super.postInit();

		this.N_PaymentTab!.postInit();
		this.N_CGVTab!.postInit();
		this.N_PrintOptionTab!.postInit();
		this.N_ComptaTab!.postInit();
		this.N_BillingRequestTab!.postInit();
		this.N_ContentTab?.postInit();
	}

	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		newData.infos = newData.infos || {};

		if (newData.infos.date) {
			newData.infos.date = newData.infos.date.format('x');
		}

		if (newData.infos.datePayment) {
			newData.infos.datePayment = newData.infos.datePayment.format('x');
		}

		if (newData.infos.sendDate) {
			newData.infos.sendDate = newData.infos.sendDate.format('x');
		}

		if (newData.relunch && newData.relunch.date) {
			newData.relunch.date = newData.relunch.date.format('x');
		}

		newData.content = [];
		newData.printCount = newData.printCount || 0;

		newData.content = this.N_ContentTab!.data;
		newData.billingRequests = this.N_BillingRequestTab!.data;
		newData.payment = this.N_PaymentTab!.data;
		newData.CGV = this.N_CGVTab!.data;
		newData.comptaDetailsCredit = this.N_ComptaTab!.comptaDetailsCredit;
		newData.options = this.N_PrintOptionTab!.data.options;

		return newData;
	}

	private updateEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsBill = this.el.querySelector('[data-edit="detailsBill"]') as HTMLButtonElement;
		const N_edit_Dates = this.el.querySelector('[data-edit="dates"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.disabled = this.isLock;
		N_edit_Dates.disabled = this.isLock;
		N_edit_DetailsBill.disabled = this.isLock;
		N_edit_AdditionalInformation.disabled = this.isLock;

		this.N_ContentTab!.isLock = this.isLock;
		this.N_CGVTab!.isLock = this.isLock;
	}

	private updateInfos() {
		const N_notPaidPrice = this.el.querySelector('#not_paid_price') as HTMLElement;
		const N_paidPrice = this.el.querySelector('#paid_price') as HTMLElement;

		let paidPrice = new Decimal(this.N_PaymentTab!.getPaidPrice());

		const { globalPriceTTC, globalPrice, globalPriceTVA } = this.N_ContentTab!.getInfos();

		const notPaid = globalPriceTTC.minus(paidPrice).toDecimalPlaces(2);

		const percent_paidPrice = paidPrice.times(100).dividedBy(globalPriceTTC).toDecimalPlaces(2);
		const percent_notPaidPrice = notPaid.times(100).dividedBy(globalPriceTTC).toDecimalPlaces(2);

		paidPrice = paidPrice.toDecimalPlaces(2);

		const str_paidPrice = paidPrice.setSuffixAndHumanizeNumber('€');
		const str_notPaidPrice = notPaid.setSuffixAndHumanizeNumber('€');

		N_paidPrice.innerHTML = `${str_paidPrice} | ${percent_paidPrice.humanizePercent()}%`;
		N_notPaidPrice.innerHTML = `${str_notPaidPrice} | ${percent_notPaidPrice.humanizePercent()}%`;

		this.form?.setData({
			globalPrice: globalPrice.toNumber(),
			globalPriceTTC: globalPriceTTC.toNumber(),
			globalPriceTVA: globalPriceTVA.toNumber()
		});
	}

	private async updateCommandQuotes() {
		const N_numberCustomerCommand = this.el.querySelector('#numberCustomerCommand') as HTMLElement;
		const N_numberQuotes = this.el.querySelector('#numberQuotes') as HTMLElement;

		const command = this.form?.getDataByName('infos.command') as string;

		if (command) {
			try {
				const tmp = await S_C_Order.getInstance().getById(command);

				N_numberCustomerCommand.innerHTML = tmp.infos.number + (tmp.infos.addonNumber ? ' / ' + tmp.infos.addonNumber : '');
			} catch (e) {

			}
		}

		N_numberQuotes.innerHTML = this.quotes.length.toString();
	}
}

export default BillsCustomerCtrl;
