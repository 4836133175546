// CORE
import { DOM, Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import CE_Address from '@libs/customElement/Address';
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE

class EditPageModal<T> extends Modal {
	protected previousCallback: null | (() => void) = null;
	protected nextCallback: null | ((data: any) => void) = null;
	protected selectPostinit: { [key: string]: CE_Select2 | CE_Address } = {};

	protected form: Form | null;

	constructor(data: T, tpl: string) {
		super({
			tpl,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.preInit(data);

			this.form = new Form(this.formElement as HTMLFormElement);

			await this.setData(data);

			this.postInit();

			this.initEventButton();
		});
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected preInit(data: T) {

	}

	protected initEventButton() {
		const N_previous = this.element.querySelector('#previous') as HTMLButtonElement;

		N_previous && N_previous.addEventListener('click', () => {
			if (this.previousCallback) {
				this.previousCallback();
			}
			DOM.nextTick(() => {
				this.reject(false);
			});
		});

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save && N_save.addEventListener('click', async () => {
			if (this.checkValidityForm()) {
				N_save.loading(new Promise(async () => {
					let data = this.form?.getData() as { [key: string]: any };

					data = await this.getDataForm(data);

					if (this.nextCallback) {
						this.nextCallback(data);
					}

					DOM.nextTick(() => {
						this.resolve(data);
					});
				}));
			}
		});
	}

	protected checkValidityForm() {
		return true;
	}

	protected get formElement(): HTMLFormElement {
		return this.element.querySelector('form')!;
	}

	protected async getDataForm(data: { [key: string]: any }) {
		return data;
	}

	protected async setData(data: T) {
		this.form?.setData(data as { [key: string]: any });
	}

	public setPreviousCallback(cb: () => void) {
		this.previousCallback = cb;

		this.once('opened', () => {
			const N_previous = this.element.querySelector('#previous') as HTMLButtonElement;
			N_previous && N_previous.classList.remove('d-none');
		});

		return this;
	}

	public setNextCallback(cb: (data: any) => void) {
		this.nextCallback = cb;

		this.once('opened', () => {
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			N_save && (N_save.innerHTML = 'Suivant');
		});

		return this;
	}

	protected postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default EditPageModal;
