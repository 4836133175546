import GenericService from '../GenericService';

import _ from 'lodash';
class UserService extends GenericService {
	private static instance: UserService | null = null;

	constructor() {
		super('users');
	}

	public static getInstance(): UserService {
		if (!UserService.instance) {
			UserService.instance = new UserService();
		}

		return UserService.instance;
	}

	public async getQuotesManagerToSelect2() {
		return await this.get_function('quotesManagerToSelect2');
	}

	public displayRefByData(item: { [key: string]: any }) {
		return `${item.lastname.toUpperCase()} ${_.upperFirst(item.firstname)}`;
	}
}

export default UserService;
