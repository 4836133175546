import { LoggedUser, Module, Modules, Router, Settings, global } from '@autoprog/core-client';

import h from 'hyperscript';

import C_User from './js/controllers/User';
import T_User from './tpl/user.html';

import Utils from '@libs/utils/Utils';

import C_SettingsUser from './js/controllers/Settings.User';
import T_Tabs from '@tpl/blocks/tabs.html';

import './css/companies.scss';
import './css/user.scss';
import './css/additionalInformations.scss';
import './css/profilePicture.scss';

class ProfileModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/profile/user',
			controller: C_User as any,
			tpl: T_User,
			requireValidUser: true
		});

		const settings = Settings.getInstance();
		const page = settings.getPageById('MAIN');

		if (page) {
			page.addTab({
				id: 'settingsUserV2',
				name: 'Utilisateurs',
				controller: C_SettingsUser as any,
				template: T_Tabs,
				requireValidUser: true
			});
		}
	}

	public async postInit() {
		if (!global.IS_MOBILE) {
			const loggedUser = LoggedUser.getInstance();

			const N_navbarRightContent = document.querySelector('#navbar-right-content') as HTMLElement;

			const N_container = document.createElement('div');

			N_container.classList.add('border-left', 'ml-2');

			const N_icon = h('i.icon.icon-solid-user-circle.mr-2.pr-1');

			const N_username = h('button.custom-nav-button.ml-3', N_icon);

			N_container.appendChild(N_username);

			N_navbarRightContent.appendChild(N_container);

			N_username.addEventListener('click', () => {
				C_User.open(null);
			});

			if (loggedUser.get('firstname') && loggedUser.get('lastname')) {
				N_username.innerHTML += loggedUser.get('firstname') + ' ' + loggedUser.get('lastname')[0] + '.';

				if (this.isModeAdmin()) {
					document.body.classList.add('is-admin');
				} else {
					document.body.classList.remove('is-admin');
				}
			}

			loggedUser.on('login', () => {
				if (loggedUser.get('firstname') && loggedUser.get('lastname')) {
					N_username.innerHTML += loggedUser.get('firstname') + ' ' + loggedUser.get('lastname')[0] + '.';

					if (this.isModeAdmin()) {
						document.body.classList.add('is-admin');
					} else {
						document.body.classList.remove('is-admin');
					}
				}
			});

			loggedUser.on('logout', () => {
				N_username.innerHTML = '';
				N_username.appendChild(N_icon);
			});
		}
	}

	private isModeAdmin() {
		return Utils.userID === '1' && !window.location.host.includes('localhost') && !window.location.host.includes('127.0.0.1');
	}
}

Modules.registerModule('Profil', ProfileModule);
