import EventEmitter from '@autoprog/eventemitter';

import ModalManager from '@managers/ModalManager';
import Permissions from '@libs/Permissions';
import ServiceManager from '@managers/ServiceManager';

import Select2Utils, { OverrideOptions } from '@libs/utils/select2Utils';

type ValueRef = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | string | boolean;

class Address extends HTMLElement {
	public static readonly tagName: string = 'ap-select-address';

	private select: Select2Utils | null = null;
	private table: string = '';

	public selectElement: HTMLSelectElement | null = null;

	private textAreaElement: HTMLTextAreaElement | null = null;

	private eventEmitter = new EventEmitter();

	public async connectedCallback() {
		const nameSelect = this.getAttribute('name-select');
		const nameTextarea = this.getAttribute('name-textarea');
		this.table = this.getAttribute('table') || '';
		const required = this.getAttribute('required');

		this.classList.add('w-100');

		this.innerHTML = `
			<div id="container_form">
				<div class="d-flex">
					<div class="input-group flex-nowrap">
						<select name="${nameSelect}" class="form-control" ${required ? 'required' : ''}></select>
					</div>
					<div class="bg-white border d-flex ml-1 p-0" style="border-radius: 5px;">					
						<button class="btn btn-white" id="synchronize" type="button" style="border-radius: 5px;">
							<i class="icon icon-solid-sync-alt"></i>
						</button>
						<button class="btn btn-white" id="edit" type="button" style="border-radius: 5px;" permission="${Permissions[this.table]}.OPEN">
							<i class="icon icon-edit"></i>
						</button>
						<button class="btn btn-white" id="clear" type="button" style="border-radius: 5px;">
							<i class="icon icon-solid-eraser"></i>
						</button>
					</div>
				</div>
				<textarea name="${nameTextarea}" rows="4" class="mt-2 form-control"></textarea>
			</div>
        `;

		this.selectElement = this.querySelector('select') as HTMLSelectElement;
		this.textAreaElement = this.querySelector('textarea') as HTMLTextAreaElement;

		this.select = new Select2Utils(this.table, this.selectElement);

		this.initButtons();
	}

	private initButtons() {
		const N_synchronize = this.querySelector('#synchronize') as HTMLButtonElement;
		N_synchronize.addEventListener('click', async () => {
			const value = this.selectElement!.value;
			if (value) {
				this.updateFullAddress();
			}
		});

		const N_edit = this.querySelector('#edit') as HTMLButtonElement;
		N_edit.addEventListener('click', async () => {
			const value = this.selectElement!.value;

			if (value) {
				const data = await ModalManager.getInstance().openWithReturnSelect2Data(this.table, value);

				const tmp = this.selectElement!.querySelector(`[value="${data.id}"]`);
				tmp && tmp.remove();

				const option = new Option(data.text, data.id, true, true);
				$(this.selectElement!).append(option).trigger('change');

				///@ts-ignore
				$(this.selectElement!).trigger({
					type: 'select2:select',
					params: {
						data
					}
				});

				this.updateFullAddress();
			}
		});

		const N_clear = this.querySelector('#clear') as HTMLButtonElement;
		N_clear.addEventListener('click', async () => {
			$(this.selectElement!).val(('') as any).trigger('change');

			this.textAreaElement!.value = '';
		});
	}

	public setRef(data: { [key: string]: ValueRef }) {
		for (const key in data) {
			if (data[key] instanceof HTMLElement) {
				this.select?.setRefElement(key, data[key] as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement);
			}

			if (typeof data[key] === 'string' || typeof data[key] === 'boolean') {
				this.select?.setRefValue(key, data[key]);
			}
		}
	}

	public create(parent: HTMLElement, overrideOption: OverrideOptions = {}) {
		this.select?.create(parent, overrideOption);

		this.removeAttribute('name-select');
		this.removeAttribute('name-textarea');
		this.removeAttribute('table');
	}

	public postInit() {
		$(this.selectElement!).on('select2:select', async () => {
			this.updateFullAddress();
		});
	}

	private async updateFullAddress() {
		try {
			const service = ServiceManager.get(this.table)?.getInstance() as any;
			this.textAreaElement!.value = await service.getFullAddress(this.selectElement!.value) || '';
		} catch (e) {
			this.textAreaElement!.value = '';
		}
	}

	public on(key: string, cb: (value: string) => void) {
		this.eventEmitter.on(key, cb);
	}

	public static register() {
		customElements.define(Address.tagName, Address);
	}
}

export default Address;
