// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../tpl/modals/customers/billingRequest.html';

// LIBS
import Modal from '@libs/Modal';
import Utils from '@libs/utils/Utils';
import moment from 'moment';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
// CUSTOM_ELEMENT
import S_C_Bill from '@services/Customer/CustomerBillService';

// SERVICE

class BillingRequest extends Modal {
	private form: Form | null = null;

	private priceOldRequest: Decimal;
	private priceBill: Decimal;

	private order: { [key: string]: any };

	private orderPrice: Decimal;
	private notBill: Decimal;

	constructor(order: { [key: string]: any }, priceOldRequest: Decimal, oldData: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.priceOldRequest = priceOldRequest;
		this.priceBill = new Decimal(0);

		this.order = order;
		this.orderPrice = Decimal.setDisplayNumber(order.infos.price);
		this.notBill = new Decimal(0);

		this.on('opened', async () => {
			const bills = await S_C_Bill.getInstance().getByOrder(order._id);

			for (const item of (bills || [])) {
				if (item.infos.type === 'credit' || item.infos.type === 'credit-error') {
					this.priceBill = this.priceBill.minus(Decimal.setDisplayNumber(item.globalPrice));
				} else {
					this.priceBill = this.priceBill.plus(Decimal.setDisplayNumber(item.globalPrice));
				}
			}

			this.notBill = this.orderPrice.minus(this.priceBill).minus(this.priceOldRequest);

			const N_title = this.element.querySelector('#title') as HTMLElement;

			N_title.innerHTML += ` | ${this.order.infos.internalNumber}`;

			const N_form = this.element.querySelector('.modal-body') as HTMLFormElement;

			this.form = new Form(N_form);

			this.init(oldData);

			const N_typePrice = this.element.querySelector('[name="typePrice"]') as HTMLSelectElement;

			N_typePrice.addEventListener('change', () => {
				this.displayByTypePrice();
			});

			const N_type = this.element.querySelector('[name="type"]') as HTMLSelectElement;

			N_type.addEventListener('change', () => {
				this.displayByType();
				this.updatePrice();
			});

			const N_percentList = this.element.querySelector('[name="request.percentList"]') as HTMLSelectElement;
			const N_percent = this.element.querySelector('[name="request.percent"]') as HTMLInputElement;

			N_percentList.addEventListener('change', () => {
				this.form?.setDataByName('request.percent', N_percentList.value);
				this.updatePrice();
			});

			N_percent.addEventListener('change', () => {
				this.form?.setDataByName('request.percentList', N_percent.value);
				this.updatePrice();
			});

			const N_requestPrice = this.element.querySelector('[name="request.price"]') as HTMLInputElement;
			N_requestPrice.addEventListener('input', () => {
				this.updatePrice();
			});

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				const formData = this.form?.getData() as { [key: string]: any };

				this.resolve({
					price: Decimal.setDisplayNumber(formData.request.price).toNumber(),
					typePrice: formData.typePrice,
					type: formData.type,
					comments: formData.comments,
					designation: formData.designation,
					user: Utils.userID,
					date: Date.now(),
					billingStartDate: formData.billingStartDate.valueOf()
				});
			});
		});
	}

	private init(oldData: { [key: string]: any }) {
		const percentPriceBill = this.priceBill.times(100).dividedBy(this.orderPrice);

		this.form?.setDataByName('price.command', this.orderPrice.setSuffixAndHumanizeNumber('€'));
		this.form?.setDataByName('price.bill', `${this.priceBill.setSuffixAndHumanizeNumber('€')} | ${percentPriceBill.humanizePercent()}%`);

		const data: { [key: string]: any } = {
			typePrice: oldData.typePrice || 'percent',
			type: oldData.type || 'normal',
			price: {
				notBillAfterRequest: '',
				oldRequest: this.priceOldRequest.setSuffixAndHumanizeNumber('€')
			},
			request: {
				percentList: this.order.infos.advance,
				percent: this.order.infos.advance,
				price: oldData.price || 0,
				priceText: '0 €'
			},
			designation: oldData.designation || '',
			comments: oldData.comments || '',
			billingStartDate: oldData.billingStartDate || moment().valueOf()
		};

		if (oldData.price) {
			const percent = Decimal.setDisplayNumber(oldData.price).times(100).dividedBy(this.orderPrice).toDecimalPlaces(2);

			data.request.percent = percent;
			data.request.percentList = percent;

			data.request.priceText = new Decimal(oldData.price).setSuffixAndHumanizeNumber('€');
		}

		this.form?.setData(data);
		this.displayByType();
		this.displayByTypePrice();
		this.updatePrice();
	}

	private displayByTypePrice() {
		const type = this.form?.getDataByName('typePrice');

		const N_ContainerFix = this.element.querySelector('#price_fix') as HTMLElement;
		const N_ContainerPercent = this.element.querySelector('#price_percent') as HTMLElement;
		const N_percentList = this.element.querySelector('[name="request.percentList"]') as HTMLSelectElement;
		const N_percent = this.element.querySelector('[name="request.percent"]') as HTMLInputElement;

		if (type === 'percent') {
			N_ContainerFix.classList.add('d-none');
			N_ContainerPercent.classList.remove('d-none');

			N_percent.disabled = false;
			N_percentList.disabled = false;
		}

		if (type === 'price') {
			N_ContainerFix.classList.remove('d-none');
			N_ContainerPercent.classList.add('d-none');

			N_percent.disabled = true;
			N_percentList.disabled = true;
		}
	}

	private displayByType() {
		const type = this.form?.getDataByName('type');

		const N_price_container = this.element.querySelector('#price_container') as HTMLElement;
		const N_type_container = this.element.querySelector('#type_container') as HTMLElement;

		if (type === 'last') {
			N_type_container.classList.add('d-none');
			N_price_container.classList.add('d-none');
		} else {
			N_type_container.classList.remove('d-none');
			N_price_container.classList.remove('d-none');
		}
	}

	private updatePrice() {
		const typePrice = this.form?.getDataByName('typePrice');

		const percent = this.form?.getDataByName('request.percent') as number;
		const price = this.form?.getDataByName('request.price') as number;

		if (typePrice === 'percent') {
			const request = this.orderPrice.times(Decimal.setDisplayNumber(percent).dividedBy(100));
			const notBillAfterRequest = this.notBill.minus(request);

			const percent_notBillAfterRequest = notBillAfterRequest.times(100).dividedBy(this.orderPrice).toDecimalPlaces(0);

			this.form?.setData({
				price: {
					notBillAfterRequest: `${notBillAfterRequest.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€')} | ${percent_notBillAfterRequest.humanizePercent()}%`
				},
				request: {
					price: request.toNumber(),
					priceText: request.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€')
				}
			} as any);

			this.updateInvalid(notBillAfterRequest, request);
		}

		if (typePrice === 'price') {
			const request = Decimal.setDisplayNumber(price);

			const notBillAfterRequest = this.notBill.minus(request);
			const percent_notBillAfterRequest = notBillAfterRequest.times(100).dividedBy(this.orderPrice).toDecimalPlaces(2);

			const percent_request = new Decimal(100).times(request).dividedBy(this.orderPrice).toDecimalPlaces(2).toString();

			this.form?.setData({
				price: {
					notBillAfterRequest: `${notBillAfterRequest.setSuffixAndHumanizeNumber('€')} | ${percent_notBillAfterRequest.humanizePercent()}%`
				},
				request: {
					percentList: percent_request,
					percent: percent_request
				}
			} as any);

			this.updateInvalid(notBillAfterRequest, request);
		}
	}

	private updateInvalid(D_notBillAfterRequest: Decimal, D_request: Decimal) {
		const type = this.form?.getDataByName('type') as string;

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;
		const N_error = this.element.querySelector('#error') as HTMLElement;
		const N_notBillAfterRequest = this.element.querySelector('#notBillAfterRequest') as HTMLElement;

		if (type === 'last') {
			N_save.classList.remove('cursor-not-allowed');

			N_save.disabled = false;

			N_error.classList.add('d-none');
		} else {
			const N_price_notBillAfterRequest = this.element.querySelector('[name="price.notBillAfterRequest"]') as HTMLElement;
			const N_price_request = this.element.querySelector('[name="request.priceText"]') as HTMLElement;

			(N_price_notBillAfterRequest.parentNode as HTMLElement).classList.remove('text-red');
			N_price_request.classList.remove('text-red');

			const notBillAfterRequest = D_notBillAfterRequest.toNumber();
			const request = D_request.toNumber();

			if (notBillAfterRequest <= 0 || request <= 0) {
				if (notBillAfterRequest <= 0) {
					(N_price_notBillAfterRequest.parentNode as HTMLElement).classList.add('text-red');
				}

				if (request <= 0) {
					N_price_request.classList.add('text-red');
				}

				N_save.disabled = true;

				N_save.classList.add('cursor-not-allowed');

				N_error.classList.remove('d-none');
			} else {
				N_save.classList.remove('cursor-not-allowed');

				N_save.disabled = false;

				N_error.classList.add('d-none');
				N_notBillAfterRequest.classList.remove('text-red');
			}
		}
	}
}

export default BillingRequest;
