// CORE
import { LoggedUser, toaster } from '@autoprog/core-client';

import _ from 'lodash';

// AgGrid
import { AllModules, ColDef, GetContextMenuItemsParams, Grid, GridOptions, MenuItemDef } from '@ag-grid-enterprise/all-modules';

// MANAGER
import ConfigManager from '@libs/ConfigManager';
import GridOptionsManager from '@managers/GridOptionsManagers';
import ModalManager from '@managers/ModalManager';
import ServiceManager from '@managers/ServiceManager';

// SERVICE
import GenericService from '@services/GenericService';

// LIBS
import Permissions from '@libs/Permissions';
import StateSaver from '@libs/agGrid/StateSaver';
import Utils from '@libs/utils/Utils';

import CE_FilterButton, { Config as ConfigFilter } from '@libs/customElement/FilterButton';
import CE_GridSidebar from '@libs/customElement/GridSidebar';
import CE_HeaderDashboard from '@libs/customElement/HeaderDashboard';
import CE_OpenDocument from '@libs/customElement/OpenDocument';
import CE_SearchBar from '@libs/customElement/searchBar';

import M_PrintPreview from '@libs/modals/PrintPreview';

/**
 * Controller générique de l'application
 */
class DatabaseDesktopCtrl {
	public config: { [key: string]: any } = {};
	private tableName = '';
	private el: HTMLElement;
	public gridOptions: GridOptions = {};
	public tableService: GenericService;

	private permissionKey = '';

	private abortController: AbortController;
	public get abortSignal(): AbortSignal {
		return this.abortController.signal;
	}

	public cancellers: EventListenerCanceller[] = [];

	constructor(el: HTMLElement, data: any) {
		this.el = el;

		this.abortController = new AbortController();

		const configManager = ConfigManager.getInstance();

		const N_title = document.querySelector('ap-header-dashboard') as CE_HeaderDashboard;
		if (N_title) {
			N_title.reset();
			N_title.setTitle(data.name);
		}

		this.tableName = data.database;
		this.tableService = ServiceManager.get(data.database)!.getInstance();

		this.permissionKey = Permissions[data.database];

		this.config = configManager.getConfig(this.tableName);

		const N_add = document.querySelector('#add') as HTMLButtonElement;
		const N_reload = document.querySelector('#reload') as HTMLButtonElement;

		N_reload.addEventListener('click', async () => {
			Utils.removeTooltip();
			N_reload.loading(this.refreshData());
		}, {
			signal: this.abortSignal
		});

		N_add.setAttribute('permission', `${this.permissionKey}.ADD`);

		if (!_.get(this.config, 'options.button.cancelEvent.add')) {
			N_add.addEventListener('click', async () => {
				await ModalManager.getInstance().open(this.tableName);

				this.refreshData();
			}, {
				signal: this.abortSignal
			});
		}

		if (_.get(this.config, 'options.button.hidden.add')) {
			N_add.classList.add('d-none');
		}

		const N_footer = this.el.querySelector('#footer-grid') as HTMLElement;
		const T_footer = this.footer();

		if (T_footer) {
			N_footer.appendChild(T_footer);
		} else {
			N_footer.innerHTML = '';
		}

		const N_header = this.el.querySelector('#header-grid') as HTMLElement;
		const T_header = this.header();

		if (T_header) {
			N_header.insertBefore(T_header, N_header.firstChild);
		}

		this.initGridOptions();
		this.initFilterAndData();
	}

	public get configFilter(): ConfigFilter {
		return this.config.filter || [];
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected getPermissionKey(data: any) {
		return this.permissionKey;
	}

	public async initGridOptions() {
		const gridOptionsManager = GridOptionsManager.getInstance();

		this.gridOptions = gridOptionsManager.get(this.tableName, {
			sideBar: {
				hiddenByDefault: true
			},
			defaultColDef: {
				valueGetter: this.valueGetter.bind(this)
			},
			getContextMenuItems: this.getContextMenu.bind(this),
			getRowStyle: this.getRowStyle.bind(this),
			onRowDataChanged: this.onDataUpdate.bind(this),
			getRowHeight: this.getRowHeight.bind(this),
			onFilterChanged: this.onDataUpdate.bind(this),
			onRowSelected: this.onDataUpdate.bind(this),
			suppressRowClickSelection: true,
			rowSelection: !_.get(this.config, 'options.columns.hidden.selection') ? 'multiple' : undefined
		});

		if (!_.get(this.config, 'options.columns.hidden.icon') || !_.get(this.config, 'options.columns.hidden.selection')) {
			const obj: ColDef = {
				headerComponentParams: {
					template: `
							<div class="ag-cell-label-container" role="presentation">
								<div ref="eLabel" class="ag-header-cell-label" role="presentation">
									<i class="icon h5 icon-solid-ellipsis-h"></i>
								</div>
							</div>
						`
				},
				checkboxSelection: !_.get(this.config, 'options.columns.hidden.selection'),
				headerCheckboxSelectionFilteredOnly: !_.get(this.config, 'options.columns.hidden.selection'),
				headerCheckboxSelection: !_.get(this.config, 'options.columns.hidden.selection'),
				headerClass: 'ag-theme-custom-text-center',
				field: '_icons_',
				filter: false,
				pinned: 'left',
				sortable: false,
				suppressMovable: true,
				suppressColumnsToolPanel: true,
				cellRenderer: (params) => {
					const N_container = document.createElement('div');
					N_container.classList.add('d-flex', 'align-items-center');

					const icons = this.iconsColumn(params);

					for (const el of icons) {
						N_container.appendChild(el);
					}

					return N_container;
				}
			};

			this.gridOptions.columnDefs?.push(obj);
		}

		if (!_.get(this.config, 'options.columns.hidden.button')) {
			const obj: ColDef = {
				headerName: 'Action',
				headerClass: 'ag-theme-custom-text-center',
				field: '_id',
				filter: false,
				pinned: 'right',
				sortable: false,
				suppressMovable: true,
				suppressColumnsToolPanel: true,
				cellRenderer: (params) => {
					const N_container = document.createElement('div');
					N_container.classList.add('btn-group');

					params.data._disableButton_ = params.data._disableButton_ || {};

					if (!_.get(this.config, 'options.button.hidden.edit')) {
						const N_edit = document.createElement('button');

						N_edit.setAttribute('permission', `${this.getPermissionKey(params.data)}.OPEN`);
						N_edit.classList.add('h-100', 'py-0', 'btn-transparent');
						N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';

						N_edit.disabled = params.data._disableButton_.edit;

						if (!N_edit.disabled) {
							N_edit.setAttribute('tooltip', 'Éditer');
							N_edit.addEventListener('click', () => {
								this.buttonEdit(params);
							});
						}

						N_container.appendChild(N_edit);
					}

					if (!_.get(this.config, 'options.button.hidden.duplicate')) {
						const N_duplicate = document.createElement('button');

						N_duplicate.setAttribute('permission', `${this.getPermissionKey(params.data)}.DUPLICATE`);
						N_duplicate.classList.add('h-100', 'py-0', 'btn-transparent');

						N_duplicate.innerHTML = '<i class="text-purple h5 icon icon-clone"></i>';

						N_duplicate.disabled = params.data._disableButton_.duplicate;

						if (!N_duplicate.disabled) {
							N_duplicate.setAttribute('confirmation', '');
							N_duplicate.setAttribute('tooltip', 'Dupliquer');
							N_duplicate.addEventListener('click', async () => {
								this.buttonDuplicate(params);
							});
						}

						N_container.appendChild(N_duplicate);
					}

					if (!_.get(this.config, 'options.button.hidden.delete')) {
						const N_delete = document.createElement('button');

						N_delete.setAttribute('permission', `${this.getPermissionKey(params.data)}.DELETE`);
						N_delete.classList.add('h-100', 'py-0', 'btn-transparent');
						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';

						N_delete.disabled = params.data._disableButton_.delete;

						if (!N_delete.disabled) {
							N_delete.setAttribute('confirmation', '');
							N_delete.setAttribute('tooltip', 'Supprimer');
							N_delete.addEventListener('click', async () => {
								this.buttonDelete(params);
							});
						}

						N_container.appendChild(N_delete);
					}

					if (!_.get(this.config, 'options.button.hidden.print')) {
						const N_print = document.createElement('button');

						N_print.classList.add('h-100', 'py-0', 'btn-transparent');
						N_print.innerHTML = '<i class="text-dark h5 icon icon-printer-outiline "></i>';
						N_print.disabled = params.data._disableButton_.print;

						if (!N_print.disabled) {
							N_print.setAttribute('tooltip', 'Imprimer');
							N_print.addEventListener('click', async () => {
								this.buttonPrint(params);
							});
						}

						N_container.appendChild(N_print);
					}

					const addOn = this.addOnButtonGrid(params);

					for (const el of addOn) {
						N_container.appendChild(el);
					}

					return N_container;
				}
			};

			this.gridOptions.columnDefs?.push(obj);
		}

		const objFilterCreateBy: ColDef = {
			headerName: 'Créé par',
			field: '_createBy_',
			toolPanelClass: Utils.userID === '1' ? '' : 'd-none',
			hide: Utils.userID !== '1'
		};

		this.gridOptions.columnDefs?.push(objFilterCreateBy);

		const N_grid = this.el.querySelector('#grid') as HTMLElement;

		new Grid(N_grid, this.gridOptions, { modules: AllModules });
	}

	private async initFilterAndData() {
		const N_filter = document.createElement('ap-filter-button') as CE_FilterButton;
		const N_header = this.el.querySelector('#header-grid') as HTMLElement;
		const N_title = document.querySelector('ap-header-dashboard') as CE_HeaderDashboard;
		const N_searchBar = document.createElement('ap-search-bar') as CE_SearchBar;

		N_header.insertBefore(N_filter, N_header.firstChild);
		N_filter.setConfig(this.configFilter, this.gridOptions);

		N_header.insertBefore(N_searchBar, N_filter.nextSibling);
		N_searchBar.setGridOptions(this.gridOptions);

		N_title && N_title.setGridOptions(this.gridOptions);

		try {
			await this.initData();
			this.initFilter();
			this.initGridSidebar();
		} catch (error: any) {
			if (error.code !== 'ERR_CANCELED') {
				console.error(error.message);
				toaster.error('Erreur lors du chargement des données');
			}
		}
	}

	private async initData() {
		const N_reload = this.el.querySelector('#reload') as HTMLButtonElement;
		N_reload?.loading();

		const { rowData, settings } = await this.tableService.getDataToAgGrid(this.abortController);
		this.gridOptions.api?.setRowData(rowData);

		const stateSaver = new StateSaver(this.gridOptions as any, this.tableName);
		stateSaver.setData(settings);
		this.onDataUpdate(this.gridOptions);

		N_reload?.loadingSuccess();
	}

	private initFilter() {
		const N_els = this.el.querySelectorAll('[data-filter]') as NodeListOf<HTMLElement>;

		const N_all = this.el.querySelector('[data-filter="ALL"]') as HTMLButtonElement;

		const instance = this.gridOptions.api?.getFilterInstance('_filter_');

		if (N_all) {
			N_all.classList.add('btn');
			N_all.classList.remove('btn-outline');
		}

		let values: string[] = [];

		N_els.forEach((N_el) => {
			const key = N_el.dataset.filter || '';

			if (key !== 'ALL') {
				N_el.classList.add('btn-outline');
				N_el.classList.remove('btn');

				N_el.addEventListener('click', () => {
					if (instance) {
						const index = values.indexOf(key);

						if (index === -1) {
							values.push(key);

							N_el.classList.add('btn');
							N_el.classList.remove('btn-outline');
						} else {
							values.splice(index, 1);

							N_el.classList.remove('btn');
							N_el.classList.add('btn-outline');
						}

						if (values.length) {
							N_all && N_all.classList.remove('btn');
							N_all && N_all.classList.add('btn-outline');
						} else {
							N_all && N_all.classList.add('btn');
							N_all && N_all.classList.remove('btn-outline');
						}

						instance.setModel({
							values: values.length ? values : null
						});

						this.gridOptions.api?.onFilterChanged();
					}
				});
			}
		});

		N_all && N_all.addEventListener('click', () => {
			const instance = this.gridOptions.api?.getFilterInstance('_filter_');

			if (instance) {
				values = [];

				instance.setModel({
					values: null
				});

				this.gridOptions.api?.onFilterChanged();

				N_els.forEach((N_el) => {
					N_el.classList.remove('btn');
					N_el.classList.add('btn-outline');
				});

				N_all.classList.add('btn');
				N_all.classList.remove('btn-outline');
			}
		});
	}

	private initGridSidebar() {
		const N_GridSidebar = this.el.querySelector('ap-grid-sidebar') as CE_GridSidebar;
		N_GridSidebar.initSideBar(this.gridOptions);
	}

	public refreshData() {
		return new Promise<void>((resolve, reject) => {
			this.tableService.getDataToAgGrid(this.abortController).then(({ rowData }) => {
				this.gridOptions.api?.setRowData(rowData);
				resolve();
			}).catch((error: any) => {
				if (error.code !== 'ERR_CANCELED') {
					console.error(error.message);
					toaster.error('Erreur lors du chargement des données');
				}
				reject(error);
			});
		});
	}

	public valueColFilter() {
		return '';
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected getRowHeight(params: any) {
		return 42;
	}

	protected valueGetter(params: any) {
		const value = _.get(params.data, params.colDef.field || '');

		if (_.isUndefined(value) || _.isNull(value)) {
			return '';
		} else {
			return value;
		}
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	public onDataUpdate(params: any) { }

	public buttonEdit(params: any) {
		ModalManager.getInstance().open(this.tableName, params.data._id).then(() => {
			this.refreshData();
		}).catch(() => {
			this.refreshData();
		});
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	public buttonPrint(params: any): void {
		new M_PrintPreview(this.tableName, params.data._id).open();
	}

	/**
	 * Duplication d'une donnée de la table
	 * @param params
	 * @param ignoreRefresh
	 */
	public async buttonDuplicate(params: any) {
		try {
			ModalManager.getInstance().openDuplicate(this.tableName, params.value).then(() => {
				this.refreshData();
			}).catch(() => {
				this.refreshData();
			});
		} catch (error) {
			console.error(error);
			toaster.error('Impossible de dupliquer la donnée.', 'Erreur');
		}
	}

	/**
	 * Supprime la donnée
	 * @param params
	 */
	public async buttonDelete(params: any) {
		try {
			await this.tableService.delete(params.value);
			params.data.deleted = true;

			this.removeRow([params.data]);

			toaster.success('Suppresion terminée.');
		} catch (error) {
			toaster.error('Impossible de supprimer la donnée.', 'Erreur');
		}
	}

	public updateRow(data: { [key: string]: any }[], addIndex?: number) {
		this.gridOptions.api?.applyTransaction({
			add: data,
			addIndex
		});
	}

	public removeRow(data: { [key: string]: any }[]) {
		this.gridOptions.api?.applyTransaction({ remove: data });
	}

	/**
	 * Permet d'ajouter des éléments HTML dans la colonne d'actions
	 * @param params
	 * @returns un tableau d'éléments html
	 */
	// eslint-disable-next-line unused-imports/no-unused-vars
	public addOnButtonGrid(params: any): HTMLElement[] {
		return [];
	}

	/**
	 * Permet d'ajouter des éléments HTML dans la colonne des infos
	 * @param params
	 * @returns un tableau d'éléments html
	 */
	// eslint-disable-next-line unused-imports/no-unused-vars
	public iconsColumn(params: any): HTMLElement[] {
		return [];
	}

	public getContextMenu(params: GetContextMenuItemsParams): (MenuItemDef | string)[] {
		const loggedUser = LoggedUser.getInstance();

		const result: (MenuItemDef | string)[] = [];

		const titleContextMenu = _.get(this.config, 'options.contextMenu.title');
		const lengthTitle = _.get(this.config, 'options.contextMenu.lengthTitle');

		if (titleContextMenu && params.node) {
			let title = _.template(titleContextMenu)({ data: params.node.data });

			if (lengthTitle && lengthTitle < title.length) {
				title = title.slice(0, lengthTitle) + '...';
			}

			result.push({
				name: title,
				disabled: true,
				cssClasses: ['title-context-menu']
			});
		}

		if (!_.get(this.config, 'options.button.hidden.edit')) {
			result.push({
				name: 'Éditer',
				icon: '<i class="icon icon-edit"></i>',
				disabled: !params.node || !loggedUser.hasPermission(`${this.getPermissionKey(params.node.data)}.OPEN`),
				action: () => {
					this.buttonEdit({ ...params, data: params.node.data });
				}
			});
		}

		if (_.get(this.config, 'options.contextMenu.print')) {
			result.push({
				name: 'Imprimer',
				icon: '<i class="icon icon-printer-outiline "></i>',
				action: () => {
					this.buttonPrint({ ...params, data: params.node.data });
				}
			});
		}

		return result;
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	public getRowStyle(params: any): { [key: string]: string } {
		return {};
	}

	public header(): HTMLElement | null {
		return null;
	}

	public footer(): HTMLElement | null {
		return null;
	}

	public setDataTitle(data: { price: string, text: string }[]) {
		const N_title = document.querySelector('ap-header-dashboard') as CE_HeaderDashboard;
		N_title.setData(data);
	}

	public destructor() {
		this.abortController.abort('destroyed');

		for (const canceller of this.cancellers) {
			canceller();
		}

		if (this.gridOptions && this.gridOptions.api) {
			this.gridOptions.api.destroy();
		}

		const N_datepicker = document.querySelectorAll('.daterangepicker');

		for (const N_el of N_datepicker) {
			N_el.remove();
		}

		const N_tooltip = document.querySelectorAll('.tooltip');

		for (const N_el of N_tooltip) {
			N_el.remove();
		}

		CE_OpenDocument.destructor();
	}
}

export default DatabaseDesktopCtrl;
