class SitesFinalCustomerReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-sites-final-customer-readonly';

	public connectedCallback() {
		const nameSitesSelect = this.getAttribute('name-sites-select') as string;
		const nameSiteCustom = this.getAttribute('name-site-custom') as string;
		const nameFinalCustomer = this.getAttribute('name-final-customer') as string;
		const nameHasFinalCustomer = this.getAttribute('name-has-final-customer') as string;

		this.innerHTML = `
			<div class="d-flex mb-2">
				<div class="page-card-label mr-1">Sous-Traitance :</div>
				<div class="page-card-value" name="${nameHasFinalCustomer}"></div>
			</div>
			
			<div id="database">
				<div class="d-flex mb-2">
					<div class="page-card-label mr-1">Site(s) :</div>
					<div class="page-card-value" name="${nameSitesSelect}"></div>
				</div>
			</div>

			<div id="custom">
			
				<div class="d-flex mb-2">
					<div class="page-card-label mr-1">Client final :</div>
					<div class="page-card-value" name="${nameFinalCustomer}"></div>
				</div>

				<div class="d-flex mb-2">
					<div class="page-card-label mr-1">Site(s) :</div>
					<div class="page-card-value" name="${nameSiteCustom}"></div>
				</div>

			</div>
        `;

		this.update(false);

		this.classList.add('w-100');
	}

	public update(value: boolean) {
		const N_database = this.querySelector('#database') as HTMLElement;
		const N_custom = this.querySelector('#custom') as HTMLElement;

		if (value) {
			N_database.classList.add('d-none');
			N_custom.classList.remove('d-none');
		} else {
			N_database.classList.remove('d-none');
			N_custom.classList.add('d-none');
		}
	}

	public static register() {
		customElements.define(SitesFinalCustomerReadonly.tagName, SitesFinalCustomerReadonly);
	}
}

export default SitesFinalCustomerReadonly;
