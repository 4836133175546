import ApIcon from '@autoprog/icons';

import '@css/customElement/ap-lost-connexion.scss';

export interface ApServerStatusHTMLElement extends HTMLElement{

	set serverConnected(serverConnected: boolean)
}

class ApLostConnexion extends ApIcon implements ApServerStatusHTMLElement {
	public static readonly tagName = 'ap-lost-connexion';

	public constructor() {
		super('signal-wifi-off', 'line');
		this.setAttribute('tooltip', 'Connexion perdue');
	}

	public set serverConnected(serverConnected:boolean) {
		this.style.setProperty('display', serverConnected ? 'none' : null);
	}

	public static register() {
		if (customElements.get(ApLostConnexion.tagName) === undefined) {
			customElements.define(ApLostConnexion.tagName, ApLostConnexion);
		}
	}
}

export default ApLostConnexion;
