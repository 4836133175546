// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../tpl/modals/last.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

class Last extends Modal {
	constructor(quotes: any[]) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			let html = '';

			for (const quote of quotes) {
				html += `
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="${quote.id}" id="${quote.id}">
                        <label class="custom-control-label" for="${quote.id}">${quote.text}</label>
                    </div>
                `;
			}

			const N_quotes = this.element.querySelector('#quotes') as HTMLElement;

			N_quotes.innerHTML = html;

			const form = new Form(this.element.querySelector('form')!);

			const N_save = this.element.querySelector('#save');

			N_save?.addEventListener('click', () => {
				const data = form.getData();

				//suppresion des devis non selectionné car les boolean sont convertis en string dans une request GET
				for (const id in data) {
					if (!data[id]) {
						delete data[id];
					}
				}

				this.resolve(data);
			});
		});
	}
}

export default Last;
