import { Form } from '@autoprog/core-client';

import _ from 'lodash';

import ConfigManager from '@libs/ConfigManager';
import Modal from '@libs/Modal';

import T_modal from '../../tpl/modals/printProducts.html';

import P_print from '../libs/printer/Products';
import moment from 'moment';

class PrintProduct extends Modal {
	constructor(rowData: { [key: string]: any }[], settingsCol: { [key: string]: any }[]) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		const data = _.cloneDeep(rowData);

		this.on('opened', () => {
			const config = ConfigManager.getInstance().getConfig('products');

			const N_form = this.element.querySelector('form') as HTMLFormElement;

			const N_printColumns = this.element.querySelector('#columnsToPrint');

			const columns = [];
			for (const value of Object.values(settingsCol)) {
				const colParams = _.find(config.columns, { key: value.colId });

				if (colParams && colParams.type !== 'file' && colParams.type !== 'primaryKey' && colParams.key !== 'url_photo') {
					columns.push({
						key: colParams.key,
						name: colParams.name,
						hide: value.hide
					});
				}
			}

			//On découpe en groupe de 20 pour afficher 20 propriétées max par colonne
			const columnsGroup = _.chunk(columns, 20);

			for (const group of columnsGroup) {
				const N_col = document.createElement('div');
				N_col.classList.add('col');

				for (const col of group) {
					N_col.innerHTML += `
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="print-modal-${col.key}" name="${col.key}" ${col.hide ? '' : 'checked'}>
                            <label class="custom-control-label" for="print-modal-${col.key}">${col.name}</label>
                        </div>
                    `;
				}

				N_printColumns?.appendChild(N_col);
			}

			const form = new Form(N_form);

			const N_selectAll = this.element.querySelector('#selectAll') as HTMLButtonElement;
			const N_unselectAll = this.element.querySelector('#unselectAll') as HTMLButtonElement;

			N_selectAll.addEventListener('click', () => {
				const N_checkbox = N_printColumns?.querySelectorAll('[type="checkbox"]') as NodeListOf<HTMLInputElement>;

				N_checkbox.forEach((N_el) => {
					N_el.checked = true;
				});
			});

			N_unselectAll.addEventListener('click', () => {
				const N_checkbox = N_printColumns?.querySelectorAll('[type="checkbox"]') as NodeListOf<HTMLInputElement>;

				N_checkbox.forEach((N_el) => {
					N_el.checked = false;
				});
			});

			const N_print = this.element.querySelector('#print') as HTMLButtonElement;

			N_print.addEventListener('click', () => {
				const formData = form.getData();

				const N_checkboxList = this.element.querySelectorAll('[type="checkbox"]') as NodeListOf<HTMLInputElement>;

				const keyDate: { [key: string]: any } = {};

				const settings = new Set();
				for (const N_checkbox of N_checkboxList) {
					if (N_checkbox.checked) {
						const key = N_checkbox.name;
						const colParams = _.find(config.columns, { key });

						if (colParams) {
							if (colParams.type === 'Decimal') {
								settings.add({
									key: `${key}.formattedValue`,
									class: 'text-right',
									name: colParams.name
								});
							} else if (colParams.type === 'date') {
								keyDate[key] = colParams;
								settings.add({
									key,
									name: colParams.name
								});
							} else {
								settings.add({
									key,
									name: colParams.name
								});
							}
						}
					}
				}

				for (const item of data) {
					for (const key in keyDate) {
						if (item[key]) {
							item[key] = moment(item[key], keyDate[key].formatFrom || 'x').format(keyDate[key].formatTo || 'DD/MM/YYYY');
						} else {
							item[key] = '&nbsp;';
						}
					}
				}

				const orientation = formData.orientation as 'portrait' || 'landscape';

				new P_print(data, settings, orientation).print();
			});
		});
	}
}

export default PrintProduct;
