import { Form } from '@autoprog/core-client';

import CE_Select2 from '@libs/customElement/Select2';

import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';

class OutputTracaForm extends HTMLElement {
	public static readonly tagName: string = 'ap-output-traca-form';

	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null = null;

	public async connectedCallback() {
		this.classList.add('w-100');

		const mode = this.getAttribute('mode');

		this.innerHTML = `
			<div class="d-flex ${mode === 'vertical' ? 'flex-column' : ''}" id="form">

				<div class="d-flex flex-column pr-2 ${mode === 'vertical' ? 'mb-3' : ''}">
					
					<div class="font-weight-bold mb-2">
						Veuillez sélectionner un type de sortie
					</div>

					<div class="custom-control custom-radio custom-control-inline mb-2">
						<input type="radio" id="customer" name="type" class="custom-control-input" value="customer" checked>
						<label class="custom-control-label" for="customer">Envoi Client</label>
					</div>

					<div class="custom-control custom-radio custom-control-inline">
						<input type="radio" id="stock" name="type" class="custom-control-input" value="stock">
						<label class="custom-control-label" for="stock">Transfert Stock</label>
					</div>
				</div>

				<div class="card border-radius flex-grow-1" id="container-customer">
					<div class="card-body"> 

						<div>
							<label>Commande client :</label>
							<ap-select2-button name="traceability.command_customer" table="commands-customer"
								hidden-btn-add="true" hidden-btn-edit="true">
							</ap-select2-button>
						</div>

						<div class="mt-2">
							<label>Client :</label>
							<ap-select2-button name="traceability.customer" table="customers">
							</ap-select2-button>
						</div>

						<div class="mt-2">
							<label>Site :</label>
							<ap-select2-button name="traceability.site" table="sites">
							</ap-select2-button>
						</div>

					</div>
				</div>

				<div class="card border-radius flex-grow-1 d-none" id="container-stock">
					<div class="card-body"> 

						<div>
							<label>Stock :</label>
							<ap-select2-button name="traceability.stock" table="stock">
							</ap-select2-button>
						</div>

					</div>
				</div>
			</div>
        `;
	}

	public init(parent: HTMLElement = this, N_stock: HTMLSelectElement) {
		const N_T_site = this.querySelector('[name="traceability.site"]') as CE_Select2;
		const N_T_customer = this.querySelector('[name="traceability.customer"]') as CE_Select2;
		const N_T_stock = this.querySelector('[name="traceability.stock"]') as CE_Select2;
		const N_T_C_command = this.querySelector('[name="traceability.command_customer"]') as CE_Select2;

		N_T_customer.create(parent);

		N_T_site.setRef({ customer: N_T_customer.selectElement! });
		N_T_site.create(parent);

		N_T_stock.setRef({ currentStock: N_stock });
		N_T_stock.create(parent);

		N_T_C_command.create(parent);

		this.selectPostinit['traceability.site'] = N_T_site;
		this.selectPostinit['traceability.customer'] = N_T_customer;
		this.selectPostinit['traceability.command_customer'] = N_T_C_command;
		this.selectPostinit['traceability.stock'] = N_T_stock;

		this.form = new Form(this.querySelector('#form') as HTMLFormElement);

		this.updateRender();
	}

	public postInit() {
		const N_TypeStock = this.querySelector('#stock[name="type"]') as HTMLInputElement;
		const N_TypeCustomer = this.querySelector('#customer[name="type"]') as HTMLInputElement;

		N_TypeStock.addEventListener('change', () => {
			this.updateRender();
		});

		N_TypeCustomer.addEventListener('change', () => {
			this.updateRender();
		});

		this.selectPostinit['traceability.command_customer'].on('change', async (value) => {
			if (value) {
				this.selectPostinit['traceability.customer'].disable();

				const data = await S_C_Order.getInstance().getById(value as string);

				const customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer);

				this.form?.setDataByName('traceability.customer', customer);
			} else {
				this.selectPostinit['traceability.customer'].enable();
			}
		});

		this.selectPostinit['traceability.site'].postInit();
		this.selectPostinit['traceability.customer'].postInit();
		this.selectPostinit['traceability.command_customer'].postInit();
		this.selectPostinit['traceability.stock'].postInit();
	}

	private updateRender() {
		const N_customer = this.querySelector('#container-customer') as HTMLElement;
		const N_stock = this.querySelector('#container-stock') as HTMLElement;

		N_customer.classList.add('d-none');
		N_stock.classList.add('d-none');
		const type = this.form?.getDataByName('type');

		if (type === 'customer') {
			N_customer.classList.remove('d-none');
		} else {
			this.form?.setDataByName('traceability.command_customer', { id: '', text: '' });
			this.form?.setDataByName('traceability.customer', { id: '', text: '' });
			this.form?.setDataByName('traceability.site', { id: '', text: '' });
		}

		if (type === 'stock') {
			N_stock.classList.remove('d-none');
		} else {
			this.form?.setDataByName('traceability.stock', { id: '', text: '' });
		}
	}

	public static register() {
		customElements.define(OutputTracaForm.tagName, OutputTracaForm);
	}
}

export default OutputTracaForm;
