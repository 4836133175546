import { Form, Modal, toaster } from '@autoprog/core-client';

import moment, { Moment } from 'moment';

import Utils from '@libs/utils/Utils';

import T_Modal from '../../../tpl/modals/AddQuote/ChangeState.html';

import C_CommandCustomerFromQuote from '@modules/Commands/js/controllers/Command.Customer.FromQuote';

import S_Quotes from '@services/QuoteService';
import S_User from '@services/User/UserService';

import CE_QuoteRelance from '../../libs/customElement/QuoteRelance';

class ChangeState extends Modal {
	private id: string;

	private formSendDate: Form | null;

	private newState = '';
	private oldState = '';

	private userToValidate: { [key: string]: any } = {};
	private dataToSendDate: { [key: string]: any } = {};

	constructor(id: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.id = id;

		this.formSendDate = null;

		this.on('opened', async () => {
			const quote = await S_Quotes.getInstance().getById(id);

			const N_number = this.element.querySelector('#number') as HTMLElement;

			N_number.innerHTML = quote.infos.number;

			this.newState = quote.infos.state.toString();
			this.oldState = quote.infos.state.toString();

			if (['0', '5'].includes(quote.infos.state) && quote.infos.sendDate) {
				this.newState = 'send';
				this.oldState = 'send';
			}

			this.formSendDate = new Form(this.element.querySelector('#setSendDate-form') as HTMLFormElement);

			this.updateState();
			this.initButton();
			this.initSendDate();
			this.initValidateUser();
			this.initReadyToSend();

			const N_relunch = this.element.querySelector('ap-quote-relance') as CE_QuoteRelance;
			N_relunch.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				//"1" : Accepté
				if (this.newState === '1') {
					this.resolve();
					C_CommandCustomerFromQuote.open(this.id);
				} else {
					//"6" : A valider
					if (this.newState === '6') {
						await S_Quotes.getInstance().toValidate(this.id, this.userToValidate.id, Utils.userID);
						toaster.success('Notification envoyée');
					}

					if (this.newState === 'send') {
						this.newState = '5';
					}

					if (Object.keys(this.dataToSendDate).length) {
						await S_Quotes.getInstance().setNewSendDate(this.dataToSendDate, this.id);
					}

					await S_Quotes.getInstance().changeState(this.id, this.newState);

					this.resolve();
				}
			});
		});
	}

	private initButton() {
		const N_inProgress = this.element.querySelector('[data-state="0"]') as HTMLButtonElement;
		const N_toValidate = this.element.querySelector('[data-state="6"]') as HTMLButtonElement;
		const N_readyToBeSent = this.element.querySelector('[data-state="5"]') as HTMLButtonElement;
		const N_send = this.element.querySelector('[data-state="send"]') as HTMLButtonElement;
		const N_waitingOrder = this.element.querySelector('[data-state="4"]') as HTMLButtonElement;
		const N_createOrder = this.element.querySelector('[data-state="1"]') as HTMLButtonElement;
		const N_refuse = this.element.querySelector('[data-state="2"]') as HTMLButtonElement;

		const N_reset = this.element.querySelector('#resetState') as HTMLButtonElement;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		if (['1'].includes(this.oldState)) {
			N_reset.classList.add('disabled');
			N_save.classList.add('d-none');
		}

		N_inProgress.addEventListener('click', async () => {
			this.newState = '0';
			this.updateState();
		});

		N_toValidate.addEventListener('click', async () => {
			this.hiddenReadyToSend();
			this.hiddenNewState();
			this.displayValidate();
		});

		N_readyToBeSent.addEventListener('click', async () => {
			this.newState = '5';
			this.updateState();
		});

		N_send.addEventListener('click', () => {
			this.hiddenReadyToSend();
			this.displaySendDate();
		});

		N_waitingOrder.addEventListener('click', async () => {
			this.newState = '4';
			this.updateState();
		});

		N_createOrder.addEventListener('click', () => {
			this.newState = '1';
			this.updateState();
		});

		N_refuse.addEventListener('click', async () => {
			this.newState = '2';
			this.updateState();
		});

		N_reset.addEventListener('click', () => {
			//0: En cours, 6 : A valider, 
			if (['0', '6', '5'].includes(this.oldState)) {
				this.newState = '0';
				this.dataToSendDate = {};
				this.userToValidate = {};
			}

			//send : Envoyé, 4 : Attente commande,  2 : Refusé, 7: Revisé
			if (['send', '4', '2', '7'].includes(this.oldState)) {
				this.newState = 'send';
				this.dataToSendDate = {};
				this.userToValidate = {};
			}

			this.updateState();
		});
	}

	private initSendDate() {
		const N_copy = this.element.querySelector('#copy') as HTMLButtonElement;
		const N_save = this.element.querySelector('#save-sendDate') as HTMLButtonElement;
		const N_validityType = this.element.querySelector('[name="validityType"]') as HTMLSelectElement;
		const N_date = this.element.querySelector('[name="date"]') as HTMLInputElement;
		const N_relunch = this.element.querySelector('ap-quote-relance') as CE_QuoteRelance;

		N_date.addEventListener('change', () => {
			N_relunch.setSendDate(moment(N_date.valueAsNumber));
		});

		N_copy.addEventListener('click', async () => {
			try {
				await navigator.clipboard.writeText(this.formSendDate?.getDataByName('email') as string);
				toaster.success('Copie réussie');
			} catch (e) {
				toaster.error('Erreur lors de la copie');
			}
		});

		N_save.addEventListener('click', async () => {
			const data = this.formSendDate?.getData() as { [key: string]: any };

			data.date = (data.date as Moment).format('x');
			data.validityDate = (data.validityDate as Moment).format('x');

			data.relunch.date = (data.relunch.date as Moment).format('x');

			this.dataToSendDate = data;

			this.newState = 'send';
			this.updateState();
		});

		N_validityType.addEventListener('change', () => {
			const date = moment();

			const [numberValidity, typeValidity] = N_validityType.value.split('_');

			const validityDate = date.clone().add(numberValidity, typeValidity as any);

			this.formSendDate!.setDataByName('validityDate', validityDate);
		});
	}

	private async initValidateUser() {
		const data = await S_User.getInstance().getQuotesManagerToSelect2();

		const N_save = this.element.querySelector('#save-validate') as HTMLButtonElement;
		const N_validateUser = this.element.querySelector('#validateUser') as HTMLSelectElement;

		for (const item of data) {
			N_validateUser.appendChild(new Option(item.text, item.id));
		}

		N_save.addEventListener('click', async () => {
			this.userToValidate = data.find((item: any) => item.id === N_validateUser.value);

			this.newState = '6';
			this.updateState();
		});
	}

	private initReadyToSend() {
		const N_iframe = this.element.querySelector('#readyToSend') as HTMLIFrameElement;
		N_iframe.src = `/api/quotes/pdf?id=${this.id}&user=${Utils.userID}&companyID=${Utils.companyID}#view=FitH`;
	}

	private resetState() {
		const N_stateList = this.element.querySelectorAll('[data-state]') as NodeListOf<HTMLElement>;

		for (const N_state of N_stateList) {
			const N_icon = N_state.querySelector('ap-icon') as HTMLElement;
			N_icon && N_icon.setAttribute('name', 'checkbox-blank-circle/line');

			N_state.classList.remove('validate', 'current', 'next');
		}
	}

	private activeCurrentState() {
		const N_state = this.element.querySelector(`[data-state="${this.newState}"]`) as HTMLElement;
		N_state.classList.add('current');

		const N_icon = N_state.querySelector('ap-icon') as HTMLElement;
		N_icon.setAttribute('name', 'arrow-right-s/line');
	}

	private validateState(stateList: string[]) {
		for (const item of stateList) {
			const N_state = this.element.querySelector(`[data-state="${item}"]`) as HTMLElement;
			N_state.classList.add('validate');

			const N_icon = N_state.querySelector('ap-icon') as HTMLElement;
			N_icon.setAttribute('name', 'checkbox-circle/fill');
		}
	}

	private activeNextStep(stateList: string[]) {
		for (const item of stateList) {
			const N_state = this.element.querySelector(`[data-state="${item}"]`) as HTMLElement;
			N_state.classList.add('next');

			const N_icon = N_state.querySelector('ap-icon') as HTMLElement;
			N_icon.setAttribute('name', 'checkbox-blank-circle/line');
		}
	}

	private validateChevronState(stateList: string[]) {
		for (const item of stateList) {
			const N_state = this.element.querySelector(`[data-state="${item}"]`) as HTMLElement;
			N_state.classList.add('validate');
		}
	}

	private updateState() {
		const N_toValidate = this.element.querySelector('#toValidate') as HTMLElement;
		const N_nameToValidate = this.element.querySelector('#nameToValidate') as HTMLElement;
		const N_validate = this.element.querySelector('#validate') as HTMLElement;
		const N_setSendDate = this.element.querySelector('#setSendDate') as HTMLElement;

		N_toValidate.classList.add('d-none');
		N_validate.classList.add('d-none');
		N_validate.classList.remove('d-flex');
		N_setSendDate.classList.add('d-none');
		N_setSendDate.classList.remove('d-flex');

		this.resetState();
		this.activeCurrentState();
		this.displayNewState();

		//"0": "En Cours" 
		if (this.newState === '0') {
			this.validateState([]);
			this.validateChevronState([]);
			this.activeNextStep(['6', '5']);
		}

		//"6": "À Valider"
		if (this.newState === '6') {
			N_toValidate.classList.remove('d-none');

			N_nameToValidate.innerHTML = '&nbsp;par ' + this.userToValidate.text;

			this.validateState(['0']);
			this.validateChevronState(['0-6']);
			this.activeNextStep(['5']);

			this.hiddenNewState();
		}

		//"5": "Prêt à être envoyé"
		if (this.newState === '5') {
			this.validateState(['0', '6']);
			this.validateChevronState(['0-6', '6-5']);
			this.activeNextStep(['send']);

			this.hiddenNewState();
			this.displayReadyToSend();
		} else {
			this.hiddenReadyToSend();
		}

		if (this.newState === 'send') { //Envoyé 
			this.validateState(['0', '6', '5']);
			this.validateChevronState(['0-6', '6-5', '5-send']);
			this.activeNextStep(['4', '1', '2']);
		}

		//"4": "Attente commande"
		if (this.newState === '4') {
			this.validateState(['0', '6', 'send', '5']);
			this.validateChevronState(['0-6', '6-5', '5-send', 'send-4']);
			this.activeNextStep(['1', '2']);
		}

		if (this.newState === '1') { //Accepté
			this.validateState(['0', '6', 'send', '5', '4']);
			this.validateChevronState(['0-6', '6-5', '5-send', 'send-4', '4-1-2-7']);
			this.activeNextStep([]);
		}

		if (this.newState === '2') { //Refusé
			this.validateState(['0', '6', 'send', '5', '4']);
			this.validateChevronState(['0-6', '6-5', '5-send', 'send-4', '4-1-2-7']);
			this.activeNextStep([]);
		}

		if (this.newState === '7') { //Révisé
			this.validateState(['0', '6', 'send', '5', '4']);
			this.validateChevronState(['0-6', '6-5', '5-send', 'send-4', '4-1-2-7']);
			this.activeNextStep([]);
		}
	}

	private async displaySendDate() {
		const N_setSendDate = this.element.querySelector('#setSendDate') as HTMLElement;
		const N_relunch = this.element.querySelector('ap-quote-relance') as CE_QuoteRelance;

		N_setSendDate.classList.remove('d-none');
		N_setSendDate.classList.add('d-flex');

		const value = await S_Quotes?.getInstance().getNewSendDate(this.id);

		this.formSendDate?.setData(value);

		N_relunch.setSendDate(moment(value.date));
	}

	private async displayValidate() {
		const N_validate = this.element.querySelector('#validate') as HTMLElement;
		N_validate.classList.remove('d-none');
		N_validate.classList.add('d-flex');
	}

	private displayReadyToSend() {
		const N_iframe = this.element.querySelector('#readyToSend') as HTMLIFrameElement;
		const N_cardBody = this.element.querySelector('.card-body') as HTMLElement;

		N_iframe.classList.remove('d-none');
		N_cardBody.classList.add('p-0');
	}

	private hiddenReadyToSend() {
		const N_iframe = this.element.querySelector('#readyToSend') as HTMLIFrameElement;
		const N_cardBody = this.element.querySelector('.card-body') as HTMLElement;
		N_iframe.classList.add('d-none');
		N_cardBody.classList.remove('p-0');
	}

	private displayNewState() {
		const N_state = this.element.querySelector('#new-state') as HTMLElement;
		N_state.classList.remove('d-none');

		const translate: { [key: string]: string } = {
			0: 'En cours',
			send: 'Envoyé',
			4: 'En attente commande',
			1: 'Accepté',
			2: 'Refusé'
		};

		N_state.innerHTML = `État&nbsp;<span>${translate[this.newState] || this.newState}</span>&nbsp;sélectionné`;
	}

	private hiddenNewState() {
		const N_state = this.element.querySelector('#new-state') as HTMLElement;
		N_state.classList.add('d-none');
	}
}

export default ChangeState;
