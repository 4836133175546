// CORE
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

// TEMPLATE
// LIBS
import SettingsApps from '@libs/Settings';
import Utilsbills from '../Utilsbills';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
import M_EditCompta from '../../modals/customers/EditCompta';

// CUSTOM_ELEMENT
// SERVICE
import S_C_Bills from '@services/Customer/CustomerBillService';

class ComptaBillTab extends HTMLElement {
	public static readonly tagName: string = 'ap-compta-bill-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private _gridOptions: GridOptions = {};

	private _infosBill: { [key: string]: any } = {};

	private _comptaDetailsCredit: { [key: string]: any } = {};

	private getFormData: () => any = () => { };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		const data = (SettingsApps.getInstance().get('COMPTA') || {}) as { [key: string]: any };

		if (data.disable) {
			this.classList.add('d-none');
		}

		this.setAttribute('permission', 'BILLS._CUSTOMERS._COMPTA.SHOW');

		this.innerHTML = `
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Comptabilité">
						<i class="icon icon-ri-money-euro"></i>
					</div>
					<span>Comptabilité</span>
				</a>
			</li>
        `;

		this.id = '';
	}

	public postInit() {
		this.update();
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');
		N_div.id = this.idTab;
		N_div.innerHTML = `
			<div class="d-flex flex-column h-100">
                <div class="title">

                    Export Comptable

					<div class="ml-auto">

						<button class="btn btn-info d-none" id="edit" permission="BILLS._CUSTOMERS._COMPTA.EDIT" type="button">
							<i class="icon icon-edit mr-2"></i> Editer
						</button>
						
						<button class="btn btn-info" id="export" permission="BILLS._CUSTOMERS._COMPTA.EXPORT" type="button">
							<i class="icon icon-solid-file-export mr-2"></i> Exporter
						</button>

						<button class="btn btn-info" id="update"  type="button">
							<i class="icon icon-solid-sync mr-2"></i> Mise à jours
						</button>

						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>

                </div>
				<div class="alert alert-danger d-none" id="alert"></div>
                <div class="flex-grow-1 h-100 ag-theme-alpine grid"></div>
            </div>
		`;

		const N_edit = N_div.querySelector('#edit') as HTMLButtonElement;

		N_edit.addEventListener('click', () => {
			new M_EditCompta(this.data, this._infosBill.globalPrice).open().then((data) => {
				this.gridOptions.api?.forEachNode((node) => {
					if (data[node.data.compta]) {
						node.setDataValue('debit', data[node.data.compta]);
					}
				});

				this._comptaDetailsCredit = data;
			});
		});

		const N_update = N_div.querySelector('#update') as HTMLButtonElement;

		N_update.addEventListener('click', async () => {
			const data = await S_C_Bills.getInstance().getComptaData(this.getFormData());
			this._gridOptions.api?.setRowData(data);
		});

		const N_export = N_div.querySelector('#export') as HTMLButtonElement;

		N_export.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			this._gridOptions.api?.forEachNodeAfterFilter(node => {
				data.push(node.data);
			});

			Utilsbills.exportCompta(data, this._infosBill.infos.number);
		});

		N_container.append(N_div);

		this.initGrid();
	}

	private initGrid() {
		this._gridOptions = agUtils.french({
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Journal',
					field: 'log'
				},
				{
					headerName: 'Date de pièce',
					field: 'date'
				},
				{
					headerName: 'Compte',
					field: 'compta'
				},
				{
					headerName: 'Libellé compte',
					field: 'customer'
				},
				{
					headerName: 'N° de pièces (Facture)',
					field: 'bill.number'
				},
				{
					headerName: 'Libellé Mouvement',
					field: 'bill.object'
				},
				{
					headerName: 'Débit',
					field: 'debit',
					cellClass: ['text-right', 'text-monospace'],
					cellRenderer: (params) => {
						if (params.value) {
							return Decimal.setDisplayNumber(params.value).humanizeNumber();
						} else {
							return '';
						}
					}
				},
				{
					headerName: 'Crédit',
					field: 'credit',
					cellClass: ['text-right', 'text-monospace'],
					cellRenderer: (params) => {
						if (params.value) {
							return Decimal.setDisplayNumber(params.value).humanizeNumber();
						} else {
							return '';
						}
					}
				},
				{
					headerName: 'CODE TVA',
					field: 'tva'
				}
			]
		} as GridOptions);

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	public set infosBill(value: { [key: string]: any }) {
		if (value.infos.type === 'credit' || value.infos.type === 'credit-error') {
			const N_edit = document.querySelector(`#${this.idTab} #edit`) as HTMLButtonElement;
			N_edit.classList.remove('d-none');
		}

		this._infosBill = value;
	}

	public set data(data: any[]) {
		if (data[0] && data[0].log === 'ERR') {
			const N_container = document.querySelector(`#${this.idTab}`) as HTMLElement;
			const N_alert = N_container.querySelector('#alert') as HTMLElement;
			const N_edit = N_container.querySelector('#edit') as HTMLButtonElement;
			const N_update = N_container.querySelector('#update') as HTMLButtonElement;
			const N_export = N_container.querySelector('#export') as HTMLButtonElement;

			N_edit.disabled = true;
			N_update.disabled = true;
			N_export.disabled = true;

			N_alert.classList.remove('d-none');
			N_alert.innerHTML = data[0].bill.object;
		}

		this.gridOptions.api?.setRowData(data);
	}

	public get data(): any[] {
		const results: any[] = [];

		this._gridOptions.api?.forEachNode((node) => {
			results.push(node.data);
		});

		return results;
	}

	public setCallback(cb: () => any) {
		this.getFormData = cb;
	}

	public async update() {
		const data = await S_C_Bills.getInstance().getComptaData(this.getFormData());
		this._gridOptions.api?.setRowData(data);
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public set comptaDetailsCredit(value: { [key: string]: any }) {
		this._comptaDetailsCredit = value || {};
	}

	public get comptaDetailsCredit() {
		return this._comptaDetailsCredit;
	}

	public static register() {
		customElements.define(ComptaBillTab.tagName, ComptaBillTab);
	}
}

export default ComptaBillTab;
