// CORE
import { Alert, toaster } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
// LIBS
import C_CommandProvider from '@modules/Commands/js/controllers/Command.Provider';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_Product from '@services/Product/ProductService';

/**
 * Classe pour récupérer des élémenents de contextMenu liées aux produits
 */
class ProductContextMenu {
	/**
	 * Retourne le contextMenu pour commander le produit passé en paramètre
	 * @param product
	 * @param disabled
	 * @returns
	 */
	public static orderProduct(products: { [key: string]: any }[], disabled: boolean = false, cb: () => void = () => { }) {
		return {
			name: `Commander ${products.length > 1 ? 'les produits' : 'le produit'}`,
			disabled,
			icon: '<i class="icon icon-solid-cart-plus"></i>',
			action: async () => {
				this.createOrder(products, cb);
			}
		};
	}

	public static async createOrder(products: { [key: string]: any }[], cb: () => void = () => { }) {
		const uniq = _.uniqBy(products, 'defaultProvider.id');

		if (uniq.length === 1) {
			const product = products[0];
			try {
				const lastOrder = await S_Product.getInstance().getLastOrderProvider(product._id);

				if (lastOrder && lastOrder.length) {
					Alert.prompt(
						'Commande en cours',
						`
							<span class="font-weight-bold"> ${lastOrder.length} commande${lastOrder.length > 1 ? 's' : ''} </span> en cours chez le fournisseur : <span class="font-weight-bold">${product.defaultProvider.id}</span >
							<br>
							<br>
							Veuillez sélectionner une commande:
						`,
						{
							values: [{ value: '-1', name: 'Nouvelle commande' }, ...lastOrder.map((item: any) => { return { value: item.id, name: item.text }; })],
							type: 'select'
						}
					).then((orderSelect) => {
						cb();
						if (orderSelect !== '-1') {
							C_CommandProvider.open(orderSelect as string, {
								type: 'product',
								products: products.map(item => item._id)
							});
						} else {
							C_CommandProvider.open(null, {
								type: 'product',
								products: products.map(item => item._id)
							});
						}
					});
				} else {
					cb();
					C_CommandProvider.open(null, {
						type: 'product',
						products: products.map(item => item._id)
					});
				}
			} catch (e: any) {
				if (e.response?.data) {
					toaster.error(e.response?.data);
				} else if (e instanceof Error) {
					toaster.error(e.message);
				} else {
					toaster.error('Impossible de créer une commande', 'Erreur serveur');
				}
			}
		} else {
			toaster.error('Impossible de créer une commande', 'Fournisseurs différents');
		}
	}
}
export default ProductContextMenu;
