import { global } from '@autoprog/core-client';

import Utils from '@libs/utils/Utils';

import _ from 'lodash';
import axios from 'axios';

type getAllData = {
	rowData: any,
	settings: { [key: string]: any }
};

type saveData = {
	ok: boolean,
	err?: string,
	data: {
		data: any,
		history?: any
	}
};
class GenericService {
	protected baseURI: string = '/api/';
	protected functionURI: string = '/function/';

	protected constructor(protected urlService: string) { }

	get uri(): string {
		return this.baseURI.concat(this.urlService);
	}

	get uriFunction(): string {
		return this.functionURI.concat(this.urlService);
	}

	public async getAll(): Promise<any> {
		return axios.get(`${this.uri}`).then(reponse => reponse.data.data);
	}

	/**
	 * Renvoie les données pour l'ag-grid. 
	 * Si un ID de société est spécifié, les données seront filtrées pour n'afficher que celles de cette société.
	 * @param companyID l'ID de la société
	 * @returns 
	 */
	public async getDataToAgGrid(abortController?: AbortController): Promise<getAllData> {
		return axios.get(`${this.uri}/all`, {
			params: {
				companyID: Utils.companyID,
				user: Utils.userID
			},
			signal: abortController?.signal
		}).then(response => {
			if (response.data.ok) {
				return response.data.data;
			}

			return {};
		});
	}

	public async getDataToAgGridByID(id: string): Promise<getAllData> {
		return axios.get(`${this.uri}/ag-grid/${id}`, {
			params: {
				user: Utils.userID
			}
		}).then(response => {
			if (response.data.ok) {
				return response.data.data;
			}

			return {};
		});
	}

	public async getDataToModal(id: string = '', defaultvalue: { [key: string]: any } = {}) {
		const { data } = await axios.get(`${this.uri}/dataToModal`, {
			params: {
				user: Utils.userID,
				companyID: Utils.companyID,
				id,
				defaultvalue
			}
		});

		return data;
	}

	public async getTitleToModal(formData: { [key: string]: any } = {}) {
		const { data } = await axios.post(`${this.uri}/titleToModal`, { data: formData });

		return data;
	}

	public async getById(id: string): Promise<any | undefined> {
		const { data } = await axios.get(`${this.uri}/${id}`);
		return data.data;
	}

	public async getNewRelunchDate(id: string) {
		const { data } = await axios.get(`${this.uri}/newRelunchDate/${id}`);
		return data.data;
	}

	public async setNewRelunchDate(data: { [key: string]: any }, id: string) {
		await axios.put(`${this.uri}/newRelunchDate/${id}`, {
			data,
			user: Utils.userID
		});
	}

	public async getNewSendDate(id: string) {
		const { data } = await axios.get(`${this.uri}/newSendDate/${id}`);
		return data.data;
	}

	public async setNewSendDate(data: { [key: string]: any }, id: string) {
		await axios.put(`${this.uri}/newSendDate/${id}`, {
			data,
			user: Utils.userID
		});
	}

	public async save(data: { [key: string]: any }, otherData: { [key: string]: any } = {}): Promise<saveData> {
		//pour eviter que _id vaut chaine vide
		if (!data._id) {
			delete data._id;
		}
		const result = await axios.post<saveData>(`${this.uri}/save`, {
			user: Utils.userID,
			companyID: Utils.companyID,
			data,
			otherData
		});

		return result.data;
	}

	public async duplicate(id: string, otherData: { [key: string]: any } = {}): Promise<any> {
		const { data } = await axios.post(`${this.uri}/duplicate`, {
			id,
			user: Utils.userID,
			companyID: Utils.companyID,
			otherData
		});

		return data;
	}

	public async delete(id: string) {
		return axios.delete(`${this.uri}/${id}`).then(response => response.data.data);
	}

	public createSelect2URL(): string {
		return `${this.uri}/by-pattern`;
	}

	public async searchByPattern(searchPartern: string, refData: { [key: string]: any } = {}) {
		const { data } = await axios.get(`${this.createSelect2URL()}`, {
			params: {
				search: searchPartern || '',
				refData
			}
		});

		return data.data;
	}

	public async getDataToSelect2ByID(id: string) {
		const { data } = await axios.get(`${this.uri}/dataToSelect2ById/${id}`);

		return data.data;
	}

	public async getDisplayRefByID(id: string) {
		const { data } = await axios.get(`${this.uri}/displayRefById/${id}`);

		return data.data;
	}

	protected async get_function(func: string, params: { [key: string]: any } = {}) {
		const { data } = await axios.get(`${this.uriFunction}/${func}`, { params });
		return data.data;
	}

	protected async post_function(func: string, params: { [key: string]: any } = {}) {
		const { data } = await axios.post(`${this.uriFunction}/${func}`, params);
		return data.data;
	}

	protected async put_function(func: string, params: { [key: string]: any } = {}) {
		await axios.put(`${this.uriFunction}/${func}`, params);
	}

	//TODO: sert uniquement pour import, a supprimer quand la migration est faite
	get FullUri(): string {
		return `${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}`.concat(this.urlService);
	}

	public async find(selector: { [key: string]: any }, limit: number = 99999): Promise<any> {
		return await axios.post(`${this.FullUri}/_find`, { selector, limit }).then((result) => result.data);
	}

	public async create(doc: any): Promise<any> {
		doc._id = doc._id || _.uniqueId(Date.now().toString(36) + '_');
		return axios.put(`${this.FullUri}/${doc._id}`, doc).then(() => {
			return doc;
		}).catch(error => {
			throw error.response.data;
		});
	}

	public async updateMultiple(docs: { [key: string]: any }[]): Promise<any> {
		return await axios.post(`${this.FullUri}/_bulk_docs`, { docs });
	}
	//TODO: fin

	/**
	* Retourne la clé a exporter pour les données avec une référence
	* @returns le nom de la clé
	*/
	public getExportKey(): string {
		return '_id';
	}

	/**
	* Exporte les données de la table dans un fichier au format csv
	* @param cols
	* @param separator
	* @param filter
	*/
	public async exportData(cols: { [key: string]: any }, separator: string, filter: { [key: string]: any }): Promise<any> {
		const csvData = await axios.post(`${this.uri}/export`, {
			cols,
			separator,
			filter
		});
		return csvData.data.data;
	}
}

export default GenericService;
