// CORE
import { Form, global } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../../../tpl/modals/deliveries/editPage/target.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

//SERVICE
import S_C_Contact from '@services/Customer/CustomerContactService';
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';
import S_P_Contact from '@services/Provider/ProviderContactService';
import S_Provider from '@services/Provider/ProviderService';

type TargetData = {
	infos: {
		customer: string,
		contactIDCustomer: string,
		order: string,
		orderNumber: string,
		provider: string,
		contactIDProvider: string,
		other: string,
		contact: string,
		businessName: string //Objet de la commande
	},
	type: string
};

class Target extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null;

	private businessName: string;
	private createFrom: string;

	constructor(data: TargetData, createFrom: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		//On récupère l'objet de la commande
		this.businessName = data.infos.businessName;
		this.createFrom = createFrom;

		this.on('opened', async () => {
			this.init();

			this.updateHiddenFrom(createFrom);

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const formData = this.form?.getData() as { [key: string]: any };

					formData.infos.customer = await S_Customer.getInstance().getDataToSelect2ByID(formData.infos.customer);
					formData.infos.contactIDCustomer = await S_C_Contact.getInstance().getDataToSelect2ByID(formData.infos.contactIDCustomer);
					formData.infos.order = await S_C_Order.getInstance().getDataToSelect2ByID(formData.infos.order);

					formData.infos.provider = await S_Provider.getInstance().getDataToSelect2ByID(formData.infos.provider);
					formData.infos.contactIDProvider = await S_P_Contact.getInstance().getDataToSelect2ByID(formData.infos.contactIDProvider);

					const data = _.merge(formData, { infos: { businessName: this.businessName } });

					this.resolve(data);
				}));
			});

			this.updateType();
			this.initEvents();
			this.updateButtonSave();
		});
	}

	private initEvents() {
		const N_type = this.element.querySelector('[name="type"]') as HTMLSelectElement;
		const N_other = this.element.querySelector('[name="infos.other"]') as HTMLInputElement;

		N_type.addEventListener('change', () => {
			this.updateType();
			this.updateButtonSave();
		});

		N_other.addEventListener('input', () => {
			this.updateButtonSave();
		});

		let cancelEventCustomer = false;
		this.selectPostinit['infos.customer'].on('change', async (customer) => {
			if (!cancelEventCustomer) {
				const contacts = await S_C_Contact.getInstance().getByCustomerToSelect2(customer as string);

				if (contacts.length && customer) {
					this.form?.setDataByName('infos.contactIDCustomer', contacts[0]);
				} else {
					this.form?.setDataByName('infos.contactIDCustomer', { id: '', text: '' });
				}

				this.form?.setDataByName('infos.order', '');
				this.form?.setDataByName('infos.orderNumber', '');
				this.businessName = '';

				this.updateButtonSave();
			}
		});

		this.selectPostinit['infos.order'].on('change', async (value) => {
			if (value) {
				cancelEventCustomer = true;

				const orderData = await S_C_Order.getInstance().getById(value as string);
				const customer = await S_Customer.getInstance().getDataToSelect2ByID(orderData.infos.customer);
				const contact = await S_C_Contact.getInstance().getDataToSelect2ByID(orderData.infos.contact);

				this.form?.setDataByName('infos.orderNumber', orderData.infos.number);
				this.form?.setDataByName('infos.customer', customer);
				this.form?.setDataByName('infos.contactIDCustomer', contact);
				this.businessName = orderData.infos.label;

				cancelEventCustomer = false;
			} else {
				this.form?.setDataByName('infos.orderNumber', '');
				this.businessName = '';
			}

			this.updateButtonSave();
		});

		this.selectPostinit['infos.provider'].on('change', async (provider) => {
			const contacts = await S_P_Contact.getInstance().getByProviderToSelect2(provider as string);

			if (contacts.length && provider) {
				this.form?.setDataByName('infos.contactIDProvider', contacts[0]);
			} else {
				this.form?.setDataByName('infos.contactIDProvider', { id: '', text: '' });
			}

			this.updateButtonSave();
		});
	}

	private updateHiddenFrom(createFrom: string) {
		if (createFrom === 'order') {
			const N_list = this.element.querySelectorAll('[data-hidden-from="order"]');

			for (const N_el of N_list) {
				N_el.classList.add('d-none');
			}
		}
	}

	private updateType() {
		const type = this.form?.getDataByName('type');

		const N_customer = this.element.querySelectorAll('[data-type="customer"]') as NodeListOf<HTMLElement>;
		const N_provider = this.element.querySelectorAll('[data-type="provider"]') as NodeListOf<HTMLElement>;
		const N_other = this.element.querySelectorAll('[data-type="other"]') as NodeListOf<HTMLElement>;

		for (const N_el of N_customer) {
			N_el.classList.add('d-none');
		}
		for (const N_el of N_provider) {
			N_el.classList.add('d-none');
		}
		for (const N_el of N_other) {
			N_el.classList.add('d-none');
		}

		if (type === 'customer') {
			for (const N_el of N_customer) {
				N_el.classList.remove('d-none');
			}
		}

		if (type === 'provider') {
			for (const N_el of N_provider) {
				N_el.classList.remove('d-none');
			}
		}

		if (type === 'other') {
			for (const N_el of N_other) {
				N_el.classList.remove('d-none');
			}
		}
	}

	private init() {
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_contactIDCustomer = this.element.querySelector('[name="infos.contactIDCustomer"]') as CE_Select2;
		const N_order = this.element.querySelector('[name="infos.order"]') as CE_Select2;

		N_customer.create(this.element, { disabled: this.createFrom === 'order' });

		N_contactIDCustomer.setRef({ id_customer: N_customer.selectElement! });
		N_contactIDCustomer.create(this.element);

		N_order.setRef({ 'infos.customer': N_customer.selectElement! });
		N_order.create(this.element);

		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['infos.contactIDCustomer'] = N_contactIDCustomer;
		this.selectPostinit['infos.order'] = N_order;

		const N_provider = this.element.querySelector('[name="infos.provider"]') as CE_Select2;
		const N_contactIDProvider = this.element.querySelector('[name="infos.contactIDProvider"]') as CE_Select2;

		N_provider.create(this.element);

		N_contactIDProvider.setRef({ id_provider: N_provider.selectElement! });
		N_contactIDProvider.create(this.element);

		this.selectPostinit['infos.provider'] = N_provider;
		this.selectPostinit['infos.contactIDProvider'] = N_contactIDProvider;

		if (global.IS_MOBILE) {
			this.initMobileView();
		}
	}

	private initMobileView() {
		const N_modal_document = this.element.querySelector('[role="document"]');
		const N_modal_content = this.element.querySelector('.modal-content');
		N_modal_document?.classList.add('modal-dialog-scrollable');
		N_modal_document?.classList.remove('modal-40');
		N_modal_content?.classList.add('h-100');
	}

	private async setData(data: TargetData) {
		const res: { [key: string]: any } = {
			infos: {
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer),
				contactIDCustomer: await S_C_Contact.getInstance().getDataToSelect2ByID(data.infos.contactIDCustomer),
				order: await S_C_Order.getInstance().getDataToSelect2ByID(data.infos.order),
				orderNumber: data.infos.orderNumber,
				provider: await S_Provider.getInstance().getDataToSelect2ByID(data.infos.provider),
				contactIDProvider: await S_P_Contact.getInstance().getDataToSelect2ByID(data.infos.contactIDProvider),
				other: data.infos.other,
				contact: data.infos.contact
			},
			type: data.type
		};

		this.form?.setData(res);
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}

	private updateButtonSave() {
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;
		const type = this.form?.getDataByName('type');

		const customer = this.form?.getDataByName('infos.customer') as string;
		const provider = this.form?.getDataByName('infos.provider') as string;
		const other = this.form?.getDataByName('infos.other') as string;

		if (type === 'customer') {
			N_save.disabled = !customer;
		}

		if (type === 'provider') {
			N_save.disabled = !provider;
		}

		if (type === 'other') {
			N_save.disabled = !other;
		}
	}
}

export default Target;
