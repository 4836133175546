// CORE
import { global, toaster } from '@autoprog/core-client';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

// TEMPLATE
import T_ListSelectOrder from '../../../tpl/modals/providers/ListSelectOrder.html';

// LIBS
import GridOptionsManager from '@managers/GridOptionsManagers';
import Keyboard from '@libs/Keyboard';
import Modal from '@libs/Modal';
import agUtils from '@libs/agGrid/french';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';

class ListSelectOrder extends Modal {
	private gridOptions: GridOptions = {};

	private isElectron = global.IS_ELECTRON;

	/**
	 * Constructeur.
	 * Si un ID de société est donné, les commandes à sélectionner seront filtrées pour n'afficher que celles de cette société.
	 * 
	 * @param companyID (facultatif) l'ID de la société
	 */
	constructor() {
		super({
			tpl: T_ListSelectOrder,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const displayCols = ['infos.internalNumber', 'infos.number', 'infos.customer', 'infos.label', 'infos.date', 'infos.sites', 'infos.contact', 'infos.price', 'state'];

			const columnDefs = GridOptionsManager.getInstance().getDefaultColumns('commands-customer');

			for (const item of columnDefs) {
				item.hide = !displayCols.includes(item.field!);

				item.checkboxSelection = item.field === 'infos.internalNumber';
			}

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			const N_title = this.element.querySelector('#title') as HTMLButtonElement;

			let selectData: { [key: string]: any } = {};

			this.gridOptions = agUtils.french<GridOptions>({
				rowSelection: 'single',
				columnDefs,
				floatingFilter: !this.isElectron,
				suppressRowClickSelection: true,
				suppressContextMenu: true,
				defaultColDef: {
					filter: 'agTextColumnFilter',
					filterParams: {
						newRowsAction: 'keep'
					},
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					sortable: true,
					suppressMenu: true,
					resizable: true
				},
				onGridReady: (params) => {
					S_C_Order.getInstance().getDataToAgGrid().then((data) => {
						params.api?.setRowData(data.rowData);
						params.api?.sizeColumnsToFit();
					}).catch(() => {
						toaster.error('Erreur lors de la récupération des données');
					});
				},
				onRowSelected: (params) => {
					selectData = {};
					N_save.disabled = true;
					N_title.innerHTML = '';
					params.api.forEachNode((node) => {
						if (node.isSelected()) {
							selectData = params.node.data._id;
							N_title.innerHTML = `Commande sélectionnée : ${params.node.data.infos.internalNumber}`;
							N_save.disabled = false;
						}
					});
				}

			});

			const N_grid = this.element.querySelector('#grid');
			if (N_grid) {
				new Grid(N_grid as HTMLElement, this.gridOptions, { modules: AllModules });
			}

			N_save.addEventListener('click', () => {
				this.resolve(selectData);
			});

			const N_search = this.element.querySelector('#search') as HTMLInputElement;
			const N_container_search = this.element.querySelector('#container_search') as HTMLElement;

			if (this.isElectron) {
				N_container_search.classList.add('d-flex');
			} else {
				N_container_search.classList.add('d-none');
			}

			new Keyboard(N_search, {
				eventClick: global.CLICK_EVENT_TACTILE,
				parent: this.element.querySelector('#container-keyboard') as HTMLElement
			});

			N_search.addEventListener('input', () => {
				this.gridOptions.api?.setQuickFilter(N_search.value);
			});
		});
	}
}

export default ListSelectOrder;
