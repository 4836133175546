import { toaster } from '@autoprog/core-client';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

import S_Reminders from '@services/RemindersService';
import S_Users from '@services/User/UserService';

import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter';
import SelectFilter from '@libs/agGrid/SelectFilter';

import M_addReminder from '@libs/modals/AddReminder';

import _ from 'lodash';
import moment from 'moment';

class RemindersTab extends HTMLElement {
	public static readonly tagName: string = 'ap-reminders-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private table: string = '';

	private documentId: string = '';

	private _gridOptions: GridOptions = {};

	private N_el: HTMLElement | null = null;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		const type = this.getAttribute('type');

		if (type === 'page') {
			this.innerHTML = `
				<li ap-hidden="mobile">
					<a data-toggle="tab" href="#${this.idTab}" role="tab">
						<div class="icon-container" tooltip="Rappels">
							<i class="icon icon-ri-notification-3-fill"></i>
						</div>
						<span>Rappels</span>
					</a>
				</li>
			`;
		} else {
			this.innerHTML = `
				<li class="nav-item border-right" ap-hidden="mobile">
					<a class="nav-link px-3 position-relative" data-toggle="tab" href="#${this.idTab}" role="tab">
						Rappels
					</a>
				</li>
			`;
		}

		this.removeAttribute('type');
		this.removeAttribute('id');
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this._gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'h-100', 'fade');
		this.N_el.setAttribute('role', 'tabpanel');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="title">
					Rappels
                    <div class="ml-auto d-flex align-items-center">
                        <button class="btn btn-success" type="button" id="add">
                            <i class="icon icon-solid-plus mr-2"></i>Ajouter
                        </button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
                    </div>
				</div>
				<div class="h-100 ag-theme-alpine grid"></div>
			</div>
		`;

		N_container.append(this.N_el);
		this.initGrid();
		this.initButton();
	}

	private async initGrid() {
		const users = await S_Users.getInstance().getAll();

		this._gridOptions = agUtils.french<GridOptions>({
			localeText: { noRowsToShow: 'Aucun rappel' },
			animateRows: true,
			rowData: this._gridOptions.rowData || [],
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowDragManaged: true,
			suppressContextMenu: true,
			defaultColDef: {
				suppressMenu: true,
				resizable: true,
				filter: true,
				sortable: true,
				floatingFilter: true
			},
			columnDefs: [
				{
					headerName: 'Personne',
					field: 'value.user',
					width: 200,
					suppressSizeToFit: true,
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					valueGetter: (params) => {
						const user = _.find(users, { _id: params.data.value.user }) as any;
						return `${user.lastname} ${user.firstname}`;
					}
				}, {
					headerName: 'Date',
					field: 'value.date',
					sort: 'desc',
					width: 250,
					suppressSizeToFit: true,
					filter: 'agNumberColumnFilter',
					floatingFilterComponent: DatePickerRangeFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					cellRenderer: (params) => {
						return moment(params.value, 'x').format('DD/MM/YYYY HH:mm');
					}
				}, {
					headerName: 'État',
					field: 'value.state',
					width: 200,
					filter: 'agTextColumnFilter',
					floatingFilterComponent: SelectFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true,
						options: {
							data: [{
								text: 'Actif',
								id: '0'
							}, {
								text: 'Désactivé',
								id: '1'
							}]
						}
					},
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						let result = 'Actif';
						if (params.value === '0') {
							result = 'Actif';
						}
						if (params.value === '1') {
							result = 'Désactivé';
						}
						return result;
					}
				}, {
					headerName: 'Description',
					filter: false,
					field: 'value.description'
				}, {
					headerName: 'Action',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					suppressSizeToFit: true,
					width: 150,
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						const state = params.data.value.state;

						const N_toggleState = document.createElement('button');

						N_toggleState.classList.add('h-100', 'py-0', 'btn-transparent');

						N_toggleState.innerHTML = '<i class="text-grey-600 h5 icon icon-solid-bell-slash"></i>';
						N_toggleState.type = 'button';

						// '0' => Actif
						if (state === '0') {
							N_toggleState.setAttribute('tooltip', 'Désactiver');
							N_toggleState.innerHTML = '<i class="text-grey-600 h5 icon icon-solid-bell-slash"></i>';
						}

						// '1' => 'Désactivé'
						if (state === '1') {
							N_toggleState.setAttribute('tooltip', 'Réactiver');
							N_toggleState.innerHTML = '<i class="text-green h5 icon icon-solid-bell"></i>';
						}

						N_toggleState.addEventListener('click', async () => {
							const newData = { ...params.data.value };

							newData._id = params.data.id;
							newData.state = state === '0' ? '1' : '0';

							const res = await S_Reminders.getInstance().save(newData);

							if (res.ok) {
								toaster.success("Changement d'état réussi");

								params.data.value = newData;

								params.api?.resetRowHeights();

								params.api?.refreshCells({ force: true });
							} else {
								console.error(res.err);
								toaster.error("Erreur lors du changement d'état");
							}
						});

						const N_edit = document.createElement('button');

						N_edit.classList.add('h-100', 'py-0', 'btn-transparent');
						N_edit.setAttribute('tooltip', 'Éditer');
						N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';
						N_edit.type = 'button';

						N_edit.addEventListener('click', () => {
							new M_addReminder(this.table, this.documentId, params.data).open().then(async (data) => {
								data.value._id = data.id;

								const res = await S_Reminders.getInstance().save(data.value);

								if (res.ok) {
									toaster.success('Sauvegarde réussie');

									params.data = data;

									params.api?.resetRowHeights();

									params.api?.refreshCells({ force: true });
								} else {
									console.error(res.err);
									toaster.error('Erreur lors de la sauvegarde');
								}
							});
						});

						const N_delete = document.createElement('button');

						N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
						N_delete.setAttribute('confirmation', '');
						N_delete.setAttribute('tooltip', 'Supprimer');
						N_delete.type = 'button';

						N_delete.addEventListener('click', async () => {
							try {
								await S_Reminders.getInstance().delete(params.data.id);
								toaster.success('Suppression réussie');

								params.api?.updateRowData({
									remove: [params.data]
								});
							} catch (err) {
								console.error(err);
								toaster.error('Erreur lors de la suppression');
							}
						});

						N_div.appendChild(N_toggleState);
						N_div.appendChild(N_edit);
						N_div.appendChild(N_delete);

						return N_div;
					}
				}
			],
			onColumnResized: (params) => {
				if (params.finished) {
					params.api.resetRowHeights();
				}
			}
		});

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private initButton() {
		const N_btn = document.querySelector(`#${this.idTab} #add`) as HTMLButtonElement;

		N_btn && N_btn.addEventListener('click', () => {
			let index = 0;

			this._gridOptions.api?.forEachNode(() => {
				index++;
			});

			if (this.table && this.documentId) {
				new M_addReminder(this.table, this.documentId).open().then(async (data) => {
					const res = await S_Reminders.getInstance().save(data.value);

					if (res.ok) {
						data.id = res.data.data._id;

						toaster.success('Sauvegarde réussie');

						this._gridOptions.api?.applyTransaction({
							add: [data]
						});

						this._gridOptions.api?.ensureIndexVisible(index);

						this._gridOptions.api?.refreshCells({
							force: true
						});
					} else {
						console.error(res.err);
						toaster.error('Erreur lors de la sauvegarde');
					}
				});
			}
		});
	}

	public set data(data: any[]) {
		this._gridOptions.rowData = data;
		this._gridOptions.api?.setRowData(data);
	}

	public async init(table: string, documentId: string) {
		this.table = table;
		this.documentId = documentId;

		if (this.documentId && this.table) {
			const data = await S_Reminders.getInstance().getByDocument(table, documentId);
			this.data = data;
		}
	}

	public static register() {
		customElements.define(RemindersTab.tagName, RemindersTab);
	}
}

export default RemindersTab;
