// CORE
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

// TEMPLATE

// LIBS
import Options from '@modules/Quotes/js/libs/GridOptions';
import UtilsQuote from '@modules/Quotes/js/libs/UtilsQuotes';

// PRINTER
// UTILS
import Utils from '@libs/utils/Utils';

// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_Quotes from '@services/QuoteService';

class GroupQuotesTab extends HTMLElement {
	public static readonly tagName: string = 'ap-group-quotes-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private N_el: HTMLElement | null = null;

	private _update = (): void => { };

	private _selectingRows: { [key: string]: boolean } = {};

	private options: Options | null = null;

	private gridOptions: GridOptions = {};

	private globalPrice: number = 0;

	public connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `
            <li>
                <a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Sélection groupes devis">
                    	<i class="icon icon-solid-table"></i>
					</div>
                    <span>Sélection groupes devis</span>
                </a>
            </li>
        `;

		this.removeAttribute('type');
		this.removeAttribute('id');
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'h-100', 'fade');
		this.N_el.setAttribute('role', 'tabpanel');
		this.N_el.id = this.idTab;

		this.N_el.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="title" display="desktop">
					Selection groupe devis
					
					<div class="ml-auto d-flex">
						<div class="px-4 font-weight-bold text-green border-right align-items-center d-flex text-md">
							Montant sélection : <span id="price" class="text-monospace pl-2"></span>
						</div>

						<div class="px-4 d-flex align-items-center text-md">
							<div class="badge pl-2 text-dark">
								Légende :
							</div>
							<small class="d-flex align-items-center text-purple-900 mr-2">
								<div class="badge-legend bg-purple-100">
								</div>
								Catégorie en option
							</small>
						</div>

						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
				</div>

                <div id="grid" class="ag-theme-alpine h-100 w-100"></div>
			</div>
		`;

		N_container.append(this.N_el);
	}

	public async initGroupQuotes(id: string, selectingRows: { [key: string]: boolean }) {
		if (this.N_el) {
			this._selectingRows = selectingRows;
			this.options = new Options();

			const data = await S_Quotes.getInstance().getById(id);

			for (const item of data.data) {
				item.quantityQuote = item.quantity;
				item.quantity = this._selectingRows[item._idGrid];
			}

			this.options.updateInfos(data.infos);
			this.options.updatePref(data.pref);
			this.options.updateQuoteOptions(data.quoteOptions);

			const N_price = this.N_el.querySelector('#price') as HTMLElement;

			const columnsOptions = this.options.gridMaster(this, 'quote');

			columnsOptions.defaultColDef.editable = () => {
				return false;
			};

			columnsOptions.columnDefs[0].hide = true;

			const indexQuantity = columnsOptions.columnDefs.findIndex(item => item.field === 'quantity');

			columnsOptions.columnDefs[indexQuantity].editable = true;

			columnsOptions.columnDefs.splice(indexQuantity, 0, {
				headerName: 'Qté devis',
				field: 'quantityQuote',
				cellClass: 'text-right text-monospace',
				suppressSizeToFit: true,
				width: 120,
				cellRenderer: (params) => {
					return params.value || 0;
				}
			});

			columnsOptions.columnDefs.pop();

			this.gridOptions = agUtils.french<GridOptions>({
				rowData: data.data,
				rowSelection: 'multiple',
				suppressRowClickSelection: true,
				...columnsOptions,
				onGridReady: (params) => {
					params.api.sizeColumnsToFit();
					this.updatePrice(data.pref);
					N_price.innerHTML = Utils.setSuffixAndHumanizeNumber(this.globalPrice, '€', data.pref?.decimalNumber);
				},
				onCellEditingStopped: (params) => {
					params.api.refreshCells({ force: true });
					this.updatePrice(data.pref);
					N_price.innerHTML = Utils.setSuffixAndHumanizeNumber(this.globalPrice, '€', data.pref?.decimalNumber);
				},
				onColumnResized: (params) => {
					if (params.finished) {
						params.api.resetRowHeights();
					}
				},
				getRowStyle: (params: any) => {
					if (params.data.isOption) {
						return {
							'background-color': 'var(--ap-purple-50)',
							color: 'var(--ap-purple-900)'
						};
					} else {
						return {
							background: '',
							color: ''
						};
					}
				}
			});

			const N_grid = this.N_el.querySelector('#grid') as HTMLElement;

			new Grid(N_grid, this.gridOptions, { modules: AllModules });
		}
	}

	private updatePrice(pref: { [key: string]: any }) {
		this._selectingRows = {};

		const data: any[] = [];

		this.gridOptions.api?.forEachNode((node) => {
			this._selectingRows[node.data._idGrid] = node.data.quantity;
			data.push(node.data);
		});

		this.globalPrice = UtilsQuote.calculateGlobalPrice(data, pref).all;

		this._update();
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public get price() {
		return this.globalPrice;
	}

	public get selectingRows() {
		return this._selectingRows;
	}

	public static register() {
		customElements.define(GroupQuotesTab.tagName, GroupQuotesTab);
	}
}

export default GroupQuotesTab;
