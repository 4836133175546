import { LoggedUser } from '@autoprog/core-client';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import agUtils from '@libs/agGrid/french';

import S_Users from '@services/User/UserService';

import M_addPaymentBill from '../../modals/AddLinePaymentBill';

import Decimal from '@libs/utils/Decimal';

import _ from 'lodash';
import moment from 'moment';

class PaymentsTab extends HTMLElement {
	public static readonly tagName: string = 'ap-payment-tab';

	private selectorTab: string = '';

	private idTab: string = '';
	private permission: string = '';

	private _gridOptions: GridOptions = {};

	private getGlobalPriceTTC: () => string = () => { return '0'; };
	private getTable: () => string = () => { return ''; };
	private getID: () => string = () => { return ''; };
	private _updateInfos: () => void = () => { };

	private callback: () => Promise<void> = async () => { };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.permission = (this.getAttribute('mode') || '').toUpperCase();

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Paiements">
						<i class="icon icon-ri-exchange-euro-fill"></i>
						<span class="nav-icon-badge d-none" id="number">0</span>
					</div>	
					<span>Paiements</span>
				</a>
			</li>
		`;

		this.removeAttribute('id');
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');
		N_div.id = this.idTab;
		N_div.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="title">
					Paiements
					<div class="ml-auto">
						<button class="btn btn-add ml-auto" type="button" id="add" permission="BILLS._${this.permission}._PAYMENTS.ADD">
							<i class="icon icon-solid-plus mr-2"></i>Ajouter
						</button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
				</div>
				<div class="h-100 ag-theme-alpine grid"></div>
			</div>
		`;

		N_container.append(N_div);

		this.initGrid();
		this.initButton();
	}

	public setCallback(cb: () => Promise<void>) {
		this.callback = cb;
	}

	public setUpdateInfos(cb: () => void) {
		this._updateInfos = cb;
	}

	private initButton() {
		const N_btn = document.querySelector(`#${this.idTab} #add`) as HTMLButtonElement;

		N_btn.addEventListener('click', async () => {
			let index = 0;

			this._gridOptions.api?.forEachNode(() => {
				index++;
			});

			await this.callback();
			new M_addPaymentBill(this.getTable(), this.getID()).open().then((data) => {
				this._gridOptions.api?.applyTransaction({
					add: [data]
				});

				this._gridOptions.api?.ensureIndexVisible(index);

				this._gridOptions.api?.refreshCells({ force: true });

				this.callback();
			});
		});
	}

	public setGetglobalPriceTTC(cb: () => string) {
		this.getGlobalPriceTTC = cb;
	}

	public setGetTable(cb: () => string) {
		this.getTable = cb;
	}

	public setGetID(cb: () => string) {
		this.getID = cb;
	}

	private async initGrid() {
		const users = await S_Users.getInstance().getAll();

		this._gridOptions = agUtils.french<GridOptions>({
			rowData: this._gridOptions.rowData || [],
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Personne',
					field: 'user',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return '';
						} else {
							const user = _.find(users, { _id: params.value }) as any;
							return `${user.lastname} ${user.firstname}`;
						}
					}
				}, {
					headerName: 'Date',
					field: 'date',
					sort: 'asc',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return '';
						} else {
							return moment(params.value, 'x').format('DD/MM/YYYY');
						}
					}
				}, {
					headerName: 'Montant TTC (€)',
					field: 'price',
					cellClass: 'text-right text-monospace',
					cellRenderer: (params) => {
						const globalPriceTTC = Decimal.setDisplayNumber(this.getGlobalPriceTTC());

						if (params.node.rowPinned) {
							let price = new Decimal(0);

							params.api.forEachNode((node: any) => {
								price = price.plus(Decimal.setDisplayNumber(node.data.price));
							});

							const percent = new Decimal(100).times(price).dividedBy(globalPriceTTC).toDecimalPlaces(2);

							return price.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€') + ' | ' + percent.humanizePercent() + '%';
						} else {
							const price = Decimal.setDisplayNumber(params.value);
							const percent = new Decimal(100).times(price).dividedBy(globalPriceTTC).toDecimalPlaces(2);

							return price.setSuffixAndHumanizeNumber('€') + ' | ' + percent.humanizePercent() + '%';
						}
					}
				}, {
					headerName: 'Type',
					field: 'type',
					cellRenderer: (params) => {
						if (params.data.type === 'Chèque') {
							return `${params.data.type}${params.data.bank ? ' - ' + params.data.bank : ''}${params.data.numberCheque ? ' - N° ' + params.data.numberCheque : ''}`;
						} else if (params.data.type === 'Virement') {
							return `${params.data.type}${params.data.bank ? ' - ' + params.data.bank : ''}`;
						}

						return params.data.type;
					}
				}, {
					headerName: 'Commentaire',
					field: 'comment'
				}, {
					headerName: 'Action',
					width: 80,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return '';
						} else {
							const N_div = document.createElement('div');

							const N_edit = document.createElement('button');

							N_edit.classList.add('h-100', 'py-0', 'btn-transparent');
							N_edit.setAttribute('tooltip', 'Éditer');
							N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';
							N_edit.type = 'button';

							N_edit.setAttribute('permission', `BILLS._${this.permission}._PAYMENTS.EDIT`);

							N_edit.addEventListener('click', () => {
								new M_addPaymentBill(this.getTable(), this.getID(), params.data).open().then((data) => {
									params.data = data;

									params.node.setData(data);

									params.api?.refreshCells({ force: true });

									this.callback();
								});
							});

							N_div.appendChild(N_edit);

							return N_div;
						}
					}
				}
			],
			getContextMenuItems: (params) => {
				const result = [{
					name: 'Ajouter un paiement',
					disabled: !LoggedUser.getInstance().hasPermission(`BILLS._${this.permission}._PAYMENTS.ADD`),
					action: async () => {
						let index = 0;

						params.api?.forEachNode(() => {
							index++;
						});

						await this.callback();
						new M_addPaymentBill(this.getTable(), this.getID()).open().then((data) => {
							params.api?.applyTransaction({
								add: [data]
							});

							params.api?.ensureIndexVisible(index);

							params.api?.refreshCells({ force: true });

							this.callback();
						});
					}
				}];

				return result;
			},
			onRowDataChanged: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);

				if (number !== 0) {
					params.api?.setPinnedBottomRowData([{}]);
				}
			},
			onRowDataUpdated: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);

				params.api?.setPinnedBottomRowData([{}]);
			},
			onGridReady: () => {
				this._updateInfos();
			}
		});

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector('#number') as HTMLElement;

		if (number) {
			N_number.innerHTML = number.toString();
			N_number.classList.remove('d-none');
		} else {
			N_number.classList.add('d-none');
		}
	}

	public set data(data: any[]) {
		this._gridOptions.rowData = data;
		this._gridOptions.api?.setRowData(data);
		this._updateInfos();
	}

	public get data(): any[] {
		const results: any[] = [];

		this._gridOptions.api?.forEachNode((node) => {
			results.push(node.data);
		});

		return results;
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public getPaidPrice() {
		let paidPrice = new Decimal(0);

		this.gridOptions.api?.forEachNode((node) => {
			const item = node.data;

			if (item.price) {
				paidPrice = paidPrice.plus(Decimal.setDisplayNumber(item.price));
			}
		});

		return paidPrice.toNumber();
	}

	public static register() {
		customElements.define(PaymentsTab.tagName, PaymentsTab);
	}
}

export default PaymentsTab;
