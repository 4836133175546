// CORE
import { Alert } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
// LIBS
import Decimal from '@libs/utils/Decimal';

// PRINTER
// UTILS
// MODAL
import M_BillingRequest from '../modals/customers/BillingRequest';

// CUSTOM_ELEMENT
// SERVICE
//TYPE
import BillingRequest from '../type/Customer/billingRequest';

class BillingRequestContextMenu {
	public static checkBillRequest(billingRequest: BillingRequest[]) {
		return new Promise<string>((resolve, reject) => {
			let find = false;

			for (const br of billingRequest) {
				if (br.state === 'waiting' && !br.deleted && !find) {
					find = true;
					break;
				}
			}

			if (find) {
				Alert.confirm('Demande(s) de facturation non traitée(s)', 'Veuillez traiter les demandes de facturation en cours avant de créer une nouvelle facture.', {
					noColor: 'close-modal',
					noText: 'Annuler',
					yesText: 'Accéder aux demandes',
					yesColor: 'validate-modal'
				}).then(() => resolve('open-modal')).catch((e) => reject(e));
			} else {
				resolve('open-bill');
			}
		});
	}

	public static addBillingRequest(disabled: { [key: string]: boolean }, orderRowData: { [key: string]: any }, allRequests: any[], callback: (data: any) => void) {
		const findLast = !!_.find(allRequests, { type: 'last' });

		let price = new Decimal(0);

		for (const item of allRequests) {
			if (item.state === 'waiting') {
				price = price.plus(Decimal.setDisplayNumber(item.price));
			}
		}

		return {
			name: 'Demande de facturation',
			subMenu: [
				{
					name: 'Acompte',
					disabled: disabled.advance || findLast || !!_.find(allRequests, { type: 'advance' }),
					action: async () => {
						const data = await new M_BillingRequest(orderRowData, price, { type: 'advance' }).open();

						data.type = 'advance';
						data.state = 'waiting';

						callback(data);
					}
				}, {
					name: 'Avancement',
					disabled: disabled.normal || findLast,
					action: async () => {
						const order = _.cloneDeep(orderRowData);
						order.infos.advance = '0';
						const data = await new M_BillingRequest(order, price, { type: 'normal' }).open();

						data.type = 'normal';
						data.state = 'waiting';

						callback(data);
					}
				}, {
					name: 'Définitive',
					disabled: disabled.last || findLast,
					action: async () => {
						const data = await new M_BillingRequest(orderRowData, price, { type: 'last' }).open();

						data.type = 'last';
						data.state = 'waiting';

						callback(data);
					}
				}
			]
		};
	}
}

export default BillingRequestContextMenu;
