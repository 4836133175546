import SettingsApps from '@libs/Settings';

import CGVTab, { CGV } from '@libs/customElement/CGVTab';
import BillPrintOptionTab from '../../libs/customElement/BillPrintOption';
import Modal from '@libs/Modal';
import SettingsTab from '../../libs/customElement/SettingsTab';

import T_optionBill from '../../../tpl/modals/customers/optionBill.html';
import Utils from '@libs/utils/Utils';

type OptionBillData = {
	CGV: CGV | null,
	options: {
		print: {
			[key: string]: any
		}
	} | null,
	settings: {
		[key: string]: any
	} | null
};

class OptionBill extends Modal {
	private readonly CGV = 'BILL.CGV';
	private readonly OPTIONS = 'BILL.OPTIONS';
	private readonly SETTINGS = 'BILL';

	private data: OptionBillData;
	private N_CGVTab: CGVTab | null;
	private N_PrintOptionTab: BillPrintOptionTab | null;
	private N_SettingsTab: SettingsTab | null;

	private settings: SettingsApps;

	constructor() {
		super({
			tpl: T_optionBill,
			keyboard: false,
			backdrop: 'static'
		});

		this.settings = SettingsApps.getInstance();
		this.data = this.getData();
		this.N_CGVTab = null;
		this.N_PrintOptionTab = null;
		this.N_SettingsTab = null;

		this.on('opened', async () => {
			await this.init();
			await this.postInit();
		});
	}

	private async init() {
		// On récupère les données des options des factures
		this.initTab();
		this.setData();
		this.initSave();
	}

	private async postInit() {
		await this.N_PrintOptionTab?.postInit();
		this.N_CGVTab!.postInit();
		this.N_SettingsTab!.postInit();
	}

	private initSave() {
		const N_saveBtn = this.element.querySelector('#save') as HTMLButtonElement;
		N_saveBtn.addEventListener('click', () => {
			N_saveBtn.loading(
				new Promise(async (resolve, reject) => {
					try {
						await this.save();
						this.resolve();
					} catch (e) {
						reject(e);
					}
				})
			);
		});
	}

	private save() {
		const CGV = this.N_CGVTab!.data;
		const printOptions = this.N_PrintOptionTab!.data;
		const settings = this.N_SettingsTab!.data;
		this.settings.setGeneral(this.CGV, CGV);
		this.settings.setGeneral(this.OPTIONS, printOptions.options);
		this.settings.setGeneral(this.SETTINGS, settings);
	}

	/**
	 * Initialise les onglets
	 */
	private initTab() {
		// on initialise l'onglet des options d'impression
		this.N_PrintOptionTab = this.element.querySelector(BillPrintOptionTab.tagName) as BillPrintOptionTab;
		this.N_PrintOptionTab.setParentElement(this.element);

		this.N_PrintOptionTab.setGetData(() => {
			return {
				companyID: Utils.companyID,
				...this.N_PrintOptionTab!.data,
				CGV: this.N_CGVTab!.data
			};
		});

		this.N_PrintOptionTab.setID(() => {
			return 'fakeBill';
		});

		// on initialise l'onglet des options de CGV
		this.N_CGVTab = this.element.querySelector(CGVTab.tagName) as CGVTab;
		this.N_CGVTab.setParentElement(this.element);

		// on initialise l'onglet des options de paramtre
		this.N_SettingsTab = this.element.querySelector(SettingsTab.tagName) as SettingsTab;
		this.N_SettingsTab.setParentElement(this.element);
	}

	/**
	 * Initialisation de l'onglet CGV
	 */
	private setData() {
		if (this.data.CGV) {
			this.N_CGVTab!.data = this.data.CGV;
		} else {
			console.error('no CGV found');
		}

		if (this.data.options) {
			this.N_PrintOptionTab!.data = { options: this.data.options };
		} else {
			console.error('no printOptions found');
		}

		if (this.data.settings) {
			this.N_SettingsTab!.data = this.data.settings;
		} else {
			console.error('no settings found');
		}
	}

	/**
	 * Récupère les données des options des factures
	 */
	private getData(): OptionBillData {
		const data = {
			CGV: this.settings.get(this.CGV) as CGV || null,
			options: this.settings.get(this.OPTIONS) as { print: { [key: string]: any } },
			settings: this.settings.get(this.SETTINGS) as { [key: string]: any }
		};
		return data;
	}
}
export default OptionBill;
