// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../tpl/modals/customers/addProduct.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE

class Edit extends Modal {
	constructor() {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_close = this.element.querySelector('.close') as HTMLButtonElement;
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_close.addEventListener('click', (e: MouseEvent) => {
				this.reject();

				e.stopImmediatePropagation();
				e.stopPropagation();
			});

			const N_product = this.element.querySelector('[name="product"]') as CE_Select2;

			N_product.create(this.element);

			N_product.on('change', (value: any) => {
				N_save.disabled = !value;
			});

			const N_form = this.element.querySelector('form') as HTMLFormElement;

			const form = new Form(N_form);

			N_save.addEventListener('click', async () => {
				const data = form.getData() as { [key: string]: any };

				this.resolve(data);
			});

			N_product.postInit();
		});
	}
}

export default Edit;
