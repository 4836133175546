import SettingsApps from '@libs/Settings';

import ConfigService from '@js/services/ConfigService';

class ConfigManager {
	private static instance: ConfigManager;

	private cache: { [key: string]: any } = {};

	private constructor() {
	}

	public async init() {
		this.cache = (await ConfigService.getInstance().getAll()).data;

		this.initProducts();
		this.initOrdersCustomer();
	}

	private initProducts() {
		const settings = (SettingsApps.getInstance().get('COMPTA') || {}) as { [key: string]: any };

		if (this.cache.products && settings.disable) {
			const index = this.cache.products.columns.findIndex((item: any) => item.key === 'comptaVente');
			this.cache.products.columns.splice(index, 1);
		}
	}

	private initOrdersCustomer() {
		const settings = (SettingsApps.getInstance().get('COMPTA') || {}) as { [key: string]: any };

		if (this.cache['commands-customer'] && settings.disable) {
			const index = this.cache['commands-customer'].columns.findIndex((item: any) => item.key === 'compta.error');
			this.cache['commands-customer'].columns.splice(index, 1);
		}
	}

	public getConfig(key: string) {
		this.cache[key] = this.cache[key] || {};
		this.cache[key].columns = this.cache[key].columns || [];
		this.cache[key].grid = this.cache[key].grid || {};
		this.cache[key].displayRef = this.cache[key].displayRef || {};
		this.cache[key].agGrid = this.cache[key].agGrid || {};
		this.cache[key].modal = this.cache[key].modal || {};
		this.cache[key].mobile = this.cache[key].mobile || {};
		this.cache[key].options = this.cache[key].options || {};

		return this.cache[key] || {};
	}

	public getListTables() {
		return Object.keys(this.cache) || [];
	}

	public static getInstance() {
		if (!ConfigManager.instance) {
			ConfigManager.instance = new ConfigManager();
		}

		return ConfigManager.instance;
	}
}

export default ConfigManager;
