// CORE
import { Router } from '@autoprog/core-client';

// NODE_MODULE
import { GetContextMenuItemsParams } from '@ag-grid-enterprise/all-modules';

// TEMPLATE
// LIBS
import C_Database from '@js/controllers/Database';

import C_BillsProvider from '@modules/Bills/js/controllers/Bills.Provider';
import C_OrdersProvider from '../../controllers/Command.Provider';

// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
import M_SetSendDate from '@libs/modals/SetSendDate';

// CUSTOM_ELEMENT
import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

// SERVICE
import Utils from '@js/libs/utils/Utils';

class ListCommand extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Commandes - Fournisseurs',
			type: 'database',
			database: 'commands-provider'
		});

		const N_add = document.querySelector('#add') as HTMLButtonElement;

		N_add.addEventListener('click', () => {
			C_OrdersProvider.open(null);
		}, {
			signal: this.abortSignal
		});
	}

	protected header() {
		const router = Router.getInstance();

		const N_div = document.createElement('div');

		N_div.classList.add('d-flex', 'flex-grow-1');

		N_div.innerHTML = `
			<div class="ml-auto d-flex">
				<button class="btn btn-action" id="recap" permission="ORDERS._PROVIDERS.RECAP_BILLS">
					<i class="icon icon-ri-line-chart-fill"></i>Récapitulatif
				</button>
			</div>
        `;

		const N_recap = N_div.querySelector('#recap') as HTMLButtonElement;
		N_recap.addEventListener('click', () => {
			router.navigate('module/commands_export/provider');
		});

		return N_div;
	}

	protected getContextMenu(params: GetContextMenuItemsParams) {
		const objBill = {
			name: 'Créer une facture',
			icon: '<i class="icon icon-outline-file-invoice-dollar"></i>',
			disabled: params.node.data.bill.state === '2' || params.node.data.bill.state === 'waitingSendDate',
			subMenu: [
				{
					name: 'Acompte/Avancement',
					action: () => {
						C_BillsProvider.open(null, {
							type: 'advance',
							idCommand: params.node.data._id
						});
					}
				},
				{
					name: 'Définitive',
					action: () => {
						C_BillsProvider.open(null, {
							type: 'last',
							idCommand: params.node.data._id
						});
					}
				}
			]
		};

		const sendDate = {
			name: 'Définir date d\'envoi',
			icon: '<i class="icon icon-envelope"></i>',
			disabled: params.node.data.infos.sendDate,
			action: () => {
				const modal = new M_SetSendDate('commands-provider', params.node.data._id);

				modal.open().then(() => {
					this.refreshData();
				});
			}
		};

		return [
			...super.getContextMenu(params),
			'separator',
			sendDate,
			'separator',
			objBill
		];
	}

	protected buttonEdit(params: any) {
		C_OrdersProvider.open(params.data._id);
	}

	protected async buttonDuplicate(params: any) {
		C_OrdersProvider.setMode('duplicate').open(params.data._id);
	}

	protected iconsColumn(params: any) {
		const N_open = document.createElement('ap-open-document') as HTMLElement;
		N_open.dataset.id = params.data._id;
		N_open.dataset.table = 'commands-provider';

		return [N_open];
	}

	protected async onDataUpdate(params: any) {
		let priceTotal = new Decimal(0);
		let priceBill = new Decimal(0);
		let priceCredit = new Decimal(0);
		let deliveryPrice = new Decimal(0);
		let waitingPrice = new Decimal(0);

		params.api.forEachNodeAfterFilter(async (node: any) => {
			priceTotal = priceTotal.plus(node.data.globalPrice.value);
			priceBill = priceBill.plus(node.data.bill.price.value);
			priceCredit = priceCredit.plus(node.data.credit.price.value);
			deliveryPrice = deliveryPrice.plus(node.data.delivery.price.value);

			if (node.data.receipt.state === 'waiting') {
				waitingPrice = waitingPrice.plus(node.data.globalPrice.value);
			}
		});

		const notBill = priceTotal.minus(priceBill).toDecimalPlaces(2);

		priceTotal = priceTotal.toDecimalPlaces(2);
		priceBill = priceBill.toDecimalPlaces(2);
		priceCredit = priceCredit.toDecimalPlaces(2);
		deliveryPrice = deliveryPrice.toDecimalPlaces(2);

		this.setDataTitle([{
			text: 'Global HT',
			price: priceTotal.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Facturé HT',
			price: priceBill.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Restant à facturer HT',
			price: notBill.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'En attente de reception HT',
			price: waitingPrice.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Avoir HT',
			price: priceCredit.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Frais de port HT',
			price: deliveryPrice.setSuffixAndHumanizeNumber('€')
		}]);
	}

	protected getRowStyle(params: any): { [key: string]: string } {
		//facturer a 100%
		if (params.data.bill.state === '2') {
			return {
				'background-color': 'var(--ap-green-50)',
				color: 'var(--ap-green-900)'
			};
		}

		//pas facturer
		if (params.data.bill.state === '0') {
			return {
				'background-color': 'var(--ap-red-50)',
				color: 'var(--ap-red-900)'
			};
		}

		//en cours de facturation
		if (params.data.bill.state === '1') {
			return {
				'background-color': 'var(--ap-orange-50)',
				color: 'var(--ap-orange-900)'
			};
		}

		return {
			'background-color': 'white'
		};
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: '_createBy_',
					type: 'switch',
					filters: [
						{
							value: Utils.userID,
							text: 'Commande personnelle'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'isLate',
					type: 'switch',
					filters: [
						{
							value: 'true',
							text: 'En retard'
						}
					]
				}, {
					type: 'separator'
				}, {
					type: 'title',
					title: 'État facturation : '
				}, {
					column: 'bill.state',
					type: 'checkbox',
					filters: [
						{
							value: '0',
							text: 'Facture en attente de réception',
							color: 'red-200'
						}, {
							value: '1',
							text: 'Facture partielle reçue',
							color: 'orange-200'
						}, {
							value: '2',
							text: 'Facture définitive reçue',
							color: 'green-200'
						}
					]
				}, {
					type: 'separator'
				}, {
					type: 'title',
					title: 'État :'
				}, {
					column: 'receipt.state',
					type: 'checkbox',
					filters: [{
						value: 'inProgress',
						text: 'En cours'
					}, {
						value: 'waiting',
						text: 'En attente de réception'
					}, {
						value: 'partial',
						text: 'Reçue partiellement'
					}, {
						value: 'total',
						text: 'Reçue en totalité'
					}]
				}
			]
		];
	}
}

export default ListCommand;
