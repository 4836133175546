import { LoggedUser, SocketIO } from '@autoprog/core-client';

class Messages {
	private element: HTMLElement;
	private loggedUser: LoggedUser = LoggedUser.getInstance();

	constructor(el: HTMLElement) {
		this.element = el;
		const io = SocketIO.getInstance();

		io.connect().then(() => {
			io.on('messages.value', (value: string) => {
				this.element.innerHTML = value;
			});

			if (this.loggedUser.isLogged) {
				io.emit('messages.init', true);
			}

			this.loggedUser.on('login', () => {
				io.emit('messages.init', true);
			});
		});
	}
}

export default Messages;
