// CORE
// NODE_MODULE
// TEMPLATE
import T_modal from '../../../../tpl/modals/customers/printReport/selectQuote.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT

// SERVICE

type SelectQuoteData = {
	quotes: any[]
};

class SelectQuote extends Modal {
	private checkboxes: HTMLInputElement[] = [];

	constructor(data: SelectQuoteData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			this.checkboxes = [];

			this.initList(data);
			this.initValidator();

			const N_print = this.element.querySelector('#print') as HTMLButtonElement;

			N_print.addEventListener('click', async () => {
				N_print.loading(new Promise(async () => {
					const quotes: any[] = [];

					for (const checkbox of this.checkboxes) {
						if (checkbox.checked) {
							const index = Number.parseInt(checkbox.value);
							quotes.push(data.quotes[index]);
						}
					}

					this.resolve(quotes);
				}));
			});
		});
	}

	private initList(data: SelectQuoteData) {
		const N_quotesList = this.element.querySelector('#quotesList');

		for (const index in data.quotes) {
			const N_li = document.createElement('li') as HTMLLIElement;
			N_li.classList.add('list-group-item');

			const N_div = document.createElement('div') as HTMLDivElement;
			N_div.classList.add('custom-control', 'custom-checkbox');

			const N_input = document.createElement('input') as HTMLInputElement;
			N_input.classList.add('custom-control-input');
			N_input.setAttribute('value', index);
			N_input.setAttribute('type', 'checkbox');
			N_input.setAttribute('id', 'check' + index);

			if (index === '0') {
				N_input.setAttribute('checked', 'checked');
			}

			this.checkboxes.push(N_input);

			const N_label = document.createElement('label') as HTMLLabelElement;
			N_label.classList.add('custom-control-label');
			N_label.setAttribute('for', 'check' + index);
			N_label.innerHTML = data.quotes[index].infos.number;

			N_li.appendChild(N_div);
			N_div.appendChild(N_input);
			N_div.appendChild(N_label);

			N_quotesList?.appendChild(N_li);
		}
	}

	private initValidator() {
		for (const checkbox of this.checkboxes) {
			checkbox.addEventListener('change', () => {
				this.validate();
			});
		}
	}

	private validate() {
		let atLeastOneIsChecked = false;
		for (const checkbox of this.checkboxes) {
			atLeastOneIsChecked = atLeastOneIsChecked || checkbox.checked;
		}

		const N_print = this.element.querySelector('#print') as HTMLButtonElement;
		const N_invalidText = this.element.querySelector('#invalidText') as HTMLSpanElement;

		if (atLeastOneIsChecked) {
			N_print?.removeAttribute('disabled');
			N_invalidText.classList.add('d-none');
		} else {
			N_print?.setAttribute('disabled', 'disabled');
			N_invalidText.classList.remove('d-none');
		}
	}
}

export default SelectQuote;
