// CORE
import { Alert, LoggedUser, Router, toaster, utils } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';
import _ from 'lodash';

// TEMPLATE
// LIBS
import OpenDocuments from '@libs/customElement/OpenDocuments';

import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';
import CE_SitesFinalCustomerReadonly from '@libs/customElement/Sites-FinalCustomer-Readonly';
import CE_TVA from '@libs/customElement/TVA';

import BillTab from '../libs/customElement/Bills';
import BillingRequestContextMenu from '../libs/BillingRequestContextMenu';
import BillingRequestTab from '../libs/customElement/customers/BillingRequest';
import ComptaTab from '../libs/customElement/customers/ComptaTab';
import DeliveryTab from '../libs/customElement/customers/DeliveryTab';
import MaterialsTab from '../libs/customElement/customers/MaterialsTab';
import OutputStockTab from '../libs/customElement/customers/OutputStockTab';
import ProviderOrderTab from '../libs/customElement/customers/ProviderOrder';
import QuotesTab from '../libs/customElement/customers/QuotesTab';
import TrackingProductTab from '@modules/TrackingProduct/js/customElement/TrackingProductTab';

import Decimal from '@libs/utils/Decimal';
import Loader from '@libs/Loader';
import Notifications from '@modules/Apps/js/libs/Notifications';

import ModalManager from '@managers/ModalManager';

import C_BillsCustomer from '@modules/Bills/js/controllers/Bills.Customer';

// PRINTER

// UTILS
// MODAL
import M_AdditionalInformation from '../modals/customers/editPage/AdditionalInformation';
import M_DetailsOrder from '../modals/customers/editPage/DetailsOrder';
import M_GeneralInformation from '../modals/customers/editPage/GeneralInformation';

import M_PrintPreview from '@js/libs/modals/PrintPreview';
import M_SelectQuote from '../modals/customers/printReport/SelectQuote';

// CUSTOM_ELEMENT
// SERVICE
import S_C_Address from '@services/Customer/CustomerAddressService';
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';

class CommandCustomerCtrl extends ControllerPageID {
	private N_BillTab: BillTab | null = null;
	private N_ProviderOrderTab: ProviderOrderTab | null = null;
	private N_DeliveryTab: DeliveryTab | null = null;
	private N_BillingRequestTab: BillingRequestTab | null = null;
	private N_MaterialsTab: MaterialsTab | null = null;
	private N_TrackingProductTab: TrackingProductTab | null = null;
	private N_QuotesTab: QuotesTab | null = null;
	private N_OutputStockTab: OutputStockTab | null = null;

	private N_ComptaTab: ComptaTab | null = null;

	private dataBills: { [key: string]: any } = {};

	constructor(el: HTMLElement) {
		super(el);

		this.reloadAfterSave = true;

		const query = utils.getQuery();
		const id = query.id || '';

		this.options = CommandCustomerCtrl.options || {};

		CommandCustomerCtrl.options = {};

		this.routeReturn = 'module/commands/customers';

		this.init('commands-customer', id);
	}

	private static options: { [key: string]: any } = {};
	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		CommandCustomerCtrl.options = options || {};

		if (id) {
			await OpenDocuments.checkOpen(id, 'commands-customer');
			Router.getInstance().navigate(`/module/order/customer?id=${id}`);
		} else {
			Router.getInstance().navigate('/module/order/customer');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		this.initTabs();
		this.initEditButton();

		let data = await this.getData();

		data = await this.fromQuotes(data);

		this.setData(data);

		this.updateDeliveryTypeAddress();

		this.postInit();

		this.initFullscreen();

		this.updateEditButton();
	}

	private initEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsOrder = this.el.querySelector('[data-edit="detailsOrder"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			const res = {
				infos: {
					manager: this.form?.getDataByName('infos.manager') as string,
					number: this.form?.getDataByName('infos.number') as string,
					addonNumber: this.form?.getDataByName('infos.addonNumber') as string,
					customer: this.form?.getDataByName('infos.customer') as string,
					contact: this.form?.getDataByName('infos.contact') as string
				}
			};

			new M_GeneralInformation(res).open().then(async (data) => {
				this.setDataForm(data);
				if (res.infos.customer !== data.infos.customer.id) {
					await this.updateAddress();
				}
				this.updateSaveButton();
				this.updateTitle();
			});
		});

		N_edit_DetailsOrder.addEventListener('click', () => {
			const res = {
				infos: {
					label: this.form?.getDataByName('infos.label') as string,
					description: this.form?.getDataByName('infos.description') as string
				}
			};

			new M_DetailsOrder(res).open().then((data) => {
				this.setDataForm(data);
				this.updateSaveButton();
			});
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			const res = {
				infos: {
					customer: this.form?.getDataByName('infos.customer') as string,
					addressID: this.form?.getDataByName('infos.addressID') as string,
					fullAddress: this.form?.getDataByName('infos.fullAddress') as string,
					addressIDDelivery: this.form?.getDataByName('infos.addressIDDelivery') as string,
					fullAddressDelivery: this.form?.getDataByName('infos.fullAddressDelivery') as string,
					date: this.form?.getDataByName('infos.date') as Moment,
					entryDate: this.form?.getDataByName('infos.entryDate') as Moment,
					advance: this.form?.getDataByName('infos.advance') as string,
					tva: this.form?.getDataByName('infos.tva') as string,
					deadlinePayment: this.form?.getDataByName('infos.deadlinePayment') as string,
					typeDeadlinePayment: this.form?.getDataByName('infos.typeDeadlinePayment') as string,
					fixedDateDeadline: this.form?.getDataByName('infos.fixedDateDeadline') as Moment,
					finalCustomer: this.form?.getDataByName('infos.finalCustomer') as string,
					hasFinalCustomer: this.form?.getDataByName('infos.hasFinalCustomer') as string,
					sites: this.form?.getDataByName('infos.sites') as string[],
					siteCustom: this.form?.getDataByName('infos.siteCustom') as string,
					comments: this.form?.getDataByName('infos.comments') as string,
					detailsBill: this.form?.getDataByName('infos.detailsBill') as string,
					autoliquidation: this.form?.getDataByName('infos.autoliquidation') as boolean,
					nonValorisee: this.form?.getDataByName('infos.nonValorisee') as boolean,
					delivery: {
						typeAddressCustomer: this.form?.getDataByName('infos.delivery.typeAddressCustomer') as string,
						addressIDCustomer: this.form?.getDataByName('infos.delivery.addressIDCustomer') as string,
						siteCustomer: this.form?.getDataByName('infos.delivery.siteCustomer') as string,
						fullAddress: this.form?.getDataByName('infos.delivery.fullAddress') as string,
						GPSCoordinates: this.form?.getDataByName('infos.delivery.GPSCoordinates') as string
					}

				}
			};

			new M_AdditionalInformation(res).open().then((data) => {
				this.setDataForm(data);

				this.updateDeliveryTypeAddress();
				this.updateNumber();
				this.updateSaveButton();
				this.updateTitle();
			});
		});
	}

	private async fromQuotes(data: DataServer) {
		//FIXME: a suppr verif dans l'enregistrement   
		if (this.options.type === 'newOrder') {
			//verif pour le delais de paiement
			if (data.data.infos.customer) {
				if (!data.data.infos.deadlinePayment) {
					const tmp = await S_Customer.getInstance().getById(data.data.infos.customer);

					if (!tmp.deadlinePayment) {
						try {
							toaster.info('Aucun délais paiement renseigné');

							const newData = await ModalManager.getInstance().openWithReturnData('customers', tmp._id);

							data.infos.deadlinePayment = newData.deadlinePayment;
							data.infos.typeDeadlinePayment = newData.typeDeadlinePayment;
							data.infos.fixedDateDeadline = newData.fixedDateDeadline;
						} catch (e) {
						}
					}
				}
			}
		}

		return data;
	}

	protected initButton() {
		super.initButton();

		const N_cancel = this.el.querySelector('#cancel') as HTMLElement;
		const N_report = this.el.querySelector('#report') as HTMLDivElement;
		const N_advance = this.el.querySelector('#advance') as HTMLButtonElement;
		const N_last = this.el.querySelector('#last') as HTMLButtonElement;

		N_report.addEventListener('click', async () => {
			const quotes = this.N_QuotesTab?.data;

			if (quotes.length > 1) {
				const res = {
					quotes
				};

				new M_SelectQuote(res).open().then(async (data) => {
					const ids = data.map((item: any) => item._id);
					(await new M_PrintPreview('commands-customer', this.id, 'pdfReport').getToken(ids)).open();
				});
			} else {
				const ids = quotes.map((item: any) => item._id);
				(await new M_PrintPreview('commands-customer', this.id, 'pdfReport').getToken(ids)).open();
			}
		});

		N_advance.addEventListener('click', async () => {
			const billingRequests = this.N_BillingRequestTab?.data;

			BillingRequestContextMenu.checkBillRequest(billingRequests!).then((value) => {
				if (value === 'open-modal') {
					this.N_BillingRequestTab?.openTab();
				}

				if (value === 'open-bill') {
					C_BillsCustomer.open(null, {
						type: 'advance',
						idCommand: this.id
					});
				}
			}).catch(() => {
			});
		});

		N_last.addEventListener('click', async () => {
			C_BillsCustomer.open(null, {
				type: 'last',
				idCommand: this.id
			});
		});

		const title = '<i class="icon icon-warning text-danger"></i> Annulation de la commande <i class="icon icon-warning text-danger"></i>';
		const content = '<span class="font-weight-bold">Attention</span>, cette opération va réinitialiser le stock et les devis associés à la commande seront refusés.<br><br>Voulez-vous continuez ?';

		N_cancel.addEventListener('click', () => {
			Alert.confirm(title, content).then(async () => {
				Loader.getInstance().open();
				await this.save(true);

				await S_C_Order.getInstance().cancelOrder(this.id);

				toaster.success(`Commande N° ${this.form?.getDataByName('infos.number')} annulée`);

				Loader.getInstance().close();

				this.return();
			}).catch(() => {
				Loader.getInstance().close();
			});
		});

		const N_closeNotification = this.el.querySelector('#close_notification') as HTMLButtonElement;

		if (this.options.notification) {
			(N_closeNotification.parentNode as HTMLElement).classList.remove('d-none');
		}

		N_closeNotification.addEventListener('click', async () => {
			await Notifications.closeNotification(this.options.notification);
			this.return();
		});
	}

	private initTabs() {
		this.initProviderOrder();
		this.initBill();
		this.initDelivery();
		this.initBillingRequest();
		this.initProducts();
		this.initTrackingProduct();
		this.initQuotes();
		this.initCompta();
	}

	private initProviderOrder() {
		this.N_ProviderOrderTab = this.el.querySelector(ProviderOrderTab.tagName) as ProviderOrderTab;

		this.N_ProviderOrderTab.setParentElement(this.el);

		this.N_ProviderOrderTab.setIdOrder(() => {
			return this.id;
		});
	}

	private async initBill() {
		this.N_BillTab = this.el.querySelector(BillTab.tagName) as BillTab;

		this.N_BillTab.setMode('customers');

		this.N_BillTab.setParentElement(this.el);

		this.N_BillTab.setOpenModal((id) => {
			C_BillsCustomer.open(id);
		});

		this.N_BillTab.setIdCommand(() => {
			return this.id;
		});
	}

	private async initDelivery() {
		this.N_DeliveryTab = this.el.querySelector(DeliveryTab.tagName) as DeliveryTab;

		this.N_DeliveryTab.setParentElement(this.el);

		this.N_DeliveryTab.setIdOrder(() => {
			return this.id;
		});
	}

	private async initBillingRequest() {
		this.N_BillingRequestTab = this.el.querySelector(BillingRequestTab.tagName) as BillingRequestTab;

		this.N_BillingRequestTab.setParentElement(this.el);

		this.N_BillingRequestTab.setDataOrder(() => {
			return this.saveData;
		});

		this.N_BillingRequestTab.setOnUpdate(() => {
			this.updateBillingRequestSum();
			this.updateSaveButton();
		});

		this.N_BillingRequestTab.setIsSave(() => {
			return this.isSave;
		});
	}

	private initProducts() {
		this.N_MaterialsTab = this.el.querySelector(MaterialsTab.tagName) as MaterialsTab;

		this.N_MaterialsTab.setParentElement(this.el);

		this.N_MaterialsTab.setIdOrder(() => {
			return this.id;
		});

		this.N_MaterialsTab.setOnUpdate(() => {
			this.updateSaveButton();
		});

		this.N_OutputStockTab = this.el.querySelector(OutputStockTab.tagName) as OutputStockTab;

		this.N_OutputStockTab.setParentElement(this.el);

		this.N_OutputStockTab.setOnUpdate(() => {
			this.updateSaveButton();
		});
	}

	private initTrackingProduct() {
		this.N_TrackingProductTab = this.el.querySelector('ap-tracking-product-tab') as TrackingProductTab;

		this.N_TrackingProductTab.setParentElement(this.el, TrackingProductTab.customerOrderMode);
	}

	private initQuotes() {
		this.N_QuotesTab = this.el.querySelector(QuotesTab.tagName) as QuotesTab;

		this.N_QuotesTab.setParentElement(this.el);

		this.N_QuotesTab.setIdOrder(() => {
			return this.id;
		});

		this.N_QuotesTab.setCustomer(() => {
			return this.form?.getDataByName('infos.customer');
		});

		this.N_QuotesTab.setOnUpdate(async () => {
			this.reload();
		});

		this.N_QuotesTab.setIsSave(() => {
			return new Promise<void>((resolve, reject) => {
				if (!this.isSave) {
					reject(new Error('Veuillez sauvegarder la commande afin d\'accéder à l\'enregistrement du devis.'));
				} else {
					resolve();
				}
			});
		});

		this.N_QuotesTab.setCheckDelete((quote) => {
			return new Promise<void>((resolve, reject) => {
				//si la commande n'est pas sauvegardé
				if (!this.isSave) {
					reject(new Error('Veuillez sauvegarder la commande'));
				}

				//si le montant non facturé > montant du devis
				if (this.dataBills.sum.notBill.value < quote.price) {
					reject(new Error('Le montant à facturer est inférieur au montant du devis'));
				}

				const billingRequests = this.N_BillingRequestTab?.data || [];

				let find = false;
				for (const br of billingRequests) {
					if (br.state === 'waiting' && !br.deleted && !find) {
						find = true;
						break;
					}
				}

				//si il y a des demandes de facturation en attente
				if (find) {
					reject(new Error('Veuillez terminer ou supprimer toutes les demandes de facturation en cours'));
				} else {
					resolve();
				}
			});
		});
	}

	private async initCompta() {
		this.N_ComptaTab = this.el.querySelector(ComptaTab.tagName) as ComptaTab;

		this.N_ComptaTab.setParentElement(this.el);
	}

	private get isLock() {
		const finish = this.form?.getDataByName('infos.finish');
		return finish || !LoggedUser.getInstance().hasPermission('ORDERS._CUSTOMERS.EDIT');
	}

	protected convertData(data: { [key: string]: any; }) {
		if (data.infos.tva) {
			data.infos.tva = CE_TVA.convertValue(data.infos.tva);
		}

		return data;
	}

	protected setDataForm(data: { [key: string]: any; }) {
		super.setDataForm(data);

		const N_sites = this.el.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
		N_sites.update(data.infos.hasFinalCustomer);
	}

	protected setData(data: DataServer) {
		super.setData(data);

		this.N_BillTab!.data = data.bills || {};
		this.N_DeliveryTab!.data = data.deliveries || {};
		this.N_ProviderOrderTab!.data = data.orderProvider.data || [];

		this.N_OutputStockTab!.setData({
			idOrder: this.id,
			locations: data.stock.locations,
			products: data.stock.materials
		});
		this.N_MaterialsTab!.data = data.data.materials || [];
		this.N_BillingRequestTab!.data = data.data.billingRequest;
		this.N_TrackingProductTab!.data = data.data.trackingProducts;

		this.N_ComptaTab!.data = data.compta.data || [];

		this.N_QuotesTab!.setUsers(data.users);
		this.N_QuotesTab!.data = data.data.quotes;

		const N_cancel = this.el.querySelector('#cancel') as HTMLElement;

		if (data.bills.data.length) {
			N_cancel.classList.add('disabled');
		} else {
			N_cancel.classList.add('cursor-pointer');
		}

		this.N_BillingRequestTab!.disabled = {
			advance: data.bills.data.length >= 1,
			normal: data.bills.sum.notBill.value === 0,
			last: data.bills.sum.notBill.value === 0,
			createBill: data.compta.error || data.bills.sum.notBill.value === 0
		};

		this.dataBills = data.bills;

		const N_state = this.el.querySelector('#state') as HTMLSelectElement;
		const colState = _.find(this.config.columns, { key: 'state' }) as any;

		N_state.innerHTML = colState.object[data.deliveries.state];

		this.updateBillingRequestSum();

		const N_notBillPrice = this.el.querySelector('#not_bill_price') as HTMLElement;
		const N_billPrice = this.el.querySelector('#bill_price') as HTMLElement;
		const N_credit = this.el.querySelector('#credit') as HTMLElement;

		const N_advance = this.el.querySelector('#advance') as HTMLButtonElement;
		const N_last = this.el.querySelector('#last') as HTMLButtonElement;

		N_billPrice.innerHTML = data.bills.sum.bill.formattedValue;
		N_notBillPrice.innerHTML = data.bills.sum.notBill.formattedValue;
		N_credit.innerHTML = data.bills.sum.credit.formattedValue;

		if (data.bills.sum.notBill.value === 0 || data.compta.error || data.data.infos.nonValorisee) {
			N_advance.disabled = true;
			N_last.disabled = true;
		} else {
			N_advance.disabled = false;
			N_last.disabled = false;
		}

		const N_report = this.el.querySelector('#report') as HTMLElement;
		N_report.classList.remove('disabled');

		const N_numberQuotes = this.el.querySelector('#numberQuotes') as HTMLElement;
		N_numberQuotes.innerHTML = data.data.quotes.length.toString();

		const N_comptaErrorAlert = this.el.querySelector('#comptaError') as HTMLDivElement;

		if (data.compta.error) {
			N_comptaErrorAlert.classList.remove('d-none');
		} else {
			N_comptaErrorAlert.classList.add('d-none');
		}

		const N_nonValoriseeAlert = this.el.querySelector('#nonValorisee') as HTMLDivElement;
		if (data.data.infos.nonValorisee) {
			N_nonValoriseeAlert.classList.remove('d-none');
		} else {
			N_nonValoriseeAlert.classList.add('d-none');
		}
	}

	protected postInit(): void {
		super.postInit();

		this.N_TrackingProductTab!.postInit();
		this.N_BillTab!.postInit();
		this.N_ProviderOrderTab!.postInit();
		this.N_DeliveryTab!.postInit();
		this.N_BillingRequestTab!.postInit();
		this.N_MaterialsTab!.postInit();
		this.N_QuotesTab!.postInit();
		this.N_OutputStockTab!.postInit();
		this.N_ComptaTab!.postInit();
	}

	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		if (newData.infos.date) {
			newData.infos.date = newData.infos.date.format('x');
		}

		if (newData.infos.deliveryDate) {
			newData.infos.deliveryDate = newData.infos.deliveryDate.format('x');
		}

		if (newData.infos.entryDate) {
			newData.infos.entryDate = newData.infos.entryDate.format('x');
		}

		newData.trackingProducts = this.N_TrackingProductTab!.data;
		newData.billingRequest = this.N_BillingRequestTab!.data;
		newData.materials = this.N_MaterialsTab!.data || [];

		return newData;
	}

	private updateBillingRequestSum() {
		const N_billing_request_price = this.el.querySelector('#billing_request_price') as HTMLElement;
		const globalPrice = Decimal.setDisplayNumber(this.form?.getDataByName('infos.price') as string);

		const data = this.N_BillingRequestTab!.data;

		let price = new Decimal(0);

		let hasLast = false;
		for (const item of data) {
			if (item.state === 'waiting') {
				if (item.type === 'last') {
					hasLast = true;
					break;
				} else {
					price = price.plus(Decimal.setDisplayNumber(item.price));
				}
			}
		}

		if (hasLast) {
			N_billing_request_price.innerHTML = this.dataBills.sum.notBill.formattedValue.toString();
		} else {
			const percent = price.times(100).dividedBy(globalPrice);

			N_billing_request_price.innerHTML = `${price.setSuffixAndHumanizeNumber('€')} | ${percent.humanizePercent()}%`;
		}
	}

	protected updateSaveButton() {
		super.updateSaveButton();

		const N_advance = this.el.querySelector('#advance') as HTMLButtonElement;
		const N_last = this.el.querySelector('#last') as HTMLButtonElement;
		const N_report = this.el.querySelector('#report') as HTMLElement;

		N_advance.disabled = true;
		N_last.disabled = true;
		N_report.classList.add('disabled');
	}

	private async updateAddress() {
		this.form?.setDataByName('infos.addressID', '');
		this.form?.setDataByName('infos.fullAddress', '');

		this.form?.setDataByName('infos.addressIDDelivery', '');
		this.form?.setDataByName('infos.fullAddressDelivery', '');

		this.form?.setDataByName('infos.delivery.addressIDCustomer', '');
		this.form?.setDataByName('infos.delivery.siteCustomer', '');
		this.form?.setDataByName('infos.delivery.fullAddress', '');
		this.form?.setDataByName('infos.delivery.GPSCoordinates', '');

		const customer = this.form?.getDataByName('infos.customer') as string;

		if (customer) {
			let typeAddressCustomer = this.form?.getDataByName('infos.delivery.typeAddressCustomer') as string;
			typeAddressCustomer = typeAddressCustomer || 'customer';

			const customerBillAddressList = await S_C_Address.getInstance().getBillAddressByCustomer(customer) || [];

			if (customerBillAddressList[0]) {
				const id = customerBillAddressList[0]._id as string;

				const addressIDCustomer = await S_C_Address.getInstance().getDataToSelect2ByID(id);
				const addressInfos = await S_C_Address.getInstance().getAddressInfos(id);

				this.form?.setDataByName('infos.addressID', addressIDCustomer);
				this.form?.setDataByName('infos.fullAddress', addressInfos.fullAddress);
			}

			const customerDeliveryAddressList = await S_C_Address.getInstance().getDeliveryAddressByCustomer(customer) || [];

			if (customerDeliveryAddressList[0]) {
				const id = customerDeliveryAddressList[0]._id as string;

				const addressIDCustomer = await S_C_Address.getInstance().getDataToSelect2ByID(id);
				const addressInfos = await S_C_Address.getInstance().getAddressInfos(id);

				if (typeAddressCustomer === 'customer') {
					this.form?.setDataByName('infos.delivery.addressIDCustomer', addressIDCustomer);
					this.form?.setDataByName('infos.delivery.fullAddress', addressInfos.fullAddress);
					this.form?.setDataByName('infos.delivery.GPSCoordinates', addressInfos.GPSCoordinates);
				}
			}
		}
	}

	private updateEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsOrder = this.el.querySelector('[data-edit="detailsOrder"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.disabled = this.isLock;
		N_edit_DetailsOrder.disabled = this.isLock;
		N_edit_AdditionalInformation.disabled = this.isLock;
	}

	private updateDeliveryTypeAddress() {
		let type = this.form?.getDataByName('infos.delivery.typeAddressCustomer') as string;
		type = type || 'customer';

		const N_addressTypeCustomer = this.el.querySelectorAll('[address-type="customer"]') as NodeListOf<HTMLElement>;
		const N_addressTypeSite = this.el.querySelectorAll('[address-type="site"]') as NodeListOf<HTMLElement>;

		for (const N_el of N_addressTypeCustomer) {
			N_el.classList.add('d-none');
		}
		for (const N_el of N_addressTypeSite) {
			N_el.classList.add('d-none');
		}

		if (type === 'customer') {
			for (const N_el of N_addressTypeCustomer) {
				N_el.classList.remove('d-none');
			}
		}

		if (type === 'site') {
			for (const N_el of N_addressTypeSite) {
				N_el.classList.remove('d-none');
			}
		}
	}

	private updateNumber() {
		const internalNumber = this.form?.getDataByName('infos.internalNumber');
		const entryDate = this.form?.getDataByName('infos.entryDate');

		const value = S_C_Order.getInstance().updateNumberByDate(internalNumber || '', entryDate);

		this.form?.setDataByName('infos.internalNumber', value);
	}
}

export default CommandCustomerCtrl;
