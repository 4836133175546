// CORE
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions, GroupCellRenderer } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// TEMPLATE
// LIBS
import C_CommandProvider from '../../../controllers/Command.Provider';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
import M_AddProduct from '../../../modals/customers/AddProduct';

import P_Print from '../../../libs/printer/MaterialsByBrand';

// CUSTOM_ELEMENT
// SERVICE
import S_Product from '@services/Product/ProductService';
import S_Provider from '@services/Provider/ProviderService';
import S_Quote from '@services/QuoteService';

class MaterialsTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-customers-materials-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private _update = (): void => { };

	private _gridOptions: GridOptions = {};

	private N_add_order: HTMLButtonElement | null = null;

	private N_btnDisable: HTMLButtonElement[] = [];
	private N_el: HTMLElement | null = null;

	private _idOrder: () => string = () => { return ''; };
	private _suppressContextMenu: () => boolean = () => { return false; };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		const type = this.getAttribute('type');

		if (type === 'page') {
			this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Liste matériels">
						<i class="icon icon-solid-box-open"></i>
					</div>
					<span>Liste matériels</span>
				</a>
			</li>
		`;
		} else {
			this.innerHTML = `
				<li class="nav-item border-right">
					<a class="nav-link px-3 position-relative" data-toggle="tab" href="#${this.idTab}" role="tab">
						Liste matériels
					</a>
				</li>
			`;
		}

		this.id = '';
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public active() {
		const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

		N_li.classList.add('active');

		const N_grid = document.querySelector(`#${this.idTab}`) as HTMLElement;

		N_grid.classList.add('active', 'show');
	}

	public disableButton() {
		for (const N_el of this.N_btnDisable) {
			N_el!.classList.add('d-none');
		}
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'h-100', 'fade');
		this.N_el.setAttribute('role', 'tabpanel');

		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="d-flex flex-column h-100">
                <div class="title">
					Liste du matériels par devis
					<div class="ml-auto d-flex">
						<div class="d-flex align-items-center">
							<div class="mr-2 text-md text-muted" id="price"></div>
							<button class="btn btn-info" type="button" id="add_order" disabled permission="ORDERS._PROVIDERS.ADD">
								Créer une commande fournisseur
							</button>
							<button class="btn btn-print-modal mx-2" type="button" id="print">
								<i class="icon icon-printer"></i>
								Imprimer
							</button>
							<button class="btn btn-info" type="button" id="add_materials">
								Ajout materiels
							</button>
						</div>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
                </div>
                <div class="h-100 ag-theme-alpine grid"></div>
            </div>
        `;

		const N_print = this.N_el.querySelector('#print') as HTMLButtonElement;
		const N_add_materials = this.N_el.querySelector('#add_materials') as HTMLButtonElement;
		this.N_add_order = this.N_el.querySelector('#add_order') as HTMLButtonElement;

		N_print.addEventListener('click', () => {
			const content: any[] = [];
			this.gridOptions.api?.forEachNode((node) => {
				if (node.data) {
					content.push(node.data);
				}
			});

			new P_Print(this._idOrder(), content);
		});

		N_add_materials.addEventListener('click', () => {
			new M_AddProduct().open().then(async (data) => {
				data.reference = data.product;
				data.product = await S_Product.getInstance().getById(data.product);

				if (data.product.defaultProvider) {
					data.defaultProvider = await S_Provider.getInstance().getDisplayRefByID(data.product.defaultProvider.id);
				}

				data.unit = 'U';
				data.quote = '_add_product';

				this._gridOptions.api?.applyTransaction({
					add: [data]
				});
			});
		});

		this.N_add_order.addEventListener('click', () => {
			this.createOrder();
		});

		this.N_btnDisable.push(N_print, N_add_materials, this.N_add_order);

		N_container.append(this.N_el);

		this.initGrid();
	}

	public setIdOrder(cb: () => string) {
		this._idOrder = cb;
	}

	public setSuppressContextMenu(cb: () => boolean) {
		this._suppressContextMenu = cb;
	}

	private initGrid() {
		this._gridOptions = agUtils.french<GridOptions>({
			localeText: { noRowsToShow: 'Aucun produit' },
			columnDefs: [
				{
					headerName: 'Devis',
					field: 'quote',
					rowGroup: true,
					hide: true
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: 'product',
					checkboxSelection: true,
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					headerCheckboxSelection: true,
					valueGetter: (params: any) => {
						return params.data.product[S_Product.getInstance().referenceKey];
					},
					cellRenderer: (params: any) => {
						return S_Product.getInstance().cellRendererByData(params.data.product);
					}
				},
				{
					headerName: 'Libellé',
					field: 'product.name',
					floatingFilter: true,
					filter: 'agTextColumnFilter'
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					floatingFilter: true,
					filter: 'agTextColumnFilter'
				},
				{
					headerName: 'Fournisseur',
					field: 'defaultProvider',
					floatingFilter: true,
					filter: 'agTextColumnFilter'
				},
				{
					headerName: 'Prix de vente',
					field: 'product.price',
					width: 150,
					cellClass: ['text-right', 'text-monospace'],
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						return Decimal.setDisplayNumber(params.value).setSuffixAndHumanizeNumber('€');
					}
				},
				{
					headerName: 'Quantité',
					field: 'quantity',
					width: 100,
					cellClass: ['text-right', 'text-monospace'],
					suppressSizeToFit: true
				},
				{
					headerName: 'Unité',
					field: 'unit',
					width: 80,
					suppressSizeToFit: true
				},
				{
					headerName: 'Commentaire',
					field: 'comment',
					floatingFilter: false,
					editable: true,
					cellRenderer: (params) => {
						return '<i class="icon icon-edit text-light-blue-600 mr-2"></i>' + (params.value || '');
					}
				}
			],
			groupRowRendererParams: {
				innerRenderer: (params: any) => {
					const el = document.createElement('div');
					if (params.value === '_add_product') {
						return 'Ajout materiel';
					} else if (params.value) {
						S_Quote.getInstance().getById(params.value).then((data) => {
							el.innerHTML = `${data.infos.number} : ${data.infos.label}`;
						}).catch(() => {
							el.innerHTML = 'Autres';
						});
					} else {
						el.innerHTML = 'Autres';
					}
					return el;
				}
			},
			groupRowRenderer: GroupCellRenderer,
			groupSelectsChildren: true,
			groupUseEntireRow: true,
			groupDefaultExpanded: 1,
			rowSelection: 'multiple',
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			suppressContextMenu: this._suppressContextMenu(),
			onRowSelected: (params) => {
				const rows = params.api.getSelectedRows();
				this.N_add_order!.disabled = !rows.length;
			},
			onCellEditingStopped: () => {
				this._update();
			},
			getContextMenuItems: (params: any) => {
				const rowSelected = params.api?.getSelectedNodes();

				return [
					{
						name: 'Créer un bon de commande fournisseur',
						disabled: rowSelected.length === 0,
						action: async () => {
							this.createOrder();
						}
					}
				];
			}
		});

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private async createOrder() {
		const rowSelected = this.gridOptions.api?.getSelectedNodes();

		C_CommandProvider.open(null, {
			type: 'command',
			materials: _.map(rowSelected, (item) => {
				return {
					quoteCustomer: item.data.quote,
					reference: item.data.product._id,
					maxQuantity: item.data.quantity,
					quantity: item.data.quantity
				};
			}),
			idOrder: this._idOrder()
		});
	}

	private updatePrice() {
		let result = new Decimal(0);

		this.gridOptions.api?.forEachNode((node) => {
			if (!node.group) {
				const quantity = Decimal.setDisplayNumber(node.data.quantity);
				const tmp = Decimal.setDisplayNumber(node.data.product.price).times(quantity);
				result = result.plus(tmp);
			}
		});

		const N_el = this.N_el!.querySelector('#price') as HTMLElement;
		N_el.innerHTML = 'Prix de vente : ' + result.setSuffixAndHumanizeNumber('€');
	}

	public set data(data: any[]) {
		this.gridOptions.rowData = data;
		this.gridOptions.api?.setRowData(data);
		this.updatePrice();
	}

	public get data(): any[] {
		const results: any[] = [];

		this._gridOptions.api?.stopEditing();

		this._gridOptions.api?.forEachNode((node) => {
			if (!node.group) {
				const item = _.cloneDeep(node.data);
				delete item.product;
				results.push(item);
			}
		});

		return results;
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public static register() {
		customElements.define(MaterialsTab.tagName, MaterialsTab);
	}
}

export default MaterialsTab;
