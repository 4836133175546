// CORE

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../../tpl/modals/providers/editPage/amounts.html';

// LIBS
import Modal from '@libs/EditPageModal';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
// CUSTOM_ELEMENT

// SERVICE

type AmountsData = {
	infos: {
		tva: string
	},
	globalPrice: string,
	tva: string,
	globalPriceTTC: string
};

class Amounts extends Modal<AmountsData> {
	constructor(data: AmountsData) {
		super(data, T_modal);
	}

	protected initEventButton() {
		super.initEventButton();

		const N_globalPrice = this.element.querySelector('[name="globalPrice"]') as HTMLInputElement;
		const N_globalPriceTTC = this.element.querySelector('[name="globalPriceTTC"]') as HTMLInputElement;
		const N_tvaPercent = this.element.querySelector('[name="infos.tva"]') as HTMLSelectElement;
		const N_tva = this.element.querySelector('[name="tva"]') as HTMLInputElement;
		const N_button_edit_tva = this.element.querySelector('#editTva') as HTMLButtonElement;

		//Les calculs de prix sont basées sur le site suivant : https://www.l-expert-comptable.com/calculateurs/calcul-tva.html
		//Pour vérifier que les montants sont bons : https://entreprendre.service-public.fr/simulateur/calcul/convertisseurPrixHTouTTC

		N_globalPrice.addEventListener('input', () => {
			const priceHT = Decimal.setDisplayNumber(this.form?.getDataByName('globalPrice') as string);

			const tvaRate = N_tvaPercent.value === '-1' ? 0 : Number(N_tvaPercent.value);

			this.form?.setData({
				tva: priceHT.times(tvaRate / 100).toDecimalPlaces(2).humanizeNumber(),
				globalPriceTTC: priceHT.times(1 + tvaRate / 100).toDecimalPlaces(2).humanizeNumber()
			});
		});

		N_globalPriceTTC.addEventListener('input', () => {
			const priceTTC = Decimal.setDisplayNumber(this.form?.getDataByName('globalPriceTTC') as string);

			const tvaRate = N_tvaPercent.value === '-1' ? 0 : Number(N_tvaPercent.value);

			this.form?.setData({
				tva: priceTTC.dividedBy(100 + tvaRate).times(tvaRate).toDecimalPlaces(2).humanizeNumber(),
				globalPrice: priceTTC.dividedBy(1 + tvaRate / 100).toDecimalPlaces(2).humanizeNumber()
			});
		});

		N_tvaPercent.addEventListener('change', () => {
			const priceHT = Decimal.setDisplayNumber(this.form?.getDataByName('globalPrice') as string);

			const tvaRate = N_tvaPercent.value === '-1' ? 0 : Number(N_tvaPercent.value);

			this.form?.setData({
				tva: priceHT.times(tvaRate / 100).toDecimalPlaces(2).humanizeNumber(),
				globalPriceTTC: priceHT.times(1 + tvaRate / 100).toDecimalPlaces(2).humanizeNumber()
			});
		});

		N_tva.addEventListener('input', () => {
			const price = Decimal.setDisplayNumber(this.form?.getDataByName('globalPrice') as string);
			const tva = Decimal.setDisplayNumber(this.form?.getDataByName('tva') as string);

			this.form?.setDataByName('globalPriceTTC', price.plus(tva).toDecimalPlaces(2).humanizeNumber());
		});

		N_button_edit_tva.addEventListener('change', () => {
			if (N_tva.getAttribute('readonly') !== null) {
				N_tva.removeAttribute('readonly');
			} else {
				N_tva.setAttribute('readonly', '');
			}
		});
	}

	protected async setData(data: AmountsData) {
		const res: { [key: string]: any } = {
			infos: {
				tva: data.infos.tva
			},
			globalPrice: data.globalPrice,
			tva: data.tva,
			globalPriceTTC: data.globalPriceTTC
		};

		this.form?.setData(res);
	}
}

export default Amounts;
