// CORE
// NODE_MODULE
// TEMPLATE

// LIBS
import C_C_Bills from '@modules/Bills/js/controllers/Bills.Customer';
import C_C_Order from '@modules/Commands/js/controllers/Command.Customer';
import C_Delivery from '@modules/Deliveries_Receipts/js/controllers/Delivery';
import C_P_Bills from '@modules/Bills/js/controllers/Bills.Provider';
import C_P_Order from '@modules/Commands/js/controllers/Command.Provider';
import C_Quote from '@modules/Quotes/js/controllers/AddQuotes';

// PRINTER

// UTILS

// MODAL
import M_QuotesToDo from '@modules/Quotes/js/modals/QuotesToDo/Information';

// CUSTOM_ELEMENT

// SERVICE
import S_History from '@services/History';

//STYLE
import '../../../css/recentlyAccessed.scss';

class RecentlyAccessed extends HTMLElement {
	public static readonly tagName: string = 'ap-recently-accessed';

	private _data: any;

	public async connectedCallback() {
		this.innerHTML = `
			<div class="text-nowrap">
				<div class="mb-3 d-flex align-items-center text-grey-800">
					<i class="icon icon-solid-history ml-1 mr-2 h5"></i><span class="font-weight-bold h4">Dernières ouvertures</span>
				</div>
				<div id="recently-accessed-container" style="height: 411px; width: 500px; font-size: 13px" class="d-flex flex-column overflow-auto pl-3 pr-1 py-3">
				</div>
			</div>
        `;

		this.init();
	}

	private async init() {
		const data = await S_History.getInstance().getLastOpen();

		const N_container = this.querySelector('#recently-accessed-container');

		if (data.length > 0) {
			for (const item of data) {
				const N_row = document.createElement('div') as HTMLDivElement;
				N_row.classList.add('my-1', 'py-1', 'px-2', 'd-flex', 'align-items-center', 'recent-item');

				const N_left_col = document.createElement('div') as HTMLDivElement;
				N_left_col.classList.add('w-100');

				const N_right_col = document.createElement('div') as HTMLDivElement;
				N_right_col.classList.add('d-flex', 'w-75');

				const N_type = document.createElement('div') as HTMLDivElement;
				N_type.classList.add('text-nowrap', 'font-weight-bold');
				N_type.innerHTML = item.type;

				const N_infos_div = document.createElement('div') as HTMLDivElement;
				N_infos_div.classList.add('d-flex');

				const N_number = document.createElement('div') as HTMLDivElement;
				N_number.classList.add('mr-auto', 'text-nowrap');
				N_number.innerHTML = item.number;

				const N_date = document.createElement('div') as HTMLDivElement;
				N_date.classList.add('ml-auto', 'text-nowrap', 'text-grey-600');
				N_date.innerHTML = item.date;

				const N_button = document.createElement('button') as HTMLButtonElement;
				N_button.classList.add('ml-auto', 'py-0', 'circle-btn');
				N_button.innerHTML = '<i class="icon icon-solid-folder-open" style="font-size: 1.2rem;"></i>';

				N_infos_div.append(N_number, N_date);

				N_left_col.append(N_type, N_infos_div);
				N_right_col.append(N_button);

				N_row.append(N_left_col, N_right_col);

				N_container?.append(N_row);

				if (item.rawData) {
					N_number.classList.add('text-blue');
					N_button.classList.add('text-grey-800');
					N_button.addEventListener('click', () => {
						if (item.rawData.table === 'quotes') {
							C_Quote.open(item.rawData.value);
						}

						if (item.rawData.table === 'quotes-to-do') {
							new M_QuotesToDo(item.rawData.value).open();
						}

						if (item.rawData.table === 'commands-provider') {
							C_P_Order.open(item.rawData.value);
						}

						if (item.rawData.table === 'commands-customer') {
							C_C_Order.open(item.rawData.value);
						}

						if (item.rawData.table === 'bills') {
							C_C_Bills.open(item.rawData.value);
						}

						if (item.rawData.table === 'bills-provider') {
							C_P_Bills.open(item.rawData.value);
						}

						if (item.rawData.table === 'deliveries') {
							C_Delivery.open(item.rawData.value);
						}
					});

					N_row.addEventListener('click', () => {
						N_button.click();
					});
				} else {
					if (item.number === 'Elément supprimé') {
						N_number.classList.add('text-grey');
					} else {
						N_number.classList.add('text-red');
					}
					N_button.classList.add('text-grey');
					N_button.disabled = true;
				}
			}
		} else {
			const N_row = document.createElement('div') as HTMLDivElement;
			N_row.classList.add('p-2', 'm-2', 'rounded', 'bg-white', 'd-flex', 'align-items-center', 'justify-content-center');
			N_row.innerHTML = 'Aucune activité';

			N_container?.append(N_row);
		}
	}

	public postInit() {
	}

	public set data(data: any) {
		this._data = data;
	}

	public get data() {
		return this._data;
	}

	public static register() {
		customElements.define(RecentlyAccessed.tagName, RecentlyAccessed);
	}
}

export default RecentlyAccessed;
