import { Form } from '@autoprog/core-client';

import SettingsApps from '@libs/Settings';

import Loader from '@libs/Loader';
import Modal from '@libs/Modal';

import T_modal from '../../../tpl/modals/AddQuote/QuoteDefaultOption.html';

import CGVTab from '@js/libs/customElement/CGVTab';

// SERVICES

import CE_QuoteOption from '../../libs/customElement/QuoteOption';
import CE_QuotePrintOption from '../../libs/customElement/QuotePrintOption';

export type SettingQuoteOption = {
	hourlyPriceType: {
		[key: string]: string
	},
	marginType: {
		[key: string]: string
	}
};

class QuoteDefaultOption extends Modal {
	private form: Form | null = null;

	private settings: SettingsApps;

	private N_QuotePrintOption: CE_QuotePrintOption | null = null;
	private N_QuoteOption: CE_QuoteOption | null = null;

	private loader: Loader;

	private N_CGVTab: CGVTab | null = null;

	constructor() {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.settings = SettingsApps.getInstance();

		this.loader = Loader.getInstance();

		this.on('opened', async () => {
			this.loader.open();

			const N_form = this.element.querySelector('.modal-content') as HTMLFormElement;

			this.initCGV();
			this.initPrint();
			this.initQuotes();

			const data = await this.getData();

			this.N_QuoteOption!.quoteOptions = data.quoteOptions;
			this.N_QuotePrintOption!.data = data.pref;
			this.N_CGVTab!.data = data.CGV;

			this.form = new Form(N_form);

			this.form.setData(data);

			this.N_CGVTab!.postInit();
			this.N_QuotePrintOption!.postInit();
			this.N_QuoteOption!.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				N_save.loading(new Promise(async (resolve, reject) => {
					try {
						const newData = await this.save();
						this.resolve(newData);
					} catch (e) {
						reject(e);
					}
				}));
			});

			this.loader.close();
		});
	}

	private async getData() {
		const data: { [key: string]: any } = {
			CGV: this.settings.get('QUOTE.CGV') || [],
			pref: this.settings.get('QUOTE.PREF') || {},
			quoteOptions: this.settings.get('QUOTE.OPTIONS') || {},
			infos: this.settings.get('QUOTE.INFOS') || {}
		};

		return data;
	}

	private initPrint() {
		this.N_QuotePrintOption = this.element.querySelector(CE_QuotePrintOption.tagName) as CE_QuotePrintOption;

		this.N_QuotePrintOption!.setParentElement(this.element);

		this.N_QuotePrintOption!.setID(() => {
			return 'fakeQuote';
		});

		this.N_QuotePrintOption!.setGetData(() => {
			const data = this.getSaveData();
			return data;
		});
	}

	private initQuotes() {
		this.N_QuoteOption = this.element.querySelector(CE_QuoteOption.tagName) as CE_QuoteOption;

		this.N_QuoteOption.setParentElement(this.element);
	}

	/**
	 * Initialise l'onglet des CGV
	 */
	private initCGV() {
		this.N_CGVTab = this.element.querySelector(CGVTab.tagName) as CGVTab;
		this.N_CGVTab.setParentElement(this.element);
	}

	private getSaveData() {
		const result = this.form?.getData() as { [key: string]: any };

		result.CGV = this.N_CGVTab!.data;
		result.quoteOptions = this.N_QuoteOption!.quoteOptions;
		result.pref = {
			...result.pref,
			...this.N_QuoteOption!.data
		};

		return result;
	}

	private async save() {
		if (this.form?.checkValidity()) {
			const newData = this.getSaveData();

			this.settings.setGeneral('QUOTE.CGV', newData.CGV);
			this.settings.setGeneral('QUOTE.PREF', newData.pref);
			this.settings.setGeneral('QUOTE.INFOS', newData.infos);

			this.settings.setGeneral('QUOTE.OPTIONS', newData.quoteOptions);

			return newData;
		} else {
			throw new Error('Formulaire invalide');
		}
	}
}

export default QuoteDefaultOption;
