import { Form, toaster } from '@autoprog/core-client';

import SettingsApps from '@libs/Settings';

import _ from 'lodash';

import ConfigManager from '@js/libs/ConfigManager';

import Options from '../GridOptions';

export type SettingQuoteOption = {
	hourlyPriceType: {
		[key: string]: string
	},
	marginType: {
		[key: string]: string
	}
};

class InputDeleteBtn extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes-options-input';

	// eslint-disable-next-line unused-imports/no-unused-vars
	private callback: (name: string, type: string) => void = () => { };

	public async connectedCallback() {
		const text = this.getAttribute('text');
		const name = this.getAttribute('name') || '';
		const type = this.getAttribute('type') || '';
		const isDefault = !!this.getAttribute('default');
		const id = name.replace(/\./gmi, '_');

		const nameCompta = name.replace('price', 'comptaVente');

		this.innerHTML = `
			<div class="d-flex w-100 mb-3">
				<label class="mt-1" style="min-width: 200px;" for="${id}">${text}</label>
				<div class="input-group ml-auto" style="min-width: 25%; width: 25%;">
					<input class="form-control" style="border-bottom-right-radius: 0; border-top-right-radius: 0;" type="number" step="0.01" name="${name}" id="${id}">
					<div class="input-group-append">
						<span class="input-group-text" style="border-bottom-right-radius: 0.5rem; border-top-right-radius: 0.5rem;">${type === 'hourlyPrice' ? '€' : '%'}</span>
					</div>
				</div>
				${name.split('.')[1] === 'price' ? '<select class="form-control ml-2" name="' + nameCompta + '"></select>' : ''}
				<button class="btn btn-red ml-2 d-none" id="${type === 'hourlyPrice' ? 'delete-price-hour-type' : 'delete-margin-type'}" confirmation>
					<i class="icon icon-solid-trash"></i>
				</button>
			</div>
		`;

		const N_deleteBtn = this.querySelector(`#${type === 'hourlyPrice' ? 'delete-price-hour-type' : 'delete-margin-type'}`) as HTMLElement;

		if (isDefault && name.split('.')[2] !== Options.KILOMETRAGE_COST) {
			N_deleteBtn.classList.remove('d-none');

			N_deleteBtn.addEventListener('click', () => {
				this.remove();
				this.callback(name.split('.')[2], type);
			});
		}

		this.initSelect();

		this.removeAttribute('name');
	}

	private initSelect() {
		const N_select = this.querySelector('select') as HTMLSelectElement;

		const data = (SettingsApps.getInstance().get('COMPTA') || {}) as { [key: string]: any };
		data.comptes = data.comptes || [];

		if (N_select) {
			if (data.disable) {
				N_select.classList.add('d-none');
			}

			const N_option = new Option('Veuillez sélectionner un comptable', '', true, true);
			N_option.disabled = true;
			N_select.appendChild(N_option);

			for (const item of data.comptes) {
				if (item.isMO) {
					const N_option = new Option(`${item.number} : ${item.label}`, item.number, item.defaultMO, item.defaultMO);
					N_select.appendChild(N_option);
				}
			}
		}
	}

	public setCallback(cb: (name: string, type: string) => void) {
		this.callback = cb;
	}

	public static register() {
		customElements.define(InputDeleteBtn.tagName, InputDeleteBtn);
	}
}

class QuoteOption extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes-options-tab';

	private selectorTab: string = '';

	private idTab: string = '';
	protected isDefault: boolean = false;

	protected contentOnly: boolean = false;

	private N_tab: HTMLElement | null = null;

	private settingQuoteOptions: SettingQuoteOption | undefined;

	private N_editPriceHoursBlock: HTMLDivElement | null = null;
	private N_editMarginBlock: HTMLDivElement | null = null;

	private N_selectTypeMat: HTMLSelectElement | null = null;
	private N_selectType: HTMLSelectElement | null = null;

	private form: Form | null = null;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || '';

		this.contentOnly = this.getAttribute('contentOnly') === 'true';

		if (this.contentOnly) {
			this.innerHTML = '';
		} else {
			this.innerHTML = `
				<li class="nav-item border-right">
					<a class="nav-link px-3" data-toggle="tab" href="#${this.idTab}" role="tab">
						Paramètres devis
					</a>
				</li>
			`;
		}

		this.id = '';
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') !== `#${this.idTab}`) {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;
				N_li.classList.remove('active');
			}
		});
	}

	public async setParentElement(parent: HTMLElement) {
		this.isDefault = !!this.getAttribute('default');

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100');
		N_div.setAttribute('role', 'tabpanel');
		N_div.id = this.idTab;
		N_div.innerHTML = `
			<div class="row">

				<div class="col-md-4 mb-2">

					<div class="p-3 card shadow-none rounded">

						<div class="font-weight-bold">
							Taux Horaire (€)
						</div>
						<hr>
						<div id="price-list">
						</div>
						<div id="editHoursTypeBlock">
							<hr>
							<div class="d-flex justify-content-between align-items-start">
								<div class="form-group flex-grow-1 mr-2">
									<input type="text" class="form-control mr-2" id="hourTypeInput" placeholder="Saissisez le nom du taux horaire">
									<div class="invalid-feedback">
										Taux horaire déjà existant.
									</div>
								</div>
								<button class="btn btn-blue confirm" id="add-hours-type" confirmation>
									<i class="icon icon-solid-plus"></i>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-4 mb-2">
					<div class="p-3 card shadow-none rounded" id="margin-block">
						<span class="font-weight-bold">Marges (%)</span>
						<hr>
						<div id="marges-list">
						</div>
						<div id="editMarginTypeBlock">
							<hr>
							<div class="d-flex justify-content-between align-items-start">
								<div class="form-group flex-grow-1 mr-2">
									<input type="text" class="form-control" id="marginTypeInput"
										placeholder="Saissisez le nom de la marge">
									<div class="invalid-feedback">
										Type de marges déjà existante.
									</div>
								</div>
								<button class="btn btn-blue" id="add-margin-type"
									confirmation><i class="icon icon-solid-plus"></i></button>
							</div>

						</div>
					</div>

					<div class="pt-3 mt-3 card shadow-none rounded" id="defaultValueContainer">
						<div class="w-100 text-center mb-3">
							<button class="btn btn-blue-600" id="default-value-pref"
								confirmation>
								Valeurs par défaut
							</button>
						</div>
					</div>
				</div>

				<div class="col-md-4 mb-2">
					<div class="p-3 card shadow-none rounded">

						<span class="font-weight-bold mb-3">Informations</span>

						<div class="form-group row">
							<label class="col-6 mt-1">Nombre de décimales :</label>
							<div class="col">
								<select name="pref.decimalNumber" class="form-control" style="width: 100px;"></select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-6 mt-1">Coef total :</label>
							<div class="col">
								<input type="number" min="0.01" step="0.01" name="pref.coefTotal"
									class="form-control" pattern="^[1-9][0-9]*$" style="width: 100px;">
								<div class="invalid-feedback">
									La valeur doit être différente de 0
								</div>
							</div>
						</div>

						<hr>
						<div class="font-weight-bold mb-3">Type par défaut</div>


						<div class="form-group row">
							<label class="col-6 mt-1">Marge :</label>
							<div class="col">

								<select name="pref.typeMat" class="form-control"></select>

							</div>

						</div>
						<div class="form-group row">
							<label for="inputPassword" class="col-6 mt-1">Taux horaire :</label>

							<div class="col">
								<select name="pref.type" class="form-control"></select>

							</div>

						</div>

						<hr>

						<div class="font-weight-bold mb-3">Calcul de la marge</div>

						<div class="custom-control custom-switch">
							<input type="checkbox" class="custom-control-input" id="calculMarkUp"
								name="pref.calculMarkUp">
							<label class="custom-control-label" for="calculMarkUp">Appliquer une marge
								commerciale</label>
							<small id="calcMarginHelp" class="form-text text-muted">Si désactivé, un
								coefficient simple est appliqué au prix.</small>
						</div>

						<hr>

						<div class="font-weight-bold mb-3">Option d'affichage du groupe</div>

						<div class="custom-control custom-switch">
							<input type="checkbox" class="custom-control-input" id="displayPUDetails"
								name="pref.displayPUDetails">
							<label class="custom-control-label" for="displayPUDetails">Afficher la colonne
								<b>PU total</b></label><i class="icon icon-solid-info-circle ml-2"
								tooltip="Prix unitaire avec marge"></i>
						</div>

					</div>


				</div>

			</div>
        `;

		if (this.contentOnly) {
			this.appendChild(N_div);
		} else {
			N_div.classList.add('fade');
			const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
			N_container.appendChild(N_div);
		}

		this.N_tab = N_div;

		this.N_editPriceHoursBlock = this.N_tab!.querySelector('#editHoursTypeBlock') as HTMLDivElement;
		this.N_editMarginBlock = this.N_tab!.querySelector('#editMarginTypeBlock') as HTMLDivElement;

		this.N_selectTypeMat = this.N_tab!.querySelector('[name="pref.typeMat"]') as HTMLSelectElement;
		this.N_selectType = this.N_tab!.querySelector('[name="pref.type"]') as HTMLSelectElement;

		this.form = new Form(this.N_tab as HTMLFormElement);
	}

	public set quoteOptions(quoteOptions: SettingQuoteOption) {
		this.settingQuoteOptions = quoteOptions;

		const N_priceHoursList = this.N_tab!.querySelector('#price-list') as HTMLElement;

		for (const [key, value] of Object.entries(this.settingQuoteOptions!.hourlyPriceType)) {
			if (key === Options.NO_MO) {
				const defaultMarginOption = new Option(value, key);
				this.N_selectType!.append(defaultMarginOption);
			}

			if (![Options.NO_MO].includes(key)) {
				const N_div = this.createTemplateType(`pref.price.${key}`, value, 'hourlyPrice');

				N_priceHoursList.appendChild(N_div);

				const option = new Option(value, key);
				this.N_selectType!.append(option);
			}
		}

		const N_marginList = this.N_tab!.querySelector('#marges-list') as HTMLElement;

		for (const [key, value] of Object.entries(this.settingQuoteOptions!.marginType)) {
			//On ajoute l'option aucune marge par défaut dans le select
			if (key === Options.NO_MARGIN) {
				const defaultMarginOption = new Option(value, key);
				this.N_selectTypeMat?.append(defaultMarginOption);
			}

			if (![Options.NO_MARGIN, Options.LICENCE, Options.DISCOUNT].includes(key)) {
				const N_div = this.createTemplateType(`pref.marges.${key}`, value, 'margin');

				N_marginList.appendChild(N_div);

				const option = new Option(value, key);

				this.N_selectTypeMat!.append(option);
			}
		}

		// On met à jour le formulaire
		this.form?.updateInputs();

		const config = ConfigManager.getInstance().getConfig('quotes');

		const listDecimalNumber = _.find<any>(config.columns, { key: 'pref.decimalNumber' } as any);
		const selectDecimalNumber = this.N_tab!.querySelector('[name="pref.decimalNumber"]') as HTMLSelectElement;

		for (const key of listDecimalNumber.array) {
			const option = new Option(key, key);
			selectDecimalNumber.append(option);
		}

		const N_defaultValue = this.N_tab!.querySelector('#default-value-pref') as HTMLButtonElement;

		if (!this.isDefault) {
			// On masque les blocks d'ajout de taux horaire et de marges
			this.N_editPriceHoursBlock!.classList.add('d-none');
			this.N_editMarginBlock!.classList.add('d-none');

			// TODO: Gérer le cas ou une option a été supprimée
			N_defaultValue.addEventListener('click', async () => {
				try {
					const tmp = SettingsApps.getInstance().get('QUOTE.PREF') as any;

					const data = {
						price: tmp.price || {},
						marges: tmp.marges || {},
						coefTotal: tmp.coefTotal || '',
						displayPUDetails: tmp.displayPUDetails || '',
						decimalNumber: tmp.decimalNumber || '',
						typeMat: tmp.typeMat || '',
						type: tmp.type || ''
					};

					this.form?.setData({
						pref: data
					} as any);
				} catch (e) {
					toaster.error('Paramètre introuvable');
				}
			});
		} else {
			const N_addHoursType = this.N_editPriceHoursBlock!.querySelector('#add-hours-type') as HTMLButtonElement;

			N_addHoursType.addEventListener('click', () => {
				const N_hourPriceInput = this.N_tab!.querySelector('#hourTypeInput') as HTMLInputElement;
				if (N_hourPriceInput.value) {
					this.addPriceHourInput(N_hourPriceInput.value);
				}
			});

			const N_addMarginType = this.N_editMarginBlock!.querySelector('#add-margin-type') as HTMLButtonElement;
			N_addMarginType.addEventListener('click', () => {
				const N_marginInput = this.N_tab!.querySelector('#marginTypeInput') as HTMLInputElement;
				if (N_marginInput.value) {
					this.addMarginInput(N_marginInput.value);
				}
			});

			this.N_tab!.querySelector('#defaultValueContainer')?.remove();
		}
	}

	public get quoteOptions(): SettingQuoteOption {
		return this.settingQuoteOptions!;
	}

	public get data(): { [key: string]: any } {
		const data = this.form!.getData() as { [key: string]: any };
		return data.pref;
	}

	public set data(value: { [key: string]: any }) {
		this.form!.setData({ pref: value } as any);
	}

	/**
	 * Ajoute un type de marge sur le formulaire
	 * @param name le nom du type de marge saisie par l'utilisateur
	 */
	private addMarginInput(name: string) {
		const N_marginList = this.N_tab!.querySelector('#marges-list') as HTMLElement;

		const N_marginInput = this.N_editMarginBlock!.querySelector('#marginTypeInput') as HTMLInputElement;

		const key = _.deburr(_.upperCase(name)).replace(/\s/gmi, '_');
		name = _.capitalize(name);

		// On vérifie si la clé existe
		if (!_.get(this.settingQuoteOptions!.marginType, key)) {
			// On update le select
			this.settingQuoteOptions!.marginType[key] = name;
			const option = new Option(name, key);
			this.N_selectTypeMat!.append(option);

			N_marginList.appendChild(this.createTemplateType(`pref.marges.${key}`, name, 'margin'));

			this.form?.updateInputs();

			N_marginInput.classList.remove('is-invalid');
			N_marginInput.value = '';
		} else {
			if (!N_marginInput.classList.contains('is-invalid')) {
				N_marginInput.classList.add('is-invalid');
			}
		}
	}

	/**
	 * Ajoute un type de taux horaire sur le formulaire
	 * @param name le nom du taux horaire saisie par l'utilisateur
	 */
	private addPriceHourInput(name: string) {
		const N_priceHourList = this.N_tab!.querySelector('#price-list') as HTMLElement;

		const N_priceHourInput = this.N_editPriceHoursBlock!.querySelector('#hourTypeInput') as HTMLInputElement;

		const key = _.deburr(_.upperCase(name)).replace(/\s/gmi, '_');
		name = _.capitalize(name);

		if (!_.get(this.settingQuoteOptions!.hourlyPriceType, key)) {
			// On update le select
			this.settingQuoteOptions!.hourlyPriceType[key] = name;
			const option = new Option(name, key);

			this.N_selectType!.append(option);

			N_priceHourList.appendChild(this.createTemplateType(`pref.price.${key}`, name, 'hourlyPrice'));

			this.form?.updateInputs();

			N_priceHourInput.classList.remove('is-invalid');
			N_priceHourInput.value = '';
		} else {
			if (!N_priceHourInput.classList.contains('is-invalid')) {
				N_priceHourInput.classList.add('is-invalid');
			}
		}
	}

	/**
	 * Créer le block pour les types de marges ou les taux horaires
	 */
	private createTemplateType(key: string, value: string, type: 'margin' | 'hourlyPrice'): InputDeleteBtn {
		const N_div = document.createElement('ap-quotes-options-input') as InputDeleteBtn;

		N_div.setAttribute('name', key);
		N_div.setAttribute('text', value);
		N_div.setAttribute('type', type);

		if (this.isDefault) {
			N_div.setAttribute('default', 'true');
		}

		N_div.setCallback((name: string, type: string) => {
			if (type === 'hourlyPrice') {
				delete this.settingQuoteOptions!.hourlyPriceType[name];

				this.N_selectType!.querySelectorAll('option').forEach(opt => {
					if (opt.value === name) {
						opt.remove();
					}
				});
			} else {
				delete this.settingQuoteOptions!.marginType[name];

				this.N_selectTypeMat!.querySelectorAll('option').forEach(opt => {
					if (opt.value === name) {
						opt.remove();
					}
				});
			}

			this.form?.updateInputs();
		});

		return N_div;
	}

	public static register() {
		customElements.define(QuoteOption.tagName, QuoteOption);
		InputDeleteBtn.register();
	}
}

export default QuoteOption;
