// CORE
import { LoggedUser } from '@autoprog/core-client';
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, ColDef, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// TEMPLATE
// LIBS
import SettingsApps from '@libs/Settings';

// PRINTER
// UTILS
// MODAL
import M_EditStock from '../../../modals/customers/EditStockOutput';
import M_PrintPreview from '@libs/modals/PrintPreview';

// CUSTOM_ELEMENT
// SERVICE
import S_Products from '@services/Product/ProductService';
import S_StockEvent from '@services/StockEventService';

class OutputStockTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-customers-output-stock-tab';

	private selectorTab: string = '';

	private idTab: string = '';
	private idOrder: string = '';

	private _update = (): void => { };

	private _gridOptions: GridOptions = {};

	private locations: { [key: string]: any }[] = [];

	private isEditable: boolean = false;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Suivi sortie de stock">
						<i class="icon icon-solid-database"></i>
					</div>
					<span>Suivi sortie de stock</span>
				</a>
			</li>
		`;

		this.id = '';
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');

		N_div.id = this.idTab;
		N_div.innerHTML = `
			<div class="d-flex flex-column h-100">
                <div class="title">
					Suivi sortie stock

					<div class="flex-wrap align-items-center text-md d-none" id="legend">
						<span class="font-weight-bold text-grey ml-2">Légende :</span>
							
						<small class="d-flex align-items-center ml-3 text-red-900">
							<div class="badge-legend bg-red-100"></div>
							Produit non sorti
						</small>

						<small class="d-flex align-items-center ml-3 text-orange-900">
							<div class="badge-legend bg-orange-100"></div>
							Produit sorti partiellement
						</small>

						<small class="d-flex align-items-center ml-3 text-green-900">
							<div class="badge-legend bg-green-100"></div>
							Produit sorti
						</small>
					</div>

					<div class="ml-auto">
						<button class="btn btn-print" type="button" id="print">Impression</button>
						<button class="btn btn-info d-none" type="button" id="edit">Modifier</button>

						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
                </div>
                <div class="h-100 ag-theme-alpine grid"></div>
            </div>
        `;

		const N_edit = N_div.querySelector('#edit') as HTMLButtonElement;
		const N_legend = N_div.querySelector('#legend') as HTMLElement;

		this.isEditable = (SettingsApps.getInstance().get('APPLICATION') as any)?.orderCustomer?.stockOutput?.disabled && LoggedUser.getInstance().hasPermission('STOCK.OUTPUT');

		if (this.isEditable) {
			N_edit.classList.remove('d-none');
			N_legend.classList.add('d-flex');
			N_legend.classList.remove('d-none');
		}

		N_edit.addEventListener('click', () => {
			new M_EditStock(this.idOrder).open().then((data) => {
				this.gridOptions.api?.setRowData(data);
				this.initStock();
			});
		});

		const N_print = N_div.querySelector('#print') as HTMLButtonElement;

		N_print.addEventListener('click', () => {
			new M_PrintPreview('commands-customer', this.idOrder, 'pdfStock').open();
		});

		N_container.append(N_div);
	}

	private initGrid() {
		const childrenStock: ColDef[] = [];
		const childrenLocation: ColDef[] = [];

		for (const item of this.locations) {
			((item: any) => {
				childrenStock.push({
					headerName: item.name,
					width: 100,
					field: 'currentStock.' + item._id + '.quantity',
					cellClass: 'text-center',
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.currentStock = params.data.currentStock || {};
						params.data.currentStock[item._id] = params.data.currentStock[item._id] || {};
						return params.data.currentStock[item._id].quantity;
					},
					cellRenderer: (params) => {
						return _.isUndefined(params.data.currentStock[item._id].quantity) ? '<i class="icon icon-solid-spinner icon-spin"></i>' : params.data.currentStock[item._id].quantity;
					}
				});

				childrenLocation.push({
					headerName: item.name,
					field: 'stocks.' + item._id + '.quantity',
					width: 120,
					cellClass: ['text-right', 'text-monospace'],
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.stocks = params.data.stocks || {};
						params.data.stocks[item._id] = params.data.stocks[item._id] || { quantity: 0 };
						return params.data.stocks[item._id]?.quantity || 0;
					},
					cellRenderer: (params) => {
						return params.value || 0;
					}
				});
			})(item);
		}

		childrenLocation.push({
			headerName: 'Direct client',
			field: 'deliveryToCustomer.quantity',
			width: 120,
			cellClass: ['text-right', 'text-monospace'],
			suppressSizeToFit: true,
			cellRenderer: (params) => {
				return params.value || 0;
			}
		});

		this._gridOptions = agUtils.french<GridOptions>({
			rowData: this._gridOptions.rowData || [],
			localeText: { noRowsToShow: 'Aucun produit' },
			columnDefs: [
				{
					headerComponentParams: {
						template: `
							<div class="ag-cell-label-container" role="presentation">
								<div ref="eLabel" class="ag-header-cell-label" role="presentation">
									<i class="icon h5 icon-solid-ellipsis-h"></i>
								</div>
							</div>
						`
					},
					headerClass: 'ag-theme-custom-text-center',
					field: '_icons_',
					filter: false,
					width: 60,
					pinned: 'left',
					sortable: false,
					suppressSizeToFit: true,
					suppressMovable: true,
					suppressColumnsToolPanel: true,
					cellRenderer: (params) => {
						const N_container = document.createElement('div');
						N_container.classList.add('d-flex', 'align-items-center');

						if (params.data.product.base64_photo || params.data.product.url_photo) {
							const N_image = S_Products.getInstance().cellRendererByData(params.data.product);
							N_image.innerHTML = '<i class="icon h5 icon-image"></i>';

							N_container.appendChild(N_image);
						}

						return N_container;
					}
				},
				{
					headerName: S_Products.getInstance().columnNameReference,
					field: 'product',
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params: any) => {
						return params.data.product[S_Products.getInstance().referenceKey];
					}
				},
				{
					headerName: 'Libellé',
					field: 'product.name',
					floatingFilter: true,
					filter: 'agTextColumnFilter'
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					floatingFilter: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					}
				},
				{
					headerName: 'Quantité Cmd',
					field: 'quantity',
					width: 100,
					cellClass: 'text-center',
					suppressSizeToFit: true
				},
				{
					headerName: 'Unité',
					field: 'unit',
					width: 80,
					suppressSizeToFit: true,
					cellClass: 'text-center'
				},
				...(childrenStock.length === 1 ? childrenStock : [{ headerName: 'Stock', children: childrenStock }]),
				{
					headerName: 'Sortie stock',
					children: childrenLocation
				}
			],
			getRowStyle: (params: any) => {
				if (this.isEditable) {
					const quantity = params.data.quantity;
					let outputQuantity = (params.data.deliveryToCustomer?.quantity || 0);

					for (const idStock in params.data.stocks) {
						outputQuantity += (params.data.stocks[idStock]?.quantity || 0);
					}

					//stock sortie
					if (outputQuantity >= quantity) {
						return {
							'background-color': 'var(--ap-green-50)',
							color: 'var(--ap-green-900)'
						};
					}

					//aucun stock de sortie
					if (outputQuantity === 0) {
						return {
							'background-color': 'var(--ap-red-50)',
							color: 'var(--ap-red-900)'
						};
					}

					//en cours de sortie
					if (outputQuantity < quantity) {
						return {
							'background-color': 'var(--ap-orange-50)',
							color: 'var(--ap-orange-900)'
						};
					}
				}
			},
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			onCellEditingStopped: () => {
				this._update();
			}
		});

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	public setData(data: { [key: string]: any }) {
		this.idOrder = data.idOrder;

		this.locations = data.locations;

		this._gridOptions.rowData = data.products;
		this._gridOptions.api?.setRowData(data.products);

		if (!this._gridOptions.api) {
			this.initGrid();
		}

		this.initStock();
	}

	private async initStock() {
		const { rowData } = await S_StockEvent.getInstance().getCurrentStock();

		const stock: { [key: string]: any } = {};
		for (const item of rowData) {
			stock[item.product._id] = stock[item.product._id] || {};
			stock[item.product._id][item.stock] = stock[item.product._id][item.stock] || { quantity: 0 };
			stock[item.product._id][item.stock].quantity = item.quantity.formattedValue;
		}

		this._gridOptions.api?.forEachNode((node) => {
			for (const item of this.locations) {
				node.data.currentStock = node.data.currentStock || {};
				node.data.currentStock[item._id] = stock[node.data.reference]?.[item._id] || { quantity: 0 };
			}

			node.setData(node.data);
		});
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public static register() {
		customElements.define(OutputStockTab.tagName, OutputStockTab);
	}
}

export default OutputStockTab;
