import agUtils from '@libs/agGrid/french';
import { toaster } from '@autoprog/core-client';

import _ from 'lodash';

import T_Modal from '../../../tpl/modals/AddQuote/EditGroup.html';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import M_AddFavorite from './AddFavorite';

import Clipboard from '../../libs/Clipboard';
import Modal from '@libs/Modal';
import Utils from '@libs/utils/Utils';
import UtilsQuote from '../../libs/UtilsQuotes';

import Options from '../../libs/GridOptions';

import StateSaver from '@libs/agGrid/StateSaver';

import S_Q_Favorite from '@services/Quotes/FavoriteQuoteService';

class EditGroup extends Modal {
	private gridOptions: GridOptions = {};
	private gridOptionsDetail: GridOptions = {};
	private optionsGrid: Options;

	private events: EventListenerCanceller[] = [];

	private allData: any[] = [];

	private _idGrid: string = '';

	private idFav: string = '';

	constructor(allData: any[], idGrid: string, optionsGrid: Options) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.allData = _.cloneDeep(allData);
		this._idGrid = idGrid;
		this.optionsGrid = optionsGrid;

		const clipboard = Clipboard.getInstance();

		const data = _.find(this.allData, { _idGrid: idGrid } as any) as any;

		this.on('opened', () => {
			const N_gridDetails = this.element.querySelector('#grid-group') as HTMLElement;
			const N_grid = this.element.querySelector('#grid') as HTMLElement;

			const N_close = this.element.querySelector('.close') as HTMLButtonElement;

			const N_notFinish = this.element.querySelector('#notFinish') as HTMLInputElement;

			const N_options = this.element.querySelector('#options') as HTMLInputElement;

			const N_favorite = this.element.querySelector('#favorite') as HTMLElement;

			const N_infos_quote = this.element.querySelector('#infos_quote') as HTMLElement;

			N_infos_quote.innerHTML = (document.querySelector('#page-title .title') as HTMLElement).textContent || '';

			const N_copy = this.element.querySelector('#copy') as HTMLElement;

			N_close.addEventListener('click', () => {
				this.resolve({ _idFav: this.idFav });
			});

			N_notFinish.addEventListener('change', () => {
				this.gridOptions.api?.forEachNode((node) => {
					node.data.notFinish = !node.data.notFinish;

					node.setData(node.data);
				});
			});

			N_options.addEventListener('change', () => {
				this.gridOptions.api?.forEachNode((node) => {
					node.data.isOption = !node.data.isOption;

					node.setData(node.data);
				});
			});

			if (data._idFav) {
				this.idFav = data._idFav;
				N_favorite.innerHTML = '<i class="icon icon-solid-star"></i>';
				N_favorite.setAttribute('confirmation', '');
				N_favorite.title = 'Modifier le favori ?';
			} else {
				N_favorite.innerHTML = '<i class="icon icon-star"></i>';
			}

			N_favorite.addEventListener('click', async () => {
				this.gridOptions.api?.stopEditing();
				this.gridOptionsDetail.api?.stopEditing();

				const newData = _.cloneDeep(this.getData());

				if (!newData._idFav) {
					new M_AddFavorite(newData).open().then((id) => {
						this.idFav = id;

						N_favorite.innerHTML = '<i class="text-dark h5 icon icon-solid-star"></i>';
						N_favorite.setAttribute('confirmation', '');
						N_favorite.title = 'Modifier le favori ?';
					}).catch((e) => {
						console.error(e);
					});
				} else {
					newData._id = newData._idFav;

					const data = await S_Q_Favorite.getInstance().save(newData);

					if (!data.err) {
						toaster.success('Sauvegarde réussi');
					}
				}
			});

			N_copy.addEventListener('click', () => {
				clipboard.setData(_.cloneDeep(this.getData()), 'master');
			});

			N_notFinish.checked = data.notFinish;
			N_options.checked = data.isOption;

			this.gridOptionsDetail = agUtils.french<GridOptions>({
				rowData: data.details,
				...this.optionsGrid.gridDetails(this.element, 'modal', () => {
					const data: any[] = [];

					this.gridOptionsDetail.api?.forEachNode((node) => {
						data.push(node.data);
					});

					//peut y avoir qu'une ligne
					this.gridOptions.api?.forEachNode((node) => {
						node.data.details = data;
					});
					this.gridOptions.api?.refreshCells({ force: true });

					this.updateNewPrice();
				}),
				onRowDataUpdated: () => {
					this.updateNewPrice();
				},
				onGridReady: async (params) => {
					params.api.sizeColumnsToFit();

					const settings = await StateSaver.getValue('edit-group-details-quote');
					const stateSaver = new StateSaver(this.gridOptionsDetail as any, 'edit-group-details-quote');
					stateSaver.setData(settings);
				}
			});

			new Grid(N_gridDetails, this.gridOptionsDetail, { modules: AllModules });

			this.gridOptions = agUtils.french<GridOptions>({
				singleClickEdit: true,
				...this.optionsGrid.gridMaster(this.element, 'modal'),
				getRowHeight: () => {
					return 130;//145
				},
				onGridReady: async (params) => {
					params.api.sizeColumnsToFit();

					const settings = await StateSaver.getValue('edit-group-master-quote');
					const stateSaver = new StateSaver(this.gridOptions as any, 'edit-group-master-quote');
					stateSaver.setData(settings);
				},
				onCellEditingStopped: (params) => {
					params.api.resetRowHeights();
					params.api.refreshCells({ force: true });
					this.updateNewPrice();
				},
				getContextMenuItems: (params) => {
					return [this.optionsGrid.styleContextMenu(params)];
				},
				rowData: [
					data
				]
			});

			new Grid(N_grid, this.gridOptions, { modules: AllModules });

			const N_oldPrice = this.element.querySelector('#oldPrice') as HTMLElement;

			const oldPrice = UtilsQuote.calculateGlobalPrice(this.allData, this.optionsGrid.pref).all;

			N_oldPrice.innerHTML = Utils.setSuffixAndHumanizeNumber(oldPrice, '€', this.optionsGrid.pref.decimalNumber);

			this.save();

			this.updateNewPrice();

			this.stopEditing();
		});

		this.on('closed', () => {
			for (const event of this.events) {
				event();
			}
		});
	}

	//FIXME:
	private stopEditing() {
		window.addEventListener('mousedown', (event) => {
			let stopEditing = true;
			let pointer = event.srcElement as HTMLElement;

			while (pointer && stopEditing) {
				if ((pointer.className || '').includes('ag-cell-edit-input') || pointer.tagName === 'TEXTAREA') {
					stopEditing = false;
				}

				if ((pointer.className || '').includes('ag-body') || (pointer.className || '').includes('ag-header')) {
					pointer = document as unknown as HTMLElement;
				}

				pointer = pointer.parentNode as HTMLElement;
			}

			if (stopEditing && this.gridOptions.api && this.gridOptionsDetail.api) {
				this.gridOptions.api.stopEditing();
				this.gridOptionsDetail.api.stopEditing();
			}
		}, {
			signal: this.abortSignal
		});
	}

	private updateNewPrice() {
		const index = _.findIndex(this.allData, { _idGrid: this._idGrid } as any);

		const newData = this.getData();
		newData._idGrid = this._idGrid;

		this.allData[index] = newData;

		const price = UtilsQuote.calculateGlobalPrice(this.allData, this.optionsGrid.pref).all;

		const N_newPrice = this.element.querySelector('#newPrice') as HTMLElement;
		N_newPrice.innerHTML = Utils.setSuffixAndHumanizeNumber(price, '€', this.optionsGrid.pref.decimalNumber);
	}

	private getData() {
		let result: { [key: string]: any } = {};

		this.gridOptions.api?.forEachNode((node) => {
			result = node.data;
		});

		result.details = [];

		this.gridOptionsDetail.api?.forEachNode((node) => {
			result.details.push(node.data);
		});

		if (this.idFav) {
			result._idFav = this.idFav;
		}

		return result;
	}

	private save() {
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener('click', () => {
			this.gridOptions.api?.stopEditing();
			this.gridOptionsDetail.api?.stopEditing();

			this.resolve(this.getData());
		});
	}
}

export default EditGroup;
