import { Controller, Tabs } from '@autoprog/core-client';

import T_blockTabs from '@tpl/blocks/tabs.html';

import C_Tab from '@js/controllers/TabDatabase';

import C_Customer from '../tabs/customers/ListCommand';
import C_Provider from '../tabs/providers/ListCommand';

import History from '@libs/History';

class TabsController extends Controller {
	private tabs: Tabs;

	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		this.tabs = new Tabs({
			navElement: el.querySelector('.tabs-container') as HTMLElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		params = params || {};

		const defaultTab = params.tabs ? params.tabs : 'customers';

		this.tabs.add({
			name: 'Clients',
			default: defaultTab === 'customers',
			id: 'customers',
			controller: C_Tab,
			data: {
				instance: C_Customer
			},
			template: T_blockTabs,
			permission: 'ORDERS._CUSTOMERS.SHOW'
		});

		this.tabs.add({
			name: 'Fournisseurs',
			default: defaultTab === 'providers',
			id: 'providers',
			controller: C_Tab,
			data: {
				instance: C_Provider
			},
			template: T_blockTabs,
			permission: 'ORDERS._PROVIDERS.SHOW'
		});

		let firstLoad = true;
		this.tabs.on('change', (data: { [key: string]: any }) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				History.getInstance().pushState(`#module/commands/${data.id}`);
			}
		});
	}

	public destructor() {
		this.tabs.destructor();
	}
}

export default TabsController;
