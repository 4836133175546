import { Tab, toaster } from '@autoprog/core-client';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

import SettingsApps from '@libs/Settings';

import S_Companies from '@services/CompaniesService';

import Company from '@type/company/company';

import _ from 'lodash';

class BankTabs extends Tab {
	private el: HTMLElement;

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.init();
	}

	private async init() {
		const N_save = this.el.querySelector('#save') as HTMLButtonElement;
		const N_add = this.el.querySelector('#add') as HTMLButtonElement;
		const N_grid = this.el.querySelector('#grid') as HTMLElement;

		const settings = SettingsApps.getInstance();

		const data = (settings.get('BANK') || {}) as { [key: string]: any };

		const companies: Company[] = await S_Companies.getInstance().getAll();

		const gridOptions: GridOptions = agUtils.french<GridOptions>({
			rowData: data.bankList || [],
			columnDefs: [{
				headerName: 'Nom',
				field: 'name'
			}, {
				headerName: 'IBAN',
				field: 'IBAN'
			}, {
				headerName: 'RIB',
				field: 'RIB'
			}, {
				headerName: 'Code SWIFT',
				field: 'SWIFT'
			}, {
				headerName: 'Societé',
				field: 'companyID',
				cellEditor: 'agRichSelectCellEditor',
				cellEditorParams: {
					values: companies.map((company: Company) => company._id),
					cellRenderer: (params: { value: string; }) => {
						return companies.find((company: Company) => company._id === params.value)?.name;
					}
				},
				cellRenderer: (params) => {
					return companies.find((company: Company) => company._id === params.value)?.name || '';
				}
			}, {
				headerName: 'Commande Fournisseur',
				field: 'useCommandProvider',
				editable: false,
				cellRenderer: (params) => {
					const N_checkbox = document.createElement('div');
					N_checkbox.classList.add('text-center');

					if (params.value) {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
					} else {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
					}

					N_checkbox.addEventListener('click', () => {
						const value = !params.value;

						params.node.setDataValue('useCommandProvider', value);

						if (value) {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
						} else {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
						}
					});

					return N_checkbox;
				}
			}, {
				headerName: 'Facture',
				field: 'useBill',
				editable: false,
				cellRenderer: (params) => {
					const N_checkbox = document.createElement('div');
					N_checkbox.classList.add('text-center');

					if (params.value) {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
					} else {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
					}

					N_checkbox.addEventListener('click', () => {
						const value = !params.value;

						params.node.setDataValue('useBill', value);

						if (value) {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
						} else {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
						}
					});

					return N_checkbox;
				}
			}, {
				headerName: 'Action',
				width: 80,
				suppressSizeToFit: true,
				editable: false,
				cellRenderer: (params) => {
					const N_delete = document.createElement('button');

					N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

					N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
					N_delete.setAttribute('confirmation', '');
					N_delete.setAttribute('tooltip', 'Supprimer');

					N_delete.addEventListener('click', () => {
						params.api.updateRowData({
							remove: [params.node.data]
						});
					});

					return N_delete;
				}
			}],
			defaultColDef: {
				editable: true,
				resizable: true,
				suppressMenu: true
			}
		});

		new Grid(N_grid, gridOptions, { modules: AllModules });

		N_add.addEventListener('click', () => {
			gridOptions.api && gridOptions.api.updateRowData({
				add: [{}]
			});
		});

		N_save.addEventListener('click', () => {
			const data = {
				bankList: [] as any[]
			} as { [key: string]: any };

			gridOptions.api && gridOptions.api.forEachNode((node) => {
				data.bankList.push(node.data);
			});

			data.commandProvider = _.filter(data.bankList, { useCommandProvider: true });
			data.bill = _.filter(data.bankList, { useBill: true });

			settings.setGeneral('BANK', data as any);

			toaster.success('Banques sauvegardées');
		});
	}
}

export default BankTabs;
