import T_print from '../../../tpl/blocks/Products.ejs';

import _ from 'lodash';

import Utils from '@libs/utils/Utils';

class Products {
	private data: { [key: string]: any }[] = [];
	private settings: { [key: string]: any } = [];
	private orientation: 'portrait' | 'landscape';

	constructor(data: { [key: string]: any }[], settings: { [key: string]: any }, orientation: 'portrait' | 'landscape') {
		this.data = data;
		this.settings = settings;
		this.orientation = orientation;
	}

	public async print() {
		const N_container = document.createElement('div');

		N_container.classList.add('containerPrint');

		const chunkData = _.chunk(this.data, 56);

		for (const chunk of chunkData) {
			N_container.innerHTML += T_print({
				data: chunk,
				settings: this.settings,
				orientation: this.orientation
			});
		}

		document.body.appendChild(N_container);

		setTimeout(() => {
			window.print();

			document.body.removeChild(N_container);

			Utils.removeTooltip();
		}, 1000);
	}
}

export default Products;
