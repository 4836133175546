// CORE

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../../tpl/modals/providers/editPage/generalInformation.html';

// LIBS
import Modal from '@libs/EditPageModal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_P_Contact from '@services/Provider/ProviderContactService';
import S_Provider from '@services/Provider/ProviderService';

type GeneralInformationData = {
	infos: {
		provider: string,
		contact: string,
		paymentType: string
		label: string
	}
};

class GeneralInformation extends Modal<GeneralInformationData> {
	private enabledProviderSelect: boolean;

	constructor(data: GeneralInformationData, enabledProviderSelect = false) {
		super(data, T_modal);

		this.enabledProviderSelect = enabledProviderSelect;
	}

	protected preInit() {
		const N_provider = this.element.querySelector('[name="infos.provider"]') as CE_Select2;
		const N_contact = this.element.querySelector('[name="infos.contact"]') as CE_Select2;

		N_provider.create(this.element, { disabled: !this.enabledProviderSelect });

		N_contact.setRef({ id_provider: N_provider.selectElement! });
		N_contact.create(this.element);

		this.selectPostinit['infos.provider'] = N_provider;
		this.selectPostinit['infos.contact'] = N_contact;
	}

	protected async setData(data: GeneralInformationData) {
		const res: { [key: string]: any } = {
			infos: {
				provider: await S_Provider.getInstance().getDataToSelect2ByID(data.infos.provider),
				contact: await S_P_Contact.getInstance().getDataToSelect2ByID(data.infos.contact),
				paymentType: data.infos.paymentType,
				label: data.infos.label
			}
		};

		this.form?.setData(res);
	}

	protected async getDataForm(data: { [key: string]: any }) {
		data.infos.provider = await S_Provider.getInstance().getDataToSelect2ByID(data.infos.provider);
		data.infos.contact = await S_P_Contact.getInstance().getDataToSelect2ByID(data.infos.contact);

		return data;
	}

	protected postInit() {
		super.postInit();

		this.selectPostinit['infos.provider'].on('change', async (providerID) => {
			const contacts = await S_P_Contact.getInstance().getByProviderToSelect2(providerID as string);

			if (contacts.length && providerID) {
				this.form?.setDataByName('infos.contact', contacts[0]);
			} else {
				this.form?.setDataByName('infos.contact', { id: '', text: '' });
			}

			this.updateButtonSave();
		});

		this.updateButtonSave();
	}

	private updateButtonSave() {
		const provider = this.form?.getDataByName('infos.provider') as string;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !provider;
	}
}

export default GeneralInformation;
