// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../../tpl/modals/customers/editPage/generalInformation.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Contact from '@services/Customer/CustomerContactService';
import S_Customer from '@services/Customer/CustomerService';
import S_User from '@services/User/UserService';

type GeneralInformationData = {
	infos: {
		manager: string
		number: string
		addonNumber: string
		customer: string
		contact: string
	}
};

class GeneralInformation extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null;

	constructor(data: GeneralInformationData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					data.infos.manager = await S_User.getInstance().getDataToSelect2ByID(data.infos.manager);

					data.infos.customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer);
					data.infos.contact = await S_C_Contact.getInstance().getDataToSelect2ByID(data.infos.contact);

					this.resolve(data);
				}));
			});

			this.updateButtonSave();
		});
	}

	private init() {
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_contact = this.element.querySelector('[name="infos.contact"]') as CE_Select2;

		const N_manager = this.element.querySelector('[name="infos.manager"]') as CE_Select2;

		N_manager.create(this.element);

		N_customer.create(this.element);

		N_contact.setRef({ id_customer: N_customer.selectElement! });
		N_contact.create(this.element);

		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['infos.contact'] = N_contact;
		this.selectPostinit['infos.manager'] = N_manager;
	}

	private async setData(data: GeneralInformationData) {
		const res: { [key: string]: any } = {
			infos: {
				number: data.infos.number,
				addonNumber: data.infos.addonNumber,
				manager: await S_User.getInstance().getDataToSelect2ByID(data.infos.manager),
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer),
				contact: await S_C_Contact.getInstance().getDataToSelect2ByID(data.infos.contact)
			}
		};

		this.form?.setData(res);
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}

		this.selectPostinit['infos.customer'].on('change', async (customer) => {
			const contacts = await S_C_Contact.getInstance().getByCustomerToSelect2(customer as string);

			if (contacts.length && customer) {
				this.form?.setDataByName('infos.contact', contacts[0]);
			} else {
				this.form?.setDataByName('infos.contact', { id: '', text: '' });
			}

			this.updateButtonSave();
		});
	}

	private updateButtonSave() {
		const customer = this.form?.getDataByName('infos.customer') as string;

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !customer;
	}
}

export default GeneralInformation;
