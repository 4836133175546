import { Form, Tab, toaster } from '@autoprog/core-client';

import SettingsApps from '@libs/Settings';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

class BankTabs extends Tab {
	constructor(el: HTMLElement) {
		super(el);

		const N_save = el.querySelector('#save') as HTMLButtonElement;
		const N_disable = el.querySelector('[name="disable"]') as HTMLInputElement;
		const N_add = el.querySelector('#add') as HTMLButtonElement;
		const N_grid = el.querySelector('#grid') as HTMLElement;
		const N_container = el.querySelector('#container') as HTMLElement;

		const settings = SettingsApps.getInstance();

		const form = new Form(el.querySelector('#form') as HTMLFormElement);

		const data = (settings.get('COMPTA') || {}) as { [key: string]: any };

		if (data.disable) {
			N_container.classList.add('d-none');
		}

		form.setData(data);

		const gridOptions: GridOptions = agUtils.french<GridOptions>({
			rowData: data.comptes || [],
			suppressDragLeaveHidesColumns: true,
			rowDragManaged: true,
			animateRows: true,
			columnDefs: [{
				headerName: 'Numéro compte',
				field: 'number',
				rowDrag: true,
				width: 250,
				suppressSizeToFit: true
			}, {
				headerName: 'Libellé',
				field: 'label'
			}, {
				headerName: 'Filtre',
				children: [
					{
						headerName: 'Matériel',
						field: 'isMAT',
						width: 100,
						suppressSizeToFit: true,
						editable: false,
						cellRenderer: (params) => {
							const N_checkbox = document.createElement('div');
							N_checkbox.classList.add('text-center');

							if (params.value) {
								N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
							} else {
								N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
							}

							N_checkbox.addEventListener('click', () => {
								const value = !params.value;

								params.node.setDataValue('isMAT', value);

								if (value) {
									N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
								} else {
									N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
								}
							});

							return N_checkbox;
						}
					}, {
						headerName: 'Taux horaire',
						field: 'isMO',
						width: 100,
						suppressSizeToFit: true,
						editable: false,
						cellRenderer: (params) => {
							const N_checkbox = document.createElement('div');
							N_checkbox.classList.add('text-center');

							if (params.value) {
								N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
							} else {
								N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
							}

							N_checkbox.addEventListener('click', () => {
								const value = !params.value;

								params.node.setDataValue('isMO', value);

								if (value) {
									N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
								} else {
									N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
								}
							});

							return N_checkbox;
						}
					}
				]
			}, {
				headerName: 'Compte par défaut',
				children: [
					{
						headerName: 'Matériel',
						field: 'defaultMat',
						width: 100,
						suppressSizeToFit: true,
						editable: false,
						cellRenderer: (params) => {
							const N_checkbox = document.createElement('div');
							N_checkbox.classList.add('text-center');

							if (params.value) {
								N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
							} else {
								N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
							}

							N_checkbox.addEventListener('click', () => {
								const value = !params.value;

								gridOptions.api?.forEachNode((node) => {
									node.setDataValue('defaultMat', false);
								});

								params.node.setDataValue('defaultMat', value);

								if (value) {
									N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
								} else {
									N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
								}
							});

							return N_checkbox;
						}
					}, {
						headerName: 'Taux horaire',
						field: 'defaultMO',
						width: 100,
						suppressSizeToFit: true,
						editable: false,
						cellRenderer: (params) => {
							const N_checkbox = document.createElement('div');
							N_checkbox.classList.add('text-center');

							if (params.value) {
								N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
							} else {
								N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
							}

							N_checkbox.addEventListener('click', () => {
								const value = !params.value;

								gridOptions.api?.forEachNode((node) => {
									node.setDataValue('defaultMO', false);
								});

								params.node.setDataValue('defaultMO', value);

								if (value) {
									N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
								} else {
									N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
								}
							});

							return N_checkbox;
						}
					}
				]
			}, {
				headerName: 'Action',
				width: 80,
				suppressSizeToFit: true,
				editable: false,
				cellRenderer: (params) => {
					const N_delete = document.createElement('button');

					N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

					N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
					N_delete.setAttribute('confirmation', '');
					N_delete.setAttribute('tooltip', 'Supprimer');

					N_delete.type = 'button';

					N_delete.addEventListener('click', () => {
						params.api.updateRowData({
							remove: [params.node.data]
						});
					});

					return N_delete;
				}
			}],
			defaultColDef: {
				editable: true,
				resizable: true,
				suppressMenu: true
			},
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			}
		});

		new Grid(N_grid, gridOptions, { modules: AllModules });

		N_add.addEventListener('click', () => {
			gridOptions.api?.applyTransaction({
				add: [{}]
			});
		});

		N_disable.addEventListener('change', () => {
			N_container.classList.toggle('d-none');
		});

		N_save.addEventListener('click', () => {
			const data = {
				comptes: [] as any[],
				...form.getData()
			} as { [key: string]: any };

			let errorMAT = false;
			let errorMO = false;

			gridOptions.api?.forEachNode((node) => {
				data.comptes.push(node.data);

				if (!errorMO && node.data.defaultMO && !node.data.isMO) {
					errorMO = true;
				}

				if (!errorMAT && node.data.defaultMat && !node.data.isMAT) {
					errorMAT = true;
				}
			});

			if (!errorMO && !errorMAT) {
				settings.setGeneral('COMPTA', data as any);

				toaster.success('Sauvegarde réussi');
			} else {
				if (errorMO) {
					toaster.error('Erreur sur le type "Taux Horaire"');
				}

				if (errorMAT) {
					toaster.error('Erreur sur le type "Matériel"');
				}
			}
		});
	}
}

export default BankTabs;
