// CORE
import { Alert, Form } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../tpl/modals/advance.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_C_Bill from '@services/Customer/CustomerBillService';
import S_P_Bill from '@services/Provider/ProviderBillService';

class Advance extends Modal {
	private indexBill: number = 0;

	private notBillAfterAdvance: number = 0;

	constructor(order: { [key: string]: any }, mode: 'customer' | 'provider') {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.indexBill = order.infos.index;

		this.on('opened', async () => {
			const N_addonTitle = this.element.querySelector('#addon-title') as HTMLElement;

			N_addonTitle.innerHTML = order.title;

			const price = Decimal.setDisplayNumber(order.infos.price);

			const form = new Form(this.element.querySelector('.modal-body') as HTMLFormElement);

			const data: { [key: string]: any } = {
				index: this.indexBill,
				type: 'percent',
				price: {
					command: price.setSuffixAndHumanizeNumber('€'),
					bill: '',
					notBill: '',
					advance: '',
					notBillAfterAdvance: ''
				},
				advance: {
					percentList: this.indexBill === 1 ? (order.infos.advance || 30) : '0',
					percent: this.indexBill === 1 ? (order.infos.advance || 30) : '0'
				}
			};

			let priceBill = new Decimal(0);

			let bills = [];

			if (mode === 'customer') {
				bills = await S_C_Bill.getInstance().getByOrder(order._id);
			}

			if (mode === 'provider') {
				bills = await S_P_Bill.getInstance().getByOrder(order._id);
			}

			for (const item of (bills || [])) {
				if (item.infos.type === 'credit' || item.infos.type === 'credit-error') {
					priceBill = priceBill.minus(Decimal.setDisplayNumber(item.globalPrice));
				} else {
					priceBill = priceBill.plus(Decimal.setDisplayNumber(item.globalPrice));
				}
			}

			const dataNumber = {
				notBill: price.minus(priceBill),
				priceBill,
				price
			};

			let percentBill = dataNumber.priceBill.times(100).dividedBy(price);
			let percentNotBill = dataNumber.notBill.times(100).dividedBy(price);

			percentBill = percentBill.toDecimalPlaces(0);
			percentNotBill = percentNotBill.toDecimalPlaces(0);

			data.price.bill = `${dataNumber.priceBill.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€')} | ${percentBill.humanizePercent()}%`;
			data.price.notBill = `${dataNumber.notBill.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€')} | ${percentNotBill.humanizePercent()}%`;

			this.updatePrice(data, form, dataNumber);

			const N_percentList = this.element.querySelector('[name="advance.percentList"]') as HTMLSelectElement;

			N_percentList.addEventListener('click', () => {
				data.advance.percentList = N_percentList.value;
				data.advance.percent = N_percentList.value;

				this.updatePrice(data, form, dataNumber);
			});

			const N_percent = this.element.querySelector('[name="advance.percent"]') as HTMLInputElement;

			N_percent.addEventListener('change', () => {
				data.advance.percentList = N_percent.value;
				data.advance.percent = N_percent.value;

				this.updatePrice(data, form, dataNumber);
			});

			const N_type = this.element.querySelector('[name="type"]') as HTMLSelectElement;
			const N_priceInput = this.element.querySelector('[name="price.advance"]') as HTMLInputElement;
			const N_priceDiv = this.element.querySelector('[data-key="price.advance"]') as HTMLElement;

			N_type.addEventListener('change', () => {
				data.type = N_type.value;

				if (N_type.value === 'percent') {
					N_percent.disabled = false;
					N_percentList.disabled = false;

					N_priceInput.classList.add('d-none');
					N_priceDiv.classList.remove('d-none');

					this.updatePrice(data, form, dataNumber);
				}

				if (N_type.value === 'price') {
					N_percent.disabled = true;
					N_percentList.disabled = true;

					N_priceInput.classList.remove('d-none');
					N_priceDiv.classList.add('d-none');

					data.price.advance = dataNumber.price.times(Decimal.setDisplayNumber(data.advance.percent).dividedBy(100));

					this.updatePrice(data, form, dataNumber);

					N_priceInput.select();
				}
			});

			N_priceInput.addEventListener('input', () => {
				data.price.advance = N_priceInput.value;

				this.updatePrice(data, form, dataNumber);
			});

			const N_advance = this.element.querySelector('#advance') as HTMLButtonElement;

			if (this.indexBill !== 1) {
				N_advance.classList.add('d-none');
			}

			N_advance.addEventListener('click', () => {
				if (data.type === 'price') {
					this.resolve({
						type: 'advance',
						price: Decimal.setDisplayNumber(data.price.advance).toNumber(),
						typeValue: data.type
					});
				}

				if (data.type === 'percent') {
					this.resolve({
						type: 'advance',
						percent: data.advance.percent,
						typeValue: data.type
					});
				}
			});

			const N_normal = this.element.querySelector('#normal') as HTMLButtonElement;

			N_normal.addEventListener('click', async () => {
				if (this.notBillAfterAdvance === 0) {
					await Alert.confirm('Demande de confirmation', 'Le montant restant à facturer sera de <span class="font-weight-bold">0 €</span> après création de la facture. <br> Voulez-vous continuer ?');
				}
				if (data.type === 'price') {
					this.resolve({
						type: 'normal',
						price: Decimal.setDisplayNumber(data.price.advance).toNumber(),
						typeValue: data.type
					});
				}

				if (data.type === 'percent') {
					this.resolve({
						type: 'normal',
						percent: data.advance.percent,
						typeValue: data.type
					});
				}
			});
		});
	}

	private updatePrice(data: { [key: string]: any }, form: Form, dataNumber: { [key: string]: Decimal }) {
		if (data.type === 'percent') {
			const advance = dataNumber.price.times(Decimal.setDisplayNumber(data.advance.percent).dividedBy(100));
			const notBillAfterAdvance = dataNumber.notBill.minus(advance);

			let percent_notBillAfterAdvance = notBillAfterAdvance.times(100).dividedBy(dataNumber.price);
			let percent_advance = Decimal.setDisplayNumber(data.advance.percent);

			percent_notBillAfterAdvance = percent_notBillAfterAdvance.toDecimalPlaces(0);
			percent_advance = percent_advance.toDecimalPlaces(0);

			data.price.notBillAfterAdvance = `${notBillAfterAdvance.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€')} | ${percent_notBillAfterAdvance.humanizePercent()}%`;
			data.price.advance = `${advance.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€')} | ${percent_advance.humanizePercent()}%`;

			form.setData(data);
			this.update(data);

			this.updateInvalid(notBillAfterAdvance.toNumber(), advance.toNumber());
		}

		if (data.type === 'price') {
			const advance = Decimal.setDisplayNumber(data.price.advance);
			const notBillAfterAdvance = dataNumber.notBill.minus(advance);

			let percent_notBillAfterAdvance = notBillAfterAdvance.times(100).dividedBy(dataNumber.price);
			const percent_advance = new Decimal(100).times(advance).dividedBy(dataNumber.price);

			percent_notBillAfterAdvance = percent_notBillAfterAdvance.toDecimalPlaces(0);

			data.price.notBillAfterAdvance = `${notBillAfterAdvance.setSuffixAndHumanizeNumber('€')} | ${percent_notBillAfterAdvance.humanizePercent()}%`;

			data.advance.percentList = data.advance.percent = percent_advance.toDecimalPlaces(0).toString();

			form.setData(data);

			this.update(data);

			this.updateInvalid(notBillAfterAdvance.toNumber(), advance.toNumber());
		}
	}

	private updateInvalid(notBillAfterAdvance: number, advance: number) {
		this.notBillAfterAdvance = notBillAfterAdvance;

		const N_price_notBillAfterAdvance = this.element.querySelector('[data-key="price.notBillAfterAdvance"]') as HTMLElement;
		const N_price_advance = this.element.querySelector('[data-key="price.advance"]') as HTMLElement;
		const N_advance = this.element.querySelector('#advance') as HTMLButtonElement;
		const N_normal = this.element.querySelector('#normal') as HTMLButtonElement;
		const N_error = this.element.querySelector('#error') as HTMLElement;

		N_price_notBillAfterAdvance.classList.remove('text-danger');
		(N_price_advance.parentNode as HTMLElement).classList.remove('text-danger');

		if (notBillAfterAdvance < 0 || advance <= 0) {
			if (notBillAfterAdvance < 0) {
				N_price_notBillAfterAdvance.classList.add('text-danger');
			}

			if (advance <= 0) {
				(N_price_advance.parentNode as HTMLElement).classList.add('text-danger');
			}

			N_advance.disabled = true;
			N_normal.disabled = true;

			N_advance.classList.add('cursor-not-allowed');
			N_normal.classList.add('cursor-not-allowed');

			N_error.classList.remove('d-none');
		} else {
			N_advance.classList.remove('cursor-not-allowed');
			N_normal.classList.remove('cursor-not-allowed');

			N_advance.disabled = false;
			N_normal.disabled = false;

			N_error.classList.add('d-none');
		}
	}

	private update(data: { [key: string]: any }) {
		const N_list = this.element.querySelectorAll('[data-key]') as NodeListOf<HTMLElement>;

		N_list.forEach((N_el) => {
			const key = N_el.dataset.key || '';

			const value = _.get(data, key);

			N_el.innerHTML = value;
		});
	}
}

export default Advance;
