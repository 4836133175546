// CORE
import { Form, global } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../../../tpl/modals/deliveries/editPage/additionalInformation.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Address from '@services/Customer/CustomerAddressService';
import S_Companies from '@services/CompaniesService';
import S_Customer from '@services/Customer/CustomerService';
import S_P_Address from '@services/Provider/ProviderAddressService';
import S_Provider from '@services/Provider/ProviderService';
import S_Site from '@services/Site/SiteService';
import S_User from '@services/User/UserService';

//TYPES
import Company from '@js/types/company/company';
import CompanyAddress from '@js/types/company/companyAddress';

type AdditionalInformationData = {
	infos: {
		provider: string,
		customer: string,
		typeAddressCustomer: string,
		siteCustomer: string,
		addressIDCustomer: string,
		addressIDProvider: string,
		fullAddress: string,
		GPSCoordinates: string,
		comments: string,
		user: string,
		email: string
	},
	type: string,
	address: {
		internal: {
			id: string,
			fullAddress: string
		}
	},
	company: Company
};

class AdditionalInformation extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null;

	private company: Company | undefined = undefined;

	private type: string;

	constructor(data: AdditionalInformationData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.type = data.type;

		this.form = null;

		this.on('opened', async () => {
			this.company = data.company;

			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			this.initAddressInternal();

			await this.setData(data);

			this.initEvents();
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					data.infos.customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer);
					data.infos.provider = await S_Provider.getInstance().getDataToSelect2ByID(data.infos.provider);

					data.infos.addressIDProvider = await S_P_Address.getInstance().getDataToSelect2ByID(data.infos.addressIDProvider);
					data.infos.addressIDCustomer = await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.addressIDCustomer);

					data.infos.siteCustomer = await S_Site.getInstance().getDataToSelect2ByID(data.infos.siteCustomer);

					data.infos.user = await S_User.getInstance().getDataToSelect2ByID(data.infos.user);

					this.resolve(data);
				}));
			});

			this.updateType();
		});
	}

	private initAddressInternal() {
		const N_address_internal_id = this.element.querySelector('#address_internal_id') as HTMLSelectElement;

		const addressList: CompanyAddress[] = this.company?.addressList || [];

		for (const address of addressList) {
			const option = new Option(address.name, address.name);
			N_address_internal_id.appendChild(option);
		}
	}

	private init() {
		const N_addressIDProvider = this.element.querySelector('[name="infos.addressIDProvider"]') as CE_Select2;
		const N_addressIDCustomer = this.element.querySelector('[name="infos.addressIDCustomer"]') as CE_Select2;
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_provider = this.element.querySelector('[name="infos.provider"]') as CE_Select2;
		const N_siteCustomer = this.element.querySelector('[name="infos.siteCustomer"]') as CE_Select2;
		const N_user = this.element.querySelector('[name="infos.user"]') as CE_Select2;

		N_customer.create(this.element, { disabled: true });
		N_provider.create(this.element, { disabled: true });

		N_addressIDProvider.setRef({ id_provider: N_provider.selectElement!, delivery: true });
		N_addressIDProvider.create(this.element);

		N_addressIDCustomer.setRef({ id_customer: N_customer.selectElement!, delivery: true });
		N_addressIDCustomer.create(this.element);

		N_siteCustomer.setRef({ customer: N_customer.selectElement! });
		N_siteCustomer.create(this.element);

		N_user.create(this.element);

		this.selectPostinit['infos.addressIDProvider'] = N_addressIDProvider;
		this.selectPostinit['infos.addressIDCustomer'] = N_addressIDCustomer;
		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['infos.provider'] = N_provider;
		this.selectPostinit['infos.siteCustomer'] = N_siteCustomer;
		this.selectPostinit['infos.user'] = N_user;

		if (global.IS_MOBILE) {
			this.initMobileView();
		}
	}

	private initMobileView() {
		const N_modal_dialog = this.element.querySelector('.modal-dialog');
		const N_modal_content = this.element.querySelector('.modal-content');
		N_modal_dialog?.classList.add('modal-dialog-scrollable');
		N_modal_dialog?.classList.add('w-100');
		N_modal_dialog?.classList.remove('modal-70');
		N_modal_content?.classList.add('h-100');

		const N_row = this.element.querySelector('.row');
		const N_first_col = this.element.querySelectorAll('.col')[0];
		const N_second_col = this.element.querySelectorAll('.col')[1];

		N_row?.classList.remove('row');
		N_first_col?.classList.add('border-bottom');
		N_second_col?.classList.remove('border-left');
	}

	private updateType() {
		const N_customer = this.element.querySelectorAll('[data-type="customer"]') as NodeListOf<HTMLElement>;
		const N_provider = this.element.querySelectorAll('[data-type="provider"]') as NodeListOf<HTMLElement>;

		for (const N_el of N_customer) {
			N_el.classList.add('d-none');
		}

		for (const N_el of N_provider) {
			N_el.classList.add('d-none');
		}

		if (this.type === 'customer') {
			for (const N_el of N_customer) {
				N_el.classList.remove('d-none');
			}
			this.updateTypeAddressCustomer();
		}

		if (this.type === 'provider') {
			for (const N_el of N_provider) {
				N_el.classList.remove('d-none');
			}
		}
	}

	private async setData(data: AdditionalInformationData) {
		const res: { [key: string]: any } = {
			infos: {
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer),
				provider: await S_Provider.getInstance().getDataToSelect2ByID(data.infos.provider),
				typeAddressCustomer: data.infos.typeAddressCustomer,
				siteCustomer: await S_Site.getInstance().getDataToSelect2ByID(data.infos.siteCustomer),
				addressIDCustomer: await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.addressIDCustomer),
				addressIDProvider: await S_P_Address.getInstance().getDataToSelect2ByID(data.infos.addressIDProvider),
				fullAddress: data.infos.fullAddress,
				GPSCoordinates: data.infos.GPSCoordinates,
				comments: data.infos.comments,
				user: await S_User.getInstance().getDataToSelect2ByID(data.infos.user),
				email: data.infos.email
			},
			type: data.type,
			address: {
				internal: {
					id: data.address.internal.id,
					fullAddress: data.address.internal.fullAddress
				}
			}
		};

		this.form?.setData(res);
	}

	private updateTypeAddressCustomer() {
		let type = this.form?.getDataByName('infos.typeAddressCustomer') as string;
		type = type || 'customer';

		const N_addressTypeCustomer = this.element.querySelector('[address-type="customer"]') as CE_Select2;
		const N_addressTypeSite = this.element.querySelector('[address-type="site"]') as CE_Select2;

		if (type === 'customer') {
			N_addressTypeSite.classList.add('d-none');
			N_addressTypeCustomer.classList.remove('d-none');
		}

		if (type === 'site') {
			N_addressTypeCustomer.classList.add('d-none');
			N_addressTypeSite.classList.remove('d-none');
		}
	}

	private resetAddressCustomerFields() {
		this.form?.setDataByName('infos.addressIDCustomer', '');
		this.form?.setDataByName('infos.siteCustomer', '');
		this.form?.setDataByName('infos.fullAddress', '');
		this.form?.setDataByName('infos.GPSCoordinates', '');
	}

	private initEvents() {
		const N_customerAddressType1 = this.element.querySelector('#customerAddressType1') as HTMLInputElement;
		const N_customerAddressType2 = this.element.querySelector('#customerAddressType2') as HTMLInputElement;

		const N_fullAddress = this.element.querySelector('[name="infos.fullAddress"]') as HTMLTextAreaElement;
		const N_GPSCoordinates = this.element.querySelector('[name="infos.GPSCoordinates"]') as HTMLInputElement;

		const N_addressInternalID = this.element.querySelector('#address_internal_id') as HTMLSelectElement;

		const addressList: CompanyAddress[] = this.company?.addressList || [];

		N_addressInternalID.addEventListener('change', () => {
			const address = _.find(addressList, { name: N_addressInternalID.value });
			this.form?.setDataByName('address.internal.fullAddress', S_Companies.getInstance().getFullAddressByCompanyAdress(address));
		});

		N_customerAddressType1.addEventListener('change', () => {
			this.updateTypeAddressCustomer();
			this.resetAddressCustomerFields();
		});
		N_customerAddressType2.addEventListener('change', () => {
			this.updateTypeAddressCustomer();
			this.resetAddressCustomerFields();
		});

		this.selectPostinit['infos.addressIDCustomer'].on('change', async (value) => {
			const addressInfos = await S_C_Address.getInstance().getAddressInfos(value as string);
			N_fullAddress.value = addressInfos.fullAddress || '';
			N_GPSCoordinates.value = addressInfos.GPSCoordinates || '';
		});

		this.selectPostinit['infos.siteCustomer'].on('change', async (value) => {
			const siteInfos = await S_Site.getInstance().getById(value as string);
			N_fullAddress.value = siteInfos.address || '';
			N_GPSCoordinates.value = siteInfos.latitude && siteInfos.longitude ? siteInfos.latitude + ', ' + siteInfos.longitude : '';
		});

		this.selectPostinit['infos.addressIDProvider'].on('change', async (value) => {
			const fullAddress = await S_P_Address.getInstance().getFullAddress(value as string);
			N_fullAddress.value = fullAddress || '';
			N_GPSCoordinates.value = '';
		});

		this.selectPostinit['infos.user'].on('change', async (value) => {
			const userData = await S_User.getInstance().getById(value as string);

			this.form?.setDataByName('infos.email', userData.email);
		});
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default AdditionalInformation;
