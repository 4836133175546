class SelectPaymentType extends HTMLElement {
	public static readonly tagName: string = 'ap-select-payment-type';

	public connectedCallback() {
		this.innerHTML = `
            <select class="form-control" name="` + this.getAttribute('name') + `">

            </select>
        `;

		const N_select = this.querySelector('select') as HTMLSelectElement;

		N_select.appendChild(new Option('Virement', 'Virement'));
		N_select.appendChild(new Option('Chèque', 'Chèque'));
		N_select.appendChild(new Option('Espèce', 'Espèce'));
		N_select.appendChild(new Option('Paypal', 'Paypal'));

		this.removeAttribute('name');
	}

	public static register() {
		customElements.define(SelectPaymentType.tagName, SelectPaymentType);
	}
}

export default SelectPaymentType;
