// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
import T_modal from '../../../../tpl/modals/customers/editPageFromQuote/additionalInformations.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT
import CE_Address from '@libs/customElement/Address';
import CE_Select2 from '@libs/customElement/Select2';
import CE_SitesFinalCustomer from '@libs/customElement/Sites-FinalCustomer';

// SERVICE
import S_C_Address from '@services/Customer/CustomerAddressService';
import S_Customer from '@services/Customer/CustomerService';
import S_Site from '@services/Site/SiteService';

type AdditionalInformationsData = {
	infos: {
		customer: string,
		addressID: string,
		fullAddress: string,
		addressIDDelivery: string,
		fullAddressDelivery: string,
		date: Moment,
		entryDate: Moment,
		deliveryDate: Moment,
		finalCustomer: string,
		hasFinalCustomer: string,
		sites: string[],
		siteCustom: string,
		comments: string,
		delivery: {
			typeAddressCustomer: string,
			addressIDCustomer: string,
			siteCustomer: string,
			fullAddress: string,
			GPSCoordinates: string
		}
	}
};

class AdditionalInformations extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 | CE_Address } = {};

	private form: Form | null;

	constructor(data: AdditionalInformationsData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);
			this.postInit();
			this.updateTypeAddressCustomer();
			this.initEvents();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					data.infos.sites = await this.getSites(data.infos.sites);

					data.infos.customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer);
					data.infos.addressID = await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.addressID);

					data.infos.addressIDDelivery = await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.addressIDDelivery);

					data.infos.delivery.addressIDCustomer = await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.delivery.addressIDCustomer);
					data.infos.delivery.siteCustomer = await S_Site.getInstance().getDataToSelect2ByID(data.infos.delivery.siteCustomer);

					this.resolve(data);
				}));
			});
		});
	}

	private init() {
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_address = this.element.querySelector('[name-select="infos.addressID"]') as CE_Address;
		const N_addressDelivery = this.element.querySelector('[name-select="infos.addressIDDelivery"]') as CE_Address;

		const N_deliveryAddressIDCustomer = this.element.querySelector('[name="infos.delivery.addressIDCustomer"]') as CE_Select2;
		const N_deliverySiteCustomer = this.element.querySelector('[name="infos.delivery.siteCustomer"]') as CE_Select2;

		const N_sites = this.element.querySelector('[name="infos.sites"]') as CE_Select2;

		N_customer.create(this.element, { disabled: true });

		N_address.setRef({ id_customer: N_customer.selectElement!, bill: true });
		N_address.create(this.element);

		N_addressDelivery.setRef({ id_customer: N_customer.selectElement! });
		N_addressDelivery.create(this.element);

		N_deliveryAddressIDCustomer.setRef({ id_customer: N_customer.selectElement!, delivery: true });
		N_deliveryAddressIDCustomer.create(this.element);

		N_deliverySiteCustomer.setRef({ customer: N_customer.selectElement! });
		N_deliverySiteCustomer.create(this.element);

		N_sites.setRef({ customer: N_customer.selectElement! });
		N_sites.create(this.element, { multiple: true });

		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['infos.addressID'] = N_address;
		this.selectPostinit['infos.addressIDDelivery'] = N_addressDelivery;

		this.selectPostinit['infos.delivery.addressIDCustomer'] = N_deliveryAddressIDCustomer;
		this.selectPostinit['infos.delivery.siteCustomer'] = N_deliverySiteCustomer;

		this.selectPostinit['infos.sites'] = N_sites;
	}

	private initTypeAddressRadioButtonsEvents() {
		const N_customerAddressType1 = this.element.querySelector('#customerAddressType1') as HTMLInputElement;
		const N_customerAddressType2 = this.element.querySelector('#customerAddressType2') as HTMLInputElement;

		N_customerAddressType1.addEventListener('change', () => {
			this.updateTypeAddressCustomer();
			this.resetAddressCustomerFields();
		});
		N_customerAddressType2.addEventListener('change', () => {
			this.updateTypeAddressCustomer();
			this.resetAddressCustomerFields();
		});
	}

	private initDeliveryAddressSelectEvents() {
		const N_fullAddress = this.element.querySelector('[name="infos.delivery.fullAddress"]') as HTMLTextAreaElement;
		const N_GPSCoordinates = this.element.querySelector('[name="infos.delivery.GPSCoordinates"]') as HTMLInputElement;

		this.selectPostinit['infos.delivery.addressIDCustomer'].on('change', async (value) => {
			const addressInfos = await S_C_Address.getInstance().getAddressInfos(value as string);
			// On récupère l'adresse du site ainsi que ses coordonnées
			N_fullAddress.value = addressInfos.fullAddress || '';
			N_GPSCoordinates.value = addressInfos.GPSCoordinates || '';
		});

		this.selectPostinit['infos.delivery.siteCustomer'].on('change', async (value) => {
			const siteInfos = await S_Site.getInstance().getById(value as string);
			// On récupère l'adresse du site ainsi que ses coordonnées
			N_fullAddress.value = siteInfos.address || '';
			// Sur les sites, les coordonnées sont stockées dans un objet coords
			N_GPSCoordinates.value = siteInfos.coords.latitude && siteInfos.coords.longitude ? siteInfos.coords.latitude + ', ' + siteInfos.coords.longitude : '';
		});
	}

	private updateTypeAddressCustomer() {
		let type = this.form?.getDataByName('infos.delivery.typeAddressCustomer') as string;
		type = type || 'customer';

		const N_addressTypeCustomer = this.element.querySelector('[address-type="customer"]') as CE_Select2;
		const N_addressTypeSite = this.element.querySelector('[address-type="site"]') as CE_Select2;

		if (type === 'customer') {
			N_addressTypeSite.classList.add('d-none');
			N_addressTypeCustomer.classList.remove('d-none');
		}

		if (type === 'site') {
			N_addressTypeCustomer.classList.add('d-none');
			N_addressTypeSite.classList.remove('d-none');
		}
	}

	private resetAddressCustomerFields() {
		this.form?.setDataByName('infos.delivery.addressIDCustomer', '');
		this.form?.setDataByName('infos.delivery.siteCustomer', '');
		this.form?.setDataByName('infos.delivery.fullAddress', '');
		this.form?.setDataByName('infos.delivery.GPSCoordinates', '');
	}

	private async getSites(sites: string[]) {
		const res: any[] = [];

		for (const item of sites) {
			const tmp = await S_Site.getInstance().getDataToSelect2ByID(item);
			res.push(tmp);
		}
		return res;
	}

	private async setData(data: AdditionalInformationsData) {
		const res: { [key: string]: any } = {
			infos: {
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer),
				addressID: await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.addressID),
				fullAddress: data.infos.fullAddress,
				addressIDDelivery: await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.addressIDDelivery),
				fullAddressDelivery: data.infos.fullAddressDelivery,
				date: data.infos.date,
				entryDate: data.infos.entryDate,
				deliveryDate: data.infos.deliveryDate,
				finalCustomer: data.infos.finalCustomer,
				hasFinalCustomer: data.infos.hasFinalCustomer,
				sites: await this.getSites(data.infos.sites),
				siteCustom: data.infos.siteCustom,
				comments: data.infos.comments,
				delivery: {
					typeAddressCustomer: data.infos.delivery.typeAddressCustomer,
					addressIDCustomer: await S_C_Address.getInstance().getDataToSelect2ByID(data.infos.delivery.addressIDCustomer),
					siteCustomer: await S_Site.getInstance().getDataToSelect2ByID(data.infos.delivery.siteCustomer),
					fullAddress: data.infos.delivery.fullAddress,
					GPSCoordinates: data.infos.delivery.GPSCoordinates
				}
			}
		};

		this.form?.setData(res);
	}

	private initEvents() {
		this.initTypeAddressRadioButtonsEvents();
		this.initDeliveryAddressSelectEvents();
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}

		const N_sites = this.element.querySelector('ap-sites-final-customer') as CE_SitesFinalCustomer;
		N_sites.setFinalCustomer();
	}
}

export default AdditionalInformations;
