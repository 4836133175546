// CORE
import { Alert, LoggedUser, toaster } from '@autoprog/core-client';
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import moment from 'moment';

// TEMPLATE
// LIBS
import C_CommandCustomerFromQuote from '../../../controllers/Command.Customer.FromQuote';
import C_Quote from '@modules/Quotes/js/controllers/AddQuotes';
import Decimal from '@libs/utils/Decimal';

// PRINTER
// UTILS

// MODAL
import M_SelectQuotes from '@modules/Quotes/js/modals/SelectQuotes';

// CUSTOM_ELEMENT
// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';

class QuotesTab extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private _gridOptions: GridOptions = {};

	private users: { [key: string]: any }[] = [];

	private _update: () => Promise<void> = async () => { };
	private _idOrder: () => string = () => { return ''; };
	private _idCustomer: () => string = () => { return ''; };
	private _checkDelete: (data: { [key: string]: any }) => Promise<void> = async () => { };
	private _isSave: (data: { [key: string]: any }) => Promise<void> = async () => { };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Devis">
						<i class="icon icon-solid-file-contract"></i>
						<span class="nav-icon-badge d-none" id="number">0</span>
					</div>
					<span>Devis</span>
				</a>
			</li>
		`;

		this.removeAttribute('id');
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');
		N_div.id = this.idTab;
		N_div.innerHTML = `
            <div class="d-flex flex-column h-100">
                <div class="title">
					Devis
					<div class="ml-auto">
						<button class="btn btn-add" type="button" id="add" permission="ORDERS._CUSTOMERS.ADD">
							<i class="icon icon-solid-plus"></i>Ajouter
						</button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
                </div>
                <div class="h-100 ag-theme-alpine grid"></div>
            </div>
        `;

		N_container.append(N_div);

		this.initGrid();
		this.initButton();
	}

	public setCheckDelete(cb: (data: { [key: string]: any }) => Promise<void>) {
		this._checkDelete = cb;
	}

	public setIsSave(cb: (data: { [key: string]: any }) => Promise<void>) {
		this._isSave = cb;
	}

	public setOnUpdate(cb: () => Promise<void>) {
		this._update = cb;
	}

	public setIdOrder(cb: () => string) {
		this._idOrder = cb;
	}

	public setCustomer(cb: () => string) {
		this._idCustomer = cb;
	}

	private initGrid() {
		this._gridOptions = agUtils.french({
			localeText: { noRowsToShow: 'Aucun Devis' },
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			columnDefs: [
				{
					headerName: 'Numéro Devis',
					field: 'infos.number'
				}, {
					headerName: 'Objet',
					field: 'infos.label'
				}, {
					headerName: 'Sites',
					field: 'infos.sites'
				}, {
					headerName: 'Date saisie',
					field: 'infos.entryDate',
					cellRenderer: (params: any) => {
						return params.value ? moment(params.value, 'x').format('DD/MM/YYYY') : '';
					}

				}, {
					headerName: 'Date commande',
					field: 'infos.date',
					cellRenderer: (params: any) => {
						return params.value ? moment(params.value, 'x').format('DD/MM/YYYY') : '';
					}
				}, {
					headerName: 'Date livraison',
					field: 'infos.deliveryDate',
					cellRenderer: (params: any) => {
						return params.value ? moment(params.value, 'x').format('DD/MM/YYYY') : '';
					}
				}, {
					headerName: 'Montant (€)',
					field: 'price',
					cellClass: 'text-right text-monospace',
					cellRenderer: (params: any) => {
						if (!params.node.rowPinned) {
							return Decimal.setDisplayNumber(params.value).setSuffixAndHumanizeNumber('€');
						} else {
							let result = new Decimal(0);

							params.api?.forEachNode((node: any) => {
								result = result.plus(Decimal.setDisplayNumber(node.data.price));
							});

							return result.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€');
						}
					}
				}, {
					headerName: 'Personnes informées',
					field: 'infos.users',
					cellRenderer: (params: any) => {
						if (!params.node.rowPinned && params.value) {
							const res: string[] = [];

							for (const item of params.value) {
								const tmp = _.find(this.users, { _id: item });

								if (tmp) {
									res.push(tmp.firstname + ' ' + tmp.lastname[0] + '.');
								}
							}

							return res.join(', ');
						} else {
							return '';
						}
					}
				}, {
					headerName: 'Action',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					width: 180,
					cellRenderer: (params: any) => {
						if (!params.node.rowPinned) {
							const N_div = document.createElement('div');
							N_div.classList.add('btn-group');

							const N_edit = document.createElement('button');

							N_edit.type = 'button';

							N_edit.classList.add('h-100', 'py-0', 'btn-transparent');

							N_edit.setAttribute('tooltip', 'Modifier l\'enregistrement');
							N_edit.setAttribute('permission', 'ORDERS._CUSTOMERS.EDIT');

							N_edit.innerHTML = '<i class="h5 icon icon-ri-file-text-line"></i>';

							N_edit.addEventListener('click', async () => {
								try {
									await this._isSave(params.data);
									C_CommandCustomerFromQuote.open(params.data._id, { isUpdate: true, idOrder: this._idOrder(), from: 'order' });
								} catch (e) {
									Alert.warning('Changements non sauvegardés', '<br>' + (e as Error).message, undefined);
								}
							});

							const N_view = document.createElement('button');

							N_view.type = 'button';
							N_view.classList.add('h-100', 'py-0', 'btn-transparent');

							N_view.setAttribute('permission', 'QUOTES.OPEN');

							N_view.setAttribute('tooltip', 'Voir');

							N_view.innerHTML = '<i class="h5 icon icon-eye"></i>';

							N_view.addEventListener('click', async () => {
								C_Quote.open(params.data._id);
							});

							const N_editQuote = document.createElement('button');

							N_editQuote.type = 'button';
							N_editQuote.classList.add('h-100', 'py-0', 'btn-transparent');

							N_editQuote.setAttribute('permission', 'QUOTES.FORCE_EDIT');

							N_editQuote.setAttribute('tooltip', 'Editer');

							N_editQuote.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';

							N_editQuote.addEventListener('click', async () => {
								C_Quote.open(params.node.data._id, { forceEdit: true });
							});

							const N_delete = document.createElement('button');

							N_delete.type = 'button';

							N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

							N_delete.setAttribute('confirmation', '');
							N_delete.setAttribute('permission', 'ORDERS._CUSTOMERS.EDIT');

							N_delete.setAttribute('tooltip', 'Supprimer');

							N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';

							N_delete.addEventListener('click', async () => {
								try {
									await this._checkDelete(params.data);

									await S_C_Order.getInstance().deleteQuoteToOrder(this._idOrder(), params.data._id);

									params.api.updateRowData({
										remove: [params.data]
									});

									toaster.success('Sauvegarde réussie');

									this._update();
								} catch (e) {
									Alert.warning('Impossible de supprimer le devis', '<br><br>' + (e as Error).message);
								}
							});

							N_div.appendChild(N_edit);
							N_div.appendChild(N_view);
							N_div.appendChild(N_editQuote);
							N_div.appendChild(N_delete);

							return N_div;
						}

						return '';
					}
				}
			],
			pinnedBottomRowData: [{}],
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			getContextMenuItems: (params: any) => {
				const result = [];

				if (params.node) {
					result.push({
						name: `N° ${params.node.data.infos.number}`,
						disabled: true,
						cssClasses: ['title-context-menu']
					});

					if (LoggedUser.getInstance().hasPermission('ORDERS._CUSTOMERS.EDIT')) {
						result.push({
							name: 'Modifier l\'enregistrement',
							icon: '<i class="icon icon-ri-file-text-line"></i>',
							action: () => {
								C_CommandCustomerFromQuote.open(params.node.data._id, { isUpdate: true, idOrder: this._idOrder() });
							}
						});
					}

					if (LoggedUser.getInstance().hasPermission('QUOTES.OPEN')) {
						result.push({
							name: 'Voir',
							icon: '<i class="icon icon-eye"></i>',
							action: () => {
								C_Quote.open(params.node.data._id);
							}
						});
					}

					if (LoggedUser.getInstance().hasPermission('QUOTES.FORCE_EDIT')) {
						result.push({
							name: 'Éditer',
							icon: '<i class="icon icon-edit"></i>',
							action: () => {
								C_Quote.open(params.node.data._id, { forceEdit: true });
							}
						});
					}

					result.push('separator');
				}

				if (LoggedUser.getInstance().hasPermission('ORDERS._CUSTOMERS.ADD')) {
					result.push({
						name: 'Ajouter',
						icon: '<i class="icon icon-ri-add-line"></i>',
						action: async () => {
							const customer = await S_Customer.getInstance().getDisplayRefByID(this._idCustomer());
							new M_SelectQuotes(customer).open().then((value) => {
								C_CommandCustomerFromQuote.open(value, { idOrder: this._idOrder() });
							});
						}
					});
				}

				return result;
			},
			onRowDataChanged: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		} as GridOptions);

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector('#number') as HTMLElement;

		if (number) {
			N_number.innerHTML = number.toString();
			N_number.classList.remove('d-none');
		} else {
			N_number.classList.add('d-none');
		}
	}

	public setUsers(users: { [key: string]: any }[]) {
		this.users = users;
	}

	private initButton() {
		const N_btn = document.querySelector(`#${this.idTab} #add`) as HTMLButtonElement;

		N_btn.addEventListener('click', async () => {
			const customer = await S_Customer.getInstance().getDisplayRefByID(this._idCustomer());

			new M_SelectQuotes(customer).open().then((value) => {
				C_CommandCustomerFromQuote.open(value, { idOrder: this._idOrder() });
			});
		});
	}

	public set data(data: any) {
		this.gridOptions.api?.setRowData(data);
		this.gridOptions.api?.setPinnedBottomRowData([{}]);
	}

	public get data(): any {
		const results: any[] = [];

		this._gridOptions.api?.stopEditing();

		this._gridOptions.api?.forEachNode((node) => {
			results.push(node.data);
		});

		return results;
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public static register() {
		customElements.define(QuotesTab.tagName, QuotesTab);
	}
}

export default QuotesTab;
