// CORE
import { DOM, Form } from '@autoprog/core-client';
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import { Moment } from 'moment';

// TEMPLATE
import T_modal from '../../../../tpl/modals/customers/editPageFromQuote/generalInformations.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
import Utils from '@libs/utils/Utils';

// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Contact from '@services/Customer/CustomerContactService';
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';

type GeneralInformationsData = {
	command: string,
	infos: {
		number: string,
		numberCommand: string
		addonNumberCommand: string
		customer: string
		contact: string,
		date: Moment,
	}
};

class GeneralInformations extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};
	private nextCallback: null | ((data: any) => void) = null;

	private form: Form | null;
	private command: string = '';

	private listCommand: any[] = [];

	private _gridOptions: GridOptions = {};

	private isGridOpen: boolean = false;

	constructor(data: GeneralInformationsData, disabledOrderEdition: boolean) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			if (this.nextCallback) {
				N_save.innerHTML = 'Suivant';
			}

			if (disabledOrderEdition) {
				this.updateDisabledOrderEdition();
			}

			await this.setData(data);
			this.initEvents();
			await this.initSelectionGrid();
			this.updateCustomer();
			this.postInit();

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					data.command = await this.getDataToSelect2ByIDCommand();
					data.infos.customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer);
					data.infos.contact = await S_C_Contact.getInstance().getDataToSelect2ByID(data.infos.contact);

					if (this.nextCallback) {
						this.nextCallback(data);
					}

					DOM.nextTick(() => {
						this.resolve(data);
					});
				}));
			});

			await this.updateInternalNumber();
			this.updateButtonSave();
		});
	}

	public setNextCallback(cb: (data: any) => void) {
		this.nextCallback = cb;
		return this;
	}

	private init() {
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_contact = this.element.querySelector('[name="infos.contact"]') as CE_Select2;

		N_customer.create(this.element);

		N_contact.setRef({ id_customer: N_customer.selectElement! });
		N_contact.create(this.element);

		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['infos.contact'] = N_contact;
	}

	private async setData(data: GeneralInformationsData) {
		const res: { [key: string]: any } = {
			command: await this.getDataToSelect2ByIDCommand(),
			infos: {
				number: data.infos.number,
				numberCommand: data.infos.numberCommand,
				addonNumberCommand: data.infos.addonNumberCommand,
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer),
				contact: await S_C_Contact.getInstance().getDataToSelect2ByID(data.infos.contact)
			}
		};

		this.form?.setData(res);
	}

	private async getDataToSelect2ByIDCommand() {
		if (this.command === 'new') {
			return {
				id: 'new',
				text: 'Nouvelle affaire'
			};
		} else {
			return S_C_Order.getInstance().getDataToSelect2ByID(this.command);
		}
	}

	private initEvents() {
		const N_numberCommand = this.element.querySelector('[name="infos.numberCommand"]') as HTMLInputElement;
		const N_addonNumberCommand = this.element.querySelector('[name="infos.addonNumberCommand"]') as HTMLInputElement;

		const N_cloneNumber = this.element.querySelector('#cloneNumber') as HTMLButtonElement;
		const N_toggleSelectionGrid = this.element.querySelector('#toggleSelectionGrid') as HTMLButtonElement;

		N_numberCommand.addEventListener('input', async () => {
			await this.updateInternalNumber();
			this.updateSelectedOrder();
			this.updateButtonSave();
		});

		N_addonNumberCommand.addEventListener('input', () => {
			this.updateInternalNumber();
		});

		N_cloneNumber.addEventListener('click', async () => {
			if (this.form) {
				const number = this.form.getDataByName('infos.number') as string;
				this.form.setDataByName('infos.numberCommand', number);
				await this.updateInternalNumber();
				this.updateButtonSave();
			}
			Utils.removeTooltip();
		});

		N_toggleSelectionGrid.addEventListener('click', () => {
			N_toggleSelectionGrid.classList.toggle('bg-black');
			N_toggleSelectionGrid.classList.toggle('text-white');

			this.isGridOpen = !this.isGridOpen;

			N_cloneNumber.disabled = this.isGridOpen;
			N_numberCommand.disabled = this.isGridOpen;
			N_addonNumberCommand.disabled = this.isGridOpen;

			this.toggleSelectionGrid();
			this._gridOptions.api?.sizeColumnsToFit();
			Utils.removeTooltip();
		});
	}

	private async updateSelectedCustomer() {
		const N_selected_customer = this.element.querySelector('#selected_customer') as HTMLSpanElement;
		const customerID = this.form?.getDataByName('infos.customer') as string;
		if (customerID) {
			const customerName = await S_Customer.getInstance().getDisplayRefByID(customerID);
			N_selected_customer.innerHTML = customerName || 'aucun';
		} else {
			N_selected_customer.innerHTML = 'aucun';
		}
	}

	private async updateSelectedOrder() {
		const N_selected_customer = this.element.querySelector('#selected_order') as HTMLSpanElement;
		const numberCommand = this.form?.getDataByName('infos.numberCommand') as string;
		N_selected_customer.innerHTML = numberCommand || 'aucune';
	}

	private async initSelectionGrid() {
		this.updateSelectedCustomer();
		this.updateSelectedOrder();

		await this.initGrid();
	}

	private async initGrid() {
		this.listCommand = (await S_C_Order.getInstance().getDataToAgGrid()).rowData;

		this._gridOptions = agUtils.french<GridOptions>({
			rowData: this.listCommand,
			localeText: { noRowsToShow: 'Aucune commande' },
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowDragManaged: true,
			defaultColDef: {
				filter: 'agTextColumnFilter',
				filterParams: { newRowsAction: 'keep' },
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			floatingFilter: true,
			suppressContextMenu: true,
			columnDefs: [
				{
					headerName: '',
					width: 45,
					checkboxSelection: true,
					filter: false,
					resizable: true,
					pinned: 'left'
				},
				{
					headerName: 'N° Affaire',
					field: 'infos.internalNumber'
				},
				{
					headerName: 'N° Commande Client',
					field: 'infos.number'
				},
				{
					headerName: 'Complément N° CC',
					field: 'infos.addonNumber'
				},
				{
					headerName: 'Objet',
					field: 'infos.label'
				},
				{
					headerName: 'Sites',
					field: 'infos.sites'
				},
				{
					headerName: 'Client',
					field: 'infos.customer',
					width: 200,
					suppressSizeToFit: true,
					hide: true
				},
				{
					headerName: 'state',
					field: 'bill.state',
					filter: 'agSetColumnFilter',
					width: 200,
					suppressSizeToFit: true,
					hide: true
				}
			],
			onRowClicked: (params) => {
				params.node.setSelected(!params.node.isSelected());
			},
			onRowSelected: (params) => {
				if (params.node.isSelected()) {
					const orderData = params.node.data;
					this.command = orderData._id;

					this.form?.setDataByName('infos.numberCommand', orderData.infos.number);
					this.form?.setDataByName('infos.addonNumberCommand', orderData.infos.addonNumber);

					const N_internalNumber = this.element.querySelector('#internalNumber') as HTMLElement;
					N_internalNumber.innerHTML = orderData.infos.internalNumber;
				} else {
					if (params.api.getSelectedRows().length === 0) {
						this.clearOrder();
					}
				}

				this.updateSelectedOrder();
				this.updateButtonSave();
			},
			onModelUpdated: (params) => {
				if (params.api.getDisplayedRowCount() === 0) {
					params.api.showNoRowsOverlay();
				} else {
					params.api.hideOverlay();
				}
			}
		});

		const N_grid = document.querySelector('#grid_customer_orders') as HTMLDivElement;
		new Grid(N_grid, this._gridOptions, { modules: AllModules });
	}

	private async updateCustomer() {
		const customer = this.form?.getDataByName('infos.customer');

		if (customer) {
			this._gridOptions.api?.setRowData(this.listCommand);

			const customerName = await S_Customer.getInstance().getDisplayRefByID(customer as string);

			const filterInstanceCustomer = this._gridOptions.api?.getFilterInstance('infos.customer');
			const filterInstanceState = this._gridOptions.api?.getFilterInstance('bill.state');

			filterInstanceCustomer?.setModel({
				type: 'equals',
				filter: customerName
			});

			filterInstanceState?.setModel({
				values: ['0', '1']
			});

			this._gridOptions.api?.onFilterChanged();
		} else {
			this._gridOptions.api?.setRowData([]);
		}
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}

		this.selectPostinit['infos.customer'].on('change', async (customer) => {
			this.clearOrder();
			this.updateCustomer();
			const contacts = await S_C_Contact.getInstance().getByCustomerToSelect2(customer as string);

			if (contacts.length && customer) {
				this.form?.setDataByName('infos.contact', contacts[0]);
			} else {
				this.form?.setDataByName('infos.contact', { id: '', text: '' });
			}

			await this.updateInternalNumber();

			this.updateSelectedCustomer();
			this.updateSelectedOrder();

			this.updateButtonSave();
		});
	}

	private updateDisabledOrderEdition() {
		const N_numberCommand = this.element.querySelector('[name="infos.numberCommand"]') as HTMLInputElement;
		const N_cloneNumber = this.element.querySelector('#cloneNumber') as HTMLButtonElement;
		const N_toggleSelectionGrid = this.element.querySelector('#toggleSelectionGrid') as HTMLButtonElement;
		const N_addonNumberCommand = this.element.querySelector('[name="infos.addonNumberCommand"]') as HTMLInputElement;
		const N_customer = this.element.querySelector('[name="infos.customer"]') as HTMLInputElement;

		N_numberCommand.disabled = true;
		N_cloneNumber.disabled = true;
		N_toggleSelectionGrid.disabled = true;
		N_addonNumberCommand.disabled = true;
		N_customer.disabled = true;
	}

	private updateButtonSave() {
		const customer = this.form?.getDataByName('infos.customer') as string;

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !this.command.length || !customer.length;
	}

	private async updateInternalNumber() {
		const data = this.form?.getData() as { [key: string]: any };

		if (data.infos.numberCommand) {
			const N_internalNumber = this.element.querySelector('#internalNumber') as HTMLElement;
			try {
				const order = await S_C_Order.getInstance().getOrderByNumberAndCompany(data.infos.customer, data.infos.numberCommand, data.infos.addonNumberCommand);

				N_internalNumber.innerHTML = order.infos.internalNumber;
				this.command = order._id;
			} catch (e) {
				N_internalNumber.innerHTML = 'Nouvelle affaire';
				this.command = 'new';
			}
		}
	}

	private clearOrder() {
		if (this.command !== 'new') {
			this.command = '';

			this.form?.setDataByName('infos.numberCommand', '');
			this.form?.setDataByName('infos.addonNumberCommand', '');

			const N_internalNumber = this.element.querySelector('#internalNumber') as HTMLDivElement;
			N_internalNumber.innerHTML = '';
		}
	}

	private toggleSelectionGrid() {
		const N_selectionGrid = this.element.querySelector('#selectionGrid') as HTMLDivElement;
		N_selectionGrid.classList.toggle('d-flex');
		N_selectionGrid.classList.toggle('d-none');

		const N_modal_dialog = this.element.querySelector('.modal-dialog') as HTMLDivElement;
		N_modal_dialog.classList.toggle('modal-40');
		N_modal_dialog.classList.toggle('modal-80');
	}
}

export default GeneralInformations;
