import { Controller, DOM, Router, SlimScroll, toaster } from '@autoprog/core-client';

import ConfigManager from '@libs/ConfigManager';
import GenericService from '@services/GenericService';
import ServiceManager from '@managers/ServiceManager';

import Form from '@libs/FormControllerPageID';
import History from '@libs/History';
import Loader from '@libs/Loader';
import OpenDocuments from '@libs/OpenDocuments';
import Title from '@libs/Title';
import Utils from '@libs/utils/Utils';

import CE_Address from '@libs/customElement/Address';
import CE_FilesTab from '@libs/customElement/FilesTab';
import CE_HistoryTab from '@libs/customElement/HistoryTab';
import CE_NotesTab from '@libs/customElement/NotesTab';
import CE_RemindersTab from '@libs/customElement/RemindersTab';
import CE_Select2 from '@libs/customElement/Select2';
import CE_SignatureTab from '@libs/customElement/SignatureTab';

import M_PrintPreview from '@js/libs/modals/PrintPreview';
import M_VerifSave from '@libs/modals/VerifSave';

import _ from 'lodash';

export type DataServer = {
	data: { [key: string]: any }
	[key: string]: any
};

class ControllerPageID extends Controller {
	private resolveReturn = () => { };
	private rejectReturn = () => { };

	private table: string;
	protected service: GenericService | undefined;

	protected id: string;
	protected config: { [key: string]: any } = {};
	protected options: { [key: string]: any } = {};
	private _isSave: boolean;

	protected reloadAfterSave: boolean;

	protected form: Form | null;
	protected title: Title;
	protected openDocuments: OpenDocuments;

	protected routeReturn: string;

	protected el: HTMLElement;

	protected selectPostinit: { [key: string]: CE_Address | CE_Select2 } = {};

	private N_FilesTab: CE_FilesTab | null;
	protected N_NotesTab: CE_NotesTab | null;
	protected N_HistoryTab: CE_HistoryTab | null;
	protected N_SignatureTab: CE_SignatureTab | null;
	protected N_RemindersTab: CE_RemindersTab | null;

	protected listeners: EventListenerCanceller[] = [];

	private abortController: AbortController;
	protected get abortSignal(): AbortSignal {
		return this.abortController.signal;
	}

	public static currentTab: string | null = null;

	protected static idDuplicate = '';

	protected static mode = '';
	public static setMode(mode: 'duplicate') {
		ControllerPageID.mode = mode;
		return this;
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	public static open(id: string | null, options: { [key: string]: any } = {}) {
		throw new Error('override');
	}

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.table = '';
		this.id = '';
		this.routeReturn = '';
		this.service = undefined;

		this._isSave = true;

		this.reloadAfterSave = false;

		this.N_NotesTab = null;
		this.N_FilesTab = null;
		this.N_HistoryTab = null;
		this.N_SignatureTab = null;
		this.N_RemindersTab = null;

		this.abortController = new AbortController();

		this.form = null;
		this.title = Title.getInstance();
		this.openDocuments = OpenDocuments.getInstance();
	}

	protected async init(table: string, id: string) {
		this.table = table;
		this.id = id;

		this.openDocuments.register(id, this.tableOpenDocument, this._isSave);

		this.service = ServiceManager.get(this.table)?.getInstance();

		this.config = ConfigManager.getInstance().getConfig(this.table);

		this.initVerifSave();
		this.initMenuTabs();

		await DOM.nextTick();

		if (this.N_form) {
			this.form = new Form(this.N_form!);
		}

		this.initButton();
		this.initFiles();
		this.initNotes();
		this.initHistory();
		this.initSignature();
		this.initReminders();

		this.initPrint();
	}

	private initVerifSave() {
		const router = Router.getInstance();

		router.on('route.change', (route: any, params: any, query: any, event: any) => {
			return new Promise<void>((resolve) => {
				if (!this.isSave) {
					new M_VerifSave().open().then(async (res: string) => {
						if (res === 'cancel') {
							event.stopPropagation();
							this.rejectReturn();
						}

						if (res === 'leave') {
							this.title.updateSuffix('');
							this.resolveReturn();
						}

						if (res === 'save') {
							this.title.updateSuffix('');
							await this.save(true, true);
							this.resolveReturn();
						}

						resolve();
					});
				} else {
					this.title.updateSuffix('');
					this.resolveReturn();
					resolve();
				}
			});
		}, {
			signal: this.abortSignal
		});

		window.addEventListener('beforeunload', (event) => {
			if (!this.isSave) {
				event.returnValue = true;
			}
		}, {
			signal: this.abortSignal
		});
	}

	protected initButton() {
		const N_save = this.el.querySelector('#btn-save') as HTMLButtonElement;

		N_save.disabled = true;

		N_save.addEventListener('click', () => {
			N_save.loading(this.save());
		});

		const N_return = this.el.querySelector('#btn-return') as HTMLButtonElement;

		N_return.addEventListener('click', () => {
			this.return();
		});
	}

	private initMenuTabs() {
		const N_el = this.el.querySelector('#btn-nav-tabs i') as HTMLElement;
		const N_menu = this.el.querySelector('.menu') as HTMLElement;

		let value = localStorage.getItem('apps_hidden_navigation_page') === 'true';

		if (!value) {
			N_menu && N_menu.classList.add('active');
		}

		N_el?.addEventListener('click', () => {
			N_menu && N_menu.classList.toggle('active');

			value = !value;

			localStorage.setItem('apps_hidden_navigation_page', value.toString());
		});
	}

	protected initFullscreen() {
		const N_fullscreen = this.el.querySelectorAll('[data-type="fullscreen"]') as NodeListOf<HTMLButtonElement>;

		const N_element_fullscreen = this.el.querySelector('#fullscreen') as HTMLElement;

		if (N_element_fullscreen) {
			const iconEnable = 'icon-solid-chevron-up';
			const iconDisable = 'icon-solid-chevron-down';

			const N_List = N_element_fullscreen.querySelector('#scroll-container') as HTMLElement;

			new SlimScroll(N_List);

			let fullscreenEnable = false;

			N_fullscreen.forEach((N_el) => {
				N_el.classList.remove('d-none');
				N_el.classList.add('ml-2');

				N_el.innerHTML = `<i class="icon ${iconEnable}"></i>`;

				N_el.setAttribute('tooltip', 'Agrandir');

				N_el.addEventListener('click', () => {
					fullscreenEnable = !fullscreenEnable;

					N_element_fullscreen.classList.toggle('fullscreen');

					N_fullscreen.forEach((N_el) => {
						const N_icon = N_el.querySelector('.icon') as HTMLElement;
						N_icon.classList.toggle(iconEnable);
						N_icon.classList.toggle(iconDisable);

						if (N_icon.classList.contains(iconEnable)) {
							N_el.setAttribute('tooltip', 'Agrandir');
						} else {
							N_el.setAttribute('tooltip', 'Réduire');
						}
					});
				});
			});
		}
	}

	private closeAllMobile(iconEnable: string, iconDisable: string) {
		const N_openMobile = this.el.querySelectorAll('[data-type="open-mobile"]') as NodeListOf<HTMLButtonElement>;
		N_openMobile.forEach((N_el) => {
			const N_ref = this.el.querySelector(`#${N_el.getAttribute('href')}`);
			N_ref?.classList.add('d-none');
			const N_icon = N_el.querySelector('[data-type="open-mobile-icon"] .icon') as HTMLElement;
			N_icon.classList.add(iconEnable);
			N_icon.classList.remove(iconDisable);
		});
	}

	protected initMobile() {
		const N_openMobile = this.el.querySelectorAll('[data-type="open-mobile"]') as NodeListOf<HTMLButtonElement>;

		const iconEnable = 'icon-solid-chevron-down';
		const iconDisable = 'icon-solid-chevron-up';

		N_openMobile.forEach((N_el) => {
			const N_ref = this.el.querySelector(`#${N_el.getAttribute('href')}`);

			N_ref?.classList.add('d-none');

			const N_icon_container = N_el.querySelector('[data-type="open-mobile-icon"]') as HTMLElement;
			N_icon_container.innerHTML = `<i class="tab-enable-icon icon ${iconEnable} text-grey-700"></i>`;

			N_el.addEventListener('click', () => {
				const N_icon = N_el.querySelector('[data-type="open-mobile-icon"] .icon') as HTMLElement;
				const isOpen = N_icon.classList.contains(iconEnable);

				this.closeAllMobile(iconEnable, iconDisable);

				if (isOpen) {
					N_ref?.classList.remove('d-none');
					N_icon.classList.remove(iconEnable);
					N_icon.classList.add(iconDisable);
				} else {
					N_ref?.classList.add('d-none');
				}
			});
		});
	}

	private initFiles() {
		this.N_FilesTab = this.el.querySelector(CE_FilesTab.tagName) as CE_FilesTab;

		this.N_FilesTab?.setParentElement(this.el);

		this.N_FilesTab?.setCallback(() => {
			this.save();
		});
	}

	private initNotes() {
		this.N_NotesTab = this.el.querySelector(CE_NotesTab.tagName) as CE_NotesTab;

		this.N_NotesTab?.setParentElement(this.el);

		this.N_NotesTab?.setCallback(() => {
			this.updateSaveButton();
		});
	}

	private initHistory() {
		this.N_HistoryTab = this.el.querySelector(CE_HistoryTab.tagName) as CE_HistoryTab;

		this.N_HistoryTab?.setParentElement(this.el);
	}

	private initReminders() {
		this.N_RemindersTab = this.el.querySelector(CE_RemindersTab.tagName) as CE_RemindersTab;

		this.N_RemindersTab?.setParentElement(this.el);
	}

	private initSignature() {
		this.N_SignatureTab = this.el.querySelector(CE_SignatureTab.tagName) as CE_SignatureTab;

		this.N_SignatureTab?.setParentElement(this.el);

		this.N_SignatureTab?.setOnUpdate(this.updateSaveButton.bind(this));
	}

	private initPrint() {
		const N_print = this.el.querySelector('#print') as HTMLButtonElement;

		N_print && N_print.addEventListener('click', () => {
			new M_PrintPreview(this.tableOpenDocument, this.id).open();
		});
	}

	protected async getData() {
		let data: DataServer = { data: {} };

		if (this.service) {
			Loader.getInstance().open();

			if (ControllerPageID.mode === 'duplicate') {
				const { data: tmp } = await this.service.duplicate(ControllerPageID.idDuplicate, this.options);
				data = tmp;

				ControllerPageID.mode = '';
				ControllerPageID.idDuplicate = '';
			} else {
				const { data: tmp } = await this.service.getDataToModal(this.id, this.options);
				data = tmp;
			}

			Loader.getInstance().close();
		}

		this.N_HistoryTab?.init(this.id, this.table);
		this.N_RemindersTab?.init(this.table, this.id);

		return data;
	}

	protected convertData(data: { [key: string]: any }) {
		return data;
	}

	protected setDataForm(data: { [key: string]: any }) {
		data = _.cloneDeep(data);
		data = this.convertData(data);
		this.form?.setData(data);
	}

	protected setData(data: DataServer) {
		this.setDataForm(data.data);

		this.initTitle(data);

		if (this.N_NotesTab) {
			this.N_NotesTab.data = data.data.notes || [];
		}

		if (this.N_FilesTab) {
			this.N_FilesTab.data = data.data.attachments || {};
		}

		if (this.N_SignatureTab) {
			this.N_SignatureTab.data = data.data.signature || {};
		}
	}

	protected postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
		if (this.N_NotesTab) {
			this.N_NotesTab.postInit();
		}
		if (this.N_FilesTab) {
			this.N_FilesTab.postInit();
		}
		if (this.N_HistoryTab) {
			this.N_HistoryTab.postInit();
		}
		if (this.N_RemindersTab) {
			this.N_RemindersTab.postInit();
		}
		if (this.N_SignatureTab) {
			this.N_SignatureTab.postInit();
		}
	}

	private initTitle(data: DataServer) {
		const N_title = this.el.querySelector('#title') as HTMLElement;
		if (data.title) {
			N_title.innerHTML = data.title;
		} else {
			N_title.innerHTML = 'Erreur de chargement';
		}
	}

	protected async updateTitle() {
		const N_title = this.el.querySelector('#title') as HTMLElement;
		try {
			const title = await this.service?.getTitleToModal(this.form?.getData());
			N_title.innerHTML = title;
		} catch (e) {
			N_title.innerHTML = 'Erreur de chargement';
		}
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		return {};
	}

	protected get saveData() {
		let data: { [key: string]: any } = {
			_id: this.id,
			...this.form?.getData()
		};

		if (this.N_NotesTab) {
			data.notes = this.N_NotesTab.data;
		}

		if (this.N_FilesTab) {
			data.attachments = this.N_FilesTab.data;
		}

		if (this.N_SignatureTab) {
			data.signature = this.N_SignatureTab.data;
		}

		const otherData = this.getPageData(data);

		data = {
			...data,
			...otherData
		};

		return data;
	}

	protected get otherDataSave() {
		return {};
	}

	protected async _save(disabledReload: boolean = false, disabledSetUrl: boolean = false) {
		const res = await this.service?.save(this.saveData, this.otherDataSave) || { ok: false, err: 'unknown', data: {} };

		if (res.ok) {
			toaster.success('Sauvegarde réussie');
			this.id = res.data.data._id;
			this.resetSaveButton();

			if (!disabledSetUrl) {
				this.setUrl();
			}

			await this.postSave(res.data.data);
			this.N_HistoryTab?.init(this.id, this.table);

			if (this.reloadAfterSave && !disabledReload) {
				this.reload();
			}
		} else {
			console.error(res.err);
			toaster.error('Erreur lors de la sauvegarde');
		}
	}

	protected async save(disabledReload: boolean = false, disabledSetUrl: boolean = false) {
		const N_save = this.el.querySelector('#btn-save') as HTMLButtonElement;
		N_save.disabled = true;

		Utils.removeTooltip();

		await this._save(disabledReload, disabledSetUrl);

		N_save.disabled = false;
	}

	protected setUrl() {
		const href: string[] = [];

		href.push(`id=${this.id}`);

		const url = `#${Router.getInstance().getCurrentRoute()?.url}`;

		History.getInstance().replaceState(`${url}?${href.join('&')}`);
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected async postSave(newData: { [key: string]: any }) {
	}

	protected return() {
		return new Promise<void>((resolve, reject) => {
			this.resolveReturn = resolve;
			this.rejectReturn = reject;

			this.openDocuments.unregister(this.id, this.tableOpenDocument);

			if (!History.getInstance().back()) {
				Router.getInstance().navigate(this.routeReturn);
			}
		});
	}

	protected async reload() {
		//Router.getInstance().reload();

		const data = await this.getData();
		this.setData(data);
	}

	protected get tableOpenDocument() {
		return this.table;
	}

	protected updateSaveButton() {
		this._isSave = false;

		this.openDocuments.register(this.id, this.tableOpenDocument, this._isSave);

		const N_save = this.el.querySelector('#btn-save') as HTMLButtonElement;
		const N_print = this.el.querySelector('#print') as HTMLButtonElement;

		N_save.disabled = false;
		N_print && (N_print.disabled = true);
	}

	protected resetSaveButton() {
		this._isSave = true;

		this.openDocuments.register(this.id, this.tableOpenDocument, this._isSave);

		const N_save = this.el.querySelector('#btn-save') as HTMLButtonElement;
		const N_print = this.el.querySelector('#print') as HTMLButtonElement;

		N_save.disabled = true;
		N_print && (N_print.disabled = false);
	}

	protected get isSave() {
		return this._isSave;
	}

	protected get N_form(): HTMLFormElement | null {
		return this.el.querySelector('form');
	}

	public destructor() {
		this.openDocuments.unregister(this.id, this.tableOpenDocument);

		this.abortController.abort('destroyed');

		for (const event of this.listeners) {
			event();
		}
	}
}

export default ControllerPageID;
