// CORE

// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
import T_modal from '../../../../tpl/modals/providers/editPage/additionalInformation.html';

// LIBS
import Modal from '@libs/EditPageModal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import CE_Address from '@libs/customElement/Address';
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_P_Address from '@services/Provider/ProviderAddressService';
import S_Provider from '@services/Provider/ProviderService';

type AdditionalInformationData = {
	infos: {
		provider: string,
		addressID: string,
		fullAddress: string,
		date: Moment,
		datePayment: Moment,
		receiveDate: Moment,
		comment: string
	},
	disputes: {
		comment: string
	},
	relunch: {
		date: Moment
	}
};

class AdditionalInformation extends Modal<AdditionalInformationData> {
	constructor(data: AdditionalInformationData) {
		super(data, T_modal);
	}

	protected preInit() {
		const N_provider = this.element.querySelector('[name="infos.provider"]') as CE_Select2;
		const N_address = this.element.querySelector('[name-select="infos.addressID"]') as CE_Address;

		const N_datePayment = this.element.querySelector('[name="infos.datePayment"]') as HTMLInputElement;

		N_datePayment.addEventListener('change', () => {
			const date = (this.form?.getDataByName('infos.datePayment') as Moment).clone();
			this.form?.setDataByName('relunch.date', date.subtract(3, 'days'));
		});

		N_provider.create(this.element, { disabled: true });

		N_address.setRef({ id_provider: N_provider.selectElement! });
		N_address.create(this.element);

		this.selectPostinit['infos.provider'] = N_provider;
		this.selectPostinit['infos.addressID'] = N_address;
	}

	protected async setData(data: AdditionalInformationData) {
		const res: { [key: string]: any } = {
			infos: {
				provider: await S_Provider.getInstance().getDataToSelect2ByID(data.infos.provider),
				addressID: await S_P_Address.getInstance().getDataToSelect2ByID(data.infos.addressID),
				fullAddress: data.infos.fullAddress,
				date: data.infos.date,
				datePayment: data.infos.datePayment,
				receiveDate: data.infos.receiveDate,
				comment: data.infos.comment
			},
			disputes: {
				comment: data.disputes.comment
			},
			relunch: {
				date: data.relunch.date
			}
		};

		this.form?.setData(res);
	}

	protected async getDataForm(data: { [key: string]: any }) {
		data.infos.provider = await S_Provider.getInstance().getDataToSelect2ByID(data.infos.provider);
		data.infos.addressID = await S_P_Address.getInstance().getDataToSelect2ByID(data.infos.addressID);

		return data;
	}
}

export default AdditionalInformation;
