// CORE
import { Form, toaster } from '@autoprog/core-client';

// NODE_MODULES
import _ from 'lodash';

//LIBS
import ConfigManager from '@libs/ConfigManager';
import Utils from '@js/libs/utils/Utils';

// MODAL
import Modal from '@libs/Modal';

// TEMPLATE
import T_Modal from '../../../tpl/modals/AddQuote/SaveAs.html';

// SERVICE
import S_Quote from '@services/QuoteService';
import S_User from '@services/User/UserService';

// TYPES
import { QuoteState } from '../../controllers/Quotes';
import moment from 'moment';

class SaveAs extends Modal {
	constructor(id: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			this.generateState();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.disabled = true;

			const form = new Form(this.element.querySelector('.modal-body') as HTMLFormElement);

			form.setData({
				number: 'new-number',
				manager: 'old-manager',
				save_pref: true,
				update_price: true,
				state: '-1'
			});

			const data = await S_Quote.getInstance().getById(id);
			const user = (await S_User.getInstance().getDataToSelect2ByID(data.infos.manager)).text;

			const newNumber = await S_Quote.getInstance().getNewNumberByCompany(moment(), Utils.companyID);
			const nextVersion = await S_Quote.getInstance().getNextVersionNumber(id);

			const N_newNumber = this.element.querySelector('#text-new-number') as HTMLElement;
			const N_nextVersion = this.element.querySelector('#text-next-version') as HTMLElement;
			const N_oldManager = this.element.querySelector('#text-old-manager') as HTMLElement;

			N_newNumber.innerHTML = `- ${newNumber}`;
			N_nextVersion.innerHTML = `- ${nextVersion}`;
			N_oldManager.innerHTML = ` - ${user}`;

			const N_addonTitle = this.element.querySelector('#addon-title') as HTMLElement;
			N_addonTitle.innerHTML = data.infos.number;

			if (data.infos.state.toString() === QuoteState.ACCEPTED) {
				this.disableChangeState();
			} else if (data.infos.state.toString() === QuoteState.REFUSED) {
				this.disableChangeState();
			} else if (data.infos.sendDate || data.infos.state.toString() === QuoteState.WAITING_FOR_ORDER) {
				form.setDataByName('state', QuoteState.REVISED);
				this.disableChangeState();
			}

			N_save.disabled = false;

			N_save.addEventListener('click', async () => {
				const { data } = await S_Quote.getInstance().duplicate(id, { ...form.getData(), companyID: Utils.companyID });

				toaster.success('Sauvegarde réussie');

				this.resolve(data.data._id);
			});
		});
	}

	private generateState() {
		const listState = _.find<any>(ConfigManager.getInstance().getConfig('quotes').columns, { key: 'infos.state' } as any);

		const N_state = this.element.querySelector('#state') as HTMLElement;

		for (const key of listState.order) {
			//filtre: accepté est à valider
			if (!['1', '6'].includes(key)) {
				const N_div = document.createElement('div');
				N_div.classList.add('custom-control', 'custom-radio');

				const state = parseInt(key).toString();

				N_div.innerHTML = `
					<input type="radio" class="custom-control-input" name="state" id="state-${state}" value="${state}">
					<label class="custom-control-label" for="state-${state}">${listState.object[key]}</label>
				`;

				N_state.appendChild(N_div);
			}
		}
	}

	private disableChangeState() {
		const N_stateList = this.element.querySelectorAll('#state input') as NodeListOf<HTMLInputElement>;

		N_stateList.forEach((node) => {
			node.disabled = true;
		});
	}
}

export default SaveAs;
