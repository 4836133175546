// CORE
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import h from 'hyperscript';
import moment from 'moment';

// TEMPLATE
// LIBS
import C_Delivery from '@modules/Deliveries_Receipts/js/controllers/Delivery';
// PRINTER
// UTILS
// MODAL
import M_details from '../../../modals/customers/DetailsDeliveries';

// CUSTOM_ELEMENT
// SERVICE

class DeliveryTab extends HTMLElement {
	public static readonly tagName: string = 'ap-delivery-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private _gridOptions: GridOptions = {};

	private _sum: { [key: string]: any } = {};

	private _idOrder: () => string = () => { return ''; };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Bons de livraison">
						<i class="icon icon-solid-truck"></i>
						<span class="nav-icon-badge d-none" id="number">0</span>
					</div>
					<span>Bons de livraison</span>
				</a>
			</li>
		`;

		this.removeAttribute('id');
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');
		N_div.id = this.idTab;
		N_div.innerHTML = `
            <div class="d-flex flex-column h-100">
                <div class="title">
					Bon de livraison
					<div class="ml-auto">
						<button class="btn btn-info" type="button" id="detail">
							<ap-icon name="file-list/line" class="mr-1"></ap-icon> Voir détails
						</button>
						<button class="btn btn-add" type="button" id="add" permission="DELIVERIES.ADD">
							<ap-icon name="add/line"></ap-icon>	Ajouter
						</button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
                </div>
                <div class="h-100 ag-theme-alpine grid"></div>
            </div>
        `;

		N_container.append(N_div);

		this.initGrid();

		const N_detail = N_div.querySelector('#detail') as HTMLButtonElement;

		N_detail.addEventListener('click', () => {
			new M_details(this._sum.products).open();
		});
	}

	public setIdOrder(cb: () => string) {
		this._idOrder = cb;
	}

	private initGrid() {
		this._gridOptions = agUtils.french({
			localeText: { noRowsToShow: 'Aucun Bon de livraison' },
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Numéro',
					field: 'infos.number',
					pinned: 'left',
					width: 200,
					suppressSizeToFit: true
				},
				{
					headerName: 'Date de livraison',
					field: 'infos.deliveryDate',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params: any) => {
						return params.value ? moment(params.value, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
					}
				},
				{
					headerName: 'Nom Affaire',
					field: 'infos.businessName',
					width: 100
				},
				{
					headerName: 'État',
					field: 'state',
					cellRenderer: (params) => {
						switch (params.value) {
							case '0':
								return 'En cours de préparation';
							case '1':
								return 'Prêt à expédier';
							case '2':
								return 'Expédié';
							default:
								return '';
						}
					}
				},
				{
					headerName: 'Quantité livrée',
					field: 'infos.totalDelivered',
					width: 100
				},
				{
					headerName: 'Quantité restante',
					field: 'infos.totalRemaining',
					width: 100
				},
				{
					headerName: 'Action',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_div = h('div');

						const N_edit = document.createElement('button');

						N_edit.classList.add('h-100', 'py-0', 'btn-transparent');

						N_edit.setAttribute('permission', 'DELIVERIES.EDIT');

						N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';
						N_edit.setAttribute('tooltip', 'Éditer');
						N_edit.type = 'button';

						N_edit.addEventListener('click', async () => {
							C_Delivery.open(params.data._id);
						});

						N_div.appendChild(N_edit);

						return N_div;
					}

				}

			],
			getRowStyle: (params: any) => {
				switch (params.data.state) {
					case '0':
						return {
							'background-color': 'var(--ap-red-50)',
							color: 'var(--ap-red-900)'
						};
					case '1':
						return {
							'background-color': 'var(--ap-orange-50)',
							color: 'var(--ap-orange-900)'
						};
					case '2':
						return {
							'background-color': 'var(--ap-green-50)',
							color: 'var(--ap-green-900)'
						};
				}

				return {
					'background-color': 'var(--ap-teal-50)',
					color: 'var(--ap-teal-900)'
				};
			},
			getContextMenuItems: (params) => {
				return [
					{
						name: `N° ${params.node.data.infos.number}`,
						disabled: true,
						cssClasses: ['title-context-menu']
					}, {
						name: 'Éditer',
						icon: '<i class="icon icon-edit"></i>',
						disabled: !params.node,
						action: () => {
							C_Delivery.open(params.node.data._id);
						}
					},
					'separator',
					{
						name: 'Créer un bon de livraison',
						icon: '<i class="icon icon-solid-plus"></i>',
						action: () => {
							C_Delivery.open(null, {
								from: 'order',
								idOrder: this._idOrder()
							});
						}
					}
				];
			},
			onRowDataChanged: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		} as GridOptions);

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector('#number') as HTMLElement;

		if (number) {
			N_number.innerHTML = number.toString();
			N_number.classList.remove('d-none');
		} else {
			N_number.classList.add('d-none');
		}
	}

	public set data(data: any) {
		this.gridOptions.api?.setRowData(data.data);
		this._sum = data.sum;
		this.updateButton();
	}

	private updateButton() {
		const N_btn = document.querySelector(`#${this.idTab} #add`) as HTMLButtonElement;
		let idFind: null | string = null;
		let numberFind: null | string = null;

		this.gridOptions.api?.forEachNode((node) => {
			if (node.data.state === '0') {
				idFind = node.data._id;
				numberFind = node.data.infos.number;
			}
		});

		if (idFind) {
			N_btn.classList.add('btn-info');
			N_btn.classList.remove('btn-add');
			N_btn.innerHTML = '<ap-icon name="edit-box/line"></ap-icon>Ouvrir';
			N_btn.setAttribute('tooltip', `Ouvrir le BL n° ${numberFind}`);
		}

		N_btn.addEventListener('click', () => {
			if (idFind) {
				C_Delivery.open(idFind);
			} else {
				C_Delivery.open(idFind, {
					from: 'order',
					idOrder: this._idOrder()
				});
			}
		});
	}

	public get data(): any {
		const results: any[] = [];

		this._gridOptions.api?.forEachNode((node) => {
			results.push(node.data);
		});

		return results;
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public static register() {
		customElements.define(DeliveryTab.tagName, DeliveryTab);
	}
}

export default DeliveryTab;
