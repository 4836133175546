// CORE
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, ColDef, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../../tpl/modals/customers/editStockOutput.html';

// LIBS
import Modal from '@libs/Modal';
import NumericCellEditor from '@libs/agGrid/NumericCellEditor';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Products from '@services/Product/ProductService';
import S_StockEvent from '@services/StockEventService';

class EditStockOutput extends Modal {
	private _gridOptions: GridOptions = {};

	private locations: { [key: string]: any }[] = [];

	private idOrder: string = '';

	constructor(idOrder: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.idOrder = idOrder;

		this.on('opened', async () => {
			const { locations, materials } = await this.getData();

			this.locations = locations;

			this.initGrid();

			this._gridOptions.api?.setRowData(materials);
			this._gridOptions.api?.sizeColumnsToFit();

			this.initStock();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				N_save.loading(new Promise<void>(async (resolve) => {
					this._gridOptions.api?.stopEditing();

					const stockData: { [key: string]: any }[] = [];
					const dataToRefresh: { [key: string]: any }[] = [];
					this._gridOptions.api?.forEachNode((node) => {
						stockData.push({
							deliveryToCustomer: node.data.deliveryToCustomer,
							quantity: node.data.quantity,
							reference: node.data.reference,
							stocks: node.data.stocks
						});
						dataToRefresh.push({
							deliveryToCustomer: node.data.deliveryToCustomer,
							product: node.data.product,
							quantity: node.data.quantity,
							reference: node.data.reference,
							stocks: node.data.stocks,
							unit: node.data.unit
						});
					});

					await S_C_Order.getInstance().saveStockEvent(this.idOrder, stockData);

					resolve();

					this.resolve(dataToRefresh);
				}));
			});
		});
	}

	private initGrid() {
		const childrenStock: ColDef[] = [];
		const childrenLocation: ColDef[] = [];

		for (const item of this.locations) {
			((item: any) => {
				childrenStock.push({
					headerName: item.name,
					width: 100,
					field: 'currentStock.' + item._id + '.quantity',
					cellClass: 'text-center',
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.currentStock = params.data.currentStock || {};
						params.data.currentStock[item._id] = params.data.currentStock[item._id] || {};
						return params.data.currentStock[item._id].quantity;
					},
					cellRenderer: (params) => {
						return _.isUndefined(params.data.currentStock[item._id].quantity) ? '<i class="icon icon-solid-spinner icon-spin"></i>' : params.data.currentStock[item._id].quantity;
					}
				});

				childrenLocation.push({
					headerName: item.name,
					field: 'stocks.' + item._id + '.quantity',
					width: 120,
					cellClass: ['text-right', 'text-monospace', 'cursor-pointer'],
					cellEditor: NumericCellEditor,
					editable: true,
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.stocks = params.data.stocks || {};
						params.data.stocks[item._id] = params.data.stocks[item._id] || { quantity: 0 };
						return params.data.stocks[item._id].quantity || 0;
					},
					cellRenderer: (params) => {
						return (params.value || 0) + '<i class="icon icon-edit ml-2 text-light-blue-600"></i>';
					}
				});
			})(item);
		}

		childrenLocation.push({
			headerName: 'Direct client',
			field: 'deliveryToCustomer.quantity',
			width: 120,
			cellClass: ['text-right', 'text-monospace', 'cursor-pointer'],
			cellEditor: NumericCellEditor,
			editable: true,
			suppressSizeToFit: true,
			cellRenderer: (params) => {
				return (params.value || 0) + '<i class="icon icon-edit ml-2 text-light-blue-600"></i>';
			}
		});

		this._gridOptions = agUtils.french<GridOptions>({
			rowData: this._gridOptions.rowData || [],
			localeText: { noRowsToShow: 'Aucun produit' },
			columnDefs: [
				{
					headerComponentParams: {
						template: `
							<div class="ag-cell-label-container" role="presentation">
								<div ref="eLabel" class="ag-header-cell-label" role="presentation">
									<i class="icon h5 icon-solid-ellipsis-h"></i>
								</div>
							</div>
						`
					},
					headerClass: 'ag-theme-custom-text-center',
					field: '_icons_',
					filter: false,
					width: 60,
					pinned: 'left',
					sortable: false,
					suppressSizeToFit: true,
					suppressMovable: true,
					suppressColumnsToolPanel: true,
					cellRenderer: (params) => {
						const N_container = document.createElement('div');
						N_container.classList.add('d-flex', 'align-items-center');

						if (params.data.product.base64_photo || params.data.product.url_photo) {
							const N_image = S_Products.getInstance().cellRendererByData(params.data.product);
							N_image.innerHTML = '<i class="icon h5 icon-image"></i>';

							N_container.appendChild(N_image);
						}

						return N_container;
					}
				},
				{
					headerName: S_Products.getInstance().columnNameReference,
					field: 'product',
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params: any) => {
						return params.data.product[S_Products.getInstance().referenceKey];
					}
				},
				{
					headerName: 'Libellé',
					field: 'product.name',
					floatingFilter: true,
					filter: 'agTextColumnFilter'
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					floatingFilter: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					}
				},
				{
					headerName: 'Quantité Cmd',
					field: 'quantity',
					width: 100,
					cellClass: 'text-center',
					suppressSizeToFit: true
				},
				{
					headerName: 'Unité',
					field: 'unit',
					width: 80,
					suppressSizeToFit: true,
					cellClass: 'text-center'
				},
				...(childrenStock.length === 1 ? childrenStock : [{ headerName: 'Stock', children: childrenStock }]),
				{
					headerName: 'Sortie stock',
					children: childrenLocation
				}
			],
			getRowStyle: (params: any) => {
				const quantity = params.data.quantity;
				let outputQuantity = (params.data.deliveryToCustomer?.quantity || 0);

				for (const idStock in params.data.stocks) {
					outputQuantity += (params.data.stocks[idStock]?.quantity || 0);
				}

				//stock sortie
				if (outputQuantity >= quantity) {
					return {
						'background-color': 'var(--ap-green-50)',
						color: 'var(--ap-green-900)'
					};
				}

				//aucun stock de sortie
				if (outputQuantity === 0) {
					return {
						'background-color': 'var(--ap-red-50)',
						color: 'var(--ap-red-900)'
					};
				}

				//en cours de sortie
				if (outputQuantity < quantity) {
					return {
						'background-color': 'var(--ap-orange-50)',
						color: 'var(--ap-orange-900)'
					};
				}
			},
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			}
		});

		new Grid(this.element.querySelector('#grid') as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private async initStock() {
		const { rowData } = await S_StockEvent.getInstance().getCurrentStock();

		const stock: { [key: string]: any } = {};
		for (const item of rowData) {
			stock[item.product._id] = stock[item.product._id] || {};
			stock[item.product._id][item.stock] = stock[item.product._id][item.stock] || { quantity: 0 };
			stock[item.product._id][item.stock].quantity = item.quantity.formattedValue;
		}

		this._gridOptions.api?.forEachNode((node) => {
			for (const item of this.locations) {
				node.data.currentStock = node.data.currentStock || {};
				node.data.currentStock[item._id] = stock[node.data.reference]?.[item._id] || { quantity: 0 };
			}

			node.setData(node.data);
		});
	}

	private async getData() {
		const data = await S_C_Order.getInstance().getStockById(this.idOrder);

		return data;
	}
}

export default EditStockOutput;
