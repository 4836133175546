// CORE
import { Tab } from '@autoprog/core-client';

// SERVICES
import S_Companies from '@services/CompaniesService';

// STYLE
import '@modules/Apps/css/settings.companies.scss';

// MODALES
import M_AddEditCompanies from '@modules/Apps/js/modals/AddEditCompanies';

// MODULES
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

// LIBS
import Decimal from '@libs/utils/Decimal';
import agUtils from '@libs/agGrid/french';

// CUSTOM ELEMENTS
import CE_CompanySelect from '@libs/customElement/CompanySelect';

// TYPES
import Company from '@type/company/company';

class SettingsTabs extends Tab {
	private el: HTMLElement;

	private abortController: AbortController;

	private companiesData: Company[] = [];

	private selectedCompany: Company | null = null;

	private gridOptionsAddress: GridOptions = {};

	protected get abortSignal(): AbortSignal {
		return this.abortController.signal;
	}

	constructor(el: HTMLElement) {
		super(el);

		this.abortController = new AbortController();

		this.el = el;

		this.init();
	}

	private async init() {
		await this.loadCompaniesData();
		this.initCompaniesList();
		this.initCompanyContent();

		this.initAddButton();
		this.initEditButton();
		this.initDeleteButton();
	}

	/**
	 * Charge les données des sociétés
	 */
	private async loadCompaniesData() {
		this.companiesData = await S_Companies.getInstance().getAll() as Company[];
	}

	/**
	 * Initialise la partie concernant la liste des sociétés
	 */
	private initCompaniesList() {
		const N_CompaniesList = this.el.querySelector('#companies-list') as HTMLDivElement;
		N_CompaniesList.innerHTML = '';

		if (this.companiesData.length === 0) {
			N_CompaniesList.innerHTML = `
				<div id="no-company-message">Aucune société</div>
			`;
		}

		for (const company of this.companiesData) {
			const N_CompaniesListItem = document.createElement('div');
			N_CompaniesListItem.classList.add('companies-list-item');
			N_CompaniesListItem.innerHTML = company.name || '(nom inconnu)';

			if (this.selectedCompany && this.selectedCompany._id === company._id) {
				N_CompaniesListItem.classList.add('selected');
			}

			N_CompaniesListItem.addEventListener('click', () => {
				const N_SelectedCompaniesListItem = this.el.querySelector('.companies-list-item.selected') as HTMLDivElement | null;
				if (N_SelectedCompaniesListItem) {
					N_SelectedCompaniesListItem.classList.remove('selected');
				}

				N_CompaniesListItem.classList.add('selected');

				this.selectedCompany = company;
				this.initCompanyContent();
			});

			N_CompaniesList.append(N_CompaniesListItem);
		}
	}

	/**
	 * Initialise la partie concernant le contenu de la société
	 */
	private initCompanyContent() {
		const hasSelection = this.selectedCompany !== null;

		const N_CompanyContentNoSelection = this.el.querySelector('#company-content-no-selection') as HTMLDivElement;
		const N_CompanyContentSelection = this.el.querySelector('#company-content-selection') as HTMLDivElement;

		if (hasSelection) {
			this.initCompanyContentSelection();
		}

		N_CompanyContentNoSelection.classList.toggle('d-none', hasSelection);
		N_CompanyContentSelection.classList.toggle('d-none', !hasSelection);
	}

	/**
	 * Initialise le contenu de la société selectionnée
	 * @param N_CompanyContentSelection l'element HTML du conteneur pour le contenu de la société selectionnée
	 */
	private initCompanyContentSelection() {
		const selectedCompany = this.selectedCompany as Company;

		const N_CompanyName = this.el.querySelector('#company-name') as HTMLDivElement;
		N_CompanyName.innerHTML = selectedCompany.name || '(non défini)';

		const N_CompanyCapital = this.el.querySelector('#company-capital') as HTMLDivElement;
		N_CompanyCapital.innerHTML = selectedCompany.capital !== undefined && selectedCompany.capital !== null
			? Decimal.setDisplayNumber(selectedCompany.capital).setSuffixAndHumanizeNumber('€', 0)
			: '(non défini)';

		const N_CompanyRCS = this.el.querySelector('#company-rcs') as HTMLDivElement;
		N_CompanyRCS.innerHTML = selectedCompany.RCS || '(non défini)';

		const N_CompanySIREN = this.el.querySelector('#company-siren') as HTMLDivElement;
		N_CompanySIREN.innerHTML = selectedCompany.SIREN || '(non défini)';

		const N_CompanySIRET = this.el.querySelector('#company-siret') as HTMLDivElement;
		N_CompanySIRET.innerHTML = selectedCompany.SIRET || '(non défini)';

		const N_CompanyTVACode = this.el.querySelector('#company-tva-code') as HTMLDivElement;
		N_CompanyTVACode.innerHTML = selectedCompany.TVACode || '(non défini)';

		const N_CompanyAPE = this.el.querySelector('#company-ape') as HTMLDivElement;
		N_CompanyAPE.innerHTML = selectedCompany.APE || '(non défini)';

		const N_CompanyHoursMorningStart = this.el.querySelector('#company-hours-morning-start') as HTMLDivElement;
		N_CompanyHoursMorningStart.innerHTML = selectedCompany.hours.morning.start || '(non défini)';

		const N_CompanyHoursMorningEnd = this.el.querySelector('#company-hours-morning-end') as HTMLDivElement;
		N_CompanyHoursMorningEnd.innerHTML = selectedCompany.hours.morning.end || '(non défini)';

		const N_CompanyHoursAfternoonStart = this.el.querySelector('#company-hours-afternoon-start') as HTMLDivElement;
		N_CompanyHoursAfternoonStart.innerHTML = selectedCompany.hours.afternoon.start || '(non défini)';

		const N_CompanyHoursAfternoonEnd = this.el.querySelector('#company-hours-afternoon-end') as HTMLDivElement;
		N_CompanyHoursAfternoonEnd.innerHTML = selectedCompany.hours.afternoon.end || '(non défini)';

		const N_CompanyHoursMealMorning = this.el.querySelector('#company-hours-meal-morning') as HTMLDivElement;
		N_CompanyHoursMealMorning.innerHTML = selectedCompany.hours.meal.morning || '(non défini)';

		const N_CompanyHoursMealMidday = this.el.querySelector('#company-hours-meal-midday') as HTMLDivElement;
		N_CompanyHoursMealMidday.innerHTML = selectedCompany.hours.meal.midday || '(non défini)';

		const N_CompanyHoursMealEvening = this.el.querySelector('#company-hours-meal-evening') as HTMLDivElement;
		N_CompanyHoursMealEvening.innerHTML = selectedCompany.hours.meal.evening || '(non défini)';

		const N_CompanyLogo = this.el.querySelector('#company-logo') as HTMLDivElement;

		if (!selectedCompany.logo) {
			N_CompanyLogo.innerHTML = '(non défini)';
		} else {
			N_CompanyLogo.innerHTML = `
            <img src="${selectedCompany.logo}">
            `;
		}

		this.initSelectedCompanyAddressList();
	}

	/**
	 * Initialise la liste des adresses pour la société selectionnée
	 */
	private initSelectedCompanyAddressList() {
		const N_CompanyAddressList = this.el.querySelector('#company-address-list') as HTMLDivElement;
		N_CompanyAddressList.innerHTML = '';

		const addressList = this.selectedCompany?.addressList || [];

		if (addressList.length > 0) {
			this.initGridAddressList();
			this.gridOptionsAddress.api?.setRowData(addressList);
		} else {
			N_CompanyAddressList.innerHTML = `
				<div id="no-address-message" class="company-block-value">(aucune adresse)</div>
			`;
		}
	}

	private initGridAddressList() {
		this.gridOptionsAddress = agUtils.french<GridOptions>({
			rowData: [],
			suppressDragLeaveHidesColumns: true,
			suppressContextMenu: true,
			columnDefs: [{
				headerName: 'Nom',
				field: 'name'
			}, {
				headerName: 'Adresse',
				field: 'address'
			}, {
				headerName: 'Code Postal',
				field: 'zipCode'
			}, {
				headerName: 'Ville',
				field: 'city'
			}, {
				headerName: 'Pays',
				field: 'country'
			}, {
				headerName: 'Téléphone',
				field: 'phone'
			}, {
				headerName: 'Fax',
				field: 'fax'
			}, {
				headerName: 'Email',
				field: 'email'
			}, {
				headerName: 'Par Défaut',
				field: 'default',
				cellRenderer: (params) => {
					const N_BooleanIcon = document.createElement('div');

					if (params.value) {
						N_BooleanIcon.innerHTML = '<ap-icon name="check" style="color: green"></ap-icon>';
					}

					return N_BooleanIcon;
				}
			}],
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			}
		});

		const N_grid = this.el.querySelector('#company-address-list') as HTMLElement;

		new Grid(N_grid, this.gridOptionsAddress, { modules: AllModules });
	}

	/**
	 * Initialise le bouton d'ajout d'une société
	 */
	private initAddButton() {
		const N_CompanyAdd = this.el.querySelector('#company-add') as HTMLButtonElement;
		N_CompanyAdd.addEventListener('click', () => {
			new M_AddEditCompanies().open().then(async (data) => {
				await S_Companies.getInstance().save(data).then(async (result) => {
					if (result.ok) {
						this.selectedCompany = result.data.data;

						await this.loadCompaniesData();
						this.initCompaniesList();
						this.initCompanyContent();
					}
				});
			});
		});
	}

	/**
	 * Initialise le bouton d'édition de la société sélectionnée
	 */
	private initEditButton() {
		const N_CompanyEdit = this.el.querySelector('#company-edit') as HTMLButtonElement;
		N_CompanyEdit.addEventListener('click', () => {
			if (this.selectedCompany) {
				new M_AddEditCompanies(this.selectedCompany).open().then((data) => {
					S_Companies.getInstance().save(data).then(async (result) => {
						if (result.ok) {
							this.selectedCompany = result.data.data;

							await this.loadCompaniesData();

							this.initCompaniesList();
							this.initCompanyContent();

							this.reloadCompanySelect();
						}
					});
				});
			}
		});
	}

	/**
	 * Initialise le bouton de suppression de la société sélectionnée
	 */
	private initDeleteButton() {
		const N_CompanyDelete = this.el.querySelector('#company-delete') as HTMLButtonElement;
		N_CompanyDelete.addEventListener('click', () => {
			S_Companies.getInstance().delete(this.selectedCompany?._id as string).then(async () => {
				this.selectedCompany = null;

				await this.loadCompaniesData();

				this.initCompaniesList();
				this.initCompanyContent();
			});
		});
	}

	/**
	 * Recharge le Custom Element CompanySelect de la barre de navigation en haut pour voir les modifications du nom en temps réel
	 */
	private reloadCompanySelect() {
		const N_CompanySelect = document.querySelector('#navbar ap-company-select') as CE_CompanySelect;
		N_CompanySelect.reload();
	}

	public destructor() {
		this.abortController.abort('destroyed');
	}
}

export default SettingsTabs;
