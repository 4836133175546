import Modal from '@libs/Modal';

import T_Modal from '@tpl/modals/PrintPreview.html';

import Utils from '@libs/utils/Utils';

import Loader from '@libs/Loader';

import axios from 'axios';

class PrintPreview extends Modal {
	private token: string;
	private table: string;

	constructor(table: string, id: string, url: string = 'pdf') {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.table = table;
		this.token = '';

		this.on('opened', async () => {
			Loader.getInstance().open();

			const N_fakePrint = this.element.querySelector('#fakePrint') as HTMLIFrameElement;

			N_fakePrint.addEventListener('load', () => {
				Loader.getInstance().close();
			});

			if (this.token.length) {
				N_fakePrint.src = `/api/${table}/${url}?id=${id}&user=${Utils.userID}&token=${this.token}&companyID=${Utils.companyID}`;
			} else {
				N_fakePrint.src = `/api/${table}/${url}?id=${id}&user=${Utils.userID}&companyID=${Utils.companyID}`;
			}
		});
	}

	public async getToken(data: any) {
		const token = (await axios.post(`/api/${this.table}/previewToken`, { defaultvalue: data })).data;
		this.token = token;
		return this;
	}
}

export default PrintPreview;
