import agUtils from '@libs/agGrid/french';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import T_editCompta from '../../../tpl/modals/customers/editCompta.html';

import Decimal from '@libs/utils/Decimal';
import Modal from '@libs/Modal';

class EditCompta extends Modal {
	private _gridOptions: GridOptions = {};
	private priceBill: Decimal;

	constructor(data: { [key: string]: any }[], priceBill: string) {
		super({
			tpl: T_editCompta,
			keyboard: false,
			backdrop: 'static'
		});

		this.priceBill = Decimal.setDisplayNumber(priceBill);

		this.on('opened', () => {
			const res: { [key: string]: any }[] = [];

			for (const item of data) {
				if (item.isCustom) {
					res.push(item);
				}
			}

			this.initGrid(res);

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				const res: { [key: string]: any } = {};

				this._gridOptions.api?.forEachNode((node) => {
					res[node.data.compta] = node.data.debit;
				});

				this.resolve(res);
			});
		});
	}

	private initGrid(data: { [key: string]: any }[]) {
		this._gridOptions = agUtils.french({
			rowData: data,
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Compte',
					field: 'compta'
				}, {
					headerName: 'Montant HT',
					field: 'debit',
					editable: true,
					cellClass: ['text-right', 'text-monospace'],
					cellRenderer: (params) => {
						return Decimal.setDisplayNumber(params.value).humanizeNumber();
					}
				}
			],
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
				this.updateSaveButton();
			},
			onCellEditingStopped: () => {
				this.updateSaveButton();
			}
		} as GridOptions);

		const N_grid = this.element.querySelector('#grid') as HTMLElement;
		new Grid(N_grid, this._gridOptions, { modules: AllModules });
	}

	private updateSaveButton() {
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		let price = new Decimal(0);

		this._gridOptions.api?.forEachNode((node) => {
			price = price.plus(Decimal.setDisplayNumber(node.data.debit));
		});

		N_save.disabled = !price.equals(this.priceBill);
	}
}

export default EditCompta;
