import _ from 'lodash';
import moment from 'moment';

class ExportUtils {
	public static createFileLink(txt: string) {
		const blob = new Blob([txt], { type: 'text/csv' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		return link;
	}

	public static convertTo(value: any, definition: any) {
		if (definition.type === 'object') {
			return _.get(definition.object, value);
		} else if (definition.type === 'date') {
			const mDate = moment(value, definition.formatFrom || 'x');
			return mDate.isValid() ? mDate.format(definition.formatTo || 'DD/MM/YYYY') : '';
		} else if (definition.type === 'boolean') {
			return value ? 'oui' : 'non';
		} else if (definition.type === 'Decimal') {
			return new Intl.NumberFormat(definition.formatNumber ?? 'fr-FR', { useGrouping: false }).format(value.value);
		}

		return value;
	}
}

export default ExportUtils;
