// CORE
import CSV from '@autoprog/csv';
import { Controller } from '@autoprog/core-client';

// NODE_MODULE
import { AllModules, Grid, GridOptions, ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import moment, { Moment } from 'moment';

// TEMPLATE
// LIBS
import C_Bill from '@modules/Bills/js/controllers/Bills.Customer';
import agUtils from '@libs/agGrid/french';

import ExportUtils from '@libs/utils/ExportUtils';
import drpC from '@libs/utils/daterangepickerConfig';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';

class RecapCustomerController extends Controller {
	private element: HTMLElement;

	private gridOptionsCustomers: GridOptions = {};
	private gridOptionsBills: GridOptions = {};

	private dataByCustomer: { [customer: string]: { [order: string]: any[] } } = {};
	private dataCustomer: { [key: string]: any }[] = [];

	private currentCustomerRow: { [key: string]: any } = {};

	private startDate: Moment;
	private endDate: Moment;

	constructor(el: HTMLElement) {
		super(el);

		this.element = el;

		this.startDate = moment().subtract(1, 'year');
		this.endDate = moment();

		this.init();

		this.getData();
	}

	private init() {
		this.gridOptionsCustomers = agUtils.french({
			localeText: { noRowsToShow: 'Aucun Client' },
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			rowSelection: 'single',
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				sortable: true,
				floatingFilter: true
			},
			columnDefs: [
				{
					headerName: 'Client',
					field: 'customer',
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					}
				},
				{
					headerName: 'Nb de FC',
					field: 'billsCount',
					cellClass: ['text-right', 'text-monospace'],
					width: 100,
					suppressSizeToFit: true
				},
				{
					headerName: 'Nb de CC',
					field: 'ordersCount',
					cellClass: ['text-right', 'text-monospace'],
					width: 100,
					suppressSizeToFit: true
				}
			],
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			onSelectionChanged: (params) => {
				const selectedRows = params.api.getSelectedRows();

				this.currentCustomerRow = selectedRows[0];

				const N_exportByCustomer = this.element.querySelector('#exportByCustomer') as HTMLButtonElement;
				N_exportByCustomer.removeAttribute('disabled');

				const N_customerSelected = this.element.querySelector('#customer-selected') as HTMLElement;
				N_customerSelected.innerHTML = `Client selectionné : ${this.currentCustomerRow.customer}`;

				const data: { [key: string]: any }[] = [];
				let total: { [key: string]: any } = {};

				for (const idOrder in this.dataByCustomer[this.currentCustomerRow.idCustomer]) {
					for (const item of this.dataByCustomer[this.currentCustomerRow.idCustomer][idOrder]) {
						if (idOrder === 'TOTAL') {
							total = item;
						} else {
							data.push(item);
						}
					}
				}

				this.gridOptionsBills.api?.setRowData(data || []);
				this.gridOptionsBills.api?.setPinnedBottomRowData([total]);
				this.gridOptionsBills.api?.sizeColumnsToFit();
			}
		} as GridOptions);

		this.gridOptionsBills = agUtils.french({
			localeText: { noRowsToShow: 'Aucune Facture' },
			rowData: [],
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			groupDefaultExpanded: 1,
			groupRemoveSingleChildren: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				sortable: true
			},
			autoGroupColumnDef: {
				headerName: 'N° Commande',
				field: 'bill.commandNumber',
				minWidth: 200,
				cellRendererParams: {
					suppressCount: true,
					innerRenderer: (params: any) => {
						const isGroupHeader = params.node.group;
						const isSingle = params.node.firstChild && params.node.lastChild;
						if (isGroupHeader || isSingle) {
							return params.value;
						}
						return '';
					}
				},
				cellStyle: () => {
					return { 'font-weight': 'bold' };
				}
			},
			columnDefs: [
				{
					headerName: 'N° Facture',
					field: 'bill.billNumber',
					filter: 'agTextColumnFilter',
					width: 160,
					suppressSizeToFit: true,
					cellStyle: (params) => {
						if (params.node.data?.bill.billNumber === 'Total') {
							return { 'font-weight': 'bold' };
						}
						return null;
					}

				},
				{
					headerName: 'N° Commande',
					field: 'bill.commandNumber',
					filter: 'agTextColumnFilter',
					rowGroup: true,
					hide: true,
					width: 200,
					suppressSizeToFit: true
				},
				{
					headerName: 'Montant total (HT)',
					field: 'bill.totalHT.formattedValue',
					filter: 'agTextColumnFilter',
					cellClass: ['text-right', 'text-monospace'],
					width: 165,
					suppressSizeToFit: true,
					cellStyle: (params) => {
						if (params.node.data?.bill.billNumber === 'Total') {
							return { 'font-weight': 'bold' };
						}
						return null;
					}
				},
				{
					headerName: 'Montant total (TTC)',
					field: 'bill.totalTTC.formattedValue',
					filter: 'agTextColumnFilter',
					cellClass: ['text-right', 'text-monospace'],
					width: 165,
					suppressSizeToFit: true
				},
				{
					headerName: 'Montant payé (TTC)',
					field: 'bill.payed.formattedValue',
					filter: 'agTextColumnFilter',
					cellClass: ['text-right', 'text-monospace'],
					width: 165,
					suppressSizeToFit: true
				},
				{
					headerName: 'Montant à payer (TTC)',
					field: 'bill.remaining.formattedValue',
					filter: 'agTextColumnFilter',
					cellClass: ['text-right', 'text-monospace'],
					width: 165,
					suppressSizeToFit: true
				},
				{
					headerName: 'Date',
					field: 'bill.date',
					filter: 'agTextColumnFilter',
					width: 115,
					suppressSizeToFit: true,
					cellRenderer: (params: ICellRendererParams) => {
						return params.node.rowPinned || params.node.group ? '' : moment(params.value, 'x').format('DD/MM/YYYY');
					},
					cellStyle: (params) => {
						if (params.node.data?.bill.billNumber === 'Total') {
							return { 'font-weight': 'bold' };
						}
						return null;
					}
				},
				{
					headerName: 'Type de facture',
					field: 'bill.type',
					filter: 'agTextColumnFilter',
					width: 180,
					suppressSizeToFit: true
				},
				{
					headerName: 'Action',
					headerClass: 'ag-theme-custom-text-center',
					width: 100,
					pinned: 'right',
					suppressSizeToFit: true,
					suppressMovable: true,
					sortable: false,
					suppressFiltersToolPanel: true,
					floatingFilter: false,
					suppressColumnsToolPanel: true,
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						if (params.data && params.data?.bill.billNumber !== 'Total' && !params.node.rowPinned) {
							const N_bill = document.createElement('button');

							N_bill.classList.add('h-100', 'py-0', 'btn-transparent');

							N_bill.setAttribute('tooltip', 'Ouvrir la facture n°' + params.data.bill.billNumber);
							N_bill.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';

							N_bill.addEventListener('click', () => {
								C_Bill.open(params.data.bill.id);
							});

							N_div.appendChild(N_bill);
						}

						return N_div;
					}
				}

			],
			onGridReady: () => {
				this.gridOptionsBills.api?.sizeColumnsToFit();
			}
		} as GridOptions);

		new Grid(this.element.querySelector('#customers .grid') as HTMLDivElement, this.gridOptionsCustomers, { modules: AllModules });
		new Grid(this.element.querySelector('#bills .grid') as HTMLDivElement, this.gridOptionsBills, { modules: AllModules });

		const N_export = this.element.querySelector('#export') as HTMLButtonElement;
		const N_exportByCustomer = this.element.querySelector('#exportByCustomer') as HTMLButtonElement;
		const N_datePicker = this.element.querySelector('#date-picker') as HTMLInputElement;

		N_export.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			for (const customer in this.dataByCustomer) {
				data.push(...this.getDataExportByCustomer(customer));
			}

			const title = `Export-Commande-${this.currentCustomerRow.customer}-${moment().format('DD-MM-YYYY')}`;

			this.exportData(data, title);
		});

		N_exportByCustomer.addEventListener('click', () => {
			const data = this.getDataExportByCustomer(this.currentCustomerRow.idCustomer);
			const title = `Export-Commande-${this.currentCustomerRow.customer}-${moment().format('DD-MM-YYYY')}`;

			this.exportData(data, title);
		});

		const N_input = $(N_datePicker);

		N_input.daterangepicker(drpC({
			startDate: this.startDate,
			endDate: this.endDate
		}));

		N_input.on('apply.daterangepicker', (ev, picker) => {
			this.startDate = picker.startDate;
			this.endDate = picker.endDate;

			this.getData();
		});
	}

	private getDataExportByCustomer(customer: string) {
		const tmp: { [key: string]: any }[] = [];

		const dataCustomer = this.dataCustomer.find(l => l.idCustomer === customer) || {};

		for (const order in this.dataByCustomer[customer]) {
			for (const data of this.dataByCustomer[customer][order]) {
				if (data.bill.billNumber !== 'Total du client') {
					tmp.push({
						customer: dataCustomer.customer,
						command: data.bill.commandNumber ? data.bill.commandNumber : '-',
						bill: data.bill.billNumber,
						totalHT: data.bill.totalHT ? ExportUtils.convertTo(data.bill.totalHT, { type: 'Decimal' }) : '-',
						totalTTC: data.bill.totalTTC ? ExportUtils.convertTo(data.bill.totalTTC, { type: 'Decimal' }) : '-',
						payed: data.bill.payed ? ExportUtils.convertTo(data.bill.payed, { type: 'Decimal' }) : '-',
						remaining: data.bill.remaining ? ExportUtils.convertTo(data.bill.remaining, { type: 'Decimal' }) : '-',
						date: data.bill.date ? moment(data.bill.date, 'x').format('DD/MM/YYYY') : '-',
						type: data.bill.type ? data.bill.type : '-'
					});
				}
			}
		}

		return [...tmp];
	}

	private exportData(data: { [key: string]: any }[] = [], title: string) {
		const csv = CSV.stringify(data, {
			customer: 'Client',
			command: 'N° Commande',
			bill: 'N° Facture',
			totalHT: 'Montant Total (HT)',
			totalTTC: 'Montant Total (TTC)',
			payed: 'Montant payé (TTC)',
			remaining: 'Montant à payer (TTC)',
			date: 'Date',
			type: 'Type de facture'
		}, '\n', ';');

		const a = ExportUtils.createFileLink(csv);
		// On supprime les points (<, >, :, “, /, \, |, ?, .) dans le nom du fichier
		a.download = title.replace(/[.<>:/“\\|?]/gmi, '');
		a.click();
		a.remove();
	}

	private async getData() {
		const { data, customers } = await S_C_Order.getInstance().getDataToRecap(this.startDate.valueOf(), this.endDate.valueOf());

		this.dataByCustomer = data;
		this.dataCustomer = customers;

		this.gridOptionsCustomers.api?.setRowData(customers);
		this.gridOptionsCustomers.api?.sizeColumnsToFit();
	}

	public destructor() {
	}
}

export default RecapCustomerController;
