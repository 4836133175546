module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="pagePrint" style="width:calc(100% - 1px)">\n\n    <table>\n\n        <tbody>\n\n            <tr>\n                <td colspan="' +
((__t = (stock.location.length + 5)) == null ? '' : __t) +
'">\n\n                    <div class="w-100 d-flex">\n                        Devis N° ' +
((__t = (infos.number)) == null ? '' : __t) +
' / ' +
((__t = ( infos.customer.number)) == null ? '' : __t) +
' ' +
((__t = (infos.customer.name)) == null ? '' : __t) +
'\n\n                        <span class="ml-auto text-right ' +
((__t = (infos.sites.length ? '' : 'd-none')) == null ? '' : __t) +
'">\n                            Sites : ' +
((__t = (infos.sites.join(', '))) == null ? '' : __t) +
'\n                        </span>\n                    </div>\n                </td>\n            </tr>\n\n            <tr>\n                <td class="bg-grey-500 border border-dark text-center" rowspan="2">' +
((__t = (column.reference)) == null ? '' : __t) +
'</td>\n                <td class="bg-grey-500 border border-dark text-center" rowspan="2">Libellé</td>\n                <td class="bg-grey-500 border border-dark text-center ' +
((__t = (displayStock ? '' : 'd-none')) == null ? '' : __t) +
'" colspan="' +
((__t = (stock.location.length)) == null ? '' : __t) +
'">Stock</td>\n                <td class="bg-grey-500 border border-dark text-center" rowspan="2">Quantité</td>\n                <td class="bg-grey-500 border border-dark text-center" rowspan="2">Unité</td>\n            </tr>\n\n            <tr>\n                ';
for(let item of stock.location){;
__p += '\n                    <td class="bg-grey-500 border border-dark text-center ' +
((__t = (displayStock ? '' : 'd-none')) == null ? '' : __t) +
'" >' +
((__t = (item.name)) == null ? '' : __t) +
'</td>\n                ';
};
__p += '\n            </tr>\n\n            ';
for(let item of data){;
__p += '\n\n                ';
if(item.type == 'brand'){;
__p += '\n                    <tr>\n                        <td class="font-weight-bold border border-dark" colspan="' +
((__t = (4 + stock.location.length - (displayStock ? 0 : 1))) == null ? '' : __t) +
'">\n                            ' +
((__t = (item.label)) == null ? '' : __t) +
'\n                        </td>\n                    </tr>\n                ';
}else{;
__p += '\n                    <tr >\n                        <td class="border border-dark text-center">' +
((__t = (item.product.reference)) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark text-center">' +
((__t = (item.product.name)) == null ? '' : __t) +
'</td>\n                        ';
for(let location of stock.location){;
__p += '\n    \n                            ';
 let color = '';;
__p += '\n                            ';
 if( item.currentStock[location._id]?.quantity === undefined ){ ;
__p += '                        \n                            ';
    color = '#fff'; ;
__p += '\n                            ';
 } else if (item.currentStock[location._id].quantity < obj.quantity) { ;
__p += '\n                            ';
    color = 'var(--ap-orange-50)'; ;
__p += '\n                            ';
 } else if (item.currentStock[location._id].quantity >= obj.quantity) { ;
__p += '\n                            ';
    color = 'var(--ap-green-50)'; ;
__p += '\n                            ';
 } ;
__p += '\n\n                            <td class="border border-dark text-center ' +
((__t = (displayStock ? '' : 'd-none')) == null ? '' : __t) +
'" style="background-color: ' +
((__t = (color)) == null ? '' : __t) +
';">' +
((__t = (item.currentStock[location._id] ? item.currentStock[location._id].quantity : '0')) == null ? '' : __t) +
'</td>\n                        ';
};
__p += '\n                        <td class="border border-dark text-center">' +
((__t = (item.quantity)) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark text-center">' +
((__t = (item.unit)) == null ? '' : __t) +
'</td>\n                    </tr>\n                ';
};
__p += '\n                \n            ';
};
__p += '\n\n        </tbody>\n\n    </table>\n\n</div>';

}
return __p
}