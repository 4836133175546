import { Modal, Router } from '@autoprog/core-client';

import Utils from '@libs/utils/Utils';

type ModalOptions = {
	modalID?: string,
	data?: { [key: string]: any },
	tpl?: string | ((data: object) => string),
	template?: string | ((data: object) => string),
	keyboard?: boolean
	backdrop?: boolean | 'static'
};

class ModalApps extends Modal {
	constructor(options: ModalOptions) {
		super(options);

		Router.getInstance().on('route.change', (route: any, params: any, query: any, event: any) => {
			return new Promise<void>((resolve, reject) => {
				//@ts-ignore
				if (Modal.modals[Modal.modals.length - 1].modalID === this.modalID) {
					event.stopPropagation();
					reject(new Error('close modal'));
					this.reject();
				}
			});
		}, {
			signal: this.abortSignal
		});

		this.on('opened', () => {
			Utils.removeTooltip();
		});
	}
}

export default ModalApps;
