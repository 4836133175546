// CORE
import { Form } from '@autoprog/core-client';

// TEMPLATE
import T_modal from '../../tpl/modals/companies/addEditCompanies.html';

// LIBS
import Modal from '@libs/Modal';
import agUtils from '@libs/agGrid/french';

// STYLE
import '@modules/Apps/css/modals/addEditCompanies.scss';

// MODULES
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

// TYPES
import Company from '@type/company/company';
import CompanyAddress from '@type/company/companyAddress';

class AddEditCompanies extends Modal {
	private form: Form | null = null;

	private company: Company | undefined = undefined;

	private isEditMode: boolean = false;

	private gridOptionsAddress: GridOptions = {};

	constructor(company?: Company) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			this.company = company;
			this.isEditMode = this.company !== undefined;

			this.init();
		});
	}

	private init() {
		if (this.isEditMode) {
			this.setTitleForEdit();
		}

		this.initForm();
		this.initLogoForm();

		this.updateLogoPreview();

		this.initAddressListGrid();

		this.initLogoResetButton();
		this.initLogoDeleteButton();

		this.initSaveButton();
	}

	/**
	 * Définit le titre dans le contexte d'une modale en mode édition
	 */
	private setTitleForEdit() {
		const N_Title = this.element.querySelector('#title') as HTMLDivElement;
		N_Title.innerHTML = 'Édition de la société';
	}

	/**
	 * Initialise le formulaire
	 */
	private initForm() {
		const N_form = this.element.querySelector('form') as HTMLFormElement;
		this.form = new Form(N_form);

		if (this.isEditMode) {
			this.form.setData(this.company as { [key: string]: any });
		}
	}

	/**
	 * Initialise la partie du formulaire conçernant l'icone
	 */
	private initLogoForm() {
		const N_CompanyLogoFile = this.element.querySelector('#company-logo-file') as HTMLInputElement;

		N_CompanyLogoFile.addEventListener('change', () => {
			const files = N_CompanyLogoFile.files;

			if (files && files.length > 0) {
				const file = files[0];

				const reader = new FileReader();

				reader.onloadend = () => {
					const base64 = reader.result as string;
					this.form?.setDataByName('logo', base64);
					this.updateLogoPreview();
				};

				reader.readAsDataURL(file);
			}
		});
	}

	/**
	 * Met à jour la prévisualisation du logo
	 */
	private updateLogoPreview() {
		const N_CompanyLogoPreview = this.element.querySelector('#company-logo-preview') as HTMLDivElement;

		const logo = this.form?.getDataByName<string>('logo');

		if (logo) {
			N_CompanyLogoPreview.innerHTML = `<img src="${logo}">`;
		} else {
			N_CompanyLogoPreview.innerHTML = 'Aucun logo';
		}
	}

	/**
	 * Initialise l'ag-grid de la liste des adresses
	 */
	private initAddressListGrid() {
		this.gridOptionsAddress = agUtils.french<GridOptions>({
			rowData: [],
			suppressDragLeaveHidesColumns: true,
			columnDefs: [{
				headerName: 'Nom *',
				field: 'name',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.name
				}
			}, {
				headerName: 'Adresse *',
				field: 'address',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.address
				}
			}, {
				headerName: 'Code Postal *',
				field: 'zipCode',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.zipCode
				}
			}, {
				headerName: 'Ville *',
				field: 'city',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.city
				}
			}, {
				headerName: 'Pays *',
				field: 'country',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.country
				}
			}, {
				headerName: 'Téléphone *',
				field: 'phone',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.phone
				}
			}, {
				headerName: 'Fax',
				field: 'fax'
			}, {
				headerName: 'Email *',
				field: 'email',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.email
				}
			}, {
				headerName: 'Défaut',
				field: 'default',
				editable: false,
				cellRenderer: (params) => {
					const N_checkbox = document.createElement('div');
					N_checkbox.classList.add('text-center');

					if (params.value) {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
					} else {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
					}

					N_checkbox.addEventListener('click', () => {
						const value = !params.value;

						this.gridOptionsAddress.api?.forEachNode((node) => {
							node.setDataValue('default', false);
						});

						params.node.setDataValue('default', value);

						if (value) {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
						} else {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
						}
					});

					return N_checkbox;
				}
			}, {
				headerName: 'Action',
				width: 80,
				pinned: 'left',
				suppressSizeToFit: true,
				editable: false,
				cellRenderer: (params) => {
					const N_delete = document.createElement('button');

					N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

					N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
					N_delete.setAttribute('confirmation', '');
					N_delete.type = 'button';
					N_delete.setAttribute('tooltip', 'Supprimer');

					N_delete.addEventListener('click', () => {
						params.api.updateRowData({
							remove: [params.node.data]
						});
					});

					return N_delete;
				}
			}],
			defaultColDef: {
				editable: true,
				resizable: true,
				suppressMenu: true
			},
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			getContextMenuItems: (params) => {
				return [{
					name: 'Ajouter une adresse',
					action: () => {
						params.api?.applyTransaction({ add: [{}] });
					}
				}];
			},
			localeText: {
				noRowsToShow: 'Aucune adresse'
			}
		});

		const N_grid = this.element.querySelector('#company-address-list') as HTMLElement;

		new Grid(N_grid, this.gridOptionsAddress, { modules: AllModules });

		const addressList = this.company?.addressList || [];

		if (addressList.length > 0) {
			this.gridOptionsAddress.api?.setRowData(addressList);
		}
	}

	/**
	 * Initialise le bouton de réinitialisation du logo par défaut
	 */
	private initLogoResetButton() {
		const N_CompanyLogoReset = this.element.querySelector('#company-logo-reset') as HTMLButtonElement;
		N_CompanyLogoReset.addEventListener('click', () => {
			this.form?.setDataByName('logo', this.company?.logo || '');
			this.updateLogoPreview();
		});
	}

	/**
	 * Initialise le bouton de suppression du logo
	 */
	private initLogoDeleteButton() {
		const N_CompanyLogoDelete = this.element.querySelector('#company-logo-delete') as HTMLButtonElement;
		N_CompanyLogoDelete.addEventListener('click', () => {
			this.form?.setDataByName('logo', '');
			this.updateLogoPreview();
		});
	}

	/**
	 * Initialise le bouton de sauvegarde de la modale
	 */
	private initSaveButton() {
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener('click', () => {
			const N_CompanyAddressList = this.element.querySelector('#company-address-list') as HTMLDivElement;
			const N_AddressListInvalidMessage = this.element.querySelector('#address-list-invalid-message') as HTMLDivElement;
			const N_DefaultAddressInvalidMessage = this.element.querySelector('#default-address-invalid-message') as HTMLDivElement;

			const addressList: CompanyAddress[] = [];
			this.gridOptionsAddress.api?.forEachNode(node => addressList.push(node.data));

			//On vérifie s'il y a des adresses invalides
			const hasInvalidAddress = this.element.querySelectorAll('.cell-invalid').length > 0;
			const isAddressListValid = addressList.length > 0 && !hasInvalidAddress;

			N_CompanyAddressList.classList.toggle('invalid', !isAddressListValid);
			//On affiche le message d'erreur sur le formulaire si besoin
			N_AddressListInvalidMessage.classList.toggle('d-none', isAddressListValid);

			//On vérifie si il y a une adresse par défaut
			const hasDefaultAddress = isAddressListValid ? addressList.some(address => address.default === true) : true;
			//On affiche le message d'erreur sur le formulaire si besoin
			N_DefaultAddressInvalidMessage.classList.toggle('d-none', hasDefaultAddress);

			if (this.form?.checkValidity() && isAddressListValid && hasDefaultAddress) {
				const data = this.form?.getData();

				if (data) {
					if (this.company?._id) {
						data._id = this.company._id;
					}

					data.addressList = addressList;
				}

				this.resolve(data);
			}
		});
	}
}

export default AddEditCompanies;
