// CORE
// NODE_MODULE
// TEMPLATE
import T_modal from '../../tpl/modals/Image.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

class Edit extends Modal {
	constructor(link: string, textProduct: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_close = this.element.querySelector('.close') as HTMLButtonElement;

			N_close.addEventListener('click', (e: MouseEvent) => {
				this.reject();

				e.stopImmediatePropagation();
				e.stopPropagation();
			});

			const N_product = this.element.querySelector('#product') as HTMLElement;
			const N_div = this.element.querySelector('.modal-body') as HTMLElement;

			N_product.innerHTML = textProduct;

			N_div.style.background = `url('${link}')`;
			N_div.style.backgroundSize = 'contain';
			N_div.style.backgroundRepeat = 'no-repeat';
			N_div.style.backgroundPosition = 'center';
		});
	}
}

export default Edit;
