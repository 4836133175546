import { AllModules, Grid, GridOptions, MenuItemDef } from '@ag-grid-enterprise/all-modules';
import M_PrintPreview from '@js/libs/modals/PrintPreview';
import agUtils from '@libs/agGrid/french';

import C_BillsCustomer from '@modules/Bills/js/controllers/Bills.Customer';
import C_BillsProvider from '@modules/Bills/js/controllers/Bills.Provider';

class BillsTab extends HTMLElement {
	public static readonly tagName: string = 'ap-cmd-bill-tab';

	private selectorTab: string = '';

	private idTab: string = '';
	private mode: string = '';

	private _gridOptions: GridOptions = {};

	// eslint-disable-next-line unused-imports/no-unused-vars
	private openModal: (id: string) => void = () => { };
	private idCommand: () => string = () => { return ''; };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `
			<li>
				<a data-toggle="tab" href="#${this.idTab}" role="tab">
					<div class="icon-container" tooltip="Factures">
						<i class="icon icon-solid-file-invoice-dollar"></i>
						<span class="nav-icon-badge d-none" id="number">0</span>
					</div>
					<span>Factures</span>
				</a>
			</li>
		`;

		this.id = '';
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');
		N_div.id = this.idTab;
		N_div.innerHTML = `
			<div class="d-flex flex-column h-100">
                <div class="title">
					Factures
					<div class="ml-auto">
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
                </div>
                <div class="h-100 ag-theme-alpine grid"></div>
            </div>
		`;

		N_container.append(N_div);

		this.initGrid();
	}

	public setOpenModal(cb: (id: string) => void) {
		this.openModal = cb;
	}

	public setIdCommand(cb: () => string) {
		this.idCommand = cb;
	}

	public setMode(mode: string) {
		this.mode = mode;
	}

	private async initGrid() {
		this._gridOptions = agUtils.french<GridOptions>({
			localeText: { noRowsToShow: 'Aucune Facture' },
			defaultColDef: {
				suppressMenu: true,
				sortable: true,
				resizable: true,
				cellRenderer: (params) => {
					return params.value;
				}
			},
			columnDefs: [
				{
					headerName: 'Index',
					field: 'infos.index',
					width: 100,
					sort: 'asc',
					valueGetter: (params) => {
						if (!params.data.infos) {
							return '';
						} else {
							return Number(params.data.infos.index);
						}
					}
				}, {
					headerName: 'Numéro',
					field: this.mode === 'customers' ? 'infos.number' : 'infos.internalNumber'
				}, {
					headerName: 'Type',
					field: 'infos.type'
				}, {
					headerName: 'Date',
					field: 'infos.date'
				}, {
					headerName: 'Montant HT (€)',
					field: 'globalPrice',
					cellClass: 'text-right  text-monospace',
					cellRenderer: (params) => {
						return params.value.formattedValue;
					}
				}, {
					headerName: 'Montant TTC (€)',
					field: 'globalPriceTTC',
					cellClass: 'text-right  text-monospace',
					cellRenderer: (params) => {
						return params.value.formattedValue;
					}
				}, {
					headerName: 'Montant à payer (€)',
					field: 'notPaid',
					cellClass: 'text-right  text-monospace',
					cellRenderer: (params) => {
						return params.value.formattedValue;
					}
				}, {
					headerName: 'Montant payé (€)',
					field: 'paid',
					cellClass: 'text-right text-monospace',
					cellRenderer: (params) => {
						return params.value.formattedValue;
					}
				}, {
					headerName: 'Action',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params: any) => {
						if (params.node.rowPinned) {
							return '';
						} else {
							const N_div = document.createElement('div');

							const N_edit = document.createElement('button');

							N_edit.classList.add('h-100', 'py-0', 'btn-transparent');

							N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';
							N_edit.setAttribute('tooltip', 'Éditer');
							N_edit.type = 'button';
							if (this.mode === 'customers') {
								N_edit.setAttribute('permission', 'BILLS._CUSTOMERS.EDIT');
							}

							if (this.mode === 'providers') {
								N_edit.setAttribute('permission', 'BILLS._PROVIDERS.EDIT');
							}

							N_edit.addEventListener('click', () => {
								this.openModal(params.data._id);
							});

							N_div.appendChild(N_edit);

							if (this.mode === 'customers') {
								const N_print = document.createElement('button');

								N_print.classList.add('h-100', 'py-0', 'btn-transparent');

								N_print.innerHTML = '<i class="text-dark h5 icon icon-printer-outiline "></i>';
								N_print.setAttribute('tooltip', 'Imprimer');
								N_print.type = 'button';

								N_print.addEventListener('click', () => {
									new M_PrintPreview('bills', params.data._id).open();
								});

								N_div.appendChild(N_print);
							}

							return N_div;
						}
					}
				}
			],
			getContextMenuItems: (params) => {
				if (params.node) {
					const result: (MenuItemDef | string)[] = [
						{
							name: this.mode === 'customers' ? `N° ${params.node.data.infos.number}` : `N° ${params.node.data.infos.internalNumber}`,
							disabled: true,
							cssClasses: ['title-context-menu']
						}, {
							name: 'Éditer',
							icon: '<i class="icon icon-edit"></i>',
							disabled: !params.node,
							action: () => {
								this.openModal(params.node.data._id);
							}
						}, {
							name: 'Imprimer',
							icon: '<i class="icon icon-printer-outiline "></i>',
							disabled: this.mode !== 'customers',
							action: () => {
								new M_PrintPreview('bills', params.node.data._id).open();
							}
						}
					];

					result.push({
						name: 'Créer un avoir',
						icon: '<i class="icon icon-solid-plus"></i>',
						disabled: params.node.data.isCredit,
						action: () => {
							let Controller: any;

							if (this.mode === 'customers') {
								Controller = C_BillsCustomer;
							}

							if (this.mode === 'providers') {
								Controller = C_BillsProvider;
							}

							Controller.open(null, {
								type: 'credit',
								idBill: params.node.data._id,
								idCommand: this.idCommand()
							});
						}
					});

					return result;
				} else {
					return [];
				}
			},
			onRowDataChanged: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		});

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector('#number') as HTMLElement;

		if (number) {
			N_number.innerHTML = number.toString();
			N_number.classList.remove('d-none');
		} else {
			N_number.classList.add('d-none');
		}
	}

	public set data(data: { data: any[], sum: { [key: string]: any } }) {
		this.gridOptions.api?.setRowData(data.data);
		this.gridOptions.api?.setPinnedBottomRowData([data.sum]);
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	/**
	 * Retourne le montant total facturé
	 * @retruns le montant tital facturé
	 */
	public getTotalBilledPrice(): number {
		return this.gridOptions.api?.getPinnedBottomRow(0).data.globalPrice.value;
	}

	public static register() {
		customElements.define(BillsTab.tagName, BillsTab);
	}
}

export default BillsTab;
