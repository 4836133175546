import GenericService from './GenericService';
class StockEventService extends GenericService {
	private static instance: StockEventService | null = null;

	constructor() {
		super('stock_events');
	}

	public static getInstance(): StockEventService {
		if (!StockEventService.instance) {
			StockEventService.instance = new StockEventService();
		}

		return StockEventService.instance;
	}

	public async getCurrentStock(params: { [key: string]: any } = {}) {
		return this.get_function('getCurrentStock', params);
	}
}

export default StockEventService;
