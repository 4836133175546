import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _set from 'lodash/set';

import moment from 'moment';

import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/FormControllerPageID';

class FormControllerPageID {
	private el: HTMLElement;

	private data: any = {};
	private resData: any = {};

	private divByName: { [key: string]: HTMLElement } = {};

	constructor(el: HTMLElement) {
		this.el = el;

		this.updateInputs();
	}

	public updateInputs() {
		const N_list = this.el.querySelectorAll('[name]') as NodeListOf<HTMLElement>;

		this.divByName = {};

		for (const N_div of N_list) {
			const name = N_div.getAttribute('name') || '';

			if (!this.divByName[name]) {
				this.divByName[name] = N_div;
			}
		}
	}

	public setData(data: { [key: string]: any }) {
		this.data = Utils.merge(this.data, data);
		for (const name in this.divByName) {
			const value = _get(this.data, name);
			this.setDataByName(name, value);
		}
	}

	private password(name: string, value: string, N_div: HTMLElement) {
		N_div.innerHTML = '******';
		_set(this.resData, name, value);
	}

	private percentage(name: string, value: string | number, N_div: HTMLElement) {
		N_div.innerHTML = value + ' %';
		_set(this.resData, name, value);
	}

	private price(name: string, value: string | number, N_div: HTMLElement) {
		if (value !== undefined) {
			const text = Decimal.setDisplayNumber(value).toDecimalPlaces(2).setSuffixAndHumanizeNumber('€');
			N_div.innerHTML = text;
			_set(this.resData, name, value);
		} else {
			const defaultValue = N_div.getAttribute('defaultvalue') || '&nbsp';
			N_div.innerHTML = defaultValue;
		}
	}

	private date(name: string, value: string | number, N_div: HTMLElement) {
		if (value) {
			const date = moment(value);
			if (date.isValid()) {
				N_div.innerHTML = date.format('DD/MM/YYYY');
			} else {
				N_div.innerHTML = '';
			}
			_set(this.resData, name, date);
		}
	}

	private stringOrNumber(name: string, value: string | number, N_div: HTMLElement) {
		const defaultValue = N_div.getAttribute('defaultvalue') || '&nbsp';

		let displayValue = value ?? defaultValue;
		displayValue = displayValue === '' ? defaultValue : displayValue;

		N_div.innerHTML = displayValue.toString();

		if (N_div.getAttribute('type') === 'textarea') {
			N_div.style.whiteSpace = 'pre-line';
		}

		_set(this.resData, name, value);
	}

	private boolean(name: string, value: boolean, N_div: HTMLElement) {
		N_div.innerHTML = value ? '<span class="text-success font-weight-bold">OUI</span>' : '<span class="text-danger font-weight-bold">NON</span>';
		_set(this.resData, name, value);
	}

	private object(name: string, value: { [key: string]: string }, N_div: HTMLElement) {
		if (value.id !== undefined) {
			N_div.innerHTML = value.text || value.id || '&nbsp;';
			_set(this.resData, name, value.id);
		} else {
			N_div.innerHTML = '&nbsp;';
			_set(this.resData, name, '');
		}
	}

	private array(name: string, value: { [key: string]: string }[], N_div: HTMLElement) {
		const html: string[] = [];
		const values: string[] = [];
		for (const item of value) {
			html.push(item.text || item.id);
			values.push(item.id);
		}
		N_div.innerHTML = html.join(', ');
		_set(this.resData, name, values);
	}

	public setDataByName(name: string, value: any) {
		_set(this.data, name, value);

		if (this.divByName[name]) {
			const N_div = this.divByName[name];

			N_div.setAttribute('ellipsis-tooltip', '');

			const type = N_div.getAttribute('type');

			if (value !== null) {
				if (type === 'password') {
					this.password(name, value, N_div);
				} else if (type === 'percentage') {
					this.percentage(name, value, N_div);
				} else if (type === 'price') {
					this.price(name, value, N_div);
				} else if (type === 'date') {
					this.date(name, value, N_div);
				} else if (typeof value === 'string' || typeof value === 'number') {
					this.stringOrNumber(name, value, N_div);
				} else if (typeof value === 'boolean') {
					this.boolean(name, value, N_div);
				} else if (value instanceof Array) {
					this.array(name, value, N_div);
				} else if (typeof value === 'object') {
					this.object(name, value, N_div);
				} else {
					N_div.innerHTML = '&nbsp;';
				}
			} else {
				N_div.innerHTML = '&nbsp;';
				_set(this.resData, name, '');
			}
		} else {
			_set(this.resData, name, value);
		}
	}

	public getData() {
		return _cloneDeep(this.resData);
	}

	public getDataByName(name: string) {
		return _get(this.getData(), name);
	}
}

export default FormControllerPageID;
