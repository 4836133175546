// CORE
import { Alert, LoggedUser, Router, toaster, utils } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
// LIBS
import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';

import Loader from '@libs/Loader';
import OpenDocuments from '@libs/customElement/OpenDocuments';

import C_BillsProvider from '@modules/Bills/js/controllers/Bills.Provider';
import Notifications from '@modules/Apps/js/libs/Notifications';

// UTILS
import Decimal from '@libs/utils/Decimal';
import Utils from '@js/libs/utils/Utils';

// MODAL
import M_AdditionalInformation from '../modals/providers/editPage/AdditionalInformation';
import M_Amounts from '../modals/providers/editPage/Amounts';
import M_DetailsCommand from '../modals/providers/editPage/DetailsCommand';
import M_GeneralInformation from '../modals/providers/editPage/GeneralInformation';

import M_receipt from '@modules/Deliveries_Receipts/js/modals/receipt/Infos';

// CUSTOM_ELEMENT
import BillTab from '../libs/customElement/Bills';

import CE_ContentTab from '../libs/customElement/providers/ContentTab';
import CE_PrintOptionTab from '@libs/customElement/PrintOptionTab';
import CE_ReceiptTab from '@modules/Deliveries_Receipts/js/libs/customElement/ContentReceiptGrid';
import CE_TVA from '@libs/customElement/TVA';

// SERVICE
import S_P_Address from '@services/Provider/ProviderAddressService';
import S_P_Order from '@services/Provider/ProviderOrderService';
import S_Provider from '@services/Provider/ProviderService';

// TYPES
import Company from '@js/types/company/company';

class CommandProviderCtrl extends ControllerPageID {
	private N_PrintOptionTab: CE_PrintOptionTab | null = null;
	private N_BillTab: BillTab | null = null;
	private N_ReceiptTab: CE_ReceiptTab | null = null;
	private N_ContentTab: CE_ContentTab | null = null;

	private hasBills: boolean;

	private N_ButtonReceipt: HTMLButtonElement | undefined;
	private buttonReceiptOnClickListener: () => Promise<void> = async () => { };

	private company: Company | undefined;

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.options = CommandProviderCtrl.options || {};

		CommandProviderCtrl.options = {};

		this.reloadAfterSave = true;

		this.hasBills = false;

		this.routeReturn = 'module/commands/providers';

		this.init('commands-provider', id);
	}

	private static options: { [key: string]: any } = {};
	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		CommandProviderCtrl.options = options || {};

		if (id && !ControllerPageID.mode) {
			await OpenDocuments.checkOpen(id, 'commands-provider');
			Router.getInstance().navigate(`module/order/provider?id=${id}`);
		} else {
			if (ControllerPageID.mode === 'duplicate') {
				ControllerPageID.idDuplicate = id!;
			}

			Router.getInstance().navigate('module/order/provider');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		this.initTabs();

		const data = await this.getData();

		this.company = data.company;

		this.setData(data);

		this.updateType();

		this.postInit();

		this.initFullscreen();
		this.initEditButton();

		this.updateEditButtons();

		if (!this.id) {
			this.openGeneralInformation(true);
		}

		if (this.options.type === 'product' && this.id) {
			this.updateSaveButton();
		}
	}

	private openGeneralInformation(firstOpen = false) {
		const res = {
			infos: {
				provider: this.form?.getDataByName('infos.provider') as string,
				contact: this.form?.getDataByName('infos.contact') as string
			}
		};

		new M_GeneralInformation(res).open().then(async (data) => {
			let updatePrice = false;

			if (data.infos.provider.id && this.form?.getDataByName('infos.provider').length === 0) {
				updatePrice = true;
			}

			this.setDataForm(data);

			if (data.infos.provider.id) {
				this.N_ContentTab?.enableTab();
			}

			if (updatePrice) {
				this.N_ContentTab?.updateMaterials();
			}

			if (res.infos.provider !== data.infos.provider.id) {
				await this.updateProviderAddress();
				await this.updateTVA();
			}

			this.updateSaveButton();
		}).catch(() => {
			if (firstOpen) {
				this.return();
			}
		});
	}

	private initEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsCommand = this.el.querySelector('[data-edit="detailsCommand"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;
		const N_edit_Amounts = this.el.querySelector('[data-edit="amounts"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			this.openGeneralInformation();
		});

		N_edit_DetailsCommand.addEventListener('click', () => {
			const res = {
				infos: {
					businessName: this.form?.getDataByName('infos.businessName') as string,
					commandsCustomer: this.form?.getDataByName('infos.commandsCustomer') as string[],
					sites: this.form?.getDataByName('infos.sites') as string[]
				}
			};

			new M_DetailsCommand(res).open().then((data) => {
				this.setDataForm(data);
				this.N_ContentTab?.updateCommandsCustomer(data.newMaterials);
				this.updateSaveButton();
			});
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			const res = {
				infos: {
					provider: this.form?.getDataByName('infos.provider') as string,
					addressID: this.form?.getDataByName('infos.addressID') as string,
					fullAddress: this.form?.getDataByName('infos.fullAddress') as string,
					deliveryPrice: this.form?.getDataByName('infos.deliveryPrice') as string,
					date: this.form?.getDataByName('infos.date') as Moment,
					sendDate: this.form?.getDataByName('infos.sendDate') as Moment,
					deliveryDate: this.form?.getDataByName('infos.deliveryDate') as Moment,
					user: this.form?.getDataByName('infos.user') as string,
					email: this.form?.getDataByName('infos.email') as string,
					comments: this.form?.getDataByName('infos.comments') as string
				},
				address: {
					type: this.form?.getDataByName('address.type') as string,
					customer: {
						id: this.form?.getDataByName('address.customer.id') as string,
						addressID: this.form?.getDataByName('address.customer.addressID') as string,
						fullAddress: this.form?.getDataByName('address.customer.fullAddress') as string
					},
					internal: {
						id: this.form?.getDataByName('address.internal.id') as string,
						fullAddress: this.form?.getDataByName('address.internal.fullAddress') as string
					}
				},
				company: this.company as Company
			};

			new M_AdditionalInformation(res).open().then((data) => {
				this.setDataForm(data);
				this.updateType();
				this.N_ContentTab?.updateDeliveryPrice();
				this.updateNumber();
				this.updateEditButtons();
				this.updateSaveButton();
			});
		});

		N_edit_Amounts.addEventListener('click', () => {
			const res = {
				infos: {
					tva: this.form?.getDataByName('infos.tva') as string
				}
			};

			new M_Amounts(res).open().then((data) => {
				this.setDataForm(data);
				this.updateSaveButton();
			});
		});
	}

	protected initButton() {
		super.initButton();

		const N_cancel = this.el.querySelector('#cancel') as HTMLElement;

		//Gestion de l'évènement pour annuler la commande
		const title = '<i class="icon icon-warning text-danger"></i> Annulation de la commande <i class="icon icon-warning text-danger"></i>';
		const content = '<strong>Attention</strong>, cette opération va supprimer le bon de réception et le stock sera réinitialisé.<br><br>Voulez-vous continuez ?';

		N_cancel.addEventListener('click', () => {
			Alert.confirm(title, content, {
				yesColor: 'validate-modal',
				yesText: 'Valider',
				noColor: 'close-modal',
				noText: 'Fermer'
			}).then(async () => {
				Loader.getInstance().open();
				await this.save();
				await S_P_Order.getInstance().cancelOrder(this.id);
				toaster.success(`Commande N° ${this.form?.getDataByName('infos.number')} annulée`);
				Loader.getInstance().close();
				this.return();
			});
		});

		const N_advance = this.el.querySelector('#advance') as HTMLButtonElement;
		const N_last = this.el.querySelector('#last') as HTMLButtonElement;

		N_advance.addEventListener('click', async () => {
			C_BillsProvider.open(null, {
				type: 'advance',
				idCommand: this.id
			});
		});

		N_last.addEventListener('click', async () => {
			C_BillsProvider.open(null, {
				type: 'last',
				idCommand: this.id
			});
		});

		const N_closeNotification = this.el.querySelector('#close_notification') as HTMLButtonElement;

		if (this.options.notification) {
			(N_closeNotification.parentNode as HTMLElement).classList.remove('d-none');
		}

		N_closeNotification.addEventListener('click', async () => {
			await Notifications.closeNotification(this.options.notification);
			this.return();
		});
	}

	private initTabs() {
		this.initBill();
		this.initContent();
		this.initPreviewPrint();
		this.initReceipt();
	}

	private async initBill() {
		this.N_BillTab = this.el.querySelector(BillTab.tagName) as BillTab;

		this.N_BillTab.setMode('providers');

		this.N_BillTab.setParentElement(this.el);

		this.N_BillTab.setOpenModal((id) => {
			C_BillsProvider.open(id);
		});

		this.N_BillTab.setIdCommand(() => {
			return this.id;
		});
	}

	private async initContent() {
		this.N_ContentTab = this.el.querySelector(CE_ContentTab.tagName) as CE_ContentTab;

		this.N_ContentTab.setParentElement(this.el);

		this.N_ContentTab!.setOnUpdate(() => {
			this.updateInfos();
			this.updateSaveButton();
		});

		this.N_ContentTab!.setDeleteDeliveryPrice(() => {
			this.form?.setDataByName('infos.deliveryPrice', '');
		});

		this.N_ContentTab!.setGetForm(() => {
			return this.form!.getData();
		});
	}

	private initPreviewPrint() {
		this.N_PrintOptionTab = this.el.querySelector(CE_PrintOptionTab.tagName);

		this.N_PrintOptionTab!.setParentElement(this.el);

		this.N_PrintOptionTab!.table = 'commands-provider';

		this.N_PrintOptionTab!.setID(() => {
			return this.id;
		});

		this.N_PrintOptionTab!.setGetData(() => {
			return this.saveData;
		});
	}

	private async initReceipt() {
		this.N_ReceiptTab = this.el.querySelector(CE_ReceiptTab.tagName) as CE_ReceiptTab;

		this.N_ReceiptTab.setParentElement(this.el);
	}

	private get isLock() {
		const sendDate = this.form?.getDataByName('infos.sendDate');

		if (LoggedUser.getInstance().hasPermission('ORDERS._PROVIDERS.EDIT_SENDED')) {
			return false;
		}

		return sendDate || !LoggedUser.getInstance().hasPermission('ORDERS._PROVIDERS.EDIT');
	}

	protected convertData(data: { [key: string]: any; }): { [key: string]: any; } {
		if (data.address?.type) {
			const type: { [key: string]: string } = {
				internal: 'Interne',
				customer: 'Direct Client'
			};

			data.address.type = {
				id: data.address.type,
				text: type[data.address.type]
			};
		}

		if (data.infos.tva) {
			data.infos.tva = CE_TVA.convertValue(data.infos.tva);
		}

		return data;
	}

	protected setData(data: DataServer) {
		super.setData(data);

		this.removeButtonReceiptEventListener();
		this.buttonReceipt(data.receipt, data.stock.stateReceipt);

		if (data.data.infos.provider.id) {
			this.N_ContentTab!.enableTab();
		}

		this.N_ContentTab!.data = data.data.materials;
		this.N_ReceiptTab!.data = data.stock;
		this.N_BillTab!.data = data.bills;
		this.hasBills = !!data.bills.data.length;

		//S'il n'y a pas de factures et qu'il y a un bon de réception, on peut annuler la commande
		const N_cancel = this.el.querySelector('#cancel') as HTMLElement;
		if (this.hasBills || data.stock.stateReceipt === 'total' || data.stock.stateReceipt === 'partial' || !data.data.infos.sendDate) {
			N_cancel.classList.add('disabled');
		} else {
			N_cancel.classList.remove('disabled');
		}

		const N_notBillPrice = this.el.querySelector('#not_bill_price') as HTMLElement;
		const N_billPrice = this.el.querySelector('#bill_price') as HTMLElement;
		const N_credit = this.el.querySelector('#credit') as HTMLElement;
		const N_advance = this.el.querySelector('#advance') as HTMLButtonElement;
		const N_last = this.el.querySelector('#last') as HTMLButtonElement;

		N_billPrice.innerHTML = this.id ? data.bills.sum.bill.formattedValue : '--';
		N_notBillPrice.innerHTML = this.id ? data.bills.sum.notBill.formattedValue : '--';
		N_credit.innerHTML = this.id ? data.bills.sum.credit.formattedValue : '--';

		if (data.bills.sum.notBill.value === 0 || !data.data.infos.sendDate) {
			N_advance.disabled = true;
			N_last.disabled = true;
		} else {
			N_advance.disabled = false;
			N_last.disabled = false;
		}
	}

	protected postInit(): void {
		super.postInit();

		this.N_BillTab!.postInit();
		this.N_PrintOptionTab!.postInit();
		this.N_ReceiptTab!.postInit();
		this.N_ContentTab!.postInit();
	}

	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		if (newData.infos.date) {
			newData.infos.date = newData.infos.date.format('x');
		}

		if (newData.infos.sendDate) {
			newData.infos.sendDate = newData.infos.sendDate.format('x');
		}

		if (newData.infos.deliveryDate) {
			newData.infos.deliveryDate = newData.infos.deliveryDate.format('x');
		}

		newData.materials = this.N_ContentTab!.data;

		return newData;
	}

	protected updateSaveButton() {
		super.updateSaveButton();

		const N_advance = this.el.querySelector('#advance') as HTMLButtonElement;
		const N_last = this.el.querySelector('#last') as HTMLButtonElement;
		const N_receipt = this.el.querySelector('#create-open-receipt') as HTMLButtonElement;

		N_advance.disabled = true;
		N_last.disabled = true;
		N_receipt.disabled = true;
	}

	private convertState(state: string) {
		const text: { [key: string]: string } = {
			inProgress: 'En cours',
			waiting: 'En attente de réception',
			partial: 'Reçue partiellement',
			total: 'Reçue en totalité'
		};

		return text[state];
	}

	private async buttonReceipt(idReceipt: string, state: string) {
		this.N_ButtonReceipt = this.el.querySelector('#create-open-receipt') as HTMLButtonElement;

		const N_state = this.el.querySelector('#state') as HTMLElement;

		this.N_ButtonReceipt.disabled = false;

		if (idReceipt) {
			this.N_ButtonReceipt.setAttribute('tooltip', 'Ouvrir le bon de réception');
			this.N_ButtonReceipt.setAttribute('permission', 'RECEIPTS.OPEN');
			N_state.innerHTML = this.convertState(state);
		} else {
			this.N_ButtonReceipt.setAttribute('tooltip', 'Créer un bon de réception');
			this.N_ButtonReceipt.setAttribute('permission', 'RECEIPTS.ADD');
			N_state.innerHTML = this.convertState(state);
		}

		this.buttonReceiptOnClickListener = async () => {
			Utils.removeTooltip();
			this.openReceiptModalAndReload(idReceipt);
		};

		this.N_ButtonReceipt.addEventListener('click', this.buttonReceiptOnClickListener, {
			signal: this.abortSignal
		});
	}

	private openReceiptModalAndReload(idReceipt: string) {
		if (idReceipt) {
			new M_receipt({
				id: idReceipt
			}).open().then(() => {
				this.reload();
			}).catch(() => {
				this.reload();
			});
		} else {
			new M_receipt({
				order: this.id
			}).open().then(() => {
				this.reload();
			}).catch(() => {
				this.reload();
			});
		}
	}

	private removeButtonReceiptEventListener() {
		this.N_ButtonReceipt?.removeEventListener('click', this.buttonReceiptOnClickListener);
	}

	private async updateTVA() {
		const providerID = this.form?.getDataByName('infos.provider');
		const provider = await S_Provider.getInstance().getById(providerID);

		this.setDataForm({
			infos: {
				tva: provider.tva || '20'
			}
		});
	}

	private async updateProviderAddress() {
		const provider = this.form?.getDataByName('infos.provider');
		const addresses = await S_P_Address.getInstance().getByProviderToSelect2(provider as string);

		if (addresses.length && provider) {
			const fullAddress = await S_P_Address.getInstance().getFullAddress(addresses[0]?.id);
			this.form?.setDataByName('infos.addressID', addresses[0]);
			this.form?.setDataByName('infos.fullAddress', fullAddress);
		} else {
			this.form?.setDataByName('infos.addressID', { id: '', text: '' });
			this.form?.setDataByName('infos.fullAddress', '');
		}
	}

	private updateEditButtons() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsCommand = this.el.querySelector('[data-edit="detailsCommand"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;
		const N_edit_Amounts = this.el.querySelector('[data-edit="amounts"]') as HTMLButtonElement;

		N_edit_GeneralInformation.disabled = this.isLock;
		N_edit_DetailsCommand.disabled = this.isLock;
		N_edit_AdditionalInformation.disabled = this.isLock;
		N_edit_Amounts.disabled = this.isLock;

		this.N_ContentTab!.isLock = this.isLock;
	}

	private updateInfos() {
		const { globalPrice } = this.N_ContentTab!.getInfos();

		//On met à jour le montant de la commande
		this.form?.setDataByName('globalPrice', globalPrice.toDecimalPlaces(2).humanizeNumber());

		//On met à jour le montant facturé et à facturer
		const N_notBillPrice = this.el.querySelector('#not_bill_price') as HTMLElement;
		const N_billedPrice = this.el.querySelector('#bill_price') as HTMLElement;

		const billedPrice = new Decimal(this.N_BillTab!.getTotalBilledPrice());
		const billedPercent = billedPrice.dividedBy(globalPrice).times(100).toDecimalPlaces(2);

		const notBilledPrice = globalPrice.minus(billedPrice);
		const notBilledPercent = notBilledPrice.dividedBy(globalPrice).times(100).toDecimalPlaces(2);

		N_billedPrice.innerHTML = `${billedPrice.setSuffixAndHumanizeNumber('€', 2)} | ${billedPercent.humanizePercent()}%`;
		N_notBillPrice.innerHTML = `${notBilledPrice.setSuffixAndHumanizeNumber('€', 2)} | ${notBilledPercent.humanizePercent()}%`;
	}

	private updateNumber() {
		const number = this.form?.getDataByName('infos.number');
		const date = this.form?.getDataByName('infos.date');
		const value = S_P_Order.getInstance().updateNumberByDate(number || '', date);

		this.form?.setDataByName('infos.number', value);
	}

	private updateType() {
		const type = this.form?.getDataByName('address.type');

		const N_address_customer_elements: NodeListOf<Element> = this.el.querySelectorAll('[address-type="customer"]');
		const N_address_internal_elements: NodeListOf<Element> = this.el.querySelectorAll('[address-type="internal"]');

		if (type === 'customer') {
			for (const N of N_address_internal_elements) {
				!N.classList.contains('d-none') && N.classList.add('d-none');
			}
			for (const N of N_address_customer_elements) {
				N.classList.contains('d-none') && N.classList.remove('d-none');
			}
		}

		if (type === 'internal') {
			for (const N of N_address_customer_elements) {
				!N.classList.contains('d-none') && N.classList.add('d-none');
			}
			for (const N of N_address_internal_elements) {
				N.classList.contains('d-none') && N.classList.remove('d-none');
			}
		}
	}
}

export default CommandProviderCtrl;
