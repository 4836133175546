import { global } from '@autoprog/core-client';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

import S_Users from '@services/User/UserService';

import TemplateMobileManager from '@managers/TemplateMobileManager';

import M_addNote from '@libs/modals/AddNote';
import Utils from '@libs/utils/Utils';

import _ from 'lodash';
import moment from 'moment';

class NotesTab extends HTMLElement {
	public static readonly tagName: string = 'ap-notes-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private _gridOptions: GridOptions = {};

	private dataMobile: any[] = [];

	private N_el: HTMLElement | null = null;

	private callback: () => void = () => { };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		const type = this.getAttribute('type');

		if (type === 'page') {
			if (global.IS_MOBILE) {
				this.innerHTML = `
					<div class="d-flex page-tab-mobile bg-white p-3" data-type="open-mobile" href="${this.idTab}">
						<div tooltip="Notes">
							<i class="icon icon-solid-sticky-note mr-2"></i>
						</div>
						<span>Notes</span> 
						<span class="badge badge-pill" id="number">0</span>
						<div class="ml-auto">
							<div data-type="open-mobile-icon"></div>
						</div>
					</div>
				`;
			} else {
				this.innerHTML = `
					<li>
						<a data-toggle="tab" href="#${this.idTab}" role="tab">
							<div class="icon-container" tooltip="Notes">
								<i class="icon icon-solid-sticky-note"></i>
								<span class="nav-icon-badge d-none" id="number">0</span>
							</div>
							<span>Notes</span>
						</a>
					</li>
				`;
			}
		} else {
			this.innerHTML = `
				<li class="nav-item border-right">
					<a class="nav-link px-3 position-relative" data-toggle="tab" href="#${this.idTab}" role="tab">
						Notes
						<span style="top: 5px;right: -2px;" class="badge badge-pill position-absolute" id="number">0</span>
					</a>
				</li>
			`;
		}

		this.removeAttribute('type');
		this.removeAttribute('id');
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		this.N_el = document.createElement('div');

		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="title" display="desktop">
					Notes
					<div class="ml-auto">
						<button class="btn btn-success" type="button" id="add-desktop">
							<i class="icon icon-solid-plus mr-2"></i>Ajouter
						</button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
				</div>

				<div class="page-card bg-white p-3" display="mobile">
					<button class="btn btn-success w-100" type="button" id="add-mobile">
						<i class="icon icon-solid-plus mr-2"></i> Ajouter
					</button>
				</div>
                <div class="h-100 ag-theme-alpine grid" display="desktop"></div>
                <div class="page-card bg-white p-3 grid" display="mobile"></div>
			</div>
		`;

		if (global.IS_MOBILE) {
			this.appendChild(this.N_el);
		} else {
			const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
			this.N_el.classList.add('tab-pane', 'h-100', 'fade');
			this.N_el.setAttribute('role', 'tabpanel');
			N_container.append(this.N_el);
			this.initGrid();
		}

		this.initButton();
	}

	public setCallback(cb: () => void) {
		this.callback = cb;
	}

	private initButton() {
		const N_btnDesktop = document.querySelector(`#${this.idTab} #add-desktop`) as HTMLButtonElement;

		N_btnDesktop && N_btnDesktop.addEventListener('click', () => {
			let index = 0;

			this._gridOptions.api?.forEachNode(() => {
				index++;
			});

			new M_addNote().open().then((data) => {
				this._gridOptions.api?.applyTransaction({
					add: [data]
				});

				this._gridOptions.api?.ensureIndexVisible(index);

				this._gridOptions.api?.refreshCells({
					force: true
				});

				this.callback();

				if (global.IS_MOBILE) {
					this.initMobile();
				}
			});
		});

		const N_btnMobile = document.querySelector(`#${this.idTab} #add-mobile`) as HTMLButtonElement;

		N_btnMobile && N_btnMobile.addEventListener('click', () => {
			new M_addNote().open().then((item) => {
				this.dataMobile.push(item);

				this.callback();

				this.initMobile();
			});
		});
	}

	private async initGrid() {
		const users = await S_Users.getInstance().getAll();

		this._gridOptions = agUtils.french<GridOptions>({
			localeText: { noRowsToShow: 'Aucune Note' },
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Personne',
					field: 'user',
					width: 200,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const user = _.find(users, { _id: params.value.toString() }) as any;
						return `${user.lastname} ${user.firstname}`;
					}
				}, {
					headerName: 'Date',
					field: 'date',
					sort: 'asc',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						return moment(params.value, 'x').format('DD/MM/YYYY');
					}
				}, {
					headerName: 'Description',
					field: 'text',
					autoHeight: true,
					cellClass: 'cell-wrap-text',
					cellRenderer: (params) => {
						const value = (params.value || '').split('\n');
						let result = '';

						for (const item of value) {
							result += `<div style="line-height: 17px">${item || '&nbsp;'}</div>`;
						}

						return `<div style="margin-top:5px; margin-bottom:5px;" >${result}</div>`;
					}
				}, {
					headerName: 'Action',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					suppressSizeToFit: true,
					width: 100,
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						const N_edit = document.createElement('button');

						N_edit.classList.add('h-100', 'py-0', 'btn-transparent');
						N_edit.setAttribute('tooltip', 'Éditer');
						N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';
						N_edit.type = 'button';

						N_edit.addEventListener('click', () => {
							new M_addNote(params.data).open().then((data) => {
								params.data = data;

								params.node.setDataValue('text', data.text);
								params.node.setDataValue('user', data.user);
								params.node.setDataValue('date', data.date);

								params.api?.resetRowHeights();

								params.api?.refreshCells({ force: true });

								this.callback();
							});
						});

						const N_delete = document.createElement('button');

						N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
						N_delete.setAttribute('confirmation', '');
						N_delete.setAttribute('tooltip', 'Supprimer');
						N_delete.type = 'button';

						N_delete.addEventListener('click', () => {
							params.api?.updateRowData({
								remove: [params.data]
							});

							this.callback();
						});

						N_div.appendChild(N_edit);
						N_div.appendChild(N_delete);

						return N_div;
					}
				}
			],
			onColumnResized: (params) => {
				if (params.finished) {
					params.api.resetRowHeights();
				}
			},
			getContextMenuItems: (params) => {
				const result = [{
					name: 'Ajouter ligne',
					action: () => {
						if (params.api) {
							let index = 0;

							params.api.forEachNode(() => {
								index++;
							});

							new M_addNote().open().then((data) => {
								params.api?.applyTransaction({
									add: [data]
								});

								params.api?.ensureIndexVisible(index);

								params.api?.refreshCells({
									force: true
								});

								this.callback();
							});
						}
					}
				}];

				return result;
			},
			onRowDataChanged: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		});

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector('#number') as HTMLElement;

		if (number) {
			N_number.innerHTML = number.toString();
			N_number.classList.remove('d-none');
		} else {
			N_number.classList.add('d-none');
		}
	}

	private async initMobile() {
		const N_number = this.querySelector('#number') as HTMLElement;
		N_number.innerHTML = this.dataMobile.length.toString();

		const users = await S_Users.getInstance().getAll();

		this.classList.add('position-relative');

		const el = this.N_el?.querySelector('.grid') as HTMLElement;

		el.innerHTML = '';

		if (this.dataMobile.length) {
			for (const item of this.dataMobile) {
				const N_item = document.createElement('div');

				const user = _.find(users, { _id: item.user }) as any;

				const obj = {
					user: `${user.lastname} ${user.firstname}`,
					text: item.text,
					date: moment(item.date, 'x').format('DD/MM/YYYY')
				};

				N_item.innerHTML = TemplateMobileManager.get('notes', obj);

				N_item.addEventListener('click', () => {
					new M_addNote(item).open().then((data) => {
						item.user = data.user;
						item.text = data.text;
						item.date = data.date;

						this.initMobile();

						this.callback();
					});
				});

				el.appendChild(N_item);
			}
		} else {
			const N_noData = document.createElement('div');

			N_noData.classList.add('text-muted', 'd-flex', 'justify-content-center', 'h5', 'mt-2');

			N_noData.innerHTML = 'Aucune note';

			el.appendChild(N_noData);
		}
	}

	public set data(data: any[]) {
		this._gridOptions.rowData = data;
		this._gridOptions.api?.setRowData(data);

		if (global.IS_MOBILE) {
			this.dataMobile = data;
			this.initMobile();
		}
	}

	public get data(): any[] {
		if (global.IS_MOBILE) {
			return this.dataMobile;
		} else {
			const results: any[] = [];

			this._gridOptions.api?.forEachNode((node) => {
				results.push(node.data);
			});

			return results;
		}
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public addLine(text: string) {
		this.gridOptions.api?.applyTransaction({
			add: [{
				user: Utils.userID,
				date: Date.now().toString(),
				text
			}]
		});
	}

	public addLines(values: any[]) {
		this.gridOptions.api?.applyTransaction({
			add: values
		});
	}

	public static register() {
		customElements.define(NotesTab.tagName, NotesTab);
	}
}

export default NotesTab;
