import CE_Signature from './Signature';

class SignatureTab extends HTMLElement {
	public static readonly tagName: string = 'ap-signature-tab';

	private selectorTab: string = '';

	private idTab: string = '';
	private _update = (): void => { };

	private N_el: HTMLElement | null = null;

	private N_Signature: CE_Signature | null = null;

	private type: string | null = null;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.type = this.getAttribute('type');

		if (this.type === 'page') {
			this.innerHTML = `
				<li ap-hidden="mobile">
					<a data-toggle="tab" href="#${this.idTab}" role="tab">
						<div class="icon-container" tooltip="Signature">
							<i class="icon icon-solid-signature"></i>
						</div>
						<span>Signature</span>
					</a>
				</li>
			`;
		} else {
			this.innerHTML = `
				<li class="nav-item border-right" ap-hidden="mobile">
					<a class="nav-link px-3 position-relative" data-toggle="tab" href="#${this.idTab}" role="tab">
						Signature
					</a>
				</li>
			`;
		}

		this.removeAttribute('type');
		this.removeAttribute('id');
	}

	private async initSignature() {
		this.N_Signature = this.N_el!.querySelector(CE_Signature.tagName) as CE_Signature;
		this.N_Signature?.init();

		this.N_Signature!.setOnUpdate(() => {
			this._update();
		});
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') !== `#${this.idTab}`) {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'h-100', 'fade');
		this.N_el.setAttribute('role', 'tabpanel');
		this.N_el.id = this.idTab;

		N_container.append(this.N_el);
	}

	private init() {
		const N_container = this.N_el!.parentElement;
		this.N_el!.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="title" display="desktop">
					Signature
					<div class="ml-auto">
						<button class="btn btn-blue" type="button" id="clear_signature">
							<i class="icon icon-solid-eraser mr-2"></i>Effacer
						</button>
						<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					</div>
				</div>
				<ap-signature height="${N_container!.clientHeight - 60}" width="${N_container!.clientWidth}"></ap-signature>
			</div>
		`;

		this.initSignature();
	}

	public set data(data: any) {
		setTimeout(() => {
			this.init();
			this.N_Signature!.data = data;
		}, 500);
	}

	public get data(): any {
		return this.N_Signature?.data;
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public static register() {
		customElements.define(SignatureTab.tagName, SignatureTab);
	}
}

export default SignatureTab;
