import { Alert, Form, LoggedUser } from '@autoprog/core-client';
import EventEmitter from '@autoprog/eventemitter';

import Select2Utils, { OverrideOptions } from '@libs/utils/select2Utils';

import ModalManager from '@managers/ModalManager';

import Permissions from '@libs/Permissions';

type ValueRef = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | string | boolean | (() => any);

class Select2 extends HTMLElement {
	public static readonly tagName: string = 'ap-select2-button';

	private select: Select2Utils | null = null;
	public selectElement: HTMLSelectElement | null = null;
	private form: Form | null = null;

	private table: string = '';
	private name: string = '';

	private eventEmitter = new EventEmitter();

	private isDisabled = false;

	private cancelEventChange = false;

	public async connectedCallback() {
		this.name = this.getAttribute('name') as string;
		this.table = this.getAttribute('table') || '';
		const required = this.getAttribute('required');

		this.classList.add('w-100');

		this.innerHTML = `
			<div id="container_form">
				<div id="container_select" style="max-width : calc(100% - 80px);">
					<select name="${this.name}" id="${this.name.replace(/\./, '_')}_${Date.now().toString(36)}" class="form-control" ${required ? 'required' : ''}></select>
				</div>

				<div id="custom_select_btn_container" class="bg-white border d-flex ml-1 p-0" style="border-radius: 5px;">
					<button class="btn btn-white" type="button" id="edit" style="border-radius: 5px;">
						<i class="icon icon-edit"></i>
					</button>
					<button class="btn btn-white" type="button" id="add" style="border-radius: 5px;">
						<i class="icon icon-solid-plus"></i>
					</button>
				</div>
			</div>
        `;

		const hasAddBtn = this.getAttribute('hidden-btn-add') === 'true' || !LoggedUser.getInstance().hasPermission(`${Permissions[this.table]}.ADD`);
		const hasEditBtn = this.getAttribute('hidden-btn-edit') === 'true' || !LoggedUser.getInstance().hasPermission(`${Permissions[this.table]}.OPEN`);

		this.form = new Form(this.querySelector('#container_form') as HTMLFormElement);
		if (hasAddBtn) {
			const N_add = this.querySelector('#add') as HTMLElement;
			N_add.classList.add('d-none');
		}

		if (hasEditBtn) {
			const N_edit = this.querySelector('#edit') as HTMLElement;
			N_edit.classList.add('d-none');
		}

		if (hasEditBtn && hasAddBtn) {
			const N_btnContainer = this.querySelector('#custom_select_btn_container') as HTMLElement;
			N_btnContainer.classList.remove('d-flex');
			N_btnContainer.classList.add('d-none');
		}

		this.updateWidthSelect2();

		this.selectElement = this.querySelector('select') as HTMLSelectElement;
		this.select = new Select2Utils(this.table, this.selectElement);

		this.removeAttribute('required');
	}

	public get value() {
		return this.form?.getDataByName(this.name) as string | string[];
	}

	public setRef(data: { [key: string]: ValueRef }) {
		for (const key in data) {
			if (data[key] instanceof HTMLElement) {
				this.select?.setRefElement(key, data[key] as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement);
			}

			if (typeof data[key] === 'string' || typeof data[key] === 'boolean') {
				this.select?.setRefValue(key, data[key]);
			}

			if (typeof data[key] === 'function') {
				this.select?.setRefCallback(key, data[key] as () => any);
			}
		}
	}

	public disable() {
		const N_add = this.querySelector('#add') as HTMLButtonElement;
		N_add.disabled = true;
		$(this.selectElement!).prop('disabled', true);
	}

	public enable() {
		const N_add = this.querySelector('#add') as HTMLButtonElement;
		N_add.disabled = false;
		$(this.selectElement!).prop('disabled', false);
	}

	private updateWidthSelect2() {
		const N_edit = this.querySelector('#edit') as HTMLElement;
		const N_add = this.querySelector('#add') as HTMLElement;
		const N_container_select = this.querySelector('#container_select') as HTMLElement;

		let width = 80;

		if (N_edit.classList.contains('d-none')) {
			width -= 40;
		}

		if (N_add.classList.contains('d-none')) {
			width -= 40;
		}

		N_container_select.style.maxWidth = `calc(100% - ${width}px)`;
	}

	/**
	 * Renvoie l'element Select2 créé. 
	 * Si un ID de société est donné, les données affichées dans le select seront filtrées pour n'afficher que celles de cette société.
	 * 
	 * @param parent l'element parent
	 * @param overrideOptions (facultatif) les options pour l'écrasement
	 * @param companyID (facultatif) l'ID de la société
	 * @returns 
	 */
	public create(parent: HTMLElement, overrideOptions: OverrideOptions = {}) {
		if (overrideOptions.disabled || this.getAttribute('readonly') !== null) {
			this.isDisabled = true;
			overrideOptions.allowClear = false;
			const N_container_select = this.querySelector('#container_select') as HTMLElement;
			N_container_select.style.pointerEvents = 'none';
		}

		overrideOptions.multiple = overrideOptions.multiple || this.getAttribute('multiple') === 'true';

		this.removeAttribute('name');
		this.removeAttribute('table');

		this.select?.create(parent, overrideOptions);

		return this.select!;
	}

	public postInit() {
		$(this.selectElement!).on('select2:select', async () => {
			if (!this.cancelEventChange) {
				this.dispatchEvent(new Event('select', { bubbles: true }));
				this.eventEmitter.emit('change', this.value);
			}
		});

		$(this.selectElement!).on('select2:selecting', async () => {
			this.dispatchEvent(new Event('selecting', { bubbles: true }));
		});

		$(this.selectElement!).on('select2:unselect', async () => {
			this.dispatchEvent(new Event('unselect', { bubbles: true }));
			setTimeout(() => {
				this.eventEmitter.emit('change', this.value);
			}, 500);
		});

		$(this.selectElement!).on('select2:unselecting', async () => {
			this.dispatchEvent(new Event('unselecting', { bubbles: true }));
		});

		$(this.selectElement!).on('select2:open', (e) => {
			const selectId = e.target.id;

			$(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each((key, value) => {
				value.focus();
			});
		});

		const N_edit = this.querySelector('#edit') as HTMLButtonElement;
		const N_add = this.querySelector('#add') as HTMLButtonElement;

		N_edit.addEventListener('click', async () => {
			let value = this.selectElement!.value;
			let option: HTMLOptionElement | null = null;

			if (this.selectElement!.multiple) {
				value = '';

				const values: { value: string, name: string }[] = [];

				const valuesForm = this.value as string[];

				for (const value of valuesForm) {
					const tmp = this.selectElement!.querySelector(`option[value="${value}"]`);
					values.push({
						value,
						name: tmp?.innerHTML.trim() || ''
						//récuperation du texte qui correspond a l'id pour éviter de faire des requetes
					});
				}

				if (values.length) {
					value = await Alert.prompt('Veuillez sélectionner une valeur', '', {
						type: 'select',
						values,
						cancelColor: 'close-modal',
						confirmColor: 'validate-modal'
					}) as string;
					option = this.selectElement!.querySelector(`option[value="${value}"]`);
				}
			}

			if (value) {
				const data = await ModalManager.getInstance().openWithReturnSelect2Data(this.table, value);

				if (option) {
					option.remove();
				}

				const newOption = new Option(data.text, data.id, true, true);
				$(this.selectElement!).append(newOption).trigger('change');

				///@ts-ignore
				$(this.selectElement!).trigger({
					type: 'select2:select',
					params: {
						data
					}
				});

				this.dispatchEvent(new Event('edit', { bubbles: true }));
			}
		});

		N_add.addEventListener('click', async () => {
			const data = await ModalManager.getInstance().openWithReturnSelect2Data(this.table, undefined, this.select?.refData);

			if (!this.isDisabled) {
				this.addValues([data]);
			}
		});
	}

	public addValues(values: { id: string, text: string }[]) {
		this.cancelEventChange = true;

		for (const data of values) {
			const option = new Option(data.text, data.id, true, true);
			$(this.selectElement!).append(option).trigger('change');

			///@ts-ignore
			$(this.selectElement!).trigger({
				type: 'select2:select',
				params: {
					data
				}
			});
		}

		this.dispatchEvent(new Event('add', { bubbles: true }));
		this.eventEmitter.emit('change', this.value);

		this.cancelEventChange = false;
	}

	public setValues(values: { id: string, text: string }[]) {
		this.form?.setDataByName(this.name, values);
	}

	public on(key: 'change', cb: (value: string | string[]) => void) {
		this.eventEmitter.on(key, cb);
	}

	public static register() {
		customElements.define(Select2.tagName, Select2);
	}
}

export default Select2;
