// CORE
// NODE_MODULE
// TEMPLATE
import T_modal from '@modules/Commands/tpl/modals/customers/listBillingRequest.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import BillingRequestTab from '../../libs/customElement/customers/BillingRequest';

// SERVICE

class ListBillingRequest extends Modal {
	private N_BillingRequestTab: BillingRequestTab | null = null;

	constructor(order: { [key: string]: any }) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_addonTitle = this.element.querySelector('#addon-title') as HTMLElement;

			N_addonTitle.innerHTML = ` - Commande N° ${order.infos.internalNumber} - Montant facturé : ${order.bill.price.formattedValue}`;

			this.N_BillingRequestTab = this.element.querySelector(BillingRequestTab.tagName) as BillingRequestTab;

			this.N_BillingRequestTab.setMode('onlyBill');

			this.N_BillingRequestTab.setParentElement(this.element);

			this.N_BillingRequestTab.setDataOrder(() => {
				return order.rowData;
			});

			this.N_BillingRequestTab.setIsSave(() => {
				return true;
			});

			this.N_BillingRequestTab.setOnGoToBill(() => {
				this.resolve();
			});

			this.N_BillingRequestTab!.data = order.billingRequest;

			this.N_BillingRequestTab.hiddenTabLink();

			this.N_BillingRequestTab!.active();

			this.N_BillingRequestTab!.postInit();
		});
	}

	public getElement(): HTMLElement | null {
		return this.element;
	}
}

export default ListBillingRequest;
