// CORE
// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

// TEMPLATE
import T_modal from '../../../../tpl/modals/customers/editPageFromQuote/editUsers.html';

// LIBS
import Modal from '@libs/Modal';
import agUtils from '@libs/agGrid/french';

// PRINTER
// UTILS
// MODAL
import M_AddUser from '../AddUser';

// CUSTOM_ELEMENT

// SERVICE
import S_Users from '@services/User/UserService';

class EditUsers extends Modal {
	private _gridOptions: GridOptions = {};

	private users: { [key: string]: string } = {};

	constructor(data: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_close = this.element.querySelector('.close') as HTMLButtonElement;
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			const N_addUser = this.element.querySelector('#add-user') as HTMLButtonElement;

			N_close.addEventListener('click', (e: MouseEvent) => {
				this.reject();

				e.stopImmediatePropagation();
				e.stopPropagation();
			});

			await this.initGrid();

			this.convertDataToAggrid(data);

			N_save.addEventListener('click', async () => {
				const res: { [key: string]: any } = {
					manager: { id: '', text: '' },
					purchaseManager: { id: '', text: '' },
					calendarManager: { id: '', text: '' },
					usersInfos: [],
					users: []
				};

				this._gridOptions.api?.forEachNode((node) => {
					const user = {
						id: node.data.user,
						text: this.users[node.data.user]
					};

					if (node.data.manager) {
						res.manager = user;
					}
					if (node.data.purchaseManager) {
						res.purchaseManager = user;
					}
					if (node.data.calendarManager) {
						res.calendarManager = user;
					}
					if (node.data.usersInfos) {
						res.usersInfos.push(user);
					}
					if (node.data.users) {
						res.users.push(user);
					}
				});

				this.resolve({ infos: res });
			});

			N_addUser.addEventListener('click', async () => {
				new M_AddUser().open().then((data) => {
					this._gridOptions.api?.applyTransaction({
						add: [data]
					});
				});
			});
		});
	}

	private async getUser() {
		const res: { [key: string]: any } = {};
		const data = await S_Users.getInstance().getAll();

		for (const item of data) {
			res[item._id] = await S_Users.getInstance().getDisplayRefByID(item._id);
		}

		return res;
	}

	private convertDataToAggrid(data: { [key: string]: any } = {}) {
		data.infos = data.infos || {};
		data.infos.usersInfos = data.infos.usersInfos || [];
		data.infos.users = data.infos.users || [];

		const tmp: { [user: string]: any } = {};

		if (data.infos.manager) {
			tmp[data.infos.manager] = tmp[data.infos.manager] || {};
			tmp[data.infos.manager].manager = true;
		}

		if (data.infos.purchaseManager) {
			tmp[data.infos.purchaseManager] = tmp[data.infos.purchaseManager] || {};
			tmp[data.infos.purchaseManager].purchaseManager = true;
		}

		if (data.infos.calendarManager) {
			tmp[data.infos.calendarManager] = tmp[data.infos.calendarManager] || {};
			tmp[data.infos.calendarManager].calendarManager = true;
		}

		for (const item of data.infos.usersInfos) {
			tmp[item] = tmp[item] || {};
			tmp[item].usersInfos = true;
		}

		for (const item of data.infos.users) {
			tmp[item] = tmp[item] || {};
			tmp[item].users = true;
		}

		const res: { [key: string]: any }[] = [];

		for (const user in tmp) {
			res.push({
				user,
				...tmp[user]
			});
		}
		this._gridOptions.api?.setRowData(res);
	}

	private async initGrid() {
		this.users = await this.getUser();

		this._gridOptions = agUtils.french<GridOptions>({
			rowData: [],
			localeText: { noRowsToShow: 'Aucun intervenant' },
			columnDefs: [
				{
					headerName: 'Utilisateur',
					field: 'user',
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					cellRenderer: (params) => {
						return this.users[params.value];
					}
				},
				{
					headerName: 'Responsable Affaire',
					field: 'manager',
					cellRenderer: (params) => {
						return this.cellRenderer(params, true);
					}
				},
				{
					headerName: 'Responsable Achat',
					field: 'purchaseManager',
					cellRenderer: (params) => {
						return this.cellRenderer(params, true);
					}
				},
				{
					headerName: 'Responsable planning',
					field: 'calendarManager',
					cellRenderer: (params) => {
						return this.cellRenderer(params, true);
					}
				},
				{
					headerName: 'Personnes informées',
					field: 'usersInfos',
					cellRenderer: (params) => {
						return this.cellRenderer(params);
					}
				},
				{
					headerName: 'Intervenants',
					field: 'users',
					cellRenderer: (params) => {
						return this.cellRenderer(params);
					}
				}
			],
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			}
		});

		new Grid(this.element.querySelector('#grid') as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private cellRenderer(params: any, uniq: boolean = false) {
		const field = params.colDef.field;

		const N_checkbox = document.createElement('div');
		N_checkbox.classList.add('text-center');

		if (params.value) {
			N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
		} else {
			N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
		}

		N_checkbox.addEventListener('click', () => {
			const value = !params.value;

			if (uniq) {
				params.api.forEachNode((node: any) => {
					node.setDataValue(field, false);
				});
			}

			params.node.setDataValue(field, value);

			if (field === 'manager') {
				this.updateButtonSave();
			}

			if (value) {
				N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
			} else {
				N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
			}
		});

		return N_checkbox;
	}

	private updateButtonSave() {
		let findManager = false;

		this._gridOptions.api?.forEachNode((node) => {
			if (node.data.manager) {
				findManager = true;
			}
		});

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !findManager;
	}
}

export default EditUsers;
